import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SparklineChartOptions } from '../../../../../core/_base/layout';

@Component({
	selector: 'kt-widget26',
	templateUrl: './widget26.component.html',
	styleUrls: ['./widget26.component.scss']
})

export class Widget26Component implements OnInit {

	@Input() value: string | number;
	@Input() desc: string;
	@Input() options: SparklineChartOptions;

	displayValue: number = 0;

	constructor(private cdRef: ChangeDetectorRef) {
	}

	// counterInterval: any = setInterval(() => {
	// 	this.displayValue++;
	// 	if (this.displayValue == this.value)
	// 		clearInterval(this.counterInterval);

	// 	this.cdRef.detectChanges();
	// }, 10);

	ngOnInit() {

	}

}
