import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../../_services/common.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FormControl } from '@angular/forms';
import { ExportAsConfig, ExportAsService, SupportedExtensions } from 'ngx-export-as';

@Component({
	selector: 'app-base',
	template: '',
	styles: [],
})
export class BaseComponent implements OnInit {
	constructor(
		public translationService: TranslateService,
		public commonService: CommonService,
		public exportAsService: ExportAsService
	) { }
	@Input() isEditMode = false;

	date = new Date();
	matSelectFilterCtrl = new FormControl();
	commonFilters = {
		creatorName: '',
		createdBy: '',
		from: null,
		to: null,
		times: '',
		dateTime: { label: '', from: null, to: null },
		matSelectFilter1: null,
		matSelectFilter2: null,
		matSelectFilter3: null,
		matSelectFilter4: null,
		matSelectFilter5: null,
		matSelectFilter6: null,
	};
	dateTimeFilters = [
		{
			label: 'Current day',
			from: new Date(
				this.date.getFullYear(),
				this.date.getMonth(),
				this.date.getDate()
			),
			to: new Date(
				this.date.getFullYear(),
				this.date.getMonth(),
				this.date.getDate()
			),
		},
		{
			label: 'Current week',
			from: new Date(
				this.date.getFullYear(),
				this.date.getMonth(),
				this.date.getDate() - this.date.getDay()
			),
			to: new Date(
				this.date.getFullYear(),
				this.date.getMonth(),
				this.date.getDate() + (7 - this.date.getDay())
			),
		},
		{
			label: 'Current month',
			from: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
			to: new Date(this.date.getFullYear(), this.date.getMonth() + 1, 1),
		},
	];
	ngOnInit(): void { }
	get floatLabels(): string {
		return this.isEditMode ? 'always' : 'auto';
	}
	clearDate(event) {
		event.value = null;
	}
	exportAsExcel(
		displayedColumns: { key: string; value: string }[],
		dataSource: MatTableDataSource<any>
	) {
		let columns = [];
		if (displayedColumns != null) {
			(displayedColumns || []).forEach((x) => {
				if (x.key != 'actions')
					columns.push({
						key: x.key,
						title: this.translationService.getTranslation(x.value),
						display: true,
					});
			});
		} else {
			let probs = (Object.getOwnPropertyNames(dataSource.filteredData[0] || new Object()));
			probs.forEach((x) => {
				columns.push({
					key: x,
					title: this.translationService.getTranslation(x),
					display: true,
				});
			});
		}

		this.commonService.ExporteAsExcel(
			dataSource.filteredData,
			'Exported_Data',
			columns
		);
	}
	exportAsPdf(
		displayedColumns: { key: string; value: string }[],
		dataSource: MatTableDataSource<any>
	) {
		{
			const doc: any = new jsPDF();
			let lang = this.translationService.currentLang;
			var prepare = [];
			(dataSource.filteredData || []).forEach((e) => {
				var tempObj = [];
				var newObj = [];
				displayedColumns
					.map((x) => x.key)
					.filter((x) => x != 'actions')
					.forEach((x) => {
						tempObj.push(e[x]);
					});

				if (lang == 'ar') {
					newObj = tempObj.reverse();
				} else {
					newObj = tempObj;
				}
				prepare.push(newObj);
			});
			doc.addFont('/assets/css/fonts/arial.ttf', 'arial', 'normal');
			doc.setFont('arial');
			let headObj = [];
			displayedColumns.forEach((x) => {
				if (x.key != 'actions')
					headObj.push(this.translationService.getTranslation(x.value));
			});

			var newHeadObj = [];
			if (lang == 'ar') {
				newHeadObj = headObj.reverse();
			} else {
				newHeadObj = headObj;
			}
			var head = [newHeadObj];
			if (lang == 'ar') {
				head = head.reverse();
			}
			doc.autoTable({
				head: head,
				body: prepare,
				styles: { font: 'arial', halign: 'center' },
			});
			doc.save('Exported_Data' + '.pdf');
		}
	}
	filterSelection(
		allData: any[],
		filteredDataField: string,
		searchField: string,
		keyword: string
	) {
		if (allData) {
			if ((keyword || '').length > 0) {
				this.commonFilters[filteredDataField] = allData.filter((x) =>
					(x[searchField] as string)
						.toLocaleLowerCase()
						.includes(keyword.toLocaleLowerCase())
				);
			} else {
				this.commonFilters[filteredDataField] = allData;
			}
		}

	}
	exportAsConfig: ExportAsConfig = {
		type: 'xlsx', // the type you want to download
		elementId: 'exportableTable', // the id of html/table element
	};
	exportHtml(type: SupportedExtensions, elementIdOrContent: string) {
		// download the file using old school javascript method
		this.exportAsConfig.type = type;
		this.exportAsConfig.elementId = elementIdOrContent;
		this.exportAsService.save(this.exportAsConfig, 'file');
		// get the data as base64 or json object for json type - this will be helpful in ionic or SSR
		this.exportAsService.get(this.exportAsConfig).subscribe((content) => {
			console.log(content);
		});
	}
}
