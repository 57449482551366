// Angular
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../../../app/_services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    async canActivate() {
        
        let isAuthenticated = await this.authService.isAuthenticated();

        if (isAuthenticated) {
            return true;
        }
        else {
            this.router.navigateByUrl('/auth/login');
            return false;
        }
    }
}
