// Arabic
export const locale = {
	lang: "ar",
	data: {
		TRANSLATOR: {
			SELECT: "اختر اللغة",
		},
		MENU: {
			NEW: "جديد",
			ACTIONS: "الإجراءات",
			CREATE_POST: "إنشاء منشور جديد",
			PAGES: "الصفحات",
			FEATURES: "الخصائص",
			APPS: "التطبيقات",
			DASHBOARD: "لوحة التحكم",
			USER_MANAGEMENT: "إدارة المستخدمين",
			USERS: "المستخدمون",
			ACCESS_CONTROL: "امكانية الوصول",
			GROUPS: "مجموعات المستخدمين",
			Entities: "جزئيات النظام",
			SYSTEM_SETTINGS: "إعدادات النظام",
			BRANCHES: "الفروع",
		},
		COMMON: {
			HTTP_RESPONSE_MESSAGE: {
				CONFLICT_VALUE: "قيمة موجودة مسبقا ",
				ERROR: "حدثت مشكلة الرجاء المحاولة مرة أخرى أو الاتصال بمدير النظام",
				BAD_REQUEST: "طلب سيئ: يرجى إدخال الحقل المطلوب بشكل صحيح.",
				UNAUTHORIZED: "غير مصرح",
			},
			COLUMN: {
				ID: "Id",
				NAME: "الاسم",
				NAME_AR: "الاسم العربي",
				IS_ACTIVE: "تفعيل",
				IS_GROUP: "مجموعة",
				GROUPBY: "التصنيف",
				GROUPBY_AR: "التصنيف بالعربية",
				COMPANYNAME: "اسم الشركة",
				BRANCHNAME: "الفرع",
				TRACKNAME: "المسار",
				COUNTRYNAME: "البلد",
				UNITNAME: "الوحدة",
				COURSE: "المادة",
				GRADE: "الصف",
				ISO: "iso",
				THREELETTERISOCODE: "رقم ios الثلاثي",
				LESSONNAME: "الدرس",
				DATE: "التاريخ",
				CREATE_DATE: "تاريخ الإنشاء",
				TITLE: "العنوان",
				TITLE_EN: "العنوان",
				CREATED_BY: "اضيفت بواسطة",
				STATE: "الحالة",
				SCHOOL: "المدرسة",
				SECTION: "الفصل",
				TEACHER: "المعلم",
				PERIOD_TIME: "وقت الحصة",
				TYPE: "النوع",
				IS_PRIMARY: "رئيسي",
			},
			ACTION: {
				SEARCH: "بحث",
				ADD: "إضافة",
				EDIT: "تعديل",
				LIST: "الجميع",
				VIEW: "عرض",
				CREATE: "إنشاء",
				UPDATE: "تعديل",
				DELETE: "حذف",
				ASSIGN: "إسناد",
				PREVIEW: "عرض",
				PRINT: "طباعة",
				DETAILS: "تفاصيل",
				DRAG: "سحب",
				FILTER: "تصفية",
				RETURN: "رجوع",
				SEND: "إرسال",
				FULL_MARK: "الدرجة الكاملة",
				EXPORT_TO_EXCEL: "تصدير ملف excel",
				CONFIRM: "اعتماد",
				SAVE: "حفظ",
				CANCEL: "الغاء",
				SELECT: "اختر",
				INSERT: "إضافة",
			},
			DAYS: {
				SUNDAY: "الاحد",
				MONDAY: "الاثنين",
				TUESDAY: "الثلاثاء",
				WEDNESDAY: "الاربعاء",
				THURSDAY: "الخميس",
				FRIDAY: "الجمعة",
				SATURDAY: "السبت",
			},
			ALL: "الكل",
			SEARCH: "بحث",
			SEARCH_ALL_FIELD: "بحث في جميع الحقول",
			CREATE: "إنشاء",
			EDIT: "تعديل",
			DELETE: "حذف",
			ANALYZE: "تحليل",
			ASSIGN: "إضافة إلى هذه المجموعة",
			SAVE: "حفظ",
			CANCEL: "إلغاء",
			CLOSE: "إغلاق",
			QUIT: "خروج",
			ACTIONS: "الإجراءات",
			ACTIVE: "نشط",
			PASSIVE: "غير نشط",
			RESTORE: "استرجاع",
			YES: "نعم",
			NO: "لا",
			NUM: "الرقم",
			DELETED: "محذوف",
			UNDELETED: "غير محذوف",
			BACK: "الرجوع",
			CANCEL_CHANGES: "إلغاء التعديلات",
			SAVE_CHANGES: "حفظ التعديلات",
			SAVE_AND_SENT: "حفظ و إرسال",
			HELLO: "مرحبا ",
			SIGN_OUT: "تسجيل الخروج",
			DELETE_DESCRIPTION: "هل انت متأكد من حذف هذا السجل بشكل دائم ؟",
			DELETE_WAIT_DESCRIPTION: "جاري الحذف ...",
			CONFIRM_WAIT_DESCRIPTION: "يتم اعتماد السجل...",
			DELETE_SUCCESS_MESSAGE: "تم حذف السجل بنجاح",
			PLEASE_WAIT: "يرجى الانتظار ...",
			ADDED_SUCCESSFULLY: "تم إضافة السجل بنجاح",
			UPDATED_SUCCESSFULLY: "تم تحديث بيانات السجل بنجاح",
			DELETED_SUCCESSFULLY: "تم حذف السجل بنجاح",
			MANDATORY_FIELD: "حقل إلزامي",
			FORM_ERROR_MESSAGE: "الرجاء التحقق من الحقول الإلزامية ومن ثم المحاولة مرة أخرى",
			NO_PERMISSON_MESSAGE: "للأسف! ليس لديك صلاحية لهذا الجزء.",
			ISDELETED: "هل تم حذفه",
			MULTIPLE: "هل متعدد",
			ISLIST: "هل قائمة",
			IS_REQUIRED: "إلزامي",
			CHANGE_DETECTION: "تم إيجاد تغيير",
			QUIT_DESCRIPTION_MESSAGE: "تم إيجاد تغيير هل انت متاكد من الخروج؟",
			PLEASE_ENTER: "الرجاء إدخال ",
			PLEASE_SELECT: "الرجاء اختيار",
			EMAIL_FORMAT: "يرجى إدخال صيغة البريد الإلكتروني.",
			URL_FORMAT: "صيغة الرابط غير صحيحة",
			NEW_RECORD: "إضافة",
			EDIT_RECORD: "تعديل",
			EXPORT: "تصدير",
			ALREADY_EXIST_RECORD: "السجل موجود مسبقاً",
			NO_MATCHES: "لم يتم العثور على تطابق",
			MISSING_VALUE: "قيم مفقودة",
			MAXIMUM_LENGTH: "أكبر عدد من الحروف ",
			MORE_ACTIONS: "المزيد من الإجراءات",
			RECORD_LISTED: "تم اضهار السجلات",
			ALL_FIELDS_MANDATORY: "جميع الحقول إلزامية",
			PLEASE_ENTER_INPUT: "الرجاء إدخال الحقول المطلوبة",
			START_DATE_SHOULD_BE_GREATER_THAN_NOW_DATE: "يجب أن يكون تاريخ البداية أكبر من تاريخ اليوم",
			END_DATE_SHOULD_BE_GREATER_THAN_END_DATE: "يجب أن يكون تاريخ النهاية أكبر من تاريخ البداية",
			NOTES: "ملاحظات",
			MAKE_SURE_ALL_VALUES_ARE_ENTERED_CORRECTLY: "تأكد من إدخال جميع القيم بشكل صحيح",
			SUCCESSFULLY_DONE: "تمت العملية بنجاح",
			CREATEDON: "أضيف بتاريخ",
			CREATEDBY: "أضيف بواسطة",
			FINALMARK: "الدرجة النهائية",
			SCHOOL: "المدرسة",
			BRANCH: "الفرع",
			TEACHER: "المعلم",
			VISIT_DATE: "تاريخ الزيارة",
			GRADE: "الصف",
			COURSE: "المادة",
			TOPIC: "الموضوع",
			NUMBER_OF_STUDENTS: "عدد الطلاب",
			VERY_CLEAR: "واضح جدا",
			FAIRLY_STRAIGHTFORWARD: "واضح إلى حد ما",
			NOT_NOTICED: "غير ملاحظ",
			NOTES_AND_RECOMMENDATIONS: "ملاحظات وتوصيات",
			CLEAR: "واضح",
			PERIOD: "الحصة",
			NUMBER_OF_ABSENTEES: "عدد الغياب",
			EXPAND_ALL: "توسعة الكل",
			COLLAPSE_ALL: "تصغير الكل",
			EMPTY: "فارغ",
			VALIDATION: {
				LESS_THAN_EQUALTO: "أقل من أو يساوي",
			},
			DATE_AND_TIME: "الوقت والتاريخ",
			TRUE: "صح",
			FALSE: "خطأ",
			IS_TRUE: "إجابة صحيحة؟",
			OF: "من",
			ADD_ROW: "إضافة سجل",
			ARABIC_NAME: "الاسم العربي",
			PHONE_NUMBER: "رقم الجوال",
			SELECT_CONDITION_TO_LIST_DATA: "يرجى الاختيار حتى يتم جلب البيانات",
			DATA_NOT_FOUND: "لا يوجد بيانات",
			NOT_FOUND: "لم يتم العثور على البيانات",
			ADDED_BY: "أضيف بواسطة",
			ENROLLMENT_DATE: "تاريخ الالتحاق",
			TOTAL: "المجموع",
			WEEK: "الاسبوع",
			GOOD: "جيد",
			MIDDLE: "متوسط",
			EXCELLENT: "ممتاز",
			WEAK: "ضعيف",
			PERFORMED: "تم الانتهاء",
			NOT_PERFORMED: "لم يتم",
			REASON: "السبب",
			WORK: "العمل",
			PROGRAM: "البرنامج",
			WEIGHT: "الوزن",
			FINAL: "نهائي",
			FIRST_NAME: " الاسم الأول بالانجليزي",
			STUDENT_NAME_EN: " الاسم بالانجليزي",
			SURNAME: "الاسم الاخير بالانجليزي",
			MIDDLE_NAME: "اسم الاب بالانجليزي",
			FAMILY_NAME: "اسم العائلة  بالانجليزي",
			BIRTH_CITY: "مدينة الولادة",
			ADMISSION_DATE: "تاريخ الالتحاق",
			LANGUAGE: "اللغة",
			VALUE_SHOULD_SMALL_THAN: "القيمة المدخلة يجب أن تكون أقل من @param",
			CONFIRMATION: "تأكيد",
			ARE_YOU_SURE_YOU_WANT_TO_CONFIRM: "هل أنت متأكد من اتمام العملية المطلوبة؟",
			PLEASE_FILL_MANDATORY_FIELDS: "الرجاء تعبئة كافة البيانات",
			APPROVE: "قبول",
			STAGES: {
				KINDERGARTEN: "روضة",
				PRIMARY: "ابتدائي",
				INTERMEDIATE: "متوسط",
				HIGH: "ثانوي",
				UNDEFIEND: "غير محدد",
			},
			PICTURE: "صورة الملف الشخصي",
			CHOOSE_IMAGE: "اختر صورة",
			IS_DEFAULT: "افتراضي",
			DATE_OF_BIRTH_MUST_BE_IN_THE_PAST: "تاريخ الميلاد يجب أن يكون في الماضي",
			COPY: "نسخ",
		},
		AUTH: {
			GENERAL: {
				OR: "أو",
				SUBMIT_BUTTON: "تأكيد",
				NO_ACCOUNT: "لا تملك حساب ؟",
				SIGNUP_BUTTON: "تسجيل مستخدم جديد",
				FORGOT_BUTTON: "نسيت كلمة المرور",
				BACK_BUTTON: "الرجوع",
				PRIVACY: "الخصوصية",
				LEGAL: "قانوني",
				CONTACT: "تواصل",
				PLEASE_ENTER_USERNAME_AND_PASSWORD: "الرجاء إدخال اسم المستخدم وكلمة المرور",
				LEARNING_MANAGEMENT_SYSTEM: "نظام إدارة التعلم",
				LMS_DESCRIPTION: "نظام إدارة التعلم النهائي للجيل القادم من التعليم.",
				SIGN_UP_LABEL: "لا تملك حسابا حتى الآن؟",
				LOGIN_WITH_SSO: "تسجيل الدخول باستخدام SSO",
			},
			LOGIN: {
				TITLE: "تسجيل دخول حساب",
				BUTTON: "تسجيل الدخول",
			},
			FORGOT: {
				TITLE: "هل نسيت كلمة المرور",
				DESC: "أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور",
				SUCCESS: "تم إعادة تعيين حسابك بنجاح",
			},
			REGISTER: {
				TITLE: "مستخدم جديد",
				DESC: "الرجاء إدخال بياناتك للتسجيل بنجاح",
				SUCCESS: "تم تسجيل حسابك بنجاح",
				PRE_USER_REGISTERED_SUCCESSFULLY: "تم التسجيل بنجاح.<br>تستطيع تسجيل الدخول بعد تأكيد حسابك",
				SIGN_UP_FOR_STUDENT: "تسجيل الدخول كطالب",
				SIGN_UP_FOR_PARENT: "تسجيل الدخول كولي أمر",
				THERE_IS_NO_ASSIGNED_STUDENT: "لم يتم إسناد طلاب",
				ADD_STUDENT: "إضافة طالب",
			},
			INPUT: {
				EMAIL: "البريد الإلكتروني",
				FULLNAME: "الاسم الكامل",
				PASSWORD: "كلمة المرور",
				CONFIRM_PASSWORD: "تأكيد كلمة المرور",
				USERNAME: "اسم المستخدم",
			},
			VALIDATION: {
				INVALID: "{{name}} غير صالح",
				REQUIRED: "{{name}} إلزامي",
				MIN_LENGTH: "{{name}} الحد الادنى من عدد الأحرف {{min}}",
				AGREEMENT_REQUIRED: "قبول الشروط و الاحكام إلزامي",
				NOT_FOUND: "لم يتم العثور على {{name}} المطلوب",
				INVALID_LOGIN: "بيانات غير صحيحة",
				REQUIRED_FIELD: "حقل إلزامي",
				MIN_LENGTH_FIELD: "الحد الادنى من عدد الأحرف:",
				MAX_LENGTH_FIELD: "الحد الاعلى من عدد الأحرف",
				INVALID_FIELD: "الحقول غير صالحة",
				IN_ACTIVE_USER: "هذا المستخدم بحاجة إلى تفعيل",
				PASSWORD_PATTERN: "6 أحرف على الأقل ",
				PASSWORD_NOT_MATCHING: "كلمة المرور غير متطابقة",
			},
		},
		USER_MANAGEMENT: {
			FIELS: {
				USERNAME: "اسم المستخدم",
				PASSWORD: "كلمة المرور",
				CONFIRM_PASSWORD: "تأكيد كلمة المرور",
				GENDER: "النوع",
				ROLE: "الدور",
				FULL_NAME: "الاسم الكامل",
				NATIONALITY: "الجنسية",
				EMAIL_ADDRESS: "البريد الإلكتروني",
				MOBILE_NUMBER: "رقم الجوال",
				SCHOOL_NAME: "اسم المدرسة",
				SCHOOL_NAME_EN: "اسم المدرسة بالانجليزي",
				SCHOOL_NAME_AR: "اسم المدرسة بالعربي",
				FAX: "الفاكس",
				COUNTRY: "البلد",
				CITY: "المدينة",
				MAIN_STREET: "الشارع الرئيسي",
				SUB_STREET: "الشارع الفرعي",
				BUILDING_NUMBER: "رقم المبنى",
				ZIP_CODE: "الرمز البريدي",
				BOX_MAIL: "صندوق بريد",
				ADDRESS: "العنوان",
				SELECT_GENDER: "اختر الجنس",
				SELECT_ROLE: "اختر الدور",
				SELECT_COMPANY: "اختر الشركة",
				SELECT_BRANCH: "اختر الفرع",
				SELECT_TRACK: "اختر المسار",
				SELECT_NATIONALITY: "اختر الجنسية",
				SELECT_COUNTRY: "اختر البلد",
				SELECT_CITY: "اختر المدينة",
				SELECT_PARENT: "اختر ولي الأمر",
				SELECT_SCHOOL: "اختر المدرسة",
				SELECT_SUBJECT: "اختر التخصص",
				SELECT_STAGE: "اختر المرحلة",
				IS_ACTIVE: "تنشيط",
				BIRTH_OF_DATE: "تاريخ الميلاد",
				STATUS: "الحالة",
				GENDERS: "الجنس",
				GROUP: "المجموعة",
				BRANCH: "الفرع",
				PARENT: "ولي الأمر",
				SUBJECT: "المادة",
				STAGE: "المرحلة",
				TRACK: "المسار",
				GRADE: "الصف",
				COURSE: "المادة",
				CLASSES: "الحصص",
				SELECT_JOB: "اختر المسمى الوظيفي",
			},
			USER_ADD_WIZARD: {
				BASIC_INFORMATION: "البيانات الاساسية",
				ADDITIONAL_INFORMATION: "بيانات إضافية",
				LOCATION: "الموقع",
				REVIEW_AND_SUBMIT: "المراجعة و الحفظ",
			},
			OTHER: {
				NEXT_STEP: "الخطوة التالية",
				PREVIOUS: "الخطوة السابقة",
				SUBMIT: "حفظ",
				CHOOSE_DATE: "اختر تاريخًا",
				USER_LIST: "قائمة المستخدمين",
				CREATE_NEW_USER: "إنشاء مستخدم جديد",
				NEW_USER: "مستخدم جديد",
				SEARCH_USER: "البحث عن مستخدم",
				SEARCH_IN_ALL_FIELDS: "بحث في كل المجالات",
				FILTER_BY_STATUS: "تصفية حسب الحالة",
				FILTER_BY_ROLE: "التصفية بحسب الدور",
				FILTER_BY_STUDENT: "التصفية بحسب الطالب",
				FILTER_BY_USER: "التصفية بحسب المستخدم",
				USER: "المستخدم",
				MALE: "ذكر",
				FEMALE: "أنثى",
				REVIEW_YOUR_DETAILS_AND_SUBMIT: "مراجعة التفاصيل الخاصة بك وتقديم",
				BACK_TO_THE_USERS_LIST: "العودة لقائمة المستخدمين",
				SAVE_AND_CONTINUE: "حفظ ومتابعة",
				EDIT_USER: "تحرير المستخدم",
				ANOTHER_TAB: "علامة تبويب أخرى",
				PASSWORD_DONT_MATCH: "للأسف! كلمة المرور غير متطابقة.",
				CHANGE_PASSWORD: "تغيير كلمة المرور",
				ASSIGNED_SECTIONS: "الجزئيات المختارة",
				SELECT_SECTION: "اخر الفصل",
				SECTIONS: "الفصول",
				ASSIGNMENTS: "الإسنادات",
				FILTER_BY_GROUP: "البحث حسب المجموعة",
				FILTER_BY_KEY: "البحث حسب المفتاح",
				ONE_SECTION_FOR_STUDENT: "لا يجوز إسناد الطالب لاكثر من فصل بنفس الوقت",
				SELECT_LIST: "اختر قائمة لعرضها",
				SELECT_CLASS: "اختر الحصة",
				BIRTHDAY_DATE_BIG_VARIFICATION: "تاريخ الميلاد غير مقبول",
				FILTER_BY_BRANCH: "التصفية بحسب الفرع",
				SELECT_USER: "اختر مستخدم",
				PROFILE_IMAGE_UPLOAD_MESSAGE: "Click or Drag and Drop!",
				FILTER_BY_GENDER: "التصفية بالجنس",
				AUTO_COMPLETE_HINT: "أدخل 3 أحرف على الأقل للبحث",
				STUDENT_DETAILS: "تفاصيل الطالب",
				STUDENT_DETAILS_EN: "تفاصيل الطالب بالانجليزي",
			},
			STUDENT_DETAILS: {
				IDENTITY_NO: "السجل المدني",
				SELECT_IDENTITY_DATE: "اختر تاريخ انتهاء السجل المدني",
				PASSPORT_NO: "رقم جواز السفر",
				SELECT_BIRTHCOUNTRY: "اختر دولة الميلاد",
				BIRTH_CITY: "مدينة الميلاد",
				BLOOD_TYPE: "فصيلة الدم",
				PREVIOUS_SCHOOL: "المدرسة السابقة",
				HOME_OWNED: "ملكية المنزل",
				HEALTH_STATUS: "الحالة الصحية",
				HEALTH_STATUS_RECO: "توصيات الحالة الصحية",
				EDUCATIONAL_BEHAVIOR: "السلوك / الحالة التعليمية",
				EDUCATIONAL_BEHAVIOR_RECO: "توصيات السلوك / الحالة التعليمية",
				HOOBIES_AND_TALENTS: "الميول والهوايات / القدرات والمواهب",
				HOOBIES_AND_TALENTS_RECO: "توصيات الميول والهوايات / القدرات والمواهب",
				EXPECTATION_ACHIEVEMENT: "التوقعات التحصيلية",
				EXPECTATION_ACHIEVEMENT_RECO: "توصيات التوقعات التحصيلية",
				IDENTITY_NO_IS_REQUIRED: "السجل المدني مطلوب",
			},
		},
		ACCESS_CONTROL: {
			ADD_ACCESS_CONTROL: "إضافة اماكنية الوصول",
			EDIT_ACCESS_CONTROL: "تعديل امكانية الوصول",
			PARENT: "المستوى الاعلى",
			ROOT: "الجذر",
			ASSING_ENTITIES: {
				ENTITIES: "الجزئيات",
				ADD_ENTITY: "إضافة جزئية جديدة",
				FILTER_BY_VIEW: "تصفيه حسب امكانية العرض",
				FILTER_BY_CREATE: "تصفيه حسب امكانية الإنشاء",
				FILTER_BY_UPDATE: "تصفيه حسب امكانية التعديل",
				FILTER_BY_DELETE: "تصفيه حسب امكانية الحذف",
			},
			ASSIGN_GROUPS: {
				ASSIGN_GROUPS: "إسناد مجموعة",
				SELECT_GROUP: "اختر مجموعة",
				ASSIGNED_GROUPS: "المجموعات المسندة",
			},
		},
		GROUPS: {
			GROUP_LIST: "قائمة مجموعات المستخدمين",
			GROUPS: "مجموعات المستخدمين",
			NEW_GROUP: "مجموعة مستخدمين جديدة",
			EDIT_GROUP: "تعديل مجموعة",
			GROUP_NO: "رقم المجموعة",
			GROUP_NAME: "اسم المجموعة",
		},
		LEARNING_TRACK: {
			COLOR_CODE: "اللون",
			TRACK_NAME: "اسم المسار",
			LEARNING_TRACK: "مسار التعلم",
		},
		SCHEDULE_PERIOD: {
			START_TIME: "وقت البداية",
			END_TIME: "وقت النهاية",
			ALREADY_EXIST_TIME: "الوقت مسجل مسبقا",
		},
		BREAD_CRUMB: {
			COMMON: {
				HOME: "الرئيسية",
				LIST: "الجميع",
			},
			EVA_ASSESSMENTS: {
				EVA_ASSESSMENTS_MANAGEMENT: "إدارة التقييمات",
				EVA_ASSESSMENTS_LIST: "قائمة التقييمات",
			},
			USER_MANAGEMENT: {
				EDIT_USER: "تعديل السجل",
				USER_MANAGEMENT: "إدارة المستخدمين",
				USERS: "المستخدمون",
				CREATE_USER: "إنشاء مستخدم",
				USER_PROFILE: "الملف الشخصي",
			},
			ACCESS_CONTROL: {
				ACCESS_CONTROL_MANAGEMENT: "إدارة امكانيات الوصول",
				ACCESS_CONTROL: "امكانية الوصول",
				EDIT_ACCESS_CONTROL: "تعديل امكانية الوصول",
				ACCESS_CONTROL_LIST: "امكانيات الوصول",
			},
			GROUPS: {},
			ENTINTIES: {
				ENTINTIES_MANAGEMENT: "إدارة جزئيات النظام",
				ENTINTIES: "جزئيات النظام",
				EDIT_ENTINTIES: "تعديل جزئيات النظام",
				ENTINTIES_LIST: "جزئيات النظام",
			},
			BRANCHES: {
				BRANCHES_MANAGEMENT: "إدارة الفروع",
				BRANCHES: "الفروع",
				EDIT_BRANCHES: "تعديل بيانات فرع",
				BRANCHES_LIST: "قائمة الفروع",
			},
			COMPANIES: {
				COMPANIES_MANAGEMENT: "إدارة الشركات",
				COMPANIES: "الشركات",
				EDIT_COMPANIES: "تعديل بيانات شركة",
				COMPANIES_LIST: "قائمة الشركات",
			},
			LEARNINGTRACK: {
				LEARNINGTRACK_MANAGEMENT: "إدارة مسارات التعليم",
				LEARNINGTRACKS: "مسارات التعليم",
				EDIT_LEARNINGTRACK: "تعديل مسار التعليم",
				LEARNINGTRACKS_LIST: "قائمة مسارات التعليم",
				SELECT_LEARNING_TRACK: "اختر المسار التعليمي",
				FILTER_LEARNING_TRACK: "تصفية بحسب المسار التعليمي",
			},
			COURSE: {
				COURSE_MANAGEMENT: "إدارة المواد",
				COURSES: "المواد",
				EDIT_COURSES: "تعديل مادة",
				COURSE_LIST: "قائمة المواد",
				REMOVE_COURSE: "حذف مادة",
			},
			COURSE_UNIT: {
				COURSE_UNIT_MANAGEMENT: "إدارة وحدات المناهج",
				UNITS: "الوحدات",
				UNIT: "الوحدة",
				EDIT_UNITS: "تعديل الوحدات",
				COURSE_UNIT_LIST: "قائمة الوحدات",
				ADD_COURSE_UNIT: "إضافة وحدة جديدة",
			},
			COURSE_LESSONS: {
				LESSONS_MANAGEMENT: " إدارة دروس الوحدات",
				LESSONS_LIST: "قائمة الدروس",
				EDIT_LESSONS: "تعديل الدروس",
				ADD_LESSONS: "إضافة الدروس",
			},
			OUTCOME: {
				TITLE: "العنوان",
				TITLE_AR: "العنوان بالعربي",
				ADD_OUTCOME: "إضافة ناتج تعلم جديد",
				ASSIGN_ROLE: "إسناد دور",
				TITLE_EN: "العنوان بالانجليزي",
			},
			QUESTIONBANKS: {
				QUESTIONBANKS_MANAGEMENT: "إدارة بنك الأسئلة",
				QUESTIONBANKS_LIST: "قائمة بنك الأسئلة",
			},
			STAGE: {
				MANAGE_STAGE: "إدارة المراحل",
				STAGE_LIST: "المراحل",
				NEW_STAGE: "مرحلة جديدة",
				EDIT_STAGE: "تعديل مرحلة",
			},
			GRADES: {
				GRADES_MANAGEMENT: "إدارة الصفوف",
				GRADES: "الصفوف",
				EDIT_GRADES: "تعديل الصف",
				GRADES_LIST: "قائمة الصفوف",
				NEW_GRADE: "صف جديد",
				GRADE: "الصف",
			},
			GRADES_SECTION: {
				GRADES_SECTION_MANAGEMENT: "إدارة فصول الصفوف",
				GRADES_SECTION: "فصول الصفوف",
				EDIT_GRADES_SECTION: "تعديل فصل الصف",
				GRADES_SECTION_LIST: "قائمة فصول الصفوف",
				NEW_GRADE_SECTION: "فصل صف جديدة",
			},
			CITIES: {
				CITIES_MANAGEMENT: "إدارة المدن",
				CITIES: "المدن",
				EDIT_CITIES: "تعديل المدينة",
				CITIES_LIST: "قائمة المدن",
			},
			COURSE_UNITS: {
				COURSE_UNITS_MANAGEMENT: "إدارة وحدات المناهج",
				COURSE_UNITS: "وحدات المواد",
				EDIT_COURSE_UNIT: "تعديل الوحدة",
				COURSE_UNITS_LIST: "قائمة الوحدات",
			},
			COUNTRIES: {
				COUNTRIES_MANAGEMENT: "إدارة البلاد",
				COUNTRIES: "البلاد",
				EDIT_COUNTRIES: "تعديل البلد",
				COUNTRIES_LIST: "قائمة البلاد",
			},
			APPLICATIONLOGS: {
				APPLICATIONLOGS_MANAGEMENT: "إدارة سجلات التطبيق",
				APPLICATIONLOGS: "سجلات التطبيق",
				APPLICATIONLOGS_LIST: "قائمة سجلات التطبيق",
				USERID: "المستخدم",
				ACTIONTAKEN: "الإجراء",
				REFERENCEID: "رقم المرجع",
				CATEGORY: "الفئة",
				ACTIONDATE: "تاريخ الإجراء",
			},
			ACLOBJECTS: {
				ACLOBJECTS_MANAGEMENT: "إدارة الإجراءات",
				ACLOBJECTS: "الإجراءات",
				EDIT_ACLOBJECTS: "تعديل الإجراء",
				ACLOBJECTS_LIST: "قائمة الإجراءات",
				OBJECTTYPEID: "النوع",
			},
			SCHEDULE_PERIODS: {
				SCHEDULE_PERIOD: "الحصة",
				EDIT_SCHEDULE_PERIOD: "تعديل الحصة",
				SCHEDULE_PERIOD_LIST: "قائمة الحصص المدرسية",
			},
			SCHEDULE: {
				SCHEDULE: "الجدول",
				EDIT_SCHEDULE: "تعديل الجدول",
				SCHEDULE_LIST: "قائمة الجدول",
				SCHEDULE_ADD: "إضافة جدول",
				PERIODS: "المواعيد",
				SCHEDULE_PREVIEW: "عرض الجدول",
			},
			LMSCONFIGURATIONS: {
				LMSCONFIGURATIONS: "إعدادات تقنية",
				EDIT_LMSCONFIGURATIONS: "تعديل الاعداد التقني",
				LMSCONFIGURATIONS_LIST: "قائمة الإعدادات التقنية",
				CONFIGGROUP: "المجموعة",
				CONFIGKEY: "المفتاح",
				CONFIGVALUE: "القيمة",
				DESCRIPTION: "الوصف",
			},
			COURSE_LESSON: {
				TITLE: "العنوان",
				TITLE_AR: "العناون بالعربي",
				TITLE_EN: "العناون بالانجليزي",
			},
			WFTYPES: {
				WFTYPES: "انواع العمليات",
				WFTYPES_MANAGEMENT: "إدارة انواع العمليات",
				EDIT_WFTYPES: "تعديل انواع العمليات",
				WFTYPES_LIST: "قائمة انواع العمليات ",
				WFSTEPS: "الخطوات",
				DELETE_WFSTEPS: "حذف الخطوة",
				WFSTEPS_LIST: "قائمة الخطوات",
			},
			REQUEST_TEACHERS: {
				NEW_REQUEST: "إنشاء طلب",
				REQUEST_TEACHERS: "طلب معلمين",
				EDIT_REQUEST_TEACHERS: "تعديل طلب المعلم",
				REQUEST_TEACHERS_LIST: "قائمة طلبات المعلمين",
				REQUEST_NAME: "اسم الطلب",
				REQUEST_NAMEAR: "اسم الطلب بالعربي",
				REJECT: "رفض الطلب",
				ACCEPT: "قبول الطلب",
			},
			QUESTION_TYPES: {
				NEW_TYPE: "نوع جديد",
				EDIT_TYPE: "تعديل النوع",
				DELETE_TYPE: "حذف النوع",
				QUESTION_TYPES_LIST: "قائمة انواع الاسئلة",
				QUESTION_TYPES: "انواع الاسئلة",
			},
			QUESTION_CATEGORY: {
				NEW_CATEGORY: "فئة جديدة",
				EDIT_CATEGORY: "تعديل الفئة",
				DELETE_CATEGORY: "حذف الفئة",
				QUESTION_CATEGORY_LIST: "قائمة فئات الاسئلة",
				QUESTION_CATEGORY: "فئات الاسئلة",
			},
			QUIZZ: {
				QUIZZES: "الاختبارات",
				QUIZZ: "اختبار",
			},
			QUIZZ_STATUS: {
				NEW_STATUS: "حالة جديدة",
				EDIT_STATUS: "تعديل الحالة",
				DELETE_STATUS: "حذف الحالة",
				QUIZZ_STATUS_LIST: "قائمة حالات الاختبار",
				QUIZZ_STATUS: "حالة الاختبارات",
			},
			QUIZZ_TYPES: {
				QUIZZ_TYPES_LIST: "قائمة انواع الاختبارات",
				QUIZZ_TYPES: "انواع الاختبارات",
			},

			MESSAGE: {
				MESSAGES: "الرسائل",
				MESSAGE_LIST: "قائمة الرسائل",
			},
			SESSIONS: {
				SESSIONS: "المتواجدون الان",
				SESSIONS_MANAGEMENT: "سجل المتواجدون الان",
				SESSIONS_LIST: "قائمة المتواجدون الان",
				IS_PERMENENT: "دائم",
				TEMPORARY: "مؤقت",
				CREATED_DATE: "تاريخ الإنشاء",
			},
			TEACHER_PROJECT_MANAGER: {
				TEACHER_PROJECT_MANAGER: "مجموعتي",
				TEACHER_PROJECT_MANAGER_MANAGEMENT: "إدارة مجموعتي",
				TEACHER_PROJECTS_MANAGER_MANAGEMENT: "إدارة المجموعات",
				TEACHER_PROJECT_MANAGER_LIST: "قائمة المعلمين",
				ASSIGN_STUDENTS: "إسناد طالب",
				GROUP: "المجموعة",
				SELECT_STUDENT: "اختر طالب",
				ASSIGNED_STUDENTS: "الطلاب المسندين",
				PROJECT_MANAGER: "مدير المشروع",
				CREATE_GROUP: "إنشاء مجموعة",
				EDIT_GROUP: "تعديل مجموعة",
			},
			PLANS: {
				PLANS: "الخطط",
				PLANS_LIST: "قائمة الخطط",
				PLANS_EDIT: "تعديل الخطة",
				PLANS_MANAGEMENT: "إدارة الخطط",
				GOALS: "الهدف",
				NOTES: "الملاحظات",
				START_DATE: "تاريخ البداية",
				APPROVED: "مقبول",
				REJECTED: "مرفوض",
				TERM: "الفصل الدراسي",
			},
			ACTIVITY: {
				ACTIVITY: "النشاطات",
				ACTIVITIES_LIST: "قائمة النشاطات",
				ACTIVITIES_EDIT: "تعديل النشاط",
				ACTIVITIES_MANAGEMENT: "إدارة النشاطات",
			},
			QUIZES_AND_HOMEWORK: {
				QUIZES_AND_HOMEWORK_MANAGEMENT: "إدارة الاختبارات و الواجبات",
				QUIZES_AND_HOMEWORK: "الاختبارات و الواجبات",
				QUIZES_AND_HOMEWORK_LIST: "قائمة الاختبارات و الواجبات",
				ADD_QUIZES_AND_HOMEWORK: "إضافة اختبار / واجب",
				EDIT_QUIZES_AND_HOMEWORK: "تعديل الاختبار / الواجب",
				PREVIEW_QUIZES_AND_HOMEWORK: "استعراض",
				ANALYZE_QUIZ_RESULT: "تحليل النتائج",
				OTHER_STUDENTS: "طلاب اخرون",
			},
			LESSON_PREPARATIONS: {
				LESSON_PREPARATIONS_MANAGEMENT: "إدارة تحضير الدروس",
				LESSON_PREPARATIONS: "تحضير الدروس",
				LESSON_PREPARATIONS_LIST: "قائمة تحضير الدروس",
				ADD_LESSON_PREPARATIONS: "إضافة تحضير درس",
				EDIT_LESSON_PREPARATIONS: "تعديل تحضير الدرس",
				VIEW_LESSON_PREPARATIONS: "عرض تحضير الدرس",
				VIRTUAL_CLASS: "الحصص الافتراضية",
			},
			SCHOOL_YEARS: {
				SCHOOL_YEARS_MANAGEMENT: "إدارة السنوات الدراسية",
				SCHOOL_YEARS: "السنوات الدراسية",
				SCHOOL_YEAR: "السنة الدراسية",
				SCHOOL_YEARS_LIST: "قائمة السنوات الدراسية",
				ADD_SCHOOL_YEARS: "إضافة سنة الدراسية",
				EDIT_SCHOOL_YEARS: "تعديل السنة الدراسية",
			},
			ATTENDANCE: {
				ATTENDANCE: "الحضور و الغياب",
				ATTENDANCE_LIST: "قائمة الحضور و الغياب",
				SCHOOL: "المدرسة",
				CLASS: "الصف",
				COURSE: "المادة",
				SECTION: "الفصل",
			},
			NOTIFICATIONS: {
				NOTIFICATIONS_MANAGEMENT: "إدارة الإشعارات",
				NOTIFICATIONS_LIST: "قائمة الإشعارات",
			},
		},
		ENTITIES: {
			ORDER: "الترتيب",
			MENU_URL: "المسار",
			IMAGE_PATH: "الصورة",
			GROUP_IMAGE_PATH: "الصورة الاساسية",
			SELECT_PARENT: "اختر التبويب الرئيسي",
		},
		STAGES: {
			STAGE: "المرحلة",
			STAGES: "المراحل",
			SELECT_STAGE: "اختر مرحلة",
			PLEASE_SELECT_COURSE: "من فضلك اختر مادة",
			FILTER_BY_STAGES: "التصفية بحسب المرحلة",
		},
		GRADE: {
			SELECT_GRADE: "اختر الصف",
			ASSIGN_COURCE: "إسناد مادة",
			ASSIGNED_COURSES: "المواد المسندة",
			PLEASE_SELECT_COURSE: "الرجاء اختيار المادة",
			FILTER_BY_GRADE: "التصفية بحسب الصف",
		},
		COURSES: {
			SELECT_COURSE: "اختر المادة",
			ASSIGNED_UNITS: "الوحدات المسندة",
			ASSIGN_UNIT: "إسناد وحدة",
		},
		QUIZZES: {
			SELECT_QUIZZES: "اختر الاختبار",
		},
		TERM: {
			TERM: "الفصل الدراسي",
			SELECT_TERM: "اختر الفصل",
			FIRST_TERM: "الفصل الأول",
			SECOND_TERM: "الفصل الثاني",
			THIRD_TERM: "الفصل الثالث",
		},
		SCHEDULE: {
			SCHOOL: "المدرسة",
			YEAR: "السنة",
			SCHEDULE_DETAIL: "تفاصيل الجدول",
			SELECT_PERIOD: "اختر المدة",
			ASSIGN_PERID: "إسناد مدة",
			SELECT_TEACHER: "اختر معلم",
			TIME_VALIDATION_MESSAGE: "يجب أن يكون وقت النهاية بعد وقت البداية",
			SELECT_GRADE_SECTION: "اختر فصل الصف",
			PERIODS: "المواعيد",
			SCHEDULE_PREVIEW: "معاينة الجدول",
			NOT_EXIST_SCHEDULE_PERIOD: "لا يوجد اي فترات معرفة مسبقا الرجاء إنشاء فترات جديدة!",
		},
		COURSE_LESSON: {
			COURSE_LESSON: "الدرس",
			ASSIGN_COURSE_LESSON: "إضافة درس",
			ASSIGNED_COURSE_LESSONS: " الدروس المضافة",
			SELECT_LESSON: "اختر الدرس",
		},
		COURSE_UNIT: {
			SELECT_COURSE_UNIT: "اختر الوحدة",
		},
		OUTCOME: {
			OUTCOMES: "نواتج التعلم",
			ASSIGN_OUTCOME: "إسناد نواتج التعلم",
			ASSIGNED_OUTCOMES: "نواتج التعلم المسندة",
			SELECT_OUTCOME: "اختر ناتج التعلم",
		},
		QUESTION_BANK: {
			SELECT_COGNITIVE_LEVEL: "اختر المستوى المعرفي",
			SELECT_QUESTION_BANK_TYPE: "اختر نوع السؤال ",
			SELECT_QUESTION_CATEGORY: "اختر تصنيف السؤال",
		},
		WFSTEPS: {
			ALL_FIELDS_REQUIRED: "يجب تعبئة جميع حقول الخطوة لإضافتها",
		},
		REQUEST_TEACHERS: {
			INAPPROPRIATE_REQUEST: "لا يمكن طلب معلم من نفس المدرسة",
			REQUEST_EXIST: "تم تقديم طلب لهذا المعلم مسبقا",
			SEND: "إرسال الطلب",
			SENT: "صادر",
			RECEIVED: "وارد",
			ACCEPTED: "تم قبول الطلب",
			REJECTED: "تم رفض الطلب",
		},
		MESSAGE: {
			NEW_MESSAGE: "رسالة جديدة",
			INBOX: "صندوق الوارد",
			DRAFT: "المسودات",
			SENT: "صندوق الصادر",
			TRASH: "سلة المهملات",
			RELOAD_LIST: "إعادة تحميل القائمة",
			DELETE_MESSAGE: "حذف الرسالة",
			SENT_MESSAGE: "الرسائل المرسلة",
			SAVE_AS_DRAFT: "حفظ كمسودة",
			ENTER_INSTRUCTIONS: "الرجاء إدخال التعليمات",
			ATTACHMENT_CONTEXT: "إضافة مرفقات, اسحب المرفقات أو اضغط هنا!",
			CLICK_TO_SEE_UPLOADED_FILE: "اضغط هنا لفتح الملف",
			OR: "أو",
			CLICK_TO_REMOVE: "اضغط هنا لمسحه",
			BACK_TO_LIST: "العودة إلى القائمة",
			LOAD_MORE_MESSAGE: "تحميل المزيد من الرسائل",
			MESSAGES_LOADED: "تم تحميل الرسائل",
			QUESTION_TYPE: "نوع السؤال",
		},
		TEACHER_PROJECT_MANAGER: {
			ASSIGNED_TEACHERS: "المعلمين المسندين",
			FILL_ALL_FIELDS: "املأ جميع الحقول",
		},
		ACTIVITY: {
			DATE_AND_TIME: "التاريخ و الوقت",
			PROGRAM_NAME: "البرنامج",
			PLACE: "المكان",
			REQUIRMENTS: "المتطلبات",
			NEED_AGREE: "تحتاج موافقة",
			PUBLISHED: "معلن",
			ACTIVITY_FORM: "نموذج",
			PLAN_NAME: "الخطة",
		},
		QUIZES_AND_HOMEWORK: {
			TITLE: "العنوان",
			ATTEMP_NO: "عدد المحاولات المتاحة",
			START_DATE: "تاريخ البداية",
			END_DATE: "تاريخ النهاية",
			PERIOD_IN_MINUTES: "زمن الاختبار (بالدقائق)",
			AVAILABLE: "متوفر",
			NOT_AVAILABLE: "غير متوفر",
			CLOSED: "مغلق",
			QUIZ: "اختبار",
			FINAL_EXAM: "اختبار نهائي",
			QUIZZES: "اختبار",
			ALTERNATIVE_QUIZZES: "اختبار بديل",
			HOMEWORK: "واجب",
			HOMEWORKS: "واجب",
			QUESTIONS: "الاسئلة",
			RESULTS: "النتائج",
			ALL_ATTEMPTS: "جميع المحاولات",
			IMPORT_FROM_QUESTION_BANK: "استيراد من بنك الاسئلة",
			QUESTION_BANK: "بنك الاسئلة",
			SECTION: "الفصل",
			FULL_MARK: "الدرجة العظمى",
			COURSE: "المادة",
			STATUS: "الحالة",
			PERIOD: "زمن الاختبار بالدقائق",
			START_DATE_TIME: "تاريخ و وقت البداية",
			END_DATE_TIME: "تاريخ و وقت النهاية",
			COURSE_NAME: "اسم المادة",
			TYPE: "النوع",
			QUESTION: "سؤال",
			ANSWER: "جواب",
			STUDENT: "الطالب",
			QUIZ_TITLE: "عنوان الاختبار",
			REMAINING_PERIOD: "الوقت المتبقي mm:ss",
			FINISHED: "تم الانتهاء",
			IN_PROGRESS: "في تقدم",
			MM_SS: "mm:ss",
			TOTAL_MARK: "الدرجة الكاملة",
			QUIZ_SUBMISSION: "تقديم الاختبار",
			QUIZ_SUBMISSION_INFO: "معلومات تقديم الاختبار",
			CORRECT_ANSWER: "جواب صحيح",
			WRONG_ANSWER: "جواب غير صحيح",
			STUDENT_ANSWER: "إجابة الطالب",
			BEST_ANSWER: "الإجابة النموذجية",
			SUB_QUESTION: "سؤال فرعي",
			FIRST_PAIR: "العمود الأول",
			SECOND_PAIR: "العمود الثاني",
			MAX_MARK: "الدرجة الكاملة",
			OPTION: "خيار",
			IS_BLANK: "فراغ",
			NEW_QUESTION: "سؤال جديد",
			ASSIGNED_QUESTIONS: "الأسئلة المسندة",
			EDIT_QUESTION: "تعديل سؤال",
			EDIT_ANSWER: "تعديل إجابة",
			ADD_OPTION: "إضافة خيار",
			REMAINING_ATTEMPT: "المحاولات المتبقية",
			MISSING_SKILLS: "المهارات المفقودة",
			TOTAL: "اجمالي",
			STUDENT_NAME: "اسم الطالب",
			Q: "س",
			QUIZ_PREVIEW_DENIED_MESSAGE: "لا تستطيع الاطلاع على النتيجة قبل وقت الانتهاء",
			FOLLOW_UP: "متابعة",
			SUMMARY_OF_QUIZ: "ملخص النتائج",
			HIDE_MARK: "اخفاء الدرجات",
			GAT_QUIZ: "اختبار أساس وتمكين",
		},
		LESSON_PREPARATIONS: {
			EDUCATOR: "المعلم",
			PROCEDURE: "الإجراء",
			STRATIGY: "الخطة",
			SELECT_YEAR: "اختر سنة",
			FOLLOW_INSTRUCTIONS: "اتبع التعليمات الموضحة في الفيديو بالضغط",
			HERE: "هنا",
			PRIOR_KNOWLADGE: "الحقبنة",
			KNOW_AND_DO: "التهيئة والتركيز",
			PROCEDURAL_STEPS: "خطوات إجرائية للدرس",
			LEARNING_OUTCOMES: "نواتج التعلم",
			LEARNING_OUTCOME: "ناتج التعلم",
			TEACHING_STRATIGIES: "استراتيجيات التعلم",
			RESOURCES_NEEDED: "وسائط التعلم",
			LESSON_FILES: "ملفات الدرس",
			LESSON_LINKS: "روابط الدرس",
			LESSON_PROCEDURES: "إجراءات الدرس",
			PERIOD_IN_MINUTES: "المدة بالدقائق",
			MUTE_PARTICIPANTS: " كتم جميع المشاركين",
			VIEWERS: "المطلعون",
			VCLASS_VIEWRS: "حضور الحصة الافتراضية",
			ATTENDEES: "الحضور",
			FORMATIVE_ASSIGNMENT: "تقويم بنائي",
			SUMMATIVE_ASSIGNMENT: "تقويم ختامي",
			ADD_LINKS: "إضافة روابط",
			PROTECT_CLASS: "حماية الصف الافتراضي",
			VIRTUAL_CLASS_DESCRIPTION: "لا يمكن لأحد الوصول إلى المشاركة إلا من خلال اسم المستخدم وكلمة المرور اللذين يتم توفيرهما له عند طلب الدخول. لا تفعل هذا الخيار إلا إذا كنت تعاني من الدخول غير المصرح به",
			IMPORT_HOMEWORK: "استيراد الواجب",
			NEW_HOMEWORK: "واجب جديد",
			PLEASE_SELECT_HOMEWORK: "الرجاء اختيار الواجب",
			LESSON_PREPARATION_PREVIEW: "الاطلاع على تحضير الدروس",
		},
		SCHOOL_YEARS: {
			GREGORIAN_YEARS: "السنة الدراسية بالميلادي",
			HIJRI_YEARS: "السنة الدراسية بالهجري",
			STUDENT_NAME: "اسم الطالب",
		},
		ATTENDANCE: {
			ATTEND: "حاضر",
			EXIST: "حاضر",
			LATE: "متأخر",
			EXECUSED: "مستأذن",
			ABSENT: "غائب",
			ATTENDANCE_DETAIL: "تفاصيل الحضور و الغياب",
			ALREADY_EXIST_ATTENDANCE: "تم تعبئة هذا الحضور و الغياب الرجاء تعديله",
			CLASS_TIME: "وقت الحصة",
			ATTENDANCE_DATE: "تارخ الحضور و الانصراف",
			THERE_IS_NO_SCHEDULE: "لا يوجد جدول مسند",
			DEPARTURE: "خروج",
			NO_ATTEND_RECORD: "لم يتم التحضير",
		},
		ASSESSMENT: {
			ASSESSMENT: "التقييمات",
			ASSESSMENT_LIST: "قائمة التقييمات",
			MARK: "الدرجة",
			SKILL: "المهارة",
			LATE_DAYS: "أيام التأخر",
			ABSENCE_DAYS: "أيام الغياب",
			EXCUSE_DAYS: "أيام الاستئذان",
			MISSING_SKILLS: "المهارات المفقودة",
			FULL_MARK_FOR_ALL: "الدرجة الكاملة",
			ADD: "إضافة  تقييم",
			EDIT: "تعديل التقييم",
			ASSESSMENTED: "من تقييمه",
		},
		EXAM: {
			AFTER_POST_QUESTION_ANSWER: "تم حفظ الاجابة بنجاح",
			PLEASE_ANSWER_QUESTION: "الرجاء الاجابة على السؤال",
		},
		STUDENTS: {
			STUDENTS: "الطلاب",
		},
		TEACHERS: {
			TEACHERS: "المعلمون",
			VALIDATION: {
				CHOOSE_TEACHER_FROM_LIST: "اختر معلما من قائمة المعلمين",
			},
		},

		QUESTION_TYPES: {
			FILL_BLANK: "إملأ الفراغ",
			LONG_ANSWER: "سؤال طويل",
			MATCHING: "توصيل",
			MULTIPLE_CHOICE: "خيار متعدد",
			TRUE_FALSE: "صح أو خطأ",
		},
		SHORTCUTS: {
			MANAGE_SHORTCUTS: "إدارة الاختصارات",
			SHORTCUTS_LIST: "قائمة الاختصارات",
			NEW_SHORTCUT: "اختصار جديد",
			EDIT_SHORTCUT: "تعديل الاختصارات",
			SHORTCUTS: "الاختصارات",
			PAGES: "الصفحات",
			LINK: "الرابط",
			SELECT_PAGE: "اختر الصفحة",
			ASSIGNED_SHORTCUTS: "إسناد الاختيار",
			SELECT_COLOR: "اختر اللون",
			ASSIGN_SHORTCUT: "إسناد اختصار",
		},
		SUPERVISOR_EVALUATION: {
			SUPERVISOR_EVALUATIONS_MANAGEMENT: "إدارة تقييم المشرف",
			SUPERVISOR_EVALUATIONS: " تقييمات المشرف",
			SUPERVISOR_EVALUATIONS_LIST: "قائمة تقييمات المشرف",
			YESTERDAY_NIGHT_ACTIVITIES: "نشاطات البارحة المسائية",
			FIRST_TAB_TITLE: "وقت المساء لليوم السابق",
			SECOND_TAB_TITLE: "المناوبة الصباحية",
			THIRD_TAB_TITLE: "الخدمات الاشرافية و الدعم",
			FOURTH_TAB_TITLE: "تقييم بيئة المدرسة",
			FIFTH_TAB_TITLE: "احصائيات الحضور والغياب للمعلمين و مسؤولي الخدمات التعليمية",
			SIXTH_TAB_TITLE: "احصائيات الحضور والغياب للطلاب",
			SEVENTH_TAB_TITLE: "البرامج النشطة في المدرسة",
			IN_THE_SEASON: "في الفصل",
			OUT_OF_THE_SEASONS: "خارج الفصل",
			CLEANING: "النظافة",
			SITTING_PLAN: "خطة الجلوس",
			LIGHTING: "الإضائة",
			OTHER: "أخرى",
			SQUARES: "مربعات",
			EQUIPMENTS: "المعدات",
			EXCEPT_SCHOOL: "استثناء مدرسة",
			PERCENTAGE: "النسبة المئوية",
			ABSENCES: "غائب",
			NOTE: "ملاحظات",
			ADDRESS_LINE: "العنوان",
			ENTER_ADDRESS_LINE: "أدخل العنوان",
			ENTER_STATE_LINE: "أدخل المنطقة",
			ENTER_POST_CODE: "أدخل الرمز البريدي",
			THIRD_COMPONENT_DESCRIPTION: "الفعاليات خلال مدة المقرر التعليمي",
			POST_CODE: "الرمز البريدي",
		},
		ELEOTS: {
			MANAGE_ELEOT: "إدارة Eleot",
			ELEOT_LIST: "Eleot قائمة",
			NEW_VISIT: "زيارة جديدة",
			EDIT_VISIT: "تعديل زيارة",
			COLLECTION: "المجمع",
			TEACHER: "المعلم",
			COURSE: "المادة",
			GRADE: "الصف",
			VERY_CLEAR: "واضح جداً",
			CLEAR: "واضح",
			SOMEWHAT_CLEAR: "أحيانا واضح",
			NOT_NOTICED: "غير ملحوظ",
			TEACHER_NAME: "اسم المعلم",
			IS_SENT: "مرسلة",
			COURSE_AND_GRADE: "الصف و المادة",
			ENABLED: "مفعل",
			SOMEWHAT_ENABLED: "Somewhat Enabled",
			NOT_ENABLED: "معطل",
			LEARNING_ENVIRONMENTS: "البيئة التعليمية",
			TOOLS_DESCRIPTION: "الادوات المساعدة للعملية التعليمية",
			ACHIEVEMENT_LEVEL_FOR_STUDENT: "مستوى انجازات الطلاب",
			NOTES_AND_RECOMMANDATIONS: "الملاحظات و التوصيات",
			SAVE_AND_SENT: "حفظ و إرسال",
		},
		CLASSROOM_VISITS: {
			CLASSROOM_VISITS: "الزيارات الصفية",
			CLASSROOM_VISIT: "زيارة صفية",
			ADD_CLASSROOM_VISITS: "إنشاء زيارة صفية",
			EDIT_CLASSROOM_VISITS: "تعديل زيارة صفية",
			VISIT_DATE: "تاريخ الزيارة",
			VISIT_TYPE: "نوع الزيارة",
			SCHOOL: "المدرسة",
			PERIOD: "الفترة",
			GRADE: "الصف",
			SECTION: "الفصل",
			TYPE: "النوع",
			STUDENTS_COUNT: "عدد الطالب",
			TEACHER: "المعلم",
			MOBILE_NUMBER: "رقم الهاتف",
			NATIONALITY: "الجنسية",
			SUBJECT: "الموضوع",
			COURSE: "المادة",
			TOPIC: "الموضوع",
			WRITTEN_TEST: "اختبار كتابي",
			ORAL_TEST: "اختبار شفهي",
			OBSERVATION: "الرصد",
			MARK: "الدرجة",
			SEND_DATE: "تاريخ الارسال",
			EFFECT: "التأثير",
			VISIT_INFO: "معلومات الزيارة",
			PERFORMANCE_MEASUREMENT: "قياس الأداء",
			PERFORMANCE_MEASUREMENT_MARK: "درجة قياس الأداء",
			MEASUREMENT_TYPE: "أداة القياس",
			ACHIEVEMENT_LEVEL: "مستوى الإنجازات",
			RECOMMENDATIONS: "مرئيات المشرف",
			SUPERVISOR_RECOMMENDATIONS: "توصيات المشرف",
			NOTES_AND_RECOMMENDATIONS: "ملاحظات وتوصيات",
			NOTES: "الملاحظات",
			PERFORMANCE_AND_IMPACT: "زيارة أداء وأثر",
			PERFORMANCE: "أداء",
			IMPACT: "زيارة قياس مستوي التحصيل",
			WRITTEN_WORK: "زيارة أعمال تحريرية",
			INSTRUCTIONAL_VISIT: "زيارة توجيهية",
			PREPARATIONS_ELECTRONIC: "التحضير الإلكتروني",
			GENERAL: "عام",
			MEMORIZATION: "تحفيظ",
			QUARTERLY: "مسارات",
			ARABIC: "عربي",
			COURSES: "مقررات",
			PRACTICAL: "تجريبي",
			INTRTNATIONAL: "عالمي",
			TEACHERS: "المعلمون",
			COURSE_NUMBER: "رقم الدورة",
			FIELD_WEIGHT: "وزن المجال",
			BY_FINAL_TOTALS: "بالمجاميع النهائية",
			BY_SKILLS: "بالمهارات",
			TEMPLATE: "القالب",
			ADD_TEMPLATE: "إضافة قالب",
			ADD_SKILL: "إضافة مهارة",
			SKILLS: "المهارات",
			MARK_OF_10: "الدرجة من 10",
			MARK_OF_40: "الدرجة من 40",
			MARK_OF_100: "النسبة من 100",
			HIDE_MARK: "إخفاء الدرجة",
			ATTENDENCE: "الحضور والغياب",
			ATHER: "أثر",
			REALS: {
				Q1: "توظيف المتعلم الخبرات السابقة في بناء المعرفة الجديدة (تفعيل الحقبنة).",
				Q2: "مشاركة المتعلم في أنشطة تمهيدية جاذبة.",
				Q3: "مشاركة المتعلم في استنتاج نواتج تعلم موضوع الدرس.",
				Q4: "التسلسل المنطقي في تمكين المتعلم من نواتج التعلم",
				Q5: "توفير بيئة داعمة ومحفزة وعادلة لتعلم المحتوى وإنجاز المهمات.",
				Q6: "اتباع الإجراءات الصحيحة في تنفيذ طرائق التدريس واستراتيجيات التعلم النشط.",
				Q7: "مراعاة أنماط التعلم",
				Q8: "توظيف المقرر الدراسي في ضوء دليل المعلم بما يحقق نواتج التعلم",
				Q9: "تفعيل الاعمال التحريرية",
				Q10: "الاعتماد في تقديم موضوع الدرس على مصادر ووسائل وأدوات تعلم متنوعة",
				Q11: "مشاركة المتعلم بفاعلية مستمرة في أنشطة التعلم",
				Q12: "توظيف مضامين المنهج في بناء الشخصية المتوازنة لدى المتعلم",
				Q13: "ربط موضوع الدرس بأبعاده التطبيقية",
				Q14: "تقويم الدرس تقويما تكوينياً",
				Q15: "تقديم تغذية راجعة تدعم التعلم",
				Q16: "تقويم الدرس تقويما ختاميا",
			},
			VIRTUALS: {
				Q1: "جودة ادوات التواصل االلكتروني",
				Q2: "الدخول السليم للحصة االفتراضية",
				Q3: "سلامة سير الحصة دون انقطاع",
				Q4: "مشاركة المتعلم في أنشطة تمهيدية جاذبة.",
				Q5: "توظيف المتعلم الخبرات السابقة في بناء المعرفة الجديدة )تفعيل الحقبنة(.",
				Q6: "مشاركة المتعلم في استنتاج نواتج تعلم موضوع الدرس.",
				Q7: "التسلسل المنطقي واالنتقال السلسل في تمكين المتعلم من نواتج التعلم",
				Q8: "توفير بيئة صفية افتراضية داعمة ومحفزة وعادلة لتعلم المحتوى وإنجاز المهمات.",
				Q9: "استخدام إمكانات برنامج التدريس االفتراضي بكفاءة في تنفيذ طرق واستراتيجيات التدريس المناسبة",
				Q10: "تنوع التفاعل بين المعلم والطالب",
				Q11: "توظيف المقرر الدراسي في ضوء دليل المعلم بما يحقق نواتج التعلم",
				Q12: "مطابقة موضوع الدرس لخطة توزيع المقرر",
				Q13: "مطابقة ما تم تنفيذه لما تم اعداده في التحضير االلكتروني",
				Q14: "الاعتمادفي تقديم موضوع الدرس على مصادر الكترونية ثرية ومتنوعة",
				Q15: "مشاركة المتعلم بفاعلية مستمرة في أنشطة التعلم",
				Q16: "وظيف مضامين المنهج في بناء الشخصية المتوازنة لدى المتعلم",
				Q17: "ربط موضوع الدرس بأبعاده التطبيقية",
				Q18: "تقويم الدرس تقويما تكوينيا",
				Q19: "تقديم تغذية راجعة تدعم التعلم",
				Q20: "تقويم الدرس تقويما ختاميا",
			},
			ONLINE_PREPARATIONS: {
				Q1: "وجود فصول افتراضية",
				Q2: "مناسبة نواتج التعلم لموضوع الدرس",
				Q3: "مناسبة نواتج التعلم لزمن الحصة",
				Q4: "مناسبة الحقبنة لمحتوي الدرس",
				Q5: "مناسبة الحقبنة للزمن المخصص لها",
				Q6: "قدرة الحقبنة علي الربط بين التعلم السابق والتعلم اللاحق",
				Q7: "مناسبة التهئية والتركيز لمحتوي الدرس",
				Q8: "تحقيق التهئية والتركيز لعنصري جاذبية انتباه المتعلم وتشويقه",
				Q9: "مناسبة إجراءات الدرس لتحقيق النواتج",
				Q10: "الانتقال السلسل بين إجراءات الدرس",
				Q11: "وضوح التدرج في إجراءات الدرس من البسيط للمعقد ومن السهل للصعب",
				Q12: "مناسبة استراتيجيات التعلم لتحقيق النواتج وموضوع الدرس",
				Q13: "مناسبة استراتيجيات التعلم لطبيعة نوع التعلم (افتراضي / عادي)",
				Q14: "مناسبة استراتيجيات التعلم للمستوي العمري للطلاب",
				Q15: "مناسبة الوسائط والروابط لنواتج التعلم ومحتوي الدرس",
				Q16: "مناسبة الوسائط والروابط لطبيعة نوع التعلم (افتراضي / عادي)",
				Q17: "مناسبة أسئلة التقويم البنائي لنواتج التعلم",
				Q18: "قدرة أسئلة التقويم البنائي علي اظهار تمكن الطلاب من المهارات الأساسية للدرس",
				Q19: "شمولية التقويم الختامي",
				Q20: "تنوع أنشطة التقويم الختامي",
				Q21: "شمولية الواجبات والاختبارات لنواتج تعلم الموضوع",
				Q22: "تنوع أسئلة الواجبات والاختبارات بين المقالي والموضوعي",
			},
			SECTIONS: {
				REVIEW_AND_WARM_UP: "التهيئة (التركيز)",
				TEACHING: "التدريس",
				ASSESSMENTS: "تقييم",
			},
			ONLINE_PREPARATIONS_SECTIONS: {
				VIRTUAL_CLASSES: "الفصول الافتراضية",
				LEARNING_OUTCOMES: "نواتج التعلم",
				HAQBANA: "الحقبنة",
				REVIEW_AND_WARM_UP: "التهيئة (التركيز)",
				LESSON_PROCEDURES: "إجراءات الدرس",
				LEARNING_STRATEGIES: "استراتيجيات التعلم",
				MEDIA_AND_LINKS: "الوسائط والروابط",
				CONSTRUCTIVE_EVALUATION: "التقويم البنائي",
				FINAL_EVALUATION: "تقويم ختامي",
				QUIZZES_AND_HOMEWORKS: "الاختبارات والواجبات",
			},
			VIRTUAL_CLASS_VISITS: "الزيارات الصفية الافتراضية",
			VIRTUAL_CLASS_VISIT: "زيارة صفية افتراضية",
			ADD_VIRTUAL_CLASS_VISITS: "إنشاء زيارة صفية افتراضية",
			EDIT_VIRTUAL_CLASS_VISITS: "تعديل زيارة صفية افتراضية",
		},
		SOCIAL_NETWORK: {
			ENTER_LINKEDIN_ADDRESS: "أدخل عنوان linkedIn",
			ENTER_FACEBOOK_ADDRESS: "أدخل عنوان facebook",
			ENTER_TWITTER_ADDRESS: "أدخل عنوان twitter",
			ENTER_INSTAGRAM_ADDRESS: "أدخل عنوان instagram",
			LINKEDIN_ADDRESS: "عنوان linkedIn",
			FACEBOOK_ADDRESS: "عنوان facebook",
			TWITTER_ADDRESS: "عنوان twitter",
			INSTAGRAM_ADDRESS: "عنوان instagram",
		},
		DASHBOARD: {
			START_QUIZ: "إبدأ التقديم",
			SUBMIT_EXAM: "انهاء و تسليم",
			FULL_SCREEN: "شاشة كاملة",
			EXIT_FULL_SCREEN: "تصغيير الشاشة",
			ASSING_TEACHER: "إسناد معلم",
			ADD_STEPS: "إضافة خطوة",
			EDIT_ITEM: "تعديل صنف",
			DELETE_ITEM: "حذف صنف",
			BRANCH_NAME: "اسم الفرع",
			ARABIC_BRANCH_NAME: "اسم الفرع بالعربية",
			COURSE_UNIT_NAME: "اسم الوحدة",
			ARABIC_COURSE_UNIT_NAME: "اسم الوحدة بالعربية",
			COLOR_CODE: "اللون",
			ADD_QUESTION: "إضافة سؤال",
		},
		INSTRUCTORS: {
			INSTRUCTORS: "المدربون",
			INSTRUCTOR: "المدرب",
			INSTRUCTOR_NAME: "اسم المدرب",
			INSTRUCTORS_MANAGEMENT: "إدارة المدربون",
			INSTRUCTORS_LIST: "ٌقائمة المدربون",
			SELECT_INSTRUCTOR: "اختر المدرب",
			TRAINING_COURSE: "Training Course",
			WORKSHOP: "ورشة عمل",
			SELECT_TYPE: "اخر النوع",
			OPEN_ATTENDANCE: "الحضور والغياب",
			SELECT_STATUS: "اختر الحالة",
			SELECT_SCHOOL_YEAR: "اختر السنة الدراسية",
			SELECT_TERM: "اختر الفصل",
			NUMBER_OF_TARGET: "عدد المستهدفين",
		},
		TRAINING_PROGRAMS: {
			TRAINING_PROGRAMS_MANAGEMENT: "إدارة برامج التدريب",
			TRAINING_PROGRAMS_LIST: "قائمة برامج التدريب",
			TRAINING_PROGRAMS: "برامج التدريب",
			TRAINING_COURSE: "دورة",
			WORKSHOP: "ورشة عمل",
			SELECT_TYPE: "اختر النوع",
			OPEN_ATTENDANCE: "الجضور والغياب",
			SELECT_STATUS: "اختر الحالة",
			SELECT_SCHOOL_YEAR: "اختر السنة الدراسية",
			SELECT_TERM: "اختر الفصل",
			NUMBER_OF_TARGET: "عدد المستهدفين",
			TRAINING_PROGRAM: "البرنامج التدريبي",
			SELECT_TRAINING_PROGRAM: "الرجاء اختيار البرنامج التدريبي",
			FILTER_BY_TRAINING_PROGRAM: "التصفية بحسب البرنامج التدريبي",
			ABSENCE_AVERAGE: "متوسط الغياب",
			ATTENDENCE_AVERAGE: "متوسط الحضور",
		},
		COURSE_TRAINEES: {
			PLEASE_ASSIGN_TRAINEES: "الرجاء إسناد متدربين",
			SELECT_TRAINEE: "اختر المتدرب",
			TRAINESS: "المتدربون",
			COURSE_TRAINEES_MANAGEMENT: "إدارة المتدربون",
			COURSE_TRAINEES_LIST: "قائمة المتدربون",
			COURSE_TRAINEES: "المتدربون",
			THERE_IS_NO_ASSIGNED_TRAINEES: "لا يوجد متدربون تم إسنادهم",
			TRAINEE_NAME: "اسم المتدرب",
			LATE_IN_MINUTE: "التاخير بالدقائق",
		},
		BRANCH_SCHOOL: {
			BRANCH_SCHOOL_MANAGEMENT: "إدرة مدارس الفروع",
			BRANCH_SCHOOL_LIST: "قائمة مدارس الفروع",
			BRANCH_SCHOOLS: "مدارس الفروع",
			BRANCH_SCHOOL: "المدرسة",
			MANAGER: "المدير",
			LICENSE_NUMBER: "رقم الرخصة",
			SELECT_SCHOOL_MANAGER: "اختر مدير المدرسة",
			SCHOOL_CODE: "كود المدرسة",
			SECOND_SCHOOL_CODE: "كود المدرسة الثاني",
			SELECT_BRANCH_SCHOOL: "اختر المدرسة",
			SCHOOL_PLAN_ID: "رقم خطة المدرسة",
			SCHOOLS: "المدارس",
			ADMINISTRATION: "الإدارة",
			ADMINISTRATION_AR: "الإدارة بالعربي",
			OFFICE: "مكتب التعليم",
			OFFICE_AR: "مكتب التعليم بالعربي",
			LOGO: "الشعار",
			SELECT_LOGO: "اختر شعار للمدرسة",
		},
		COURSE_TRAINEE_LECTURE: {
			COURSE_TRAINEE_LECTURES_MANAGEMENT: "إدارة المحاضرات",
			COURSE_TRAINEE_LECTURE: "المحاضرة",
			COURSE_TRAINEE_LECTURES: "المحاضرات",
			CLASS_ROOM: "القاعة",
			DATA_NOT_FOUND_SELECT_TRAINING_PROGRAM: "لا يوجد سجلات. الرجاء اختيار برنامج تدريبي",
			TRAINER_ATTENDANCE: "تحضير المتدربين",
			FILTER_BY_LECTURE: "التصفية بحسب المحاضرة",
			SELECT_LECTURE: "اختيار محاضرة",
		},
		COURSE_TRAINEE_ATTENDANCE: {
			COURSE_TRAINEE_ATTENDANCE_MANAGEMENT: "إدارة حضور المتدربون",
			COURSE_TRAINEE_ATTENDANCE: "حضور التدربون",
			COURSE_TRAINEE_ATTENDANCES: "حضور المتدربون",
			TRAINER_ATTENDANCE: "حضور بواسطة المدرب",
			WRITTING_REPORT: "تقرير كتابي",
			NUMERICAL_REPORT: "تقرير عددي",
		},
		CERTIFICATE_TEMPLATE: {
			CERTIFICATE_TEMPLATES_MANAGEMENT: "إدارة قوالب الشهادات",
			CERTIFICATE_TEMPLATES: "قوالب الشهادات",
			CERTIFICATE_TEMPLATES_LIST: "قائمة قوالب الشهادات",
		},
		REPORT_FOR_AGENT: {
			REPORT_FOR_AGENT_MANAGEMENT: "إدارة تقييم الوكيل",
			REPORT_FOR_AGENTS: "تقارير الوكيل",
			REPORT_FOR_AGENT_LIST: "قائمة تقارير الوكيل",
			MORNING: "الصباحي",
			MORNING_DUTY: "الواجب الصباحي",
			MORNING_QUEUE: "الطابور الصباحي",
			SELECT_DUTY_LEVEL: "مستوى الواجب",
			CONTROL_USERS: "Control Users",
			DUTY_LEVEL: "مستوى الواجب",
			DUTY_QUEUE: "Duty Queue",
			MORNING_LINE_UP: "الطابور الصباحي",
			IMPLEMENTATION_LEVEL: "مستوى الانجاز",
			REASONS_FOR_NON_IMPLEMENTATION: "سبب عدم الانجاز",
			SCHOOL_RADIO: "الاذاعة المدرسية",
			TEACHER_PARTICIPATION_RATE: "مستوى مشاركة المعلمين",
			STUDENT_PARTICIPATION_RATE: "مستوى مشاركة الطلاب",
			TEACHERS_WHOE_ARE_LATE_IN_LINE: "المعلمون المتاخرون",
			WATCH_DURING_WORK: "Watch During Work",
			CONTROL_LEVEL: "مستوى التحكم",
			OBSERVERS: "الرصد",
			SHIFT_END: "انتهاء المناوبة",
			SHIFT: "المناوبة",
			EVENNG_DUTY_LEVEL: "مستوى الواجب المسائي",
			DUTY_CONTROL_USERS: "Duty Control Users",
			TOTAL_NUMBER_OF_WORKS: "عدد الاعمال",
			LATE_STARTERS_FOR_THE_FIRST_CLASS: "Late starters for the first class",
			LATE_PERIOD: "Late Period",
			REGULAR_FUNCTIONING: "Regular Functioning",
			PERFORMANCE_LEVEL: "مستوى الاداء",
			PROGRAMS_AND_PROJECTS: "البرامج والمشاريع",
			IMPLEMENTER: "Implementer",
			IMPLEMENT_RATE: "Implement Rate",
		},
		INTERNATIONAL_STUDENT_GRADES: {
			MARKS_DISTRIBUTION: "توزيع الدرجات",
			INTERNATIONAL_STUDENT_GRADES: "المتابعة الاسبوعية",
			MAX_MARK: "الدرجة العظمى",
			WEEKLY_FOLLOW_UP: "المتابعة الاسبوعية",
			GRADING_TYPE: "نوع التقييم",
			DISABLE_ENABLE: "الموقوفون",
			CLASS_WORK: "اعمال الصف",
			PROJECT: "المشاريع",
			GRADING_TOPIC: "المواضيع",
			DISTRIBUTION_TYPE: "نوع توزيع الدجات",
			INTERNATIONAL_STUDENT: "طلاب العالميات",
			PREVIOUS_SCHOOL_NAME: "المدرسة السابقة",
			IS_SEND_TRUE_VALIDATION_MESSAGE: "العملية غير مقبولة بسبب اعتمادها سابقا",
			CERTIFICATES: "الشهادات",
			DETAILED_REPORT: "تقرير مفصل",
			COLLECTIVE_REPORT: "تقرير مجمع",
			TRANSCRIPT: "السجل",
		},
		PRE_USERS: {
			PRE_USERS: "حسابات الانتظار",
			PRE_USER_LISTS: "قائمة حسابات الانتظار",
			PRE_USER_MANAGEMENT: "إدارة حسابات الانتظار",
			PRE_USER_CONFIRM_DESCRIPTION: "هذا المستخدم سيتم تحوليه لصفحة إعدادات المستخدمين ليتم تفعيله",
		},
		CERTIFICATE_TEMPLATE_VARIABLES: {
			COLLECTION_NAME: "اسم المجمع",
			HOURS: "ساعات التدريب",
			FROM: "من",
			TO: "إلى",
			PROGRAM_NAME: "اسم البرنامج التدريبي",
			TRAINEE_NAME: "اسم المتدرب",
			PERIOD: "المدة",
			SCHOOL_YEAR: "السنة الدراسية",
			VARIABLES: "المتغيرات",
		},
		CONFIG: {
			DASHBOARD: "لوحة البيانات",
			DESC: "اخر التحديثات",
		},
		EHTEYAJ: {
			ID: "رقم المدرسة",
			EHTEYAJ_SCHOOLS: "مدارس الاحتياج",
			MANAGE_SCHOOLS: "إدارة المدارس",
			SCHOOL_LIST: "قائمة المدارس",
			SCHOOL_NAME: "اسم المدرسة",
			SCHOOL_NAME_EN: "اسم المدرسة بالانجليزي",
			TRACK: "المسار",
			INFO: "معلومات",
			NO_PLAN_FOR_THIS_SCHOOL: "لا يوجد خطة لهذه المدرسة!",
		},
		SCHOOL_PLANS: {
			SCHOOL_PLANS: "الخطط الدراسية",
			MANAGE_SCHOOL_PLANS: "إدارة الخطط الدراسية",
			SCHOOL_PLANS_LIST: "قائمة الخطط الدراسية",
			NEW_SCHOOLPLAN: "خطة جديدة",
			EDIT_SCHOOLPLAN: "تعديل خطة",
		},
		FINAL_NEEDS: {
			FINAL_NEEDS: "الاحتياج النهائي",
			FINAL_NEEDS_LIST: "قائمة الاحتياج",
			EHTEYAJ: "الاحتياج",
			TOTAL: "المجموع",
		},
		NEED_PLANS: {
			MANAGE_NEED_PLANS: "إدارة خطط الاحتياج",
			PLANS_LIST: "قائمة الخطط",
			FILTER_BY_PLAN: "التصفية حسب الخطة",
			PLAN: "الخطة",
		},

		TRAINING_PROGRAM_CERTIFICATE: {
			TRAINING_PROGRAM_CERTIFICATE_LIST: "قائمة شهادات البرامج التدريبية",
			TRAINING_PROGRAM_CERTIFICATE: "شهادات البرامج التدريبية",
			ISSUING_THE_CERTIFICATES: "إصدار شهادات البرامج التدريبية",
		},
		SCHOOL_PLANS_EDIT: {
			TEACHER_SPOTTER: "نصاب المعلم",
			SURPLUS_CLASSES: "حصص الفائض",
			DEFICIENCY_CLASSES: "حصص العجز",
		},
		NEED_PLAN_EDIT: {
			NUMBER_OF_CLASSES: "عدد الحصص",
			ORDER: "الترتيب",
			PRIORITY_IF_NO_OTHER_OPTIONS: "أولوية في حالة العدم",
			COUNTED_IN_DEFICIENCY: "تحسب في احتياج العجز",
			SUPPORTIVE_SUBJECTS: "مواد مساندة",
			ADD_SUBJECT_DETAILS_IN_PLAN: "إضافة بيانات مادة في خطة الاحتياج",
		},
		PROFILE: {
			MY_PROFILE: "ملفي الشخصي",
			MY_TASKS: "مهامي",
			MESSAGES: "رسالة",
			SETTINGS: "إعدادات",
			SIGN_OUT: "تسجيل الخروج",
			LATEST_TASKS_AND_PROJECTS: "اخر المهام والمشاريع",
			LOGS_AND_NOTIFICATIONS: "التقارير و التنبيهات",
			MY_ACTIVITIES: "نشاطاتي",
			INBOX_AND_TASKS: "صندوق الرسائل والمهام",
			MY_MESSAGES: "رسائلي",
			ACCOUNT_SETTING_AND_MORE: "إعدادات الحساب واكثر",
		},
		STUDENTS_DISTRIBUTION: {
			STUDENTS_DISTRIBUTION: "توزيع الطلاب",
			STUDENTS: "الطلاب",
			SECTIONS: "الفصول",
		},
		PROMOTE_STUDENTS: {
			PROMOTE_STUDENTS: "ترفيع الطلاب",
			PROMOTE_STUDENTS_LIST: "سجل ترفيع الطلاب",
			SCHOOL: "المدرسة",
			GRADE: "الصف",
			PROMOTE: "ترفيع",
			PROMOTED_TO: "انتقل للصف",
			CANCELED: "ملغي",
			ACTIVE: "مفعلة",
			PROMOTED_STUDENTS: "الطلاب الذين تم ترفيعهم",
			DO_YOU_WANT_TO_CANCEL_PROMOTE_ACTION: "هل تريد الغاء عملية ترفيع الطلاب؟",
			CANCELING_PROMOTE_ACTION: "جار الغاء عملية ترفيع الطلاب",
			CANCEL_PROMOTE_ACTION: "الغاء ترفيع الطلاب",
			YES: "نعم",
			NO: "لا",
		},
		TRAINING_PROGRAM_ASSESSMENT: {
			ASSESSMENT_MANAGEMENT: "إدارة تقييمات البرامج التدريبية",
			ASSESSMENT: "تقييمات البرامج التدريبية",
			ASSESSMENT_LIST: "قائمة تقييمات البرامج التدريبية",
			AVERAGE_OF_MARKS: "متوسط الدرجات",
			ASSESSMENTS: "التقييمات",
			REPORTS: "التقارير",
			ASSESSMENT_INFO: "تفاصيل التقرير",
			INSTRUCTOR_EVALUTION: "تقويم المدرب/ـة",
			ADDITIONAL_ITEMS_BENEFITING_REGULATORS: "بنود إضافية تفيد المنظمين",
			HIGH: "ممتاز",
			GOOD: "جيد",
			MODERATE: "متوسط",
			LOW: "منخفض",
			STRENGTHS: "نقاط القوة",
			WEAKNESSES: "نقاط الضعف",
			AVERAGE_OF_INSTRUCTOR: "معدل تقييمات المدربين",
			AVERAGE_OF_ORGANIZER: "معدل تقييمات المنظمين",
			NUMBER_OF_ASSESSMENT: "عدد التقيممات",
		},
		VISIT_MEETING: {
			VISIT_MEETING_LIST: "قائمة زيارات المحضر ",
			VISIT_MEETING_MANAGEMENT: "إدارة زيارات المحضر",
			PERFORMANCE_AND_IMPACT: "زيارة اداء واثر",
			PERFORMANCE: "زيارة اداء",
			IMPACT: "زيارة قياس مستوى التحصيل",
			WRITTEN_WORK: "زيارة اعمال تحريرية",
			INSTRUCTIONAL_VISIT: "زيارة توجيهية",
			PREPARATION_ELECTRONIC: "التحضير الإلكتروني",
			START_END_DATE_MANDATORY_MESSAGE: "الرجاء تعبئة تاريخ البداية والنهاية",
		},
		WAITING_PERIODS: {
			WAITING_PERIODS: "حصص الانتظار",
			ALTERNATIVE_TEACHER: "المعلم البديل",
			PERIOD: "الحصة",
			ATTEND: "تحضير",
			PERIOD_TIME: "وقت الحصة",
			ATTEND_DONE: "تم التحضير",
			ADD_WAITING_PERIODS: "إضافة حصة انتظار",
			SELECT_PERIOD: "اختر حصة",
		},
		TEACHER: "المعلم",
		STUDENT: "الطالب",
		FILTER_BY_USER_GROUP: "التصفية بنوع المستخدم",
		FILTER_BY_BRANCH: "التصفية بالفرع",
		FILTER_BY_IS_SENT: "التصفية بحالة الإرسال",
		FILTER_BY_SCHOOL: "التصفية بالمدرسة",
		FILTER_BY_GRADE: "التصفية بالصف",
		FILTER_BY_COURSE: "التصفية بالمادة",
		FILTER_BY_SECTION: "التصفية بالفصل",
		PARENT: "ولي الأمر",
		FILTER_BY_YEAR: "التصفية بالسنة",
		FILTER_BY_TERM: "التصفية بالفصل الدراسي",
		DAILY_PERIODS: {
			DAILY_PERIODS: "حصص اليوم",
			ATTEND_DONE: "تم التحضير",
			YES: "نعم",
			NO: "لا",
		},
		FILTER_BY_TYPE: "التصفية بالنوع",
		FILTER_BY_STATUS: "التصفية بالحالة",
		FILTER_BY_UNIT: "التصفية بالوحدة",
		FILTER_BY_TRAINING_PROGRAM: "التصفية بالبرنامج التدريبي",
		FILTER_BY_TEMPLATE_TYPE: "التفية بنوع النموذج",
		FILTER_BY_IS_APPROVED: "التصفية بحالة الاعتماد",
		DAILY_REPORT: "التقرير اليومي",
		FILTER_BY_PUBLISH: "التصفية بحالة النشر",
		FILTER_BY_PLAN: "التصفية بالخطة",
		QUIZ_TYPE: "نوع الاختبار",
		EDUCATION_TRACK: "المسار التعليمي",
		EDUCATION_TRACKS: {
			GENERAL: "عام",
			MEMORIZATION: "تحفيظ",
			QUARTERLY: "مسارات",
			ARABIC: "عربي",
			COURSES: "مقررات",
			PRACTICAL: "تجريبي",
		},
		MONTHLY_REPORT: {
			MONTHLY_REPORT: "التقرير الشهري",
			SELECT_YEAR: "اختر سنة",
			SELECT_MONTH: "اختر شهر",
			SELECT_STUDENT: "اختر طالب",
			FIRST_WEEK: "الاسبوع الأول",
			SECOND_WEEK: "الاسبوع الثاني",
			THIRD_WEEK: "الاسبوع الثالث",
			FOURTH_WEEK: "الاسبوع الرابع",
		},
		GRADE_SECTION: "الصف",
		DETAILED_ATTENDENCE_REPORT: "تقرير مفصل",
		LABORATORY_VISIT_MANAGEMENT: "إدارة زيارة المختبرات",
		LABORATORY_VISIT_LIST: "زيارة المختبرات",
		EXPERIENCE: "الخبرة",
		VISIT_VIEWERS: "تم الفتح بواسطة",
		ACHIEVEMENT_BANK: {
			ACHIEVEMENT_BANK: "بنك الانجازات",
			ACHIEVEMENT_BANK_MANAGER: "إدارة بنك الانجازات",
			ACHIEVEMENT_BANK_SUMMARY: "ملخص بنك الانجازات",
			OTHER: "اخر",
			COLLECTION: "المجمع",
			COMMANDER: "قائد المجمع",
			TEACHER: "المعلم",
			STUDENT: "الطالب",
			EDUCATIONAL_SUPERVISOR: "المشرف التعليمي",
			SCHOOL_MANAGER: "مدير المدرسة",
			SCHOOL_AGENT: "وكيل المدرسة",
			OBSERVER: "المراقب",
			ACTIVITY_PIONEER: "رائد النشاط",
			STUDENT_ORIENTATION: "الموجه الطلابي",
			THE_TRUSTED_OF_SOURCES: "امين المصدر",
			LAB_REPORT: "محضر المختبر",
			SCIENTIFIC: "علمي",
			ATHLETE: "رياضي",
			SOCIAL: "اجتماعي",
			VOLUNTARY: "تطوعي",
			FIRST: "الأول",
			SECOND: "الثاني",
			THIRD: "الثالث",
			FOURTH: "الرابع",
			FIFTH: "الخامس",
			CULTURAL: "ثقافي",
			COMPANY_LEVEL: "على مستوى شركة مدارس المتقدمة",
			EDUCATION_OFFICE: "على مستوى مكتب التعليم",
			EDUCATION_ADMINISTRATION: "على مستوى إدارة التعليم",
			MINISTRY_OF_EDUCATION: "على مستوى وزارة التعليم",
			GULF_COUNTRIES: "أقليمي (دول الخليج)",
			WORLWIDE: "عالمي",
			DRAFT: "لم يتم الإرسال",
			SENT: "تم الإرسال",
			ACCEPTED: "مقبول",
			REJECTED: "مرفوض",
			SEARCH_BY_TITLE: "البحث بالعنوان",
			DONOR: "الجهة المانحة",
			BENEFICIARY: "المستفيد",
			NEW_ACHIEVEMENT: "إنجاز جديد",
			EDIT_ACHIEVEMENT: "تعديل الانجاز",
			ACHIEVERS: "المنجزون",
			SELECT_ACHIEVERS: "اختر المنجزون",
			OTHER_ACHIEVERS: "منجزون اخرون",
			OTHER_ACHIEVERS_PLACEHOLDER: "منجزون اخرون (أدخل كل اسم في سطر جديد)",
			ACHIEVEMENT_TYPE: "نوع الانجاز",
			ACHIEVEMENT_LEVEL: "مستوى الانجاز",
			POSITION: "المركز",
			ACHIEVEMENT_SUMMARY: "وصف الانجاز",
			ATTACHMENTS: "المرفقات",
			REASON_FOR_REJECT: "سبب الرفض",
			RANK: "المرتبة",
			IS_SEEN: "تم الفتح",
			GOLDEN_TROPHY: "كأس المتقدمة",
			GOLDEN_STAR: "نجمة المتقدمة الذهبية",
			SILVER_STAR: "نجمة المتقدمة الفضية",
			BRONZE_STAR: "نجمة المتقدمة البرونزية",
		},
		FILTER_BY_PARENT: "التصفية بولي الأمر",
		PARENTS: "ولي الأمر",
		SELECT_STUDENT_PARENT: "اختر طالب أو ولي أمر",
		PAYMENT: "الدفع",
		RETURN_DUES: "ارجاع المستحقات",
		INVOICES: "الفواتير",
		SPECIAL_DISCOUNT: "الخصومات الخاصة",
		FATHER_PROMISSORY: "سندات الأمر",
		PAYMENT_REP: "كشف حساب",
		PAYMENT_COUNCHERS: "سندات القبض",
		PAYMENT_CASH: "كشف بالسندات",
		ACC_SHEET: "كشف حساب مجمع",
		DISCOUNT_REPORT: "تقرير الخصم",
		ADD_STUDENT: "إضافة طالب",
		IMPORT_STUDENT: "استيراد طالب",
		MOTHER_INFO: "بيانات الام",
		RESPONSIBLE_INFO: "بيانات ولي الأمر",
		CALCULATE_BRO_INFO: "حساب خصم الاخ",
		FILTER_BY_PARENT_PHONE_NUMBER: "التصفية حسب جوال ولي الأمر",
		FINANCIAL_OPERATIONS: "العمليات المالية",
		REPORTS_AND_BONDS: "السندات و التقارير",
		BASIC_OPERATIONS: "العمليات الاساسية",
		ADD_PAY_DETAILS: "الإستحقاقات",
		BUSES: "الباصات",
		PREVIOUS_BALANCE: "الرصيد السابق",
		ADJUSTMENT: "التسويات",
		ELECTRONIC_WITHDRAWL: "الانسحاب الإلكتروني",
		ADJUSTMENT_UPDATE: "تعديل التسويات",
		PREVIOUS_DISCOUNT: "الخصومات السابقة",
		FORMS_AND_REPORTS: "النماذج والتقارير",
		PAYMENT_SHEET: "كشف حساب تفصيلي",
		FINANCIAL_REQUEST: "مطالية مالية",
		DATA_HISTORY: "السجل التاريخي",
		BACK_ORDER: "استمارة سحب",
		REG_FORM: "استمارة التسجيل",
		ACCEPTANCE_FORM: "استمارة القبول",
		DISPLAY_ATTACHMENTS: "عرض المرفقات",
		VAT_RECALCULATION: "إعادة حساب ضريبة القيمة المضافة",
		MOVE_TO_FATHER: "انتقل إلى ولي الأمر",
		OTHER_OPERATION: "عملية أخرى",
		CHILDS: "الابناء",
		NO_ASSOCIATED_CHILD_FOUND: "لم يتم العثور على ابناء",
		ENTITY_GROUP_DELETE_MESSAGE: "لا يمكن حذف قائمة اذا كانت تحتوي على قوائم داخليه",
		WEEKLY_REPORTS: {
			WEEKLY_REPORTS: "التقارير الاسبوعية",
			NEW_WEEKLY_REPORT: "تقرير اسبوعي جديد",
			WEEKLY_REPORT_BY: "تقرير اسبوعي بواسطة",
			EDIT_WEEKLY_REPORT: "تعديل التقرير اسبوعي",
			WEEK_ACTIVITIES: "الأنشطة المنفذة خلال الأسبوع",
			INTERVIEWS: "مقابلة بعض أولياء الأمور",
			COMMUNICATION_ENABLED: "تفعيل الاتصالات",
			COMMUNICATION_TYPE: "نوع الاتصال",
			STUDENT_MONITORING: "متابعة الطلاب علميا وسلوكيا",
			UNDER_ACHIEVERS: "الملاحظات السلوكية",
			ADVANTAGES: "أبرز الإيجابيات",
			DRAWBACKS: "أبرز السلبيات",
			OTHERS: "أخرى",
			OPENIONS: "آراء ومقترحات",
		},
		MONTHLY_ASSESSMENTS: {
			MONTHLY_ASSESSMENTS: "تقييمات الشهر",
			NEW_MONTHLY_ASSESSMENT: "تقييم شهري جديد",
			MONTHLY_ASSESSMENT: "تقييم شهري",
			EDIT_MONTHLY_ASSESSMENT: "تعديل تقييم شهري",
			MARK: "الدرجة",
			ABSENCE_DAYS: "عدد ايام الغياب",
			LATE_DAYS: "عدد ايام التأخر",
			EXCUSE_DAYS: "عدد ايام الاستئذان",
			MISSING_SKILLS: "المهارات المفقودة",
			BEHAVIORAL_OBSERVATIONS: "الملاحظات السلوكية",
			IMPORT_ATTENDECE: "استيراد الحظور والغياب",
			FROM_TEACHER: "من إدخال المعلم",
			FROM_VIRTUL: "من الحصص الافتراضية",
			IMPORT: "استيراد",
			NOTES: "الملاحظات",
		},
		WEEKLY_PLANS: {
			WEEKLY_PLANS: "الخطط الدراسية الاسبوعية",
			EDIT_WEEKLY_PLANS: "تعديل الخطة الاسبوعية",
			NEW_WEEKLY_PLANS: "إضافة خطة اسبوعية",
		},
		BANKS: "البنوك",
		BANK_MANAGEMENT: "إدارة البنوك",
		BANK_NAME: "اسم البنك",
		BANK_NAME_AR: "اسم البنك بالعربي",
		DESCRIPTION: "الوصف",
		DESCRIPTION_AR: "الوصف بالعربي",
		ICON_URL: "رابط الايقونة",
		NEW_BANK: "بنك جديد",
		EDIT_BANK: "تديل بنك",
		MANAGE_BANKS: "إدارة بنك",
		BANK_LIST: "قائمة البنوك",
		MANAGE_PAYMENT_METHODS: "إدارة طرق الدفع",
		PAYMENT_METHODS_LIST: "قائمة طرق الدفع",
		EDIT_PAYMENT_METHOD: "تعديل طرق الدفع",
		NEW_PAYMENT_METHOD: "إضافة طريقة دفع",
		MANAGE_DISCOUNT_TYPE: "إدارة انواع الخصم",
		DISCOUNT_TYPE_LIST: "قائمة انواع الخصم",
		EDIT_DISCOUNT_TYPE: "تعديل نوع الخصم",
		NEW_DISCOUNT_TYPE: "نوع خصم جديد",
		MERGE_WITH_BROTHER: "الدمج مع الاخ",
		IS_PERCENTAGE: "نسبة",
		SETUPS: "الإعدادات",
		ASSIGN_PAYMENT_METHODS: "تعيين طرق الدفع",
		SELECT_ITEM: "اختر عنصر",
		SELECT_PAYMENT_MENTHOD: "اختر طريقة دفع",
		ASSIGNED_PAYMENT_METHODS: "طرق الدفع المسندة",
		WEEKS: {
			1: "الأسبوع الأول",
			2: "الأسبوع الثاني",
			3: "الأسبوع الثالث",
			4: "الأسبوع الرابع",
			5: "الأسبوع الخامس",
			6: "الأسبوع السادس",
			7: "الأسبوع السابع",
			8: "الأسبوع الثامن",
			9: "الأسبوع التاسع",
			10: "الأسبوع العاشر",
			11: "الأسبوع الحادي عشر",
			12: "الأسبوع الثاني عشر",
			13: "الأسبوع الثالث عشر",
			14: "الأسبوع الرابع عشر",
			15: "الأسبوع الخامس عشر",
			16: "الأسبوع السادس عشر",
			17: "الأسبوع السابع عشر",
			18: "الأسبوع الثامن عشر",
			19: "الأسبوع التاسع عشر",
			20: "الأسبوع العشرون",
			21: "الأسبوع الواحد والعشرين",
			22: "الأسبوع الثاني والعشرون",
			23: "الأسبوع الثالث والعشرون",
			24: "الأسبوع الرابع والعشرون",
			25: "الأسبوع الخامس والعشرون",
			26: "الأسبوع السادس والعشرون",
			27: "الأسبوع السابع والعشرون",
			28: "الأسبوع الثامن والعشرون",
			29: "الأسبوع التاسع والعشرون",
			30: "الأسبوع الثلاثون",
			31: "الأسبوع الواحد والثلاثون",
			32: "الأسبوع الثاني والثلاثون",
			33: "الأسبوع الثالث والثلاثون",
			34: "الأسبوع الرابع والثلاثون",
			35: "الأسبوع الخامس والثلاثون",
			36: "الأسبوع السادس والثلاثون",
			37: "الأسبوع السابع والثلاثون",
			38: "الأسبوع الثامن والثلاثون",
			39: "الأسبوع التاسع والثلاثون",
			40: "الأسبوع الأربعون",
			41: "الأسبوع الواحد و الأربعون",
			42: "الأسبوع الثاني والأربعون",
			43: "الأسبوع الثالث والأربعون",
			44: "الأسبوع الرابع والأربعون",
			45: "الأسبوع الخامس والأربعون",
			46: "الأسبوع السادس والأربعون",
			47: "الأسبوع السابع والأربعون",
			48: "الأسبوع الثامن والأربعون",
		},
		EDUCATOR_ASSESSMENTS: {
			ASSESSMENT_TYPE: "نوع التقييم",
			FILTER_BY_ASSESSMENT_TYPE: "التصفية بنوع التقييم",
			PROJECT_MANAGER_TO_TEACHER: "تقييم مشرف المشروع للمربي المخلص",
			AGENT_TO_PROJECT_MANAGER: "تقييم الوكيل لمشرف المشروع",
			ACADIMIC_DIRECTOR_TO_AGENT: "تقييم المشرف التعليمي للوكيل",
			ACADIMIC_DIRECTOR_TO_PROJECT_MANAGER: "تقييم المشرف التعليمي لمشرف المشروع",
			SCHOOL_ASSESSMENT: "تقييم المدرسة",
		},
		LEARNING_CARD: {
			LEARNING_CARD: "تقرير بطاقة أثر تعلم",
			LEARNING_CARD_BY_COURSE: "تقرير بطاقة أثر تعلم (وفق المادة)",
			BY_COURSE: "وفق المادة",
			LEARNING_CARD_BY_TOTAL: "تقرير بطاقة أثر تعلم (وفق المجموع)",
			BY_TOTAL: "وفق المجموع",
			TOTAL: "المجموع",
			AVERAGE: "المعدل",
			SKILL: "المهارة",
		},
		COURSE_CREDIT_HOURS: {
			COURSE_CREDIT_HOURS: "الساعات الدراسية",
			ADD_CREDIT_HOURS: "إضافة ساعات دراسية",
			EDIT_CREDIT_HOURS: "تعديل ساعات دراسية",
		},
		CERTIFICATE_GRADE_OPTIONS: {
			CERTIFICATE_GRADE_OPTIONS: "درجات الشهادات",
			ADD_CERTIFICATE_GRADE_OPTION: "إضافة درجة",
			EDIT_CERTIFICATE_GRADE_OPTION: "تعديل درجة",
			MIN_MARK: "متوسط الدرجة",
			MARK_TITLE: "عنوان الدرجة",
		},
		I_AM_A_PARENT: "أنا ولي أمر",
		I_AM_A_STUDENT: "أنا طالب",
		GENDER: "النوع",
		INCOME_TYPE_MANAGEMENT: "انواع الرسوم",
		INCOME_TYPE_LIST: "قائمة انواع الرسوم",
		NEW_INCOME_TYPE: "مصدر دخل جديد",
		EDIT_INCOME_TYPE: "تعديل نوع الرسوم",
		SEMESTER_MANAGEMENT: "إدارة الفصول الدراسية",
		SEMESTER_LIST: "قائمة الفصول الدراسية",
		NEW_SEMESTER_TYPE: "فصل دراسي جديد",
		EDIT_SEMESTER_TYPE: "تعديل الفصل الدراسي",
		INCOME_SETTINGS_MANAGEMENT: "Income Setting Management",
		INCOME_SETTINGS_LIST: "Income Setting List",
		SEMESTER: "الفصل",
		INCOME_TYPE: "الايرادات - انواع الرسوم",
		ORDERING_IN_PAYMENT: "الترتيب في السداد",
		SAUDI_VAT_RATIO: "نسبة الضريبة للسعودي ",
		NON_SAUDI_VAT_RATIO: "نسبة الضريبة لغير السعودي ",
		BROTHER_DISCOUNT_MANAGEMENT: "إدارة خصومات الاخ",
		BROTHER_DISCOUNT_LIST: "ترتيب خصم الأخوة",
		NEW_BROTHER_DISCOUNT: "إضافة خصم الاخ",
		EDIT_BROTHER_DISCOUNT: "تعديل خصم الاخ",
		DISCOUNT_VALUE: "قيمة الخصم",
		PERCENTAGE: "نسبة",
		VALUE: "مقطوع",
		BROTHER_ORDER: "ترتيب الاخ",
		DRAG_AND_DROP_ORDER_MESSAGE: "اسحب العناصر لترتيبها ومن ثم اختر حفظ",
		SAVE_ORDER: "حفظ الترتيب",
		SELECT_ALL: "اختر الكل",
		DUES_ALLOCATION: "إسناد الاستحقاقات",
		DUES_ALLOCATION_LIST: "قائمة إسنادات الاستحقاقات",
		MANAGE_DUES_ALLOCATION: "إدارة إسنادات الاستحقاقات",
		FILTER_BY_SEMESTER: "التصفية بالفصل",
		FILTER_BY_LEARNING_TRACK: "التصفية بالمسار التعليمي",
		FILTER_BY_ROW: "Filter by Row",
		ROW: "Row",
		FILTER_BY_CLASS: "التصفية بالصف",
		FILTER_BY_INCOME_TYPE: "التصفية بنوع الاستحقاق",
		FEES_STATUS: "حالة الرسوم",
		FILTER_BY_FEES_STATUS: "التصفية بحالة الرسوم",
		STUDENT_STATUS: "حالة الطالب",
		FILTER_BY_STUDENT_STATUS: "التصفية بحالة الطالب",
		STUDENTS_STATUS: "حالات الطالب",
		FILTER_BY_STUDENTS_STATUS: "التصفية بحالات الطلاب",
		CHOOSE: "اختر",
		DUE_AMOUNT: "قيمة الاستحقاق",
		STAFF_STATUS: "حالة الانتساب",
		SCHOOL_STUFF: "المنتسبين",
		NON_SCHOOL_STUFF: "غير المنتسبين",
		FILTER_BY_STUFF_STATUS: "التصفية بحالة الانتساب",
		DUE_STATUS: "حالة الاستحقاق",
		FILTER_BY_DUE_STATUS: "التصفية بحالة الاستحقاق",
		ASSIGNED: "Assigned",
		UN_ASSIGNED: "Un Assigned",
		OPPOSITE_ACCOUNT: "الحساب المقابل",
		RELAY_COA_ACCOUNT: "الربط بقيد الاستحقاق",
		CANCEL_BROTHER_DISCOUNT: "الغاء خصم الاخ",
		TAXED_DUES_DISCOUNT: "Taxed Dues Discount",
		CALCULATE_TOTAL_DUE: "Calculate Toral Due",
		CANCEL_SPECIAL_DISCOUNT: "Cancel Special Discount",
		FIRST_SEMESTER: "الفصل الأول",
		SECOND_SEMESTER: "الفصل الثاني",
		GRADE_INCOME_SETTINGS_MANAGEMENT: "تهيئة المستحقات لكل صف",
		GRADE_INCOME_SETTINGS_LIST: "قائمة المستحقات  للصفوف",
		NEW_GRADE_INCOME_SETTINGS_TYPE: "إضافة طريقة استحقاق جديدة",
		EDIT_GRADE_INCOME_SETTINGS_TYPE: "تعديل طريقة استحقاق",
		STUDY_TYPE: "نوع الدراسة",
		DISTRBUTE_VALUE: "إسنادالقيمة إلى الكل",
		EDIT_MODE: "تعديل",
		LINKING_PAYMENT_METHOD: "ربط بالحسابات",
		COMMISION_RATIO: "نسبة عمولات الفيزا",
		MAX_COMMITION: "الحد الأقصي للعمولة",
		LINKING_SCHOOLS: "الحسابات الجارية للشركات",
		MANAGE_REGISTRATION_ADJUSTMENT: "إدارة تهيئة تسويات التسجيل",
		REGISTRATION_ADJUSTMENT: "تهيئة تسويات التسجيل",
		REGISTRATION_ADJUSTMENT_LIST: "قائمة تهيئة تسويات التسجيل",
		EDIT_REGISTRATION_ADJUSTMENT: "تعديل تهيئة تسويات التسجيل",
		NEW_REGISTRATION_ADJUSTMENT: "إضافة تهيئة تسويات التسجيل",
		PERIOD_FROM: "الفترة من",
		PERIOD_TO: "الفترة إلى",
		EXIST: "موجود",
		NOT_EXIST: "غير موجود",
		SEND_EMAIL: "ارسل ايميل",
		VALUE_GREATER_THAN: "القيمة أكبر من",
		VALUE_LESS_THAN: "القيمه أقل من",
		MAX_REMAINING_PERCENT: "نسبة المتبقي أقل من",
		FINANCIAL_DUES_LIST: "قائمة المطالبات المالية",
		MANAGE_FINANCIAL_DUES: "إدارة المطالبات المالية",
		REFERENCE_NUMBER: "الرقم المرجعي",
		PAYMENT_METHOD: "طريقة الدفع",
		CHECK_NUMBER: "رقم الشيك",
		DATE_OF_CHECK_OR_TRANSFER: "تاريخ إصدار الشيك أو الحوالة",
		RESPONSIBLES: "Responsibles",
		TEXT: "Text",
		TYPE_OF_PAYMENT: "نوع عملية الدفع",
		MANAGE_PAYMENT: "إدارة عمليات الدفع",
		CASHIER: "امين الصندوق",
		PERMENANT: "دائم",
		TEMPRORARY: "مؤقت",
		IS_PERMENANT: "دائم",
		AMOUNT: "القيمة",
		VALUE_FROM: "تبدأ من",
		VALUE_TO: "حتى",
		ADD_SIBLING_DISCOUNT: "يضاف خصم الأخوة",
		ADD_SPECIAL_DISCOUNT: "يضاف الخصومات الخاصة",
		DISCOUNT_ON_BASE_AMOUNT: "احتساب على صافي الاستحقاق",
		INCLUDE_VAT: "تخصم من الإستحقاق في حالة الضريبة",
		TOTAL_STUDENT: "مجموع الطلاب",
		GRADE_OR_SECTION: "الصف / الفصل",
		STAGE_GRADE_SETTINGS: "تهيئة اعداد الطلاب بالمراحل",
		ACTIVATED_SUCCESSFULLY: "تم التفعيل بنجاح",
		DE_ACTIVATED_SUCCESSFULLY: "تم التعطيل بنجاح",
		ARE_YOU_SURE_ACTIVATE_THIS_RECORD: "تأكيد التفعيل",
		ARE_YOU_SURE_DE_ACTIVATE_THIS_RECORD: "تعطيل التفعيل",
		NUMBER_OF_PARENTS: "عدد أولياء الامور",
		NUMBER_OF_STUDENTS: "عدد الطلاب",
		NUMBER_OF_TEACHERS: "عدد المعلمين",
		NUMBER_OF_BRANCHES: "عدد الفروع",
		NUMBER_OF_LESSONS_IN_THIS_YEAR: "عدد الدروس في السنة الحالية",
		NUMBER_OF_EXAMS_IN_THIS_YEAR: "عدد الاختبارات في السنة الحالية",
		NUMBER_OF_HOMEWORKS_IN_THIS_YEAR: "عدد الواجبات في السنة الحالية",
		STUDENT_COUNT_AND_PERCENTAGE_BY_BRANCH: "عدد الطلاب بالنسة لكل فرع",
		STUDENT_COUNT_AND_BY_NATIONALITIES: "عدد الطلاب بالنسبة للجنسية",
		AUTO_COMPLETE_HINT: "أدخل 4 أحرف على الأقل للبحث",
		DUE_NAME: "اسم المطالبة",
		STUDENT_FEE_VAT: "ضريبة رسوم الطالب",
		STUDENT_FEE_VALUE: "رسوم الطالب",
		PAID_VAT: "الضريبة المدفوعة",
		PAID_VALUE: "القيمة المدفوعة",
		REMAINING_VAT: "الضريبة المتبقية",
		REMAINING_VALUE: "القيمة المتبقية",
		CHECK: "شيك",
		VALUE_CONTAIN_VAT: "القيمة تشمل الضريبة",
		VAT: "ضريبة القيمة المضافة",
		FROM_GRADE: "من صف",
		TO_GRADE: "الى صف",
		UPGRADE_GRADE: "ترفيع الصفوف",
		NEW_STUDENT_STATUS: "حالة طالب جديدة",
		EDIT_STUDENT_STATUS: "تعديل حالات الطلاب",
		SUB_STUDENT_STATUS: "حالات الطلاب الفرعية",
		NEW_SUB_STUDENT_STATUS: "حالة طالب فرعية جديدة",
		EDIT_SUB_STUDENT_STATUS: "تعديل حالة طالب فرعية",
		MAIN_STUDENT_STATUS: "حالات الطلاب الأساسية",
		CERTIFICATE_ISSUE_DATE: "تاريخ إصدار الشهادة",
		NOT_INCLUDED_IN_TOTAL: "لا يدخل في حسبة مجموع الدرجات",
		ENABLE_RE_ORDER: "تفعيل خصية الترتيب",
		PLEASE_SELECT_STUDENT: "الرجاء اختيار طالب",
		STUDENT_IS_ASSIGNED_TO_ANOTHER_GROUP: "الطالب مسند لمجموعة أخرى",
		EXPORT_AS_EXCEL: "تصدير الى اكسل",
		EXPORT_AS_PDF: "تصدير الى PDF",
		SEMESTER_INCOME_SETTINGS: "اعدادات مستحقات الفصول الدراسية",
		GRADE_INCOME_SETTINGS: "مستحقات الصفوف",
		NEW_INCOME_SETTING: "مستحق جديد",
		EDIT_INCOME_SETTING: "تعديل مستحق",
		BROTHER_DISCOUNT_ORDER: "ترتيب خصم الاخوة",
		SIBLING_ORDER: "ترتيب الاخوة",
		SIBLING_ORDER_VALIDATION_MESSAGE: "Sibling Order Values must be unique.",
		ENABLE_SET_CUMULATIVE_AMOUNT: "تفعيل خاصية تعديل الجميع",
		UPDATE_AMOUNTS: "تعديل القيم المختارة",
		ASSIGN_TO_SCHOOL: "الإسناد لمدرسة",
		ASSIGN_TO_GRADE: "الإسناد لصف",
		ASSIGN_TO_SECTION: "الإسناد لفصل",
		ASSIGN_TO_COURSE: "الإسناد لمادة",
		MULTI_ASSIGN: "متعدد الإسنادات",
		ASSIGN_SPECIAL_DISCOUNT: "إسناد خصومات خاصة",
		SPECIAL_DISCOUNT_VALUE_VALIDATION_MESSAGE: "حقل 'من قيمة' يجب ان يكون اقل من حقل 'الى قيمة'",
		SPECIAL_DISCOUNT_DATE_VALIDATION_MESSAGE: "حقل 'من تاريخ' يجب ان يكون اقل من حقل 'الى تاريخ'",
		SPECIAL_DISCOUNT_DATE_FROM_SEMESTER_START_VALIDATION_MESSAGE: "حقل 'من تاريخ' يجب ان يكون اكبر من تارخ بداية الفصل الدراسي المختار (@start_date)",
		SPECIAL_DISCOUNT_DATE_FROM_SEMESTER_END_VALIDATION_MESSAGE: "From Date must be smaller than Semester Start Date (@start_date)",
		SPECIAL_DISCOUNT_DATE_TO_SEMESTER_START_VALIDATION_MESSAGE: "To Date must be bigger than Semester Start Date (@date)",
		SPECIAL_DISCOUNT_DATE_TO_SEMESTER_END_VALIDATION_MESSAGE: "To Date must be smaller than Semester End Date (@date)",
		ANSWER: "الاجابة",
		CHOICES: "الاختيارات",
		MANAGE_BUS_SETTING: "إدارة الباصات",
		BUS_SETTING: "إعدادات الباصات",
		BUS_SETTING_LIST: "قائمة الباصات",
		EDIT_BUS_SETTING: "تعديل باص",
		NEW_BUS_SETTING: "إضافة باص",
		BUS_NUMBER: "رقم الباص",
		DRIVER_NAME: "اسم السائق",
		DRIVER_MOBILE: "جوال السائق",
		SUPERVISOR_NAME: "اسم المشرف",
		SUPERVISOR_MOBILE: "جوال المشرف",
		BUS_CYCLE: "دورة الباص",
		ACCOUNT_NO: "رقم الحساب",
		SIBLING_DISCOUNT_SAME_BRANCH_MESSAGE: "تم تهيئة خصومات الاخوة لهذا الفرع مسبقا الرجاء اختيار فرع اخر",
		YOUR_EXAM_ATTEMPT_IS_OVER: "ليس لديك محاولات أخرى لاداء الاختبار",
		PHONE_NUMBER_VALIDATION_MESSAGE: "Phone Number have to consist of 9 digits and should not start with zero",
		IS_WAITING: "انتظار؟",
		SEMESTER_FEES_DETAIL_EMPTY_MESSAGE: "بيانات غير مكتملة للخيارات المختارة",
		SEMESTER_FEES: "مستحقات الفصول الدراسية",
		ANOTHER_TEACHER_IS_ASSIGNED: "تم إسناد هذا الفصل لمعلم اخر",
		TEACHER_ASSIGNMENT: "إسنادات المعلمين",
		ANOTHER_CLASS_HAS_BEEN_ASSIGNED_TO_THIS_SLOT: "تم إضافة حصة أخرى في هذا الوقت",
		PAYMENT_NOTES: "ملاحظات الدفع",
		NEW_PAYMENT_NOTE: "ملاحظة جديدة",
		EDIT_PAYMENT_NOTE: "تعديل ملاحظة",
		NO_ASSIGNMENTS_WERE_FOUND: "لا يوجد لديك إسنادات, تواصل مع مسؤول النظام الخاص بك",
		TOTAL_AMOUNT: "المبلغ الإجمالي",
		SEMESTER_ENTITLEMENT: "المستحق",
		TEXT_OF_VALUE: "القيمة بالكلمات",
		FULL_YEAR: "الرجاء الاختيار",
		PAYMENT_VALUE_VALIDATION_MESSAGE: "لا يجوز أن تكون قمية الدفعة أكبر من قيمة المبلغ المتبقي",
		PLEASE_SELECT_A_COURSE: "الرجاء اختيار مادة",
		FILTER_BY_TEACHER: "البحث بالمعلم",
		FILTER_BY_GRADE_AND_SECTION: "البحث بالصف والفصل",
		MENU_NAME: "اسم القائمة",
		SELECT_GROUP_MESSAGE: "الرجاء اختيار مجموعة",
		SELECT_SHORT_CUT_MESSAGE: "الرجاء اختيار اختصار",
		LIST_HAS_BEEN_REFRESHED: "تم تحديث الإسنادات المعروضة",
		THERE_IS_NO_ASSIGNED_RECORD: "لا يوجد بيانات مسندة",
		ASSIGN_TO_ALL_SCHOOLS: "الإسناد لجميع المدارس",
		ONLY_ENGLISH_AND_NUMBERS: "فقط أرقام وحروف انجليزية",
		IMPORT_USER: "نقل/ندب",
		THIS_USER_CAN_NOT_BE_IMPORTED: "لايمكن استيراد هذا المستخدم",
		USER_NOT_FOUND: "لم يتم العثور على هذا المستخدم",
		CURRENT_BRANCH: "الفرع الحالي",
		IMPORT: "استيراد",
		STUDY_FEES_ONLY_ONE_RECORD_MESSAGE: "لا يمكن اسناد اكثر من مستحق دراسي واحد لنفس الطالب بنفس الفصل الدراسي ",
		"New visit report": "تقرير زيارة جديدة",
		"Impact measurement": "قياس الأثر",
		REPORT_ADDED_SUCCESSFULLY: "تم إضافة تقرير الزيارة بنجاح",
		SCHOOL_YEAR: "السنة الدراسية",
		PATH: "المسار",
		FOR_INTERNATIONAL_SCHOOLS: "إضافة المادة للعالميات",
		PLEASE_ENTER_FILE_NAME: "الرجاء ادخال اسم الملف",
		PLEASE_UPLOAD_FILE: "يرجى اختيار ملف",
		ADD_NEW_QUIZ: "اختبار جديد",
		QUIZ_SAVED_SUCCESSFULLY: "تم حفظ الاختبار بنجاح",
		QUIZ_UPDATED_SUCCESSFULLY: "تم تعديل الاختبار بنجاح",
		SENTENCE_IS_TRUE: "العبارة صحيحة؟",
		MOTHER_NAME: "اسم الام",
		MOTHER_AR_NAME: "اسم الام بالعربية",
		ID_NUMBER: "رقم الهوية",
		MOTHERS_INFO_OF: "بيانات الام لـ ",
		JOB: "الوظيفة",
		EMAIL_ADDRESS: "البريد الالكتروني",
		MOBILE_NUMBER_1: "رقم الجوال",
		MOBILE_NUMBER_2: "رقم الجوال الثانوي",
		PHONE_NUMBER_HINT: "مثال لرقم الجوال 0096655xxxxxxx",
		EMAIL_HINT: "sample@hotmail.com",
		EXAM_SUBMITED: "تم تسليم الاختبار",
		HOMEWORK_IS_ALREADY_SUBMITTED: "تم تسليم الواجب",
		TIME_REAMAINING: "الوقت المتبقي",
		ATTEMPT_REMAINING: "المحاولات المتبقية",
		SECOND_PAIR: "العمود الثاني",
		FIRST_PAIR: "العمود الأول",
		TIME_OVER_FOR_QUESTION: "انتهى وقت السؤال",
		LEFT: "تبقى",
		SECONDS_TO_FINISH: "ثانية لانتهاء الاختبار",
		SECONDS_TO_FINISH_QUESTION: "ثانية لانتهاء وقت السؤال",
		DISCOUNT_REASON: "سبب الخصم",
		DISCOUNT_TYPE: "نوع الخصم",
		GROUP_STUDENTS: "طلاب المجموعة",
		"Create Question": "إضافة سؤال",
		CALCULATE_BROTHER_INSTRUCTURE: "الرجاء اختيار الفصل الدراسي و النقر على بدء لاحتساب الخصم",
		START: "إبدأ",
		FROM_SCHOOL: "من مدرسة",
		TO_SCHOOL: "الى مدرسة",
		LESSON_PREPERATION_COPIED_SUCCESSFULLY: "تم نسخ التحضير بنجاح",
		VIEW_VIEWRS: "المطلعون",
		VIEWED_BY: "تم العرض بواسطة",
		True: "صح",
		False: "خطأ",
		"Please enter all choices": "فضلا، أدخل جميع الاختيارات",
		Close: "إغلاق",
		"Edit Question": "تعديل السؤال",
		Required: "مطلوب",
		"Value must be greater than or equals 1": "يجب أن لا تقل القيمة عن 1",
		STUDENTS_GROUPS: "مجموعات الطلاب",
		VIEW_NOTES: "عرض الملاحظات",
		ADD_NOTE: "إضافة ملاحظة",
		NOTE: "الملاحظة",
		PLEASE_WRITE_YOU_NOTES: "الرجاء كتابة ملاحظاتك",
		NOTE_SENT_SUCCESSFULLY: "تم حفظ الملاحظة بنجاح",
		NO_NOTES: "لا يوجد ملاحظات",
		MARK_SHOULD_BE_0_TO_100: "يجب أن تكون الدرجة من 0 وحتى 100",
		ENTER_USERNAME_OR_SELECT_SCHOOL: "الرجاء إدخال اسم مستخدم أو اختر مدرسة للبحث",
		CALCULATE_BRO_DISCOUNT_CATCH_MESSAGE: "فشل احتساب خصم الاخوة",
		UPGRADE_GRADE_VALIDAITON_MESSAGE: "Please select To Grade and From School and To School on changed rows.",
		PARENT_PROMISSORY_UNDERTAKE: "أتعهد بأن أدفع بموجب هذا السند لأمر",
		BENEFICERY: "المستفيد",
		PARENT_PROMISSORY_AMOUNT_DESCRIPTION: "المبلغ الموضح أعلاه و مقداره",
		PARENT_PROMISSORY_RETURN_EXPENSES_DESCRIPTION: "و لحامل هذه الورقة حق الرجوع بدون مصروفات أو إحتجاج",
		PARENT_PROMISSORY_NAME: "سند الامر",
		OCCUPATION: "Occupation",
		SIGNATURE: "التوقيع",
		PARENT_PROMISSORY_FOOT_NOTE: "هذ السند لأمر واجب الدفع دون الرجوع بلا مصروفات وبدون احتجاج (البروتسيتو) هذا سند واجب الدفع بدون تعطل أو احتجاج بموجب قرار مجلس الوزراء رقم 692 وتاريخ 26/9/1383 والمتوج بالمرسوم الملكي رقم 37 بتاريخ 11/10/1383هــ بصدور نظام الأوراق التجارية",
		VAT_NUMBER: "الرقم الضريبي",
		PARENT_PROMISSORY_DUE_DATE_FIXED: "في حالة عدم تسجيل تاريخ سيكون السند لدى الاطلاع",
		PARENT_PROMISSORY_UPON_VIEWING: "لدى الاطلاع",
		PARENT_PROMISSORY: "سندات الامر",
		FILTER_BY_CREATOR: "البحث بالمنشئ",
		CITY: "المدينة",
		PLEASE_SELECT_GRADE: "الرجاء اختيار الصف",
		DISCOUNT_NAME: "اسم الخصم",
		DOCUMENT_TYPES: "أنواع الوثائق",
		NEW_DOCUMENT_TYPE: "نوع وثيقة جديد",
		EDIT_DOCUMENT_TYPE: "تعديل انواع الوثائق",
		Q: "س",
		DRAG_AND_DROP_SECOND_COLUMN_ITEMS: "يمكن ترتيب عناصر العمود الثاني بسحبها وافلاتها بما يوافقها من العمود الأول",
		STARTS_ON: "يبدأ بتاريخ",
		ENDS_ON: "ينتهي بتاريخ",
		GRADE_ORDER: "ترتيب الصفوف",
		BROTHER_DISCOUNT: "خصم الاخوة",
		BROTHER_NUMBER: "ترتيب الاخ",
		MY_STUDENTS: "طلابي",
		SEARCH_FOR_A_STUDENT: "البحث عن طالب",
		FAITHFUL_GROUP_STUDENTS: "طلاب مجموعة المربي المخلص",
		MY_SECTIONS_STUDENTS: "طلاب فصولي",
		DOCUMENT_NUMBER: "رقم الوثيقة",
		PAYMENT_VOUCHER: "سند القبض",
		PARENT_NAME: "اسم ولي الامر",
		REFERENCE_NO: "رقم المرجع",
		PAID_AMOUNT: "القيمة المدفوعة",
		PAYMENT_DATE: "تاريخ الدفع",
		PRINT_PAYMENT_VOUCHER: "سند قبض",
		STUDENT_ID: "رقم هوية الطالب",
		STUDENT_NAME: "اسم الطالب",
		PARENT_ID: "رقم هوية ولي الامر",
		NATIONALITY: "الجنسية",
		"New instructor": "مدرب جديد",
		"New program": "برنامج جديد",
		"New trainee": "متدرب جديد",
		MESSAGE_STUDENT: "مراسلة الطالب",
		MESSAGE_PARENT: "مراسلة ولي الأمر",
		GROUP_MESSAGE_STUDENT: "رسالة جماعية للطلاب",
		GROUP_MESSAGE_PARENT: "رسالة جماعية لأولياء الامور",
		MESSAGE_SENT_SUCCESSFULLY: "تم إرسال الرسالة بنجاح",
		PAYMENT_CONFIRMATION: "تأكيد الدفع",
		TOTAL_PAYMENT: "القيمة الاجمالية",
		PAYMENT_TOTAL_VALUE_VALIDATION_MESSAGE: "مجموع توزيع المبلغ يجي ان يساوي المبلغ الاجمالي المراد دفعه",
		CHANGED_VALUE: "القيمة بعد التعديل",
		NEW_MEESAGES: "رسائل جديدة",
		TERM_1: "الفصل الأول",
		TERM_2: "الفصل الثاني",
		TERM_3: "الفصل الثالث",
		FIND_TRAINEE: "البحث عن متدرب",
		NEW_LECTURE: "محاضرة جديدة",
		EFFECT: "الأثر",
		NEW_TEMPLATE: "قالب جديد",
		MAXIMUM_IS_100: "100 كحد أعلى",
		MINIMUM_IS_1: "1 كحد أدنى",
		"Select project manager": "اختر مشرف المشروع",
		PRINT_INVOICE: "طباعة الفاتورة",
		INVOICE: "الفاتورة",
		NEW_ASSESSMENT: "تقييم جديد",
		CONTINUE: "متابعة",
		YOUR_CERTIFICATE_IS_READY: "شهادتك جاهزة",
		PENDING: "معلق",
		SEND_MESSAGE_TO_TRAINEES: "إرسال رسالة للمتدربين",
		NO_TRAINERS_WERE_ASSIGNED_TO_THIS_PROGRAM: "لم يتم إسناد متدربين لهذا التدريب",
		SORTING_TYPE: "نوع الترتيب",
		ASCENDING: "تصاعدي",
		DESCENDING: "تنازلي",
		CLONE_DISCOUNTS: "نسخ الخصومات",
		CLONE: "النسخ",
		SOURCE: "Source",
		DESTINATION: "Destination",
		SOURCE_AND_DESTINATION_SEMESTER_SAME_MESSAGE: "لا يمكن اختيار نفس الفصل الدراسي في خانتي من الفصل الدراسي و الى الفصل الدراسي",
		IS_CHECK: "شيك ؟",
		Error: "خطأ",
		"Exist before": "مضاف مسبقا",
		ANNOUNCEMENTS: "التنبيهات",
		ANNOUNCEMENTS_LATEST: "اخر التنبيهات",
		MOTHER_MANAGEMENT: "إدارة بيانات الام",
		MOTHER_ID: "رقم هوية الام",
		NUMBER_OF_CHILD: "رقم الابن",
		ASSIGNED_STUDENTS: "الابناء",
		REJECT: "رفض",
		DUES_ALLOCATION_HINT_1: "تحتوي القائمة المنسدلة الخاصة بالصفوف فقط على الصفوف التي تم تهيئة قيمة الاستحقاق لها ",
		DUES_ALLOCATION_HINT_2: "تحتوي القائمة المنسدلة الخاصة المراحل فقط على المراحل التابعة لهذا الاستحقاق ",
		"Not evaluated": "لم يتم إرسال التقييم",
		Month: "الشهر",
		Year: "السنة",
		Status: "الحالة",
		Group: "المجموعة",
		Student: "الطالب",
		"Sent by project manager": "اعتماد مشرف المشروع",
		"Sent by educator": "اعتماد المربي المخلص",
		"Assessment of month": "تقييم شهر",
		"Under revision": "تحت المراجعة",
		STUDENT_HISTORY: "تاريخ الطالب",
		USER_NAME: "اسم المستخدم",
		ITEM_NAME: "اسم الصنف",
		UPGRADE_STUDENT: "ترفيع الطلاب",
		FROM_SCHOOL_YEAR: "من السنة الدراسية",
		TO_SCHOOL_YEAR: "الى السنة الدراسية",
		FROM_SCHOOL_YEAR_TO_SCHOOL_YEAR_VALIDATION: "حقل 'من السنة الدراسية' يجب ان يكون اقدم من حقل 'الى السنة الدراسية'",
		ENTITLEMENTS_REPORT: "تقرير الاستحقاقات",
		ENTITLEMENT: "الاستحقاقات",
		DISCOUNT: "الخصم",
		REMAINING_AMOUNT: "القيمة المتبقية",
		CLAIMS_REPORT: "تقرير المطالبات",
		CLAIM_ID: "رقم المطالبة",
		FEES_NAME: "اسم المستحق",
		DETAILED_REPORT: "كشف حساب تفصيلي",
		FILE_UPLUAD_FAILED: "فشل في رفع الملف",
		OUTCOME_EN: "ناتج التعلم بالانجليزي",
		OUTCOME_AR: "ناتج التعلم بالعربي",
		YOU_HAVE_NEW_LESSON_PREPARATION__NOTE: "لديك ملاحظة جديدة في تحضير الدروس",
		HAS_ADDED_NEW_NOTE_TO_YOUR_lESSON_PREPARATION: "اضاف/اضافت ملاحظة جديدة لتحضير:",
		JOBS_LIST: "المسميات الوظيفية",
		NEW_JOB: "وظيفة جديدة",
		EDIT_JOB: "تعديل الوظيفة",
		NOOR_ALBYAN: "نور البيان",
		NEW_FILE: "ملف جديد",
		ORDER: "الترتيب",
		FILE_LINK: "رابط الملف",
		FILE_IS_NOT_SUPPORTED: "الملف غير مدعوم",
		Announcements: "الإعلانات",
		"Latest announcements": "أحدث لإعلانات",
		Notifications: "الإشعارات",
		"Latest notifications": "أحدث الإشعارات",
		Notification: "الإشعار",
		PERSONAL_INFORMATION_FOR_STUDENT: "معلومات الطالب",
		PERSONAL_INFORMATION_FOR_PARENT: "معلومات ولي الامر",
		CONTACT_INFORMATION: "معلومات الاتصال",
		STUDENT_FULL_NAME: "اسم الطالب الكامل",
		PARENT_FULL_NAME: "اسم ولي الامر الكامل",
		PLACE_OF_BIRTH: "مكان الميلاد",
		WORK_PLACE: "مكان العمل",
		PARENT_MOBILE_NUMBER: "رقم جوال ولي الامر",
		MOTHER_MOBILE_NUMBER: "رقم جوال الأم",
		ALTERNATIVE_MOBILE_NUMBER: "رقم جوال بديل",
		PLEASE_ADD_ALL_REQUIRED_DETAILS_AND_MAKE_SURE_YOU_ENTER_NUMBERS_IN_ENGLISH: "الرجاء ادخال البيانات المطلوبة والتأكد من ادخال الارقام بالانجليزية",
		GRADE_AND_TRACK: "الصف - المسار",
		STUDY_FEES: "رسوم دراسية",
		TRANSPORTATION_FEES: "رسوم نقل",
		TOTAL_DUES: "إجمالي المستحقات",
		TOTAL_PAID: "الاجمالي المدفوع",
		TOTAL_REMAIN: "الاجمالي المتبقي",
		MOBILE: "رقم الجوال",
		GRAND_TOTAL: "الإجمالي",
		DOWNLOAD: "تحميل",
		SHOW_PREVIOUS_BALANCE: "إظهار الرصيد السابق",
		STUDENT_STATEMENT: "ملخص الطالب",
		FILE_UPLOAD: "تحميل ملف",
		"Loading...": "الرجاء الانتظار...",
		SAVE_AND_CLOSE: "حفظ و اغلاق",
		FILES: "الملفات",
		ADD_FILES: "إضافة ملفات",
		UPLOAD_FILES: "تحميل الملفات",
		BACK: "رجوع",
		NO_FILES: "لا يوجد ملفات",
		MARK_OUT_OF_10: "الدرجة من 10",
		ATTACHMENT: "المرفق",
		EXAM_CONTROL: "لجنة المراقبة",
		ATTACHMENT_MANDATORY: "المرفق الزامي",
		THERE_IS_NO_ATTACHED_FILES: "لا يوجد ملفات مرفقة",
		PLEASE_FILL_REQUIRED_FILTERS: "الرجاء ادخال الحقول الالزامية",
		REGISTRATION_FORM: "إستمارة التسجيل",
		ALIAS_EN: "العنوان بالانجليزية",
		ALIAS_AR: "العنوان بالعربية",
		MANDATORY_ALIAS_MESSAGE: "عنوان الملف الزامي ",
		SPECIAL_DISCOUNT_AMOUNT_VALIDATION: "القيمة يجب انتكون بين @fromvalue و @tovalue",
		PLEASE_SELECT_SCHOOL_GRADE_SECTION_COURSE: "الرجاء اختيار مدرسة وصف وفصل و المادة",
		UPDATED_BY: "تم التعديل بواسطة",
		TEACHER_DID_NOT_SUBMMIT_YET: "لم يتم اعتماد الدرجات من المعلم بعد",
		PAYMENT_METHODS: "طرق الدفع",
		MANAGE_BANKS_AND_PAYMENT_METHODS: "إدارة البنوك و طرق الدفع",
		VIEW_AND_PRINT: "معاينة وطباعة",
		FILTER_BY_SCHOOL_YEAR: "تصفية حسب السنة الدراسية",
		FILTER_APPLIED_SUCCESSFULLY: "تم التصفية بنجاح",
		FROM_DAYS: "من اليوم",
		TO_DAYS: "الى اليوم",
		MANAGE_SETTLEMENT_SETTING: "ادارة اعدادات التسوية الالكترونية",
		SETTLEMENT_LIST: "قائمة اعدادات التسوية",
		SETTLEMENT_SETTING: "اعدادات التسوية",
		EDIT_SETTLEMENT_SETTING: "تعديل اعدادات التسوية",
		NEW_SETTLEMENT_SETTING: "اعدادات تسوية جديدة",
		NEW_INVOICE: "فاتورة جديدة",
		PREVIEW_INVOICE: "استعراض الفواتير",
		VIEW_BILLS: "استعراض الفواتير",
		CREATE_BILLS: "إنشاء الفواتير",
		STUDENT_IS_BLOCKED_PLEASE_CONTACT_SCHOOL: "الطالب موقوف, الرجاء التواصل مع ادارة المدرسة",
		THIS_PAGE_IS_FOR_INTERNATIONAL_SCHOOLS_ONLY: "هذه الصفحة لطلاب المدارس العالمية فقط",
		PARENT_NATIONAL_ID: "رقم هوية ولي الامر",
		STUDENT_NATIONALITY: "الجنسية",
		VAT_VALUE: "نسبة الضريبة المضافة",
		VAT_AMOUNT: "قيمة الضريبة المضافة",
		TOTAL_FEES_VITHOUT_VAT: "الاجمالي بدون الضرية",
		PRINT_DATE: "تاريخ الطباعة",
		DUE_DATE: "تاريخ الاستحقاق",
		PARENT_PHONE_NUMBER: "جوال ولي الامر",
		INVOICE_DATE: "تاريخ الفاتورة",
		TOTAL_DISCOUNT: "إجمالي الخصومات",
		TOTAL_TAX: "إجمالي الضريبة",
		TOTAL_CALCULATION: "المجموع النهائي",
		CREATE_BILLS_CONFIRMATION_MESSAGE: "هل انت متأكد من انشاء فاتورة للطالب : @student",
		AUTO_COMPLETE_PLACE_HOLDER_LABEL: "أدخل 4 أحرف على الأقل للبحث",
		REMAINING_VALUE_AND_VAT: "القيمة المتبقية مع الضريبة",
		CASHIER_MANAGEMENT: "إدارة الصناديق",
		ASSIGNED_STUDENT_FEES: "إسناد استحقاقات للطالب",
		SELECT_STUDENT_FEES: "اختر المستحق",
		PLEASE_SELECT_ITEMS: "الرجاء الاختيار",
		STUDENT_DUES: "مستحقات",
		SUPERVISORS_REPORTS: "تقارير المشرفين",
		MARK_AVERAGE: "متوسط الدرجات",
		EFFECT_AVERAGE: "متوسط الاثر",
		WRITTEN_TEST: "اختبار تحريري / أدائي",
		ORAL_TEST: "اختبار شفهي",
		MY_VISITS: "زياراتي",
		BUSES_SEMESTER_FEES_HINT: "تعتمد على الفصل الدراسي الذي تم اختياره",
		QUIZ_CREATOR: "معد الاختبار",
		NEW_GRADE_ORDER_VALIDATION: "لقد قمت بتغيير الترتيب الرجاء حفظ الترتيب اولا او اعادة تحميل الصفحة",
		DUE_ASSIGN_GRADE_NOT_MATCHING_VALIDATION_MESSAGE: "Cannot assign because of not matching Grade",
		SERIAL_NO: "الرقم التسلسلي",
		"Export to excel": "تصدير إلى إكسل",
		CASHIER_REPORTS: "تقارير الصناديق",
		STUDENT_SUB_STATUS: "حالات الطالب الفرعية",
		WITH_DRAWAL_DATE: "تاريخ الانسحاب",
		WITH_DRAWAL_REASON: "سبب الانسحاب",
		TRANSFERRED_SCHOOLS: "منتقل الى مدرسة",
		PRIVATE: "خاص",
		GOVERNMENTAL: "حكومي",
		INTRTNATIONAL: "عالمي",
		OUTSIDE_KSA: "خارج المملكة",
		CALCULATE: "احتساب",
		ELECTRONIC_WITHDRAWAL: "الانسحاب الالكتروني",
		REPORT_TYPE: "نوع التقرير",
		SELECT_REPORT_TYPE: "اختر نوع التقرير",
		FinancialReport: "التقارير المالية",
		PLEASE_SELECT_REPORT_TYPE: "الرجاء اختيار التقرير",
		STUDENT_FINANCIAL_REPORT: "تقرير الطلاب المالي",
		"First term": "الفصل الأول",
		"Second term": "الفصل الثاني",
		"Third term": "الفصل الثالث",
		"Final mark": "الدرجة النهائية",
		USER: "User",
		BRANCH_FINANCIAL_REPORT: "تقرير مالي بحسب الفروع",
		STUDENT_SEMESTER_FINANCIAL_REPORT: "تقرير مالي للطلاب بحسب الفصل الدراسي",
		START_PROCESS: "إبدأ الاجراء",
		IN_PROGESS_DONT_CLOSE_MESSAGE: "الرجاء الانتظار و عدم اغلاق هذه الشاشة حتى يتم الانتهاء من تنفيذ العملية",
		FATHER_FINANCIAL_REPORT: "التقرير المالي بحسب اولياء الامور",
		HISTORICAL_FINANCIAL_REPORT: "تقرير مالي تاريخي",
		DETAILED_BENEFIT_REPORT: "تقرير المستحقات المالي",
		DUES_TYPE: "انواع المستحقات",
		DUES: "المستحقات",
		REMAIN_GREATER_THAN: "المتبقي اكبر من",
		REMAIN_LESS_THAN: "المتبقي اصغر من ",
		DATE_FROM: "من تاريخ",
		DATE_TO: "الى تاريخ",
		ENTITLEMENT_AMOUNT: "قيمة المستحق",
		studentName: "اسم الطالب",
		idNumber: "رقم الهوية",
		displayGradeName: "الصف",
		mobile: "رقم الجوال",
		studyFeesAmount: "Study Fees",
		totalDiscountAmount: "اجمالي الخصومات",
		totalPaidAmount: "اجمالي المدفوع",
		totalReminingAmount: "اجمالي المتبقي",
		Export: "تصدير",
		"Export to pdf": "تصدير إلى بي دي إف",
		prevAmount: "الرصيد السابق",
		transportationFeesAmount: "رسوم النقل",
		Index: "الرقم",
		StudentFinancialReport: "تقرير الطلاب المالي",
		BranchFinancialReport: "تقرير مالي بحسب الفروع",
		StudentSemesterFinancialReport: "تقرير مالي للطلاب بحسب الفصل الدراسي",
		FatherFinancialReport: "تقرير مالي بحسب اولياء الامور",
		HistoricalFinancialReport: "تقرير مالي تاريخي",
		DetailedBenefitReport: "تقرير المستحقات المالي",
		PreviousBalanceReport: "تقرير الرصيد السابق",
		CashierFinancialReportPrintMessage: "جاري التحضير لطباعة  @RecordCount سجل. يرجى الانتظار...",
		None: "لا شيئ",
		SETTLEMENT_DATE: "تاريخ الانسحاب",
		SETTLEMENT_AMOUNT: "قيمة الانسحاب",
		TOTAL_ENTITLEMENT: "اجمالي المستحقات",
		DiscountReport: "تقرير الخصومات",
		BrotherDiscountReport: "تقرير خصومات الاخوة",
		DiscountReportByFather: "تقرير الخصومات بحسب اولياء الامور",
		PrivateDiscountReport: "تقرير الخصومات الخاصة",
		PARENT_WORK_PLACE: "مكان عمل ولي الامر",
		ALL: "الجميع",
		RECORD_SAVING: "جاري حفظ البيانات ...",
		SAVE_DESCRIPTION: "هل انت متاكد من اتمام هذه العملية?",
		FATHER: "ولي الامر",
		FinancialReconciliationReport: "تقرير التسويات المالية",
		DiscountsAndPreviousBalanceAttachments: "تقرير الخصومات و المرفقات",
		FinancialReconcileDetails: "تقرير تفصيلي للتسويات",
		BasicDataReport: "تقرير البيانات الاساسية",
		PAYMENT_STATEMENT: "Payment Statement",
		SCHOOL_GENDER: "School Gender",
		MovementDetailedDailyRegistration: "Movement Detailed Daily Registration",
		TotalDailyRegistration: "Total Daily Registration",
		CountOfTransferredAndNewStudents: "Count Of Transferred And New Students",
		JournalRecordsReport: "Journal Records Report",
		StudentsListByClassReport: "Students List By Class Report",
		ReligionAndSecondLanguageByRowReport: "Religion And Second Language By Row Report",
		SchoolCountByClassesReport: "School Count By Classes Report",
		SchoolCountByLevelsReport: "School Count By Levels Report",
		TotalStudentCountBySemester: "Total Student Count By Semester",
		TotalCountOfClassesAndStudentsReport: "Total Count Of Classes And Students Report",
		CountOfStudentsNationalitiesReport: "Count Of Students Nationalities Report",
		StudentsCountInDateReport: "Students Count In Date Report",
		AttachmentsReport: "Attachments Report",
		CASHIER_REPORT_NOT_DEFINED_REPORT: "Not Defined Report",
		taxAmount: "Tax",
		totalEntitelmentAmount: "Total Entitlement",
		displayBranchName: "Branch",
		FinancialReportFee: "Financial Report Fee",
		TotalFeesBySemester: "Total Fees By Semester",
		TotalLevelReport: "Total Level Report",
		PromissoryNoteArchive: "Promissory Note Archive",
		DUE_DURATION_FROM: "Due Duration From",
		DUE_DURATION_TO: "Due Duration To",
		FatherInstallmentReport: "Father Installment Report",
		SchoolReportCompiled: "School Report Compiled",
		DailyCashReport: "Daily Cash Report",
		PreviousBalanceSchoolReport: "Previous Balance Report",
		GeneralDues: "General Dues",
		displayStageName: "Stage",
		subStudentStatusDisplayName: "Sub Student Status",
		fatherName: "Father Name",
		parentChildrenCount: "Child Count",
		"Come at": "وقت الحضور",
		"Leave at": "وقت الانصراف",
		"Classroom link": "رابط الحصة الافتراضية",
		Details: "تفاصيل",
		Classroom: "الحصة الافتراضية",
		"School year": "السنة الدراسية",
		Settings: "إعدادات",
		"Course name": "المادة",
		"Periods count": "عدد الحصص",
		"Sections count": "عدد الفصول",
		"Course periods count": "عدد حصص المادة",
		Add: "إضافة",
		Remove: "حذف",
		Save: "حفظ",
		Edit: "تعديل",
		Cancel: "إلغاء",
		Yes: "نعم",
		No: "لا",
		"Edit all": "تعديل الجميع",
		"Only characters or numbers and from 5 to 10 length": "فقط أرقام وحروف، بأن لايقل عن 5 رموز ولا يزيد عن 10",
		"The student's result was withheld by the school administration": "تم حجب نتيجة الطالب من قبل إدارة المدرسة",
		Live: "مباشر",
		Minute: "دقيقة",
		"This live only supports Google Chrome and Firefox browsers": "هذا البث يدعم متصفح قوقل كروم وفايرفوكس فقط",
		"Open in application": "فتح من التطبيق",
		"Please choose branch": "اختر الفرع من فضلك",
		"Filter by assignment": "تصفية بالإسناد",
		Assigned: "مسند",
		"Not assigned": "غير مسند",
		"Created date": "تاريخ الإضافة",
		Title: "العنوان",
		Section: "الفصل",
		Grade: "الصف",
		Beginning: "بداية",
		Middle: "وسط",
		End: "نهاية",
		Notes: "ملاحظات",
		"Period entering time": "وقت دخول الحصة",
		Manage: "إدارة",
		"Teacher observation tool": "أداة ملاحظة المعلم",
		Home: "الرئيسية",
		View: "عرض",
		"View visit": "الاطلاع على الزيارة",
		"When upgrading, the following must be observed": "عند الترفيع يجب مراعاة التالي",
		"The third secondary grade must be promoted first, then the secondary secondary grade, and so on, up to the first primary grade": "يجب ترفيع الصف الثالث الثانوي أولا، ثم الثاني الثانوي وهكذا وصولا إلى الصف الأول الابتدائي",
		"The class to which he is raised must be empty of students, otherwise they will be merged": "يجب أن يكون الصف المرفع إليه خاليا من الطلاب وإلا سيتم دمجهم",
		"The promotion process takes place only once per grade during the academic year": "عملية الترفيع تتم مرة واحدة فقط لكل صف خلال العام الدراسي",
		Stage: "المرحلة",
		Track: "المسار",
		"Successes and strengths": "النجاحات ونقاط القوة",
		"Development aspects and support plan": "جوانب التطوير وخطة الدعم والمساندة",
		"Competencies that need to be developed": "الكفايات التي تحتاج إلى تطوير",
		Focus: "محور التركيز",
		"Supervisory method": "الأسلوب الإشرافي",
		"Proposed implementation date": "التاريخ المقترح للتنفيذ",
		"Created by": "تم بواسطة",
		Average: "المعدل",
		Teacher: "المعلم",
		Class: "الصف",
		Course: "المادة",
		Collection: "المجمع",
		"Is sent": "تم الإرسال",
		"Professional development": "التطور المهني",
		Branch: "الفرع",
		Select: "اختر",
		"Element cant be duplicate": "لا يمكن تكرار العنصر أكثر من مرة",
		Term: "الفصل الدراسي",
		Procedures: "الإجراءات",
		Beneficiaries: "المستفيدون",
		Name: "الاسم",
		Sufficiency: "الكفاية",
		"Eleot tool": "أداة إليوت",
		Print: "طباعة",
		Period: "الحصة",
		"Period time": "وقت الحصة",
		"Visit date": "تاريخ الزيارة",
		"Students total": "عدد الطلاب",
		"Hide average": "إخفاء المعدل",
		"Enter phone number in form of 05xxxxxxxx": "أدخل رقم الجوال بالصيغة 05xxxxxxxx",
		Phone: "رقم الجوال",
		"error: Invalid phone number": "خطأ: رقم الجوال غير مرتبط بأي حساب ",
		"error: Error in sending code": "خطأ: لا يمكن إرسال الكود",
		"Confirm password": "تأكيد كلمة السر",
		Code: "الرمز",
		"Enter the code sent to your mobile number": "أدخل الرمز المرسل على رقم جوالك",
		"Enter the new password": "أدخل كلمةالسر الجديدة",
		"Re-enter the password": "أعد إدخال كلمة السر",
		Password: "كلمة السر",
		"error: Invalid code": "الرمز غير صحيح",
	},
};
