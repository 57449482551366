export const environment = {
	production: false,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
	//apiUrl: 'http://35.240.66.192:8086/api/',
	apiUrl: 'https://api.moznlms.com/api/',
	//apiUrl: 'http://localhost:4969/api/',
	fileRepoUrl: "http://35.240.66.192:8086/",
	accessControlAllowOrigins: "http://localhost:4200, http://localhost:4202, http://api.moznlms.com, http://moznlms.com, http://35.240.66.192:8086/, https://www.lms.msc.edu.sa/, http://www.contoso.com, http://devlms.msc.edu.sa",
	examModuleUrl: window.location.origin,
	loginRedirectUrl: "/dashboard",
	vesrion: require('../../package.json').version + '-dev',
	moduleNameIndexFromUrl: 1,
	sso: {
		authorization_endpoint: 'https://iambeta.elm.sa/authservice/authorize',
		scope: "openid",
		response_type: "id_token",
		response_mode: "form_post",
		client_id: 16376805,
		redirect_uri: "http://devlms.msc.edu.sa/auth/callback",
		prompt: "login",
		privateKey: "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDCRl3mFqAFxKJq40ocecVndWzoMoTcjA9vWlsyTwSgw5x83qTUdH3c88yPC/tI+rbRQ0QSW+Dqo9eXTXk8vzgCUHkSQbLrxGI7RS7LEy0pDdZkAU0HM6IHC8uO9ZCd3deK+v4zfUdGHG7J9wdmrRvOZNl7TfZ2HeO1CfT0d202tuapwp2h9XPLMs8AkCM9p6ST2ByZtkMRSm1YNjrnttQ8yNgyFb4cIr54sRy616ANeYd5xWNSdDTUMoeY/QTw8/OcuOIrFsRB3+EK8S8SKdcajP4SwpSpccWzDz5W+ljW1Zm5gjrAID63VK/MbT3ShpmVk/IVepY0G6tMVVXlVqTxAgMBAAECggEADujzHmxImkC53V21y6Mt2w+YTObJqxqFubxTyPPnDUaN/UhiGpsZKMq+PZFEFxkEzGEu+YC7LY7nBinwPtEHJahS7muD5ZPUHSvlWDkPy3mQJWszI/Uav5Mwa3edwnvq5bP3K/D2tiMK/q2akA8Dlg7H4S591plbJ0MNHXEH/P2giDtxc3D2JLGuYYVYW6NKvTVMd9B37/uqTsGNsrAxH7oebMinT2EZ2kRX7qncskavvELo6jjXpavoZSv+konYVhOn9N6XloEjQ6V/MVRxhDrYfuZJv8JB+VEfb9/ps5T8UFXnN30+K5vqba5m+c/o/14LpAWPi1YeB8pJKrKN5QKBgQD9ys+ddyRbG+do5BwZbp4QGfBedAcE2jtjDTAf5Q4M5YxlVSUW89ZEjKm0H5zVsBGNchBuZXEfc5WvQl/DOlANIBLzNNV8c7xg4YgGkaGv3gxPBEAiwMS67Nh87q6mN/UZVPeaUsUixwP8w5Ar9BfdO+/AJ6uzg8CEZ9SfY2bsXQKBgQDD9wMZrDBo50pTgrt6iLpj6FZiXHP/pvayUkIg2ev1sxFUv+Yzl/9A4r4yFRnD7pvnZ4aup9RnnSBpLMULvD49WM3i0lg2OmKtABtvB2cDqcKsWL3IkldezTuXLPUN3eQOkgHP+7rjtHSuhnlAoeiuq47k58eAhrbGCj1aS6SxpQKBgQCD/YpkdqYtzCFZ5+jFQUZrUVI1jVppJ6ZjKWSx8cqQ4oFV4iZb/YyeMhnAWACVEfDPA1eQUqm2lcJvV7G+MwGXEetJGV0+UGLUb/byk6LneDAmmCtS57ZpEOy2bNvgDpImDo6HcCyenapxNrQwZ85RfO5/nSIrRoZFVkq6alQI8QKBgB8CwN/YKtT+/PkYMBjk+DIFO15XaRfk0RdJAyP1uGxqSOzTbVduzzryZszAIBKseMl2zY5oCqnW6KEcL5Z61JKasgIZ7VGvjZv4715zmm6u95qUdHwABImBCtQSjjkCiytvGZBTMrEEBo7zws5ikNLLXVKGB0EkkFnolurqG4btAoGBAInzW033J1r7S/5/3kKBnaUX+Ftt9QOpx7b7LyC/XBQ0fZKGpb9wimiGmqSt3Sv4aAIDjvLAlfiVzmNCBx4znEd1LIIFw3gjOhoRA9t1SvFSkzMQ3YZC5kqHij7knQlxxmInmKbJjCsNCOvgATEv4mvBf/FCOnuAtfpluz99DXkL"
	}
};
