import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";
import { PreUserModel } from "../_models/pre-user.model";
import { PaginationModel } from "../_models/pagination.model";
import { PaginationResponseDetails, PaginationResponseModel } from "../_models/pagination-response.model";
import { PageEvent } from "@angular/material";

@Injectable()
export class PreUserService {

	constructor(private http: HttpClient, private translate: TranslateService) { }

	private entityName: string = "PreUsers";

	getPreUsers(page?: PageEvent): Observable<PaginationResponseModel> {
		let requestUrl: string = environment.apiUrl + this.entityName + "/GetPreUser";

		//Add pagination params if exist.
		if (page && page.pageIndex != null && page.pageSize != null) {
			requestUrl += "?page=" + page.pageIndex;
			requestUrl += "&pageSize=" + page.pageSize;
		}

		return this.http.get<PaginationResponseModel>(requestUrl);
	}

	getFilteredPreUser(page?: PageEvent, text?: string, gender?: number, groupId?: number, branchId?: number, isActive?: boolean, isDeleted?: boolean): Observable<PaginationResponseModel> {

		let queryStringParams: string[] = [];

		queryStringParams.push("?1=1");

		if (text)
			queryStringParams.push("&txt=" + text);

		if (gender)
			queryStringParams.push("&Gender=" + gender);

		if (groupId)
			queryStringParams.push("&GroupId=" + groupId);

		if (branchId)
			queryStringParams.push("&branchId=" + branchId);

		if (isActive != null)
			queryStringParams.push("&isActive=" + isActive);

		if (isDeleted != null)
			queryStringParams.push("&IsDeleted=" + isDeleted);

		//Add pagination params if exist.
		if (page && page.pageIndex != null && page.pageSize != null) {
			queryStringParams.push("&page=" + page.pageIndex);
			queryStringParams.push("&pageSize=" + page.pageSize);
		}

		let requestUrl: string = environment.apiUrl + this.entityName + "/GetFilteredPreUser/" + queryStringParams.join('');

		return this.http.get<PaginationResponseModel>(requestUrl)
			.pipe(
				map((response: PaginationResponseModel) => {

					if (response.pagingDetails == undefined) {
						let tempPagination: PaginationResponseModel = new PaginationResponseModel();
						tempPagination.isSinglResult = true;
						tempPagination.results = response;

						let tempPaginationDetail: PaginationResponseDetails = new PaginationResponseDetails();
						tempPaginationDetail.totalRecordsCount = tempPagination.results.length;

						tempPagination.pagingDetails = tempPaginationDetail;

						return tempPagination;
					}

					return response;
				})
			);
	}

	getPreUserById(userId: number): Observable<PreUserModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetPreUser/" + userId;

		return this.http.get<PreUserModel>(requestUrl).pipe(
			map((serviceResponse: PreUserModel) => {
				serviceResponse.displayBrachSchoolName =
					this.translate.currentLang == enums.langs.ar
						? serviceResponse.brachSchoolNameAr
						: serviceResponse.brachSchoolName;
				serviceResponse.displayBranchName =
					this.translate.currentLang == enums.langs.ar
						? serviceResponse.branchNameAr
						: serviceResponse.branchName;

				return serviceResponse;
			})
		);
	}

	getPreUserByUsername(username: string): Observable<PreUserModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetPreUserByUsername/" + username;

		return this.http.get<PreUserModel>(requestUrl).pipe(
			map((serviceResponse: PreUserModel) => {

				if (serviceResponse != null) {
					serviceResponse.displayBrachSchoolName = this.translate.currentLang == enums.langs.ar ? serviceResponse.brachSchoolNameAr : serviceResponse.brachSchoolName;
					serviceResponse.displayBranchName = this.translate.currentLang == enums.langs.ar ? serviceResponse.branchNameAr : serviceResponse.branchName;
				}

				return serviceResponse;
			})
		);
	}

	getPreUsersByParentId(parentId: number): Observable<PreUserModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetPreUsersByParentId/" + parentId;

		return this.http.get<PreUserModel[]>(requestUrl).pipe(
			map((serviceResponses: PreUserModel[]) => {

				serviceResponses.forEach(element => {
					element.displayBrachSchoolName = this.translate.currentLang == enums.langs.ar ? element.brachSchoolNameAr : element.brachSchoolName;
					element.displayBranchName = this.translate.currentLang == enums.langs.ar ? element.branchNameAr : element.branchName;
				});

				return serviceResponses;
			})
		);
	}

	postPreUser(user: PreUserModel): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostPreUser/";

		return this.http.post<any>(requestUrl, user);
	}

	postMultiplePreUsers(users: PreUserModel[]): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostMultiplePreUsers/";

		return this.http.post<any>(requestUrl, users);
	}

	putPreUser(user: PreUserModel): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PutPreUser/";

		return this.http.post<any>(requestUrl, user);
	}

	deletePreUser(userId: number): Observable<PreUserModel> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/DeletePreUser/" + userId;

		return this.http.get<PreUserModel>(requestUrl);
	}
}
