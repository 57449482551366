import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { UserNotification } from "../_models/notification.model";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";

export class NotificationService {
	getCounts(userId: number): Observable<{ count: number }> {
		const requestUrl = environment.apiUrl + this.entityName + '/GetCounts';
		return this.http.get<{ count: number }>(requestUrl);
	}
	constructor(private http: HttpClient, private translate: TranslateService) { }

	private entityName: string = "UserNotifications";

	getNotifications(): Observable<UserNotification[]> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetAll/";
		return this.http.get<UserNotification[]>(requestUrl);
	}

	getNotification(id: number): Observable<UserNotification> {
		const requestUrl = environment.apiUrl + this.entityName + "/Get/" + id;
		return this.http.get<UserNotification>(requestUrl);
	}

	postNotification(value: UserNotification): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostNotification/";
		return this.http.post<any>(requestUrl, value);
	}

	putNotification(value: UserNotification): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PutNotification/";
		return this.http.post<any>(requestUrl, value);
	}

	deleteNotification(id: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/Delete/" + id;
		return this.http.get<any>(requestUrl);
	}
}
