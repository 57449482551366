// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { retryWhen, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService } from './layout-utils.service';
import { environment } from '../../../../../environments/environment';
import { genericRetryStrategy } from './genericRetryStrategy';
import { AlertService, MessageSeverity } from '../../../../_services/alert.service';
import { Utilities } from '../../../../_services/utilities.service';
import { enums } from '../../../../../../src/enums/enums';

@Injectable()
export class InterceptService implements HttpInterceptor {
	constructor(private alertService: AlertService,
		private layoutUtilsService: LayoutUtilsService, private translate: TranslateService) { }

	// intercept request and add token
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		request = request.clone({
			setHeaders: {
				'Access-Control-Allow-Origin': environment.accessControlAllowOrigins,
				token: localStorage.getItem(enums.LocalStorageKeys.AccessToken)
			}
		});

		const { shouldRetry } = this;

		return next.handle(request).pipe(
			retryWhen(genericRetryStrategy({ shouldRetry })),
			tap(
				event => {
					if (event instanceof HttpResponse) {
						if (event.body && event.body.error != undefined)
							this.layoutUtilsService.showActionNotification(event.body.detail, undefined, undefined, undefined, false);

						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					let errorMessage: string = null;

					switch (error.status) {
						case 400: {
							let errors = Utilities.getHttpResponseMessages(error);
							let translatedErros = [];
							if (errors.length)
								errors.forEach(x => {
									translatedErros.push(this.translate.instant(x));
								});

							this.alertService.showStickyMessage(this.translate.instant('Error'), `${translatedErros}`,
								MessageSeverity.error, error);
							break;
						}
						case 401:
							errorMessage = this.translate.instant("COMMON.HTTP_RESPONSE_MESSAGE.UNAUTHORIZED");
							break;
						case 409:
							if (error.error.message)
								errorMessage = this.translate.instant("COMMON.HTTP_RESPONSE_MESSAGE.CONFLICT_VALUE") + error.error.message;
							else
								errorMessage = this.translate.instant("COMMON.HTTP_RESPONSE_MESSAGE.CONFLICT_VALUE");
							break;
						default:
							errorMessage = this.translate.instant("COMMON.HTTP_RESPONSE_MESSAGE.ERROR")
							break;
					}

					if (errorMessage != null) {
						this.layoutUtilsService.showActionNotification(errorMessage, undefined, undefined, undefined, false);
					}
				}
			)
		);
	}

	private shouldRetry = (error) => error.status === 502;
}
