import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { SchoolYearModel } from "../_models/school-year.model";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";

@Injectable()
export class SchoolYearService {
	constructor(private http: HttpClient, private translate: TranslateService) { }

	private entityName: string = "SchoolYears";

	getSchoolYears(): Observable<SchoolYearModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetSchoolYear/";
		return this.http.get<SchoolYearModel[]>(requestUrl)
			.pipe(map((serviceResponse: SchoolYearModel[]) => {

				serviceResponse.forEach(element => {
					element.displayTitle = this.translate.currentLang == enums.langs.ar ? element.titleAr : element.title;
				});

				return serviceResponse;
			}));

	}

	getSchoolYear(id: number): Observable<SchoolYearModel> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/GetSchoolYear/" + id;
		return this.http.get<SchoolYearModel>(requestUrl).pipe(map(element => {

			element.displayTitle = this.translate.currentLang == enums.langs.ar ? element.titleAr : element.title;
			return element;
		}));
	}

	getCurrentSchoolYear(): Observable<SchoolYearModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetCurrentSchoolYear/";

		return this.http.get<SchoolYearModel>(requestUrl).pipe(map((response: SchoolYearModel) => {
			response.displayTitle = this.translate.currentLang == enums.langs.ar ? response.titleAr : response.title;
			return response;
		}));
	}

	PostSchoolYear(value: SchoolYearModel): Observable<any> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/PostSchoolYear/";
		return this.http.post<any>(requestUrl, value);
	}

	putSchoolYear(value: SchoolYearModel): Observable<any> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/PutSchoolYear/";
		return this.http.post<any>(requestUrl, value);
	}

	deleteSchoolYear(id: number): Observable<any> {
		const requestUrl =
			environment.apiUrl + this.entityName + "/DeleteSchoolYear?id=" + id;
		return this.http.get<any>(requestUrl);
	}
}
