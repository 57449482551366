// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
// RxJS
import { finalize, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Auth
import { AuthNoticeService, AuthService } from "../../../../core/auth";
import { UserService } from "../../../../_services/user.service";

@Component({
	selector: "kt-forgot-password",
	templateUrl: "./forgot-password.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	mode: "phone" | "code" | "password" = "phone";
	loading = false;
	errors: any = [];
	phone: string;
	code?: number;
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	password?: string;
	confirmPassword?: string;

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(private authService: AuthService, private userService: UserService, public authNoticeService: AuthNoticeService, private translate: TranslateService, private router: Router, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			phone: [
				"",
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.pattern("^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$"),
					Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				]),
			],
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}

		this.loading = true;

		const phone = controls.phone.value;
		this.userService
			.forgotPassword(phone)
			.pipe(
				tap((response) => {
					this.mode = "code";
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	submitCode() {
		if (this.code) {
			this.loading = true;
			this.userService
				.validateActivationCode(this.phone, this.code)
				.pipe(
					tap((response) => {
						this.mode = "password";
					}),
					takeUntil(this.unsubscribe),
					finalize(() => {
						this.loading = false;
						this.cdr.markForCheck();
					})
				)
				.subscribe();
		}
	}
	back() {
		if (this.mode === "phone") this.router.navigate(["/auth/login"]);
		else if (this.mode === "code") this.mode = "phone";
		else this.mode = "code";
	}
	submitPassword() {
		if (this.password && this.confirmPassword == this.password) {
			this.loading = true;
			this.userService
				.resetPassword(this.phone, this.code, this.password)
				.pipe(
					tap((response) => {
						this.authNoticeService.setNotice(this.translate.instant("AUTH.FORGOT.SUCCESS"), "success");
						this.mode = "password";
						setTimeout(() => {
							this.router.navigate(["/auth/login"]);
						}, 100);
					}),
					takeUntil(this.unsubscribe),
					finalize(() => {
						this.loading = false;
						this.cdr.markForCheck();
					})
				)
				.subscribe();
		}
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
