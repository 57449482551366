import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { enums } from '../../enums/enums';
import { environment } from '../../environments/environment';
import { Announcements, AnnouncementsViewers } from '../_models/announcements.model';
import { Assessments } from '../_models/Assessments';
import { PaginationResponseModel, PaginationResponseDetails } from '../_models/pagination-response.model';

@Injectable()
export class AnnouncementsService {

  constructor(private http: HttpClient, private translate: TranslateService) { }

	private entityName: string = "Announcements";

  GetAnnouncements(UserID:number , GroupId:number , Title?:string, page?: PageEvent ): Observable<PaginationResponseModel> {
    let requestUrl: string = environment.apiUrl + this.entityName + "/GetAnnouncements";

    const queryParams = [];
    queryParams.push("?1=1");

    //Add pagination params if exist.
    if (page && page.pageIndex != null && page.pageSize != null) {
      queryParams.push("&page=" + page.pageIndex)
      queryParams.push("&pageSize=" + page.pageSize)
    }

    queryParams.push("&UserID=" + UserID)
    queryParams.push("&GroupId=" + GroupId)
    if(Title) queryParams.push("&Title=" + Title)

    return this.http.get<PaginationResponseModel>(requestUrl + queryParams.join('')).pipe(map(response => {

      if (response.pagingDetails == undefined) {
        let tempPagination: PaginationResponseModel = new PaginationResponseModel();
        tempPagination.isSinglResult = true;
        tempPagination.results = response;

        let tempPaginationDetail: PaginationResponseDetails = new PaginationResponseDetails();
        tempPaginationDetail.totalRecordsCount = tempPagination.results.length;

        tempPagination.pagingDetails = tempPaginationDetail;


        return tempPagination;
      }


      return response;

    }), map(res => {

      const lang = this.translate.currentLang;

      res.results.forEach(x => {

        x.displaySchoolsName = lang == enums.langs.ar ? x.school.schoolsNameAr : x.school.schoolsNameEn;
        x.displayGroupsName = lang == enums.langs.ar ? x.grade.groupsNameAr : x.grade.groupsNameEn;


      });

      return res

    }));
  }

  GetCurrentAnnouncement(UserID:number ,  GroupId:number): Observable<Array<Announcements>> {

    let requestUrl: string = environment.apiUrl + this.entityName + "/GetCurrentAnnouncement?UserID=" + UserID + "&GroupId=" + GroupId;

    return this.http.get<Array<Announcements>>(requestUrl)
  }


  GetAnnouncementById(id: number): Observable<Announcements> {
    let requestUrl: string = environment.apiUrl + this.entityName + "/GetAnnouncementById/" + id;


    return this.http.get<Announcements>(requestUrl)
  }


  PostAnnouncement(announcements: Announcements): Observable<Announcements> {
    let requestUrl: string = environment.apiUrl + this.entityName + "/PostAnnouncement";

    return this.http.post<Announcements>(requestUrl, announcements);
  }

  PutAnnouncement(announcements: Announcements): Observable<Announcements> {
    let requestUrl: string = environment.apiUrl + this.entityName + "/PutAnnouncement";

    return this.http.post<Announcements>(requestUrl, announcements);
  }


  DeleteAnnouncement(id: number): Observable<Assessments> {
    let requestUrl: string = environment.apiUrl + this.entityName + "/DeleteAnnouncement/" + id;

    return this.http.delete<Assessments>(requestUrl);
  }

  ViewAnnouncement(announcementsViewers: AnnouncementsViewers): Observable<AnnouncementsViewers> {
    let requestUrl: string = environment.apiUrl + this.entityName + "/ViewAnnouncement";

    return this.http.post<AnnouncementsViewers>(requestUrl, announcementsViewers);
  }

  

}

