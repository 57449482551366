// Angular
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// State
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { UserModel } from '../../../../../_models/user.model';
import { AuthService } from '../../../../../_services/auth.service';
import { GroupModel } from '../../../../../../../src/app/_models/group.model';
import { UserService } from '../../../../../../../src/app/_services/user.service';
import { MessageService } from '../../../../../../app/_services/message.service';
import { NotificationService } from './../../../../../_services/notification.service';
import { enums } from './../../../../../../../src/enums/enums';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, OnDestroy {
	// Public properties
	user$: Observable<UserModel>;
	routeLinkForProfileEdit: string;

	isProd = environment.production;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	groupsOfLoggedInUser: GroupModel[] = [];
	newMessages = 0;
	newAnnouncements = 0;
	newNotification = 0;

	interval;

	constructor(private router: Router, private notificationService: NotificationService,
		private authService: AuthService, private userService: UserService, private messageService: MessageService, private cdRef: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.badge = false;
		this.avatar = false;
		this.icon = false;

		let localStorageUser = localStorage.getItem(enums.LocalStorageKeys.LoggedInUser);

		if (localStorageUser != null) {
			this.groupsOfLoggedInUser = this.authService.loggedUser.assignedGroups;

			if (this.authService.loggedUser.profileImagePath) {
				this.avatar = true;
				this.badge = false;
			}
			else {
				this.avatar = false;
				this.badge = true;
			}

			this.user$ = JSON.parse(localStorageUser);
			this.routeLinkForProfileEdit = ("/user-management/users/profile/" + btoa(this.user$["userID"]));
		}

		this.loadNewMessages()

		this.interval = setInterval(x => {
			this.loadNewMessages();
		}, 3000 * 10)
	}

	logout() {
		//Clear All Local Storage.
		localStorage.clear();
		this.authService.showTawk.emit(false);
		this.router.navigateByUrl("/auth/login");
	}

	redirectToEditProfile() {
		let userId: number = this.authService.loggedUser.userID;
		this.router.navigateByUrl("/user-management/users/profile/" + btoa(userId.toString()));
	}

	async swithUser(group: GroupModel) {
		this.authService.loggedUser.groupId = group.groupId;

		await this.userService.putUser(this.authService.loggedUser).toPromise().then();
		localStorage.setItem(enums.LocalStorageKeys.DefualtGroupId, group.groupId.toString())

		document.location.href = environment.loginRedirectUrl;
	}

	isActiveGroup(group: GroupModel) {
		let result: boolean = false;

		if (this.authService.loggedUser.groupId == group.groupId)
			result = true;

		return result;
	}

	loadNewMessages() {

		this.messageService.getCounts(this.authService.loggedUser.userID).subscribe(response => {

			this.newMessages = response.unreadInboxCount;
			if (this.newMessages > 99) this.newMessages = 99;
			this.cdRef.detectChanges();
		})

	}
	loadNewNotifications() {
		this.notificationService.getCounts(this.authService.loggedUser.userID).subscribe(response => {
			this.newNotification = response.count;
			if (this.newNotification > 99) this.newNotification = 99;
			this.cdRef.detectChanges();
		})
	}

	ngOnDestroy(): void {
		clearInterval(this.interval);
	}
}

