// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// RxJS
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
// Auth
import { UserService } from "../../../../../app/_services/user.service";
import { UserModel } from "../../../../../app/_models/user.model";
import { environment } from "../../../../../environments/environment";
import { AuthNoticeService } from "../../../../../app/core/auth";
import { AuthService } from "../../../../../app/_services/auth.service";
import moment from "moment";
import { enums } from "../../../../../enums/enums";
import { v4 as uuidv4 } from "uuid";

@Component({
	selector: "kt-login",
	templateUrl: "./login.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	isProd: boolean = environment.production;
	vesrion = environment.vesrion;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	constructor(private userService: UserService, private authService: AuthService, private router: Router, private authNoticeService: AuthNoticeService, private translate: TranslateService, private store: Store<AppState>, private fb: FormBuilder, private cdr: ChangeDetectorRef, private route: ActivatedRoute) {
		this.unsubscribe = new Subject();
	}

	ngOnInit(): void {
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe((params) => {
			this.returnUrl = params.returnUrl || "/";
		});
		if (localStorage.getItem("loggedInUser")) this.router.navigate(["/"]);

		//this.isProd = true;
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	initLoginForm() {
		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = this.translate.instant("AUTH.GENERAL.PLEASE_ENTER_USERNAME_AND_PASSWORD");
			this.authNoticeService.setNotice(initialNotice, "info");
		}

		this.loginForm = this.fb.group({
			email: [
				"",
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				]),
			],
			password: ["", Validators.compose([Validators.required, Validators.minLength(6)])],
		});
	}

	submit() {
		const controls = this.loginForm.controls;

		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}

		this.loading = true;

		const authData = {
			email: controls.email.value,
			password: controls.password.value,
		};

		this.authService.loginUser(authData.email, authData.password).subscribe((response) => {
			if (response && response.token) {
				if (response.isActive) {
					localStorage.setItem(enums.LocalStorageKeys.AccessToken, response.token);
					localStorage.setItem(enums.LocalStorageKeys.LoggeddUserId, response["userId"]);
					localStorage.setItem(enums.LocalStorageKeys.DefualtGroupId, response["groupid"]);
					const tokenExpiresAt = moment(Date.parse(response["createdDate"])).add(enums.auth.tokenAge, "hours").toLocaleString();
					localStorage.setItem(enums.LocalStorageKeys.TokenExpiresAt, tokenExpiresAt);

					//Set login status.
					this.authService.loggedIn = true;

					//Refresh and Redirect
					document.location.href = environment.loginRedirectUrl;
				} else {
					this.authNoticeService.setNotice(this.translate.instant("AUTH.VALIDATION.IN_ACTIVE_USER"), "danger");
				}
			} else {
				this.authNoticeService.setNotice(this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"), "danger");
			}

			this.loading = false;
		});
	}

	devSubmit(username, password) {
		//This code will be work only development environment.
		if (this.isProd == false) {
			this.loginForm.patchValue({
				email: username,
				password: password,
			});

			this.submit();
		}
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	loginWithSSO() {
		let nonce = uuidv4();

		let state: string = "";

		//Encode Base64
		state = btoa(environment.sso.privateKey);

		//Encode URI
		state = encodeURI(state);

		//unix timestamp in seconds
		let currentInSecond = Math.floor(Date.now() / 1000);

		// Add 5 minute
		let currentDate: Date = new Date();
		currentDate.setMinutes(currentDate.getMinutes() + 5);

		currentInSecond = currentDate.getTime() / 1000;

		//Generate Redirect URL with query string parameters
		let queryStringArray: string[] = [];
		queryStringArray.push(environment.sso.authorization_endpoint);
		queryStringArray.push("?scope=" + environment.sso.scope);
		queryStringArray.push("&response_type=" + environment.sso.response_type);
		queryStringArray.push("&response_mode=" + environment.sso.response_mode);
		queryStringArray.push("&client_id=" + environment.sso.client_id);
		queryStringArray.push("&redirect_uri=" + environment.sso.redirect_uri);
		queryStringArray.push("&nonce=" + nonce);
		queryStringArray.push("&ui_locales=" + enums.langs.en);
		queryStringArray.push("&prompt=" + environment.sso.prompt);
		queryStringArray.push("&max_age=" + currentInSecond);
		queryStringArray.push("&state=" + state);

		//Redirect to the SSO address.
		window.location.href = queryStringArray.join("");
	}
}
