import { HttpErrorResponse } from '@angular/common/http';
import { timer, throwError, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { enums } from '../../../../../enums/enums';

export interface RetryParams {
    maxAttempts?: number;
    scalingDuration?: number;
    shouldRetry?: ({ status: number }) => boolean;
}

const defaultParams: RetryParams = {
    maxAttempts: 3,
    scalingDuration: 1000,
    shouldRetry: ({ status }) => (status >= 400)
}

export const genericRetryStrategy = (params: RetryParams = {}) => (attempts: Observable<any>) => attempts.pipe(
    mergeMap((errorResponse, i) => {
        let error: HttpErrorResponse = errorResponse;

        if (error.status == enums.httpErrorStatus.conflict || error.status == enums.httpErrorStatus.unauthorized) {
            return throwError(errorResponse);
        }

        const { maxAttempts, scalingDuration, shouldRetry } = { ...defaultParams, ...params }
        const retryAttempt = i + 1;

        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        // || !shouldRetry(error)
        if (retryAttempt > maxAttempts) {
            return throwError(errorResponse);
        }

        console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);

        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
    })
);