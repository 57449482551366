import { Subscription } from "rxjs";
// Angular
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from "./core/_base/layout";
// language list
import { locale as enLang } from "./core/_config/i18n/en";
import { locale as arLang } from "./core/_config/i18n/ar";
import { AuthService } from "../app/_services/auth.service";
import { DOCUMENT } from "@angular/common";
import { AlertCommand, AlertService, MessageSeverity } from "./_services/alert.service";
import { ToastaConfig, ToastaService, ToastData, ToastOptions } from "ngx-toasta";
import { environment } from "../environments/environment";
import { SessionsService } from "./_services/session.service";
import { currentUser } from "./core/auth/_selectors/auth.selectors";

@Component({
	// tslint:disable-next-line:component-selector
	selector: "body[kt-root]",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = "Metronic";
	tawkId = "5ba692d1c9abba579677cd9b";
	showTawk;
	currentUser;
	loader: boolean;
	currentUrl: string;
	stickyToasties: number[] = [];
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private toastaService: ToastaService,
		private toastaConfig: ToastaConfig,
		private session: SessionsService,
		private alertService: AlertService,
		private translationService: TranslationService,
		public authService: AuthService,
		@Inject(DOCUMENT) private document: Document,

		private router: Router,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService
	) {
		router.events.subscribe((val: NavigationStart) => {
			if (this.currentUrl != val.url && val.url) {
				this.currentUrl = val.url;
				this.checkAppVersion();
			}
		});
		// register translations
		this.translationService.loadTranslations(enLang, arLang);
	}
	checkAppVersion() {
		this.session.getVersion().subscribe((x) => {
			var localVersion = localStorage.getItem("curVersion");
			let version = environment.vesrion;
			if (version != x.v && localVersion != x.v) {
				window.location.href = window.location.href;
				localStorage.setItem("curVersion", x.v);
			}
		});
	}
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig("loader.enabled");

		this.authService.showTawk.subscribe((value: boolean) => {
			this.showTawk = value;
		});

		const routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add("kt-page--loaded");
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
		this.alertService.getMessageEvent().subscribe((message) => this.showToast(message));
	}
	showToast(alert: AlertCommand) {
		if (alert.operation === "clear") {
			for (const id of this.stickyToasties.slice(0)) {
				this.toastaService.clear(id);
			}

			return;
		}

		const toastOptions: ToastOptions = {
			title: alert.message.summary,
			msg: alert.message.detail,
		};

		if (alert.operation === "add_sticky") {
			toastOptions.timeout = 0;

			toastOptions.onAdd = (toast: ToastData) => {
				this.stickyToasties.push(toast.id);
			};

			toastOptions.onRemove = (toast: ToastData) => {
				const index = this.stickyToasties.indexOf(toast.id, 0);

				if (index > -1) {
					this.stickyToasties.splice(index, 1);
				}

				if (alert.onRemove) {
					alert.onRemove();
				}

				toast.onAdd = null;
				toast.onRemove = null;
			};
		} else {
			toastOptions.timeout = 4000;
		}

		switch (alert.message.severity) {
			case MessageSeverity.default:
				this.toastaService.default(toastOptions);
				break;
			case MessageSeverity.info:
				this.toastaService.info(toastOptions);
				break;
			case MessageSeverity.success:
				this.toastaService.success(toastOptions);
				break;
			case MessageSeverity.error:
				this.toastaService.error(toastOptions);
				break;
			case MessageSeverity.warn:
				this.toastaService.warning(toastOptions);
				break;
			case MessageSeverity.wait:
				this.toastaService.wait(toastOptions);
				break;
		}
	}
	flag = false;
	ngAfterViewChecked() {
		const tawkIframes = document.querySelectorAll<HTMLElement>(".widget-visible iframe");
		if (tawkIframes.length > 0) {
			tawkIframes.forEach((x) => {
				x.style.left = this.translationService.getSelectedLanguage() == "ar" ? "10px" : "auto";
				x.style.right = this.translationService.getSelectedLanguage() == "ar" ? "auto" : "10px";
			});
		}
		
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
	}
}
