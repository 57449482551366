import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserModel } from "../_models/user.model";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { UserFilterModel } from "../_models/user-filter.model";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";
import { PaginationResponseModel } from "../_models/pagination-response.model";
import { BoxUserAttachmentModel } from "../_models/box-user-attachment.model";
import { BoxUserMotherModel } from "../_models/box-user-mother.model";

@Injectable()
export class UserService {
	resetPassword(phone: string, code: number, password: string) {
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post(`${environment.apiUrl}${this.entityName}/ResetPassword/${phone}/${code}/${password}`, null).pipe(
			map((res: string) => {
				return res;
			}),
			(err) => {
				return err;
			}
		);
	}
	constructor(private http: HttpClient, private translate: TranslateService) {}

	private entityName: string = "Users";

	getUserById(userId: number, groupId?: number): Observable<UserModel> {
		let queryStringParams: string[] = [];
		queryStringParams.push("?id=" + userId);
		if (typeof groupId !== "undefined") queryStringParams.push("&groupId=" + groupId);

		const requestUrl = environment.apiUrl + this.entityName + "/GetUserById/" + queryStringParams.join("");

		return this.http.get<UserModel>(requestUrl).pipe(
			map((serviceResponse: UserModel) => {
				serviceResponse.displayBrachSchoolName = this.translate.currentLang == enums.langs.ar ? serviceResponse.brachSchoolNameAr : serviceResponse.brachSchoolName;
				serviceResponse.displayBranchName = this.translate.currentLang == enums.langs.ar ? serviceResponse.branchNameAr : serviceResponse.branchName;

				if (!serviceResponse.profileImagePath) serviceResponse.profileImagePath = enums.DefaultEmptyImagePath;

				serviceResponse.boxGradeUsers.forEach((boxGradeUser) => {
					if (boxGradeUser.schoolGradeSection.grade) boxGradeUser.schoolGradeSection.grade.displayName = this.translate.currentLang == enums.langs.ar ? boxGradeUser.schoolGradeSection.grade.gradeNameAr : boxGradeUser.schoolGradeSection.grade.gradeName;

					if (boxGradeUser.schoolGradeSection.section) boxGradeUser.schoolGradeSection.section.displayName = this.translate.currentLang == enums.langs.ar ? boxGradeUser.schoolGradeSection.section.sectionNameAr : boxGradeUser.schoolGradeSection.section.sectionName;

					if (boxGradeUser.schoolGradeSection.school) boxGradeUser.schoolGradeSection.school.displaySchoolName = this.translate.currentLang == enums.langs.ar ? boxGradeUser.schoolGradeSection.school.schoolName : boxGradeUser.schoolGradeSection.school.schoolNameEn;
				});

				//Prepare Assigned Groups
				serviceResponse.assignedGroups = [];
				serviceResponse.groupIds.forEach((groupId) => {
					let foundGroup = serviceResponse.allGroups.find((x) => x.groupId == groupId);
					foundGroup.displayName = this.translate.currentLang == enums.langs.ar ? foundGroup.groupNameAr : foundGroup.groupName;

					serviceResponse.assignedGroups.push(foundGroup);
				});

				return serviceResponse;
			})
		);
	}

	getUserByUsername(username: string): Observable<UserModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetUserByUsername/" + username;

		return this.http.get<UserModel>(requestUrl).pipe(
			map((serviceResponse: UserModel) => {
				if (serviceResponse != null) {
					serviceResponse.displayBrachSchoolName = this.translate.currentLang == enums.langs.ar ? serviceResponse.brachSchoolNameAr : serviceResponse.brachSchoolName;
					serviceResponse.displayBranchName = this.translate.currentLang == enums.langs.ar ? serviceResponse.branchNameAr : serviceResponse.branchName;

					if (!serviceResponse.profileImagePath) serviceResponse.profileImagePath = enums.DefaultEmptyImagePath;
				}

				return serviceResponse;
			})
		);
	}

	getFilteredUsers(value: UserFilterModel): Observable<PaginationResponseModel> {
		let queryStringParams: string[] = [];

		if (value.page) queryStringParams.push("?page=" + value.page);
		else queryStringParams.push("?page=0");

		if (value.pageSize) queryStringParams.push("&pageSize=" + value.pageSize);

		if (value.groupId) queryStringParams.push("&groupId=" + value.groupId);

		if (value.status == true || value.status == false) queryStringParams.push("&status=" + value.status);
		if (value.isAssigned == true || value.isAssigned == false) queryStringParams.push("&isAssigned=" + value.isAssigned);

		if (value.txt) queryStringParams.push("&txt=" + value.txt);

		if (value.schoolId) queryStringParams.push("&schoolId=" + value.schoolId);

		if (value.branchId) queryStringParams.push("&branchId=" + value.branchId);

		if (value.isEducator) queryStringParams.push("&isEducator=" + value.isEducator);

		if (value.gradeId) queryStringParams.push("&gradeId=" + value.gradeId);

		if (value.sectionId) queryStringParams.push("&sectionId=" + value.sectionId);

		if (value.loggedUserGroup) queryStringParams.push("&loggedUserGroup=" + value.loggedUserGroup);

		if (value.genderId) queryStringParams.push("&genderId=" + value.genderId);

		const requestUrl = environment.apiUrl + this.entityName + "/GetFilteredUsers/" + queryStringParams.join("");

		return this.http.get<PaginationResponseModel>(requestUrl).pipe(
			map((response: PaginationResponseModel) => {
				(response.results as UserModel[]).forEach((user) => {
					if (!user.profileImagePath) user.profileImagePath = enums.DefaultEmptyImagePath;
				});

				return response;
			})
		);
	}

	getUsers(value: UserFilterModel): Observable<PaginationResponseModel> {
		let queryStringParams: string[] = [];

		if (value.page) queryStringParams.push("?page=" + value.page);
		else queryStringParams.push("?page=0");

		if (value.pageSize) queryStringParams.push("&pageSize=" + value.pageSize);

		const requestUrl = environment.apiUrl + this.entityName + "/GetUser/" + queryStringParams.join("");

		return this.http.get<PaginationResponseModel>(requestUrl).pipe(
			map((response: PaginationResponseModel) => {
				(response.results as UserModel[]).forEach((user) => {
					if (!user.profileImagePath) user.profileImagePath = enums.DefaultEmptyImagePath;
				});

				return response;
			})
		);
	}

	getFilteredUsersWithoutPagenation(value: UserFilterModel): Observable<UserModel[]> {
		let queryStringParams: string[] = [];

		queryStringParams.push("?1=1");

		if (value.groupId) queryStringParams.push("&groupId=" + value.groupId);

		if (value.schoolId) queryStringParams.push("&schoolId=" + value.schoolId);

		if (value.branchId) queryStringParams.push("&branchId=" + value.branchId);

		if (value.status == true || value.status == false) queryStringParams.push("&status=" + value.status);

		if (value.txt) queryStringParams.push("&txt=" + value.txt);

		if (value.isEducator) queryStringParams.push("&isEducator=" + value.isEducator);

		if (value.gradeId) queryStringParams.push("&gradeId=" + value.gradeId);

		if (value.sectionId) queryStringParams.push("&sectionId=" + value.sectionId);

		const requestUrl = environment.apiUrl + this.entityName + "/GetFilteredUsersWithoutPagenation/" + queryStringParams.join("");

		return this.http.get<UserModel[]>(requestUrl).pipe(
			map((response: UserModel[]) => {
				response.forEach((user) => {
					if (!user.profileImagePath) user.profileImagePath = enums.DefaultEmptyImagePath;
				});

				return response;
			})
		);
	}

	async asyncgetFilteredUsers(value: UserFilterModel) {
		let queryStringParams: string[] = [];
		queryStringParams.push("?page=" + value.page);

		if (value.pageSize) queryStringParams.push("&pageSize=" + value.pageSize);

		if (value.groupId) queryStringParams.push("&groupId=" + value.groupId);

		if (value.status == true || value.status == false) queryStringParams.push("&status=" + value.status);

		if (value.txt) queryStringParams.push("&txt=" + value.txt);

		const requestUrl = environment.apiUrl + this.entityName + "/GetFilteredUsers/" + queryStringParams.join("");

		let responseResult: Array<UserModel> = new Array<UserModel>();

		await this.http
			.get<UserModel[]>(requestUrl)
			.toPromise()
			.then((response) => {
				responseResult = response["results"];
			});

		return responseResult;
	}

	getUsersByGroupId(groupId: number): Observable<UserModel[]> {
		let queryStringParams: string[] = [];
		queryStringParams.push("?groupId=" + groupId);
		//queryStringParams.push("&page=1");
		queryStringParams.push("&pageSize=100");

		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersByGroupId/" + queryStringParams.join("");

		return this.http.get<UserModel[]>(requestUrl);
	}

	getStudentsByParentUserId(parentId: number): Observable<UserModel[]> {
		let queryStringParams: string[] = [];
		queryStringParams.push("?parentId=" + parentId);

		const requestUrl = environment.apiUrl + this.entityName + "/GetStudentsByParentUserId/" + queryStringParams.join("");

		return this.http.get<UserModel[]>(requestUrl).pipe(
			map((response: UserModel[]) => {
				response.forEach((user) => {
					if (!user.profileImagePath) user.profileImagePath = enums.DefaultEmptyImagePath;
				});

				return response;
			})
		);
	}

	postUser(user: UserModel): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostUser/";

		return this.http.post<any>(requestUrl, user);
	}

	putUser(user: UserModel, updatePassword: boolean = false): Observable<any> {
		user.boxGradeUsers = null;
		let queryStringParams: string[] = [];
		queryStringParams.push("?updatePassword=" + updatePassword);

		//Set any password to pass by required property. It wont update password, if update is false
		if (!updatePassword) user.password = "Aa@123456";

		const requestUrl = environment.apiUrl + this.entityName + "/PutUser" + queryStringParams.join("");
		return this.http.post<any>(requestUrl, user);
	}

	deleteUser(userId: number): Observable<UserModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/DeleteUser/" + userId;

		return this.http.get<UserModel>(requestUrl);
	}

	getUserByGroupId(groupId: number): Observable<UserModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersByGroupId?groupId=" + groupId;

		return this.http.get<UserModel[]>(requestUrl);
	}

	restoreUser(userId: number): Observable<UserModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/RestoreUser/" + userId;

		return this.http.get<UserModel>(requestUrl);
	}

	getUsersBySchoolAndGroupId(schoolId: number, groupId: number) {
		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersBySchoolAndGroupId?SchoolId=" + schoolId + "&groupId=" + groupId;

		return this.http.get<UserModel[]>(requestUrl);
	}

	GetUsersByBranchAndGroupId(branchId: number, groupId: number) {
		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersByBranchAndGroupId?branchId=" + branchId + "&groupId=" + groupId;

		return this.http.get<UserModel[]>(requestUrl);
	}

	getEducatorsByUserId(userId: number) {
		const requestUrl = environment.apiUrl + this.entityName + "/GetEducatorsByUserId?userId=" + userId;
		return this.http.get<UserModel[]>(requestUrl);
	}

	getUsersBySectionIdAndCourseId(sectionId: number, courseId: number): Observable<UserModel[]> {
		let queryStringParams: string[] = [];
		queryStringParams.push("?sectionId=" + sectionId);
		queryStringParams.push("&courseId=" + courseId);
		queryStringParams.push("&page=" + enums.Pagination.DefaultPageIndex);
		queryStringParams.push("&pageSize=" + enums.Pagination.DefaultPageSize100);

		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersBySectionIdAndCourseId" + queryStringParams.join("");
		return this.http.get<UserModel[]>(requestUrl);
	}

	getUsersBySectionIdAndCourseIdAndGroupId(sectionId: number, courseId: number, groupId: number): Observable<UserModel[]> {
		let queryStringParams: string[] = [];
		queryStringParams.push("?sectionId=" + sectionId);
		queryStringParams.push("&courseId=" + courseId);
		queryStringParams.push("&groupId=" + groupId);
		queryStringParams.push("&page=" + enums.Pagination.DefaultPageIndex);
		queryStringParams.push("&pageSize=" + enums.Pagination.DefaultPageSize100);

		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersBySectionIdAndCourseIdAndGroupId" + queryStringParams.join("");
		return this.http.get<UserModel[]>(requestUrl);
	}

	GetUsersBySectionIdAndSchoolIdIdAndGroupIdAndGradeId(sectionId?: number, groupId?: number, schoolId?: number, gradeId?: number, studentStatus?: number, feesStatus?: number): Observable<UserModel[]> {
		let queryStringParams: string[] = [];

		queryStringParams.push("?1=1");

		if (sectionId) queryStringParams.push("&sectionId=" + sectionId);

		if (groupId) queryStringParams.push("&groupId=" + groupId);

		if (schoolId) queryStringParams.push("&schoolId=" + schoolId);

		if (studentStatus) queryStringParams.push("&studentStatus=" + studentStatus);

		if (feesStatus) queryStringParams.push("&feesStatus=" + feesStatus);

		if (gradeId) queryStringParams.push("&gradeId=" + gradeId);

		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersBySectionIdAndSchoolIdIdAndGroupIdAndGradeId" + queryStringParams.join("");
		return this.http.get<UserModel[]>(requestUrl);
	}

	getUsersByGroupIdAndStageIdAndBranchIdAndGradeId(groupID: number, stageID: number, branchID: number, gradeID: number, studentStatus: number, feesStatus: number): Observable<UserModel[]> {
		let queryStringParams: string[] = [];

		queryStringParams.push("?1=1");

		if (groupID) queryStringParams.push("&groupID=" + groupID);

		if (stageID) queryStringParams.push("&stageID=" + stageID);

		if (branchID) queryStringParams.push("&branchID=" + branchID);

		if (gradeID) queryStringParams.push("&gradeID=" + gradeID);

		if (studentStatus) queryStringParams.push("&studentStatus=" + studentStatus);

		if (feesStatus) queryStringParams.push("&feesStatus=" + feesStatus);

		const requestUrl = environment.apiUrl + this.entityName + "/GetUsersByGroupIdAndStageIdAndBranchIdAndGradeId" + queryStringParams.join("");

		return this.http.get<UserModel[]>(requestUrl);
	}

	GetStudentsAndParentsForTeacher(teacherId: number): Observable<UserModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetStudentsAndParentsForTeacher/" + teacherId;
		return this.http.get<UserModel[]>(requestUrl);
	}

	GetStudentsAndParentsByFaithfulTeacherGroup(teacherId: number): Observable<UserModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetStudentsAndParentsByFaithfulTeacherGroup/" + teacherId;
		return this.http.get<UserModel[]>(requestUrl);
	}

	//#region Attachment

	getAllBoxUserAttachments(userId?: number): Observable<BoxUserAttachmentModel[]> {
		let queryStringParams: string[] = [];
		queryStringParams.push("?1=1");

		if (userId != undefined) queryStringParams.push("&userId=" + userId);

		const requestUrl = environment.apiUrl + this.entityName + "/getAllBoxUserAttachments/" + queryStringParams.join("");

		return this.http.get<BoxUserAttachmentModel[]>(requestUrl);
	}

	postBoxUserAttachments(values: BoxUserAttachmentModel[]): Observable<BoxUserAttachmentModel[]> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostBoxUserAttachments/";
		return this.http.post<BoxUserAttachmentModel[]>(requestUrl, values);
	}

	deleteBoxUserAttachments(attacmentId: number): Observable<any> {
		let queryStringParams: string[] = [];

		queryStringParams.push("?id=" + attacmentId);

		const requestUrl = environment.apiUrl + this.entityName + "/DeleteBoxUserAttachments/" + queryStringParams.join("");
		return this.http.delete<any>(requestUrl);
	}

	//#endregion

	//#region User Mother

	getBoxUserMotherByParentId(parentId: number): Observable<BoxUserMotherModel[]> {
		let queryStringParams: string[] = [];

		queryStringParams.push("?ParentId=" + parentId);

		const requestUrl = environment.apiUrl + this.entityName + "/getBoxUserMotherByParentId" + queryStringParams.join("");
		return this.http.get<BoxUserMotherModel[]>(requestUrl).pipe(
			map((response: BoxUserMotherModel[]) => {
				response.forEach((mother) => {
					mother.displayMotherName = this.translate.currentLang == enums.langs.ar ? mother.motherNameAr : mother.motherName;
					mother.numberOfChild = mother.user.length;
				});

				return response;
			})
		);
	}

	getBoxUserMotherByStudentId(studentId: number): Observable<BoxUserMotherModel[]> {
		let queryStringParams: string[] = [];

		queryStringParams.push("?StudentId=" + studentId);

		const requestUrl = environment.apiUrl + this.entityName + "/getBoxUserMotherByStudentId" + queryStringParams.join("");
		return this.http.get<BoxUserMotherModel[]>(requestUrl);
	}

	postBoxUserMother(value: BoxUserMotherModel): Observable<BoxUserMotherModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/PostBoxUserMother/";
		return this.http.post<BoxUserMotherModel>(requestUrl, value);
	}

	putBoxUserMother(value: BoxUserMotherModel): Observable<BoxUserMotherModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/PutBoxUserMother/";
		return this.http.put<BoxUserMotherModel>(requestUrl, value);
	}

	updateStudentAssignedMother(studentId?: number, studentMotherId?: number): Observable<BoxUserMotherModel[]> {
		let queryStringParams: string[] = [];

		if (studentId != undefined) queryStringParams.push("?StudentId=" + studentId);

		if (studentMotherId != undefined) queryStringParams.push("&StudentMotherId=" + studentMotherId);

		const requestUrl = environment.apiUrl + this.entityName + "/UpdateStudentAssignedMother" + queryStringParams.join("");
		return this.http.post<BoxUserMotherModel[]>(requestUrl, null);
	}
	forgotPassword(phone: string): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post(`${environment.apiUrl}${this.entityName}/RequestActivationCode/${phone}`, null).pipe(
			map((res: string) => {
				return res;
			}),
			(err) => {
				return err;
			}
		);
	}
	validateActivationCode(phone: string, code: number): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post(`${environment.apiUrl}${this.entityName}/ValidateActivationCode/${phone}/${code}`, null).pipe(
			map((res: string) => {
				return res;
			}),
			(err) => {
				return err;
			}
		);
	}
	deleteBoxUserMother(studentMotherId: number): Observable<any> {
		let queryStringParams: string[] = [];

		queryStringParams.push("?id=" + studentMotherId);

		const requestUrl = environment.apiUrl + this.entityName + "/DeleteBoxUserMother/" + queryStringParams.join("");
		return this.http.delete<any>(requestUrl);
	}

	//#endregion
}
