export const enums = {
	httpResponseType: {
		success: "success",
		fail: "fail",
		error: "error",
		unauthorized: "unauthorized",
	},
	httpErrorStatus: {
		conflict: 409,
		unauthorized: 401,
	},
	translationType: {
		documentType: 1,
		department: 2,
		documentField: 3,
		documentFieldOption: 4,
	},
	LocalStorageKeys: {
		LoggeddUserId: "loggeddUserId",
		LoggedInUser: "loggedInUser",
		DefualtGroupId: "defualtGroupId",
		AccessToken: "access_token",
		TokenExpiresAt: "tokenExpiresAt",
	},
	RichEditorTitleAndContextSplitter: "editor_title_splitter",
	userGroup: {
		administrator: 1,
		teacher: 12,
		student: 14,
		parentOfStudent: 29,
		coach: 25,
		tranee: 39,
		schoolManager: 31,
		SchoolSupervisor: 5,
		secretary: 4,
		learningOutcomes: 6,
		examsAuditors: 11,
		directorsOfTheLeadershipCenter: 13,
		studentGuides: 16,
		activitySupervisor: 18,
		materialCoordinator: 19,
		agent: 22,
		executiveSecretary: 26,
		branchManager: 27,
		faithfullTeacher: 38,
		educationalSupervisor: 28,
		examControl: 44,
		faithFull_Teacher_Supervisor: 45,
		CashierAdmin: 42,
		supervisor: 30,
		need: 40,
		trainingManager: 20,
		schoolSupervisor: 5,
		responsible: 46,
		academicVicePrincipal: 60,
		deputiesDirectorOfTheLeadershipCenter: 7,
	},
	userGroups: {
		keys: {
			all: 0,
			teacher: 12,
			student: 14,
			parent: 29,
		},
		values: {
			all: "COMMON.ALL",
			teacher: "TEACHER",
			student: "STUDENT",
			parent: "PARENT",
		},
	},
	// userGroupAssign: {
	// 	teacher: {
	// 		canBeAssignTo: ["isPrimary", "grade", "section", "branchSchool", "course"],
	// 		multiAssign: true,
	// 	},
	// 	student: {
	// 		canBeAssignTo: ["grade", "section", "branchSchool"],
	// 		multiAssign: false,
	// 	},
	// 	schoolManager: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool"],
	// 		multiAssign: true,
	// 	},
	// 	SchoolSupervisor: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool", "grade"],
	// 		multiAssign: true,
	// 	},
	// 	secretary: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool", "grade"],
	// 		multiAssign: true,
	// 	},
	// 	learningOutcomes: {
	// 		canBeAssignTo: ["isPrimary", "course"],
	// 		multiAssign: true,
	// 	},
	// 	examsAuditors: {
	// 		canBeAssignTo: ["isPrimary", "course"],
	// 		multiAssign: true,
	// 	},
	// 	directorsOfTheLeadershipCenter: {
	// 		canBeAssignTo: ["isPrimary", "grade", "section", "branchSchool", "course"],
	// 		multiAssign: true,
	// 	},
	// 	studentGuides: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool"],
	// 		multiAssign: true,
	// 	},
	// 	activitySupervisor: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool"],
	// 		multiAssign: true,
	// 	},
	// 	materialCoordinator: {
	// 		canBeAssignTo: ["isPrimary", "course"],
	// 		multiAssign: true,
	// 	},
	// 	agent: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool"],
	// 		multiAssign: true,
	// 	},
	// 	examControl: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool"],
	// 		multiAssign: true,
	// 	},
	// 	academicVicePrincipal: {
	// 		canBeAssignTo: ["isPrimary", "branchSchool"],
	// 		multiAssign: true,
	// 	}
	// },
	messageTypes: {
		inbox: "inbox",
		sent: "sent",
		draft: "draft",
		trash: "trash",
		newMessage: "newMessage",
		readMessage: "readMessage",
	},
	questionTypes: {
		multipleChoices: 3,
		trueFalse: 1,
		marching: 4,
		longAnswer: 6,
		fillBlank: 2,
	},
	ckeEditorWriteConfig: {
		language: "ar",
		toolbar: [
			"undo",
			"redo",
			"|",
			"heading",
			"fontFamily",
			"fontSize",
			"fontColor",
			"fontBackgroundColor",
			"|",
			"bold",
			"italic",
			"link",
			"bulletedList",
			"numberedList",
			"|",
			// "indent",
			// "outdent",
			"alignment",
			"|",
			// "MathType",
			// "ChemType",
			// "specialCharacters",
			"imageInsert",
			// 'imageUpload'
			"insertTable",
			// "mediaEmbed",
			"|",
			// "highlight",
			"horizontalLine",
			// "htmlEmbed",
			// "subscript",
			"strikethrough",
			// "superscript",
			// "underline",
			"|",
			// "exportPdf",
			// "exportWord",
			"arabicNumbers",
		],
		table: {
			contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"],
		},
		image: {
			styles: ["alignLeft", "alignCenter", "alignRight"],
			resizeOptions: [
				{
					name: "imageResize:original",
					label: "Original",
					value: null,
				},
				{
					name: "imageResize:5",
					label: "5%",
					value: "5",
				},
				{
					name: "imageResize:10",
					label: "10%",
					value: "10",
				},
				{
					name: "imageResize:25",
					label: "25%",
					value: "25",
				},
				{
					name: "imageResize:50",
					label: "50%",
					value: "50",
				},
				{
					name: "imageResize:75",
					label: "75%",
					value: "75",
				},
			],
			toolbar: [
				"imageStyle:alignLeft",
				"imageStyle:alignCenter",
				"imageStyle:alignRight",
				"|",
				"imageResize",
				// "linkImage",
			],
		},
	},
	ckeEditorPrintConfig: {
		toolbar: ["exportPdf"],
	},
	ckeEditorReadConfig: { language: "ar", toolbar: [] },
	quizStatuses: {
		keys: {
			all: 0,
			available: 1,
			notAvailable: 2,
			closed: 3,
		},
		values: {
			all: "COMMON.ALL",
			available: "QUIZES_AND_HOMEWORK.AVAILABLE",
			notAvailable: "QUIZES_AND_HOMEWORK.NOT_AVAILABLE",
			closed: "QUIZES_AND_HOMEWORK.CLOSED",
		},
	},
	lessonPreparationAttachmentType: {
		attachment: 0,
		link: 1,
	},
	itemCssClassByStatus: {
		danger: "danger",
		success: "success",
	},
	educatorAssessmentTypes: {
		keys: {
			projectManagerToTeacher: 1,
			agentToProjectManager: 2,
			academicDirectorToAgent: 3,
			academicDirectorToProjectManager: 4,
			schoolAssessment: 5,
		},
		values: {
			projectManagerToTeacher: "EDUCATOR_ASSESSMENTS.PROJECT_MANAGER_TO_TEACHER",
			agentToProjectManager: "EDUCATOR_ASSESSMENTS.AGENT_TO_PROJECT_MANAGER",
			academicDirectorToAgent: "EDUCATOR_ASSESSMENTS.ACADIMIC_DIRECTOR_TO_AGENT",
			academicDirectorToProjectManager: "EDUCATOR_ASSESSMENTS.ACADIMIC_DIRECTOR_TO_PROJECT_MANAGER",
			schoolAssessment: "EDUCATOR_ASSESSMENTS.SCHOOL_ASSESSMENT",
		},
	},
	EvaEducatorQuestionsTypes: {
		TeacherAssessment: 1,
		ProjectManagerAgentAssessment: 2,
	},
	quizAndHomeworkListType: {
		all: "all",
		byCourse: "by-course",
	},
	questionBankEditDialogFromTypes: {
		questionBankList: 1,
		formativeAssignment: 2,
	},
	scheduleTablePreviewMode: {
		allDay: "allDay",
		onlyCurrentDay: "onlyCurrentDay",
	},
	weekDay: {
		sunday: 0,
		monday: 1,
		tuesday: 2,
		wednesday: 3,
		thursday: 4,
		friday: 5,
		saturday: 6,
	},
	attendanceStatus: {
		exist: 1,
		late: 2,
		execused: 3,
		absent: 4,
	},
	gradeCodes: {
		kg1: 27,
		kg2: 28,
		kg3: 29,
		kg4: 30,
	},
	eleotEvidenceMarkTypes: {
		veryClear: 4,
		clear: 3,
		someWhatClear: 2,
		notNoticed: 1,
	},
	eleotToolMarkTypes: {
		enabled: 1,
		someWhatEnabled: 2,
		notEnabled: 3,
	},
	gender: {
		keys: {
			all: 0,
			male: 1,
			female: 2,
		},
		values: {
			all: "COMMON.ALL",
			male: "USER_MANAGEMENT.OTHER.MALE",
			female: "USER_MANAGEMENT.OTHER.FEMALE",
		},
	},
	trainingProgramTypes: {
		keys: {
			all: 0,
			trainingCourse: 1,
			workShop: 2,
		},
		values: {
			all: "COMMON.ALL",
			trainingCourse: "TRAINING_PROGRAMS.TRAINING_COURSE",
			workShop: "TRAINING_PROGRAMS.WORKSHOP",
		},
	},
	trainingProgramStatus: {
		keys: {
			create: 1,
			openAttendance: 2,
			closed: 3,
		},
		values: {
			create: "COMMON.ACTION.CREATE",
			openAttendance: "TRAINING_PROGRAMS.OPEN_ATTENDANCE",
			closed: "QUIZES_AND_HOMEWORK.CLOSED",
		},
	},
	trainingProgramTerms: {
		keys: {
			term1: 1,
			term2: 2,
			term3: 3,
		},
		values: {
			term1: "Term 1",
			term2: "Term 2",
			term3: "Term 3",
		},
	},
	langs: {
		en: "en",
		ar: "ar",
	},
	courseTraineeAttendanceStatus: {
		absent: 0,
		attend: 1,
		departure: 2,
	},
	certificateTemplatesTypes: {
		keys: {
			all: 0,
			trainees: 1,
			instructors: 2,
		},
		keyNames: {
			trainees: "trainee",
			instructors: "instructor",
		},
		values: {
			all: "COMMON.ALL",
			trainees: "COURSE_TRAINEES.TRAINESS",
			instructors: "INSTRUCTORS.INSTRUCTOR",
		},
	},
	implementationLevel: {
		keys: {
			excellent: 1,
			good: 2,
			middle: 3,
			weak: 4,
		},
		values: {
			excellent: "COMMON.EXCELLENT",
			good: "COMMON.GOOD",
			middle: "COMMON.MIDDLE",
			weak: "COMMON.WEAK",
		},
	},
	eventTypes: {
		keys: {
			performed: 1,
			notPerformed: 2,
		},
		values: {
			performed: "COMMON.PERFORMED",
			notPerformed: "COMMON.NOT_PERFORMED",
		},
	},
	terms: {
		keys: {
			first: 1,
			second: 2,
			third: 3,
		},
		values: {
			first: "TERM.FIRST_TERM",
			second: "TERM.SECOND_TERM",
			third: "TERM.THIRD_TERM",
		},
	},
	notificationDuration: {
		short: 1000,
		medium: 2000,
		long: 3000,
		extraLong: 5000,
	},
	auth: {
		tokenAge: 48,
	},
	Pagination: {
		DefaultPageIndex: 0,
		DefaultPageSize: 20,
		DefaultPageSize100: 100,
		DefaultPageSizeOptions: [20, 100, 200, 1000],
	},
	certificateTemplateVariables: {
		keys: {
			Collection_Name: "[Collection_Name]",
			Hours: "[Hours]",
			From: "[From]",
			To: "[To]",
			Program_Name: "[Program_Name]",
			Trainee_Name: "[Trainee_Name]",
			Period: "[Period]",
			School_Year: "[School_Year]",
		},
		values: {
			Collection_Name: "CERTIFICATE_TEMPLATE_VARIABLES.COLLECTION_NAME",
			Hours: "CERTIFICATE_TEMPLATE_VARIABLES.HOURS",
			From: "CERTIFICATE_TEMPLATE_VARIABLES.FROM",
			To: "CERTIFICATE_TEMPLATE_VARIABLES.TO",
			Program_Name: "CERTIFICATE_TEMPLATE_VARIABLES.PROGRAM_NAME",
			Trainee_Name: "CERTIFICATE_TEMPLATE_VARIABLES.TRAINEE_NAME",
			Period: "CERTIFICATE_TEMPLATE_VARIABLES.PERIOD",
			School_Year: "CERTIFICATE_TEMPLATE_VARIABLES.SCHOOL_YEAR",
		},
	},
	apiEndPoints: {
		Users_RefreshToken: "Users/RefreshToken",
	},
	training_program_assessment_question_type: {
		type1: 1,
		type2: 2,
	},
	training_program_assessment_answer_types: {
		high: 4,
		good: 3,
		moderate: 2,
		low: 1,
	},
	meeting_visit_types: {
		keys: {
			PerformanceAndImpact: 1,
			Performance: 2,
			Impact: 3,
			WrittenWork: 4,
			InstructionalVisit: 5,
			PreparationElectronic: 6,
		},
		values: {
			PerformanceAndImpact: "VISIT_MEETING.PERFORMANCE_AND_IMPACT",
			Performance: "VISIT_MEETING.PERFORMANCE",
			Impact: "VISIT_MEETING.IMPACT",
			WrittenWork: "VISIT_MEETING.WRITTEN_WORK",
			InstructionalVisit: "VISIT_MEETING.INSTRUCTIONAL_VISIT",
			PreparationElectronic: "VISIT_MEETING.PREPARATION_ELECTRONIC",
		},
	},
	quizzeTypes: {
		keys: {
			all: 1000,
			quiz: 0,
			homework: 1,
			gat: 2,
			finalExam: 3,
		},
		values: {
			all: "COMMON.ALL",
			quiz: "QUIZES_AND_HOMEWORK.QUIZ",
			gat: "QUIZES_AND_HOMEWORK.GAT_QUIZ",
			homework: "QUIZES_AND_HOMEWORK.HOMEWORK",
			finalExam: "QUIZES_AND_HOMEWORK.FINAL_EXAM",
		},
	},
	yesNoAnswers: {
		keys: {
			all: 1000,
			yes: 1,
			no: 0,
		},
		values: {
			all: "COMMON.ALL",
			yes: "COMMON.YES",
			no: "COMMON.NO",
		},
	},
	custom_entity_names: {
		waiting_periods: "waiting-periods",
	},
	education_track: {
		keys: {
			general: 1,
			memorization: 2,
			quarterly: 3,
			arabic: 4,
			courses: 5,
			practical: 6,
		},
		values: {
			general: "EDUCATION_TRACKS.GENERAL",
			memorization: "EDUCATION_TRACKS.MEMORIZATION",
			quarterly: "EDUCATION_TRACKS.QUARTERLY",
			arabic: "EDUCATION_TRACKS.ARABIC",
			courses: "EDUCATION_TRACKS.COURSES",
			practical: "EDUCATION_TRACKS.PRACTICAL",
		},
	},
	AchievementBanks: {
		Beneficiareis: {
			keys: {
				other: 0,
				collection: 1,
				commander: 2,
				teacher: 3,
				student: 4,
				educationalSupervisor: 5,
				schoolManager: 6,
				schoolAgent: 7,
				observer: 8,
				activityPioneer: 9,
				studentOrientation: 10,
				theTrusteeOfSources: 11,
				labReport: 12,
			},
			values: {
				other: "ACHIEVEMENT_BANK.OTHER",
				collection: "ACHIEVEMENT_BANK.COLLECTION",
				commander: "ACHIEVEMENT_BANK.COMMANDER",
				teacher: "ACHIEVEMENT_BANK.TEACHER",
				student: "ACHIEVEMENT_BANK.STUDENT",
				educationalSupervisor: "ACHIEVEMENT_BANK.EDUCATIONAL_SUPERVISOR",
				schoolManager: "ACHIEVEMENT_BANK.SCHOOL_MANAGER",
				schoolAgent: "ACHIEVEMENT_BANK.SCHOOL_AGENT",
				observer: "ACHIEVEMENT_BANK.OBSERVER",
				activityPioneer: "ACHIEVEMENT_BANK.ACTIVITY_PIONEER",
				studentOrientation: "ACHIEVEMENT_BANK.STUDENT_ORIENTATION",
				theTrusteeOfSources: "ACHIEVEMENT_BANK.THE_TRUSTED_OF_SOURCES",
				labReport: "ACHIEVEMENT_BANK.LAB_REPORT",
			},
		},
		Type: {
			keys: {
				scientific: 1,
				cultural: 2,
				athlete: 3,
				social: 4,
				voluntary: 5,
			},
			values: {
				scientific: "ACHIEVEMENT_BANK.SCIENTIFIC",
				cultural: "ACHIEVEMENT_BANK.CULTURAL",
				athlete: "ACHIEVEMENT_BANK.ATHLETE",
				social: "ACHIEVEMENT_BANK.SOCIAL",
				voluntary: "ACHIEVEMENT_BANK.VOLUNTARY",
			},
		},
		Position: {
			keys: {
				Other: 0,
				First: 1,
				Second: 2,
				Third: 3,
				Fourth: 4,
				Fifth: 5,
			},
			values: {
				Other: "ACHIEVEMENT_BANK.OTHER",
				First: "ACHIEVEMENT_BANK.FIRST",
				Second: "ACHIEVEMENT_BANK.SECOND",
				Third: "ACHIEVEMENT_BANK.THIRD",
				Fourth: "ACHIEVEMENT_BANK.FOURTH",
				Fifth: "ACHIEVEMENT_BANK.FIFTH",
			},
		},
		AchievementLevel: {
			keys: {
				CompanyLevel: 1,
				EducationOffice: 2,
				EducationAdministration: 3,
				MinistryOfEducation: 4,
				GulfCountries: 5,
				WorldWide: 6,
			},
			values: {
				CompanyLevel: "ACHIEVEMENT_BANK.COMPANY_LEVEL",
				EducationOffice: "ACHIEVEMENT_BANK.EDUCATION_OFFICE",
				EducationAdministration: "ACHIEVEMENT_BANK.EDUCATION_ADMINISTRATION",
				MinistryOfEducation: "ACHIEVEMENT_BANK.MINISTRY_OF_EDUCATION",
				GulfCountries: "ACHIEVEMENT_BANK.GULF_COUNTRIES",
				WorldWide: "ACHIEVEMENT_BANK.WORLWIDE",
			},
		},
		AchievementBankStatus: {
			keys: {
				Draft: 0,
				Sent: 1,
				Accepted: 2,
				Rejected: 3,
			},
			values: {
				Draft: "ACHIEVEMENT_BANK.DRAFT",
				Sent: "ACHIEVEMENT_BANK.SENT",
				Accepted: "ACHIEVEMENT_BANK.ACCEPTED",
				Rejected: "ACHIEVEMENT_BANK.REJECTED",
			},
		},
	},
	RecordsStatus: {
		keys: {
			Draft: 0,
			Sent: 1,
		},
		values: {
			Draft: "ACHIEVEMENT_BANK.DRAFT",
			Sent: "ACHIEVEMENT_BANK.SENT",
		},
	},
	ClassroomVisits: {
		visitTypes: {
			keys: {
				performanceAndImpact: 1,
				performance: 2,
				impact: 3,
				writtenWork: 4,
				instructionalVisit: 5,
				preparationElectronic: 6,
			},
			values: {
				performanceAndImpact: "CLASSROOM_VISITS.PERFORMANCE_AND_IMPACT",
				performance: "CLASSROOM_VISITS.PERFORMANCE",
				impact: "CLASSROOM_VISITS.IMPACT",
				writtenWork: "CLASSROOM_VISITS.WRITTEN_WORK",
				instructionalVisit: "CLASSROOM_VISITS.INSTRUCTIONAL_VISIT",
				preparationElectronic: "CLASSROOM_VISITS.PREPARATIONS_ELECTRONIC",
			},
		},
		types: {
			keys: {
				general: 1,
				memorization: 2,
				quarterly: 3,
				arabic: 4,
				courses: 5,
				practical: 6,
				international: 7,
			},
			values: {
				general: "CLASSROOM_VISITS.GENERAL",
				memorization: "CLASSROOM_VISITS.MEMORIZATION",
				quarterly: "CLASSROOM_VISITS.QUARTERLY",
				arabic: "CLASSROOM_VISITS.ARABIC",
				courses: "CLASSROOM_VISITS.COURSES",
				practical: "CLASSROOM_VISITS.PRACTICAL",
				international: "CLASSROOM_VISITS.INTRTNATIONAL",
			},
		},
		measurmentsTypes: {
			writtenTest: 1,
			oralTest: 2,
			observation: 3,
		},
		visitReals: {
			Q1: "CLASSROOM_VISITS.REALS.Q1",
			Q2: "CLASSROOM_VISITS.REALS.Q2",
			Q3: "CLASSROOM_VISITS.REALS.Q3",
			Q4: "CLASSROOM_VISITS.REALS.Q4",
			Q5: "CLASSROOM_VISITS.REALS.Q5",
			Q6: "CLASSROOM_VISITS.REALS.Q6",
			Q7: "CLASSROOM_VISITS.REALS.Q7",
			Q8: "CLASSROOM_VISITS.REALS.Q8",
			Q9: "CLASSROOM_VISITS.REALS.Q9",
			Q10: "CLASSROOM_VISITS.REALS.Q10",
			Q11: "CLASSROOM_VISITS.REALS.Q11",
			Q12: "CLASSROOM_VISITS.REALS.Q12",
			Q13: "CLASSROOM_VISITS.REALS.Q13",
			Q14: "CLASSROOM_VISITS.REALS.Q14",
			Q15: "CLASSROOM_VISITS.REALS.Q15",
			Q16: "CLASSROOM_VISITS.REALS.Q16",
		},
		visitVirtuals: {
			Q1: "CLASSROOM_VISITS.VIRTUALS.Q1",
			Q2: "CLASSROOM_VISITS.VIRTUALS.Q2",
			Q3: "CLASSROOM_VISITS.VIRTUALS.Q3",
			Q4: "CLASSROOM_VISITS.VIRTUALS.Q4",
			Q5: "CLASSROOM_VISITS.VIRTUALS.Q5",
			Q6: "CLASSROOM_VISITS.VIRTUALS.Q6",
			Q7: "CLASSROOM_VISITS.VIRTUALS.Q7",
			Q8: "CLASSROOM_VISITS.VIRTUALS.Q8",
			Q9: "CLASSROOM_VISITS.VIRTUALS.Q9",
			Q10: "CLASSROOM_VISITS.VIRTUALS.Q10",
			Q11: "CLASSROOM_VISITS.VIRTUALS.Q11",
			Q12: "CLASSROOM_VISITS.VIRTUALS.Q12",
			Q13: "CLASSROOM_VISITS.VIRTUALS.Q13",
			Q14: "CLASSROOM_VISITS.VIRTUALS.Q14",
			Q15: "CLASSROOM_VISITS.VIRTUALS.Q15",
			Q16: "CLASSROOM_VISITS.VIRTUALS.Q16",
			Q17: "CLASSROOM_VISITS.VIRTUALS.Q17",
			Q18: "CLASSROOM_VISITS.VIRTUALS.Q18",
			Q19: "CLASSROOM_VISITS.VIRTUALS.Q19",
			Q20: "CLASSROOM_VISITS.VIRTUALS.Q20",
		},
		onlinePreparations: {
			Q1: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q1",
			Q2: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q2",
			Q3: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q3",
			Q4: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q4",
			Q5: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q5",
			Q6: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q6",
			Q7: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q7",
			Q8: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q8",
			Q9: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q9",
			Q10: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q10",
			Q11: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q11",
			Q12: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q12",
			Q13: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q13",
			Q14: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q14",
			Q15: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q15",
			Q16: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q16",
			Q17: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q17",
			Q18: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q18",
			Q19: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q19",
			Q20: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q20",
			Q21: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q21",
			Q22: "CLASSROOM_VISITS.ONLINE_PREPARATIONS.Q22",
		},
		sections: {
			reviewAndWarmUp: "CLASSROOM_VISITS.SECTIONS.REVIEW_AND_WARM_UP",
			Teaching: "CLASSROOM_VISITS.SECTIONS.TEACHING",
			Assessment: "CLASSROOM_VISITS.SECTIONS.ASSESSMENTS",
		},
		onlinePreparationsSections: {
			virtualClasses: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.VIRTUAL_CLASSES",
			learningOutcomes: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.LEARNING_OUTCOMES",
			haqbana: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.HAQBANA",
			reviewAndWarmUp: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.REVIEW_AND_WARM_UP",
			lessonProcedures: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.LESSON_PROCEDURES",
			learningStrategies: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.LEARNING_STRATEGIES",
			media_and_links: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.MEDIA_AND_LINKS",
			constructive_evaluation: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.CONSTRUCTIVE_EVALUATION",
			finalEvaluation: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.FINAL_EVALUATION",
			quizzesAndHomeworks: "CLASSROOM_VISITS.ONLINE_PREPARATIONS_SECTIONS.QUIZZES_AND_HOMEWORKS",
		},
	},
	DefaultEmptyImagePath: "assets/media/users/default.jpg",
	EntityActionIds: {
		All: 0,
		View: 1,
		Create: 2,
		Update: 3,
		Delete: 4,
	},
	WeeksNumber: 48,
	colors: {
		white: "#fff",
		indigo: "#3f51b5",
		blue: "#5d78ff",
		lightBlue: "#5d78ff1a",
		pink: "#e91e63",
		purple: "#9c27b0",
	},
	filters: {
		schools: "schools",
		grades: "grades",
		courses: "courses",
		sections: "sections",
		users: "users",
		createdBefore: "createdBefore",
		createdAfter: "createdAfter",
		status: "status",
		branches: "branches",
		schoolYears: "schoolYears",
		terms: "terms",
	},
	BrotherDiscountType: {
		keys: {
			percentage: 1,
			value: 2,
		},
		values: {
			percentage: "PERCENTAGE",
			value: "VALUE",
		},
	},
	PermenantTypes: {
		keys: {
			permenant: 1,
			temprorary: 2,
		},
		values: {
			permenant: "PERMENANT",
			temprorary: "TEMPRORARY",
		},
	},
	StaffStatus: {
		keys: {
			All: 0,
			SchoolStuff: 1,
			NonSchoolStuff: 2,
		},
		values: {
			All: "COMMON.ALL",
			SchoolStuff: "SCHOOL_STUFF",
			NonSchoolStuff: "NON_SCHOOL_STUFF",
		},
	},
	DueStatus: {
		keys: {
			Assigned: 1,
			UnAssigned: 2,
		},
		values: {
			Assigned: "ASSIGNED",
			UnAssigned: "UN_ASSIGNED",
		},
	},
	LearningImpactCardsTypes: {
		LearningImpactByCourse: 0,
		LearningImpactByCourseAndBranch: 1,
		LearningImpactByCourseAndSchool: 2,
		LearningImpactByCourseAndGrade: 3,
		LearningImpactByCourseCourseAndSchoolAndGradeAndSection: 4,
		LearningImpactReportByTotalAndSchool: 5,
		LearningImpactReportByTotalAndGrade: 6,
		LearningImpactReportByTotalAndStudent: 7,
		LearningImpactReportByTotalAndSchoolAndGradeAndSection: 8,
	},
	StudentStatus: {
		keys: {
			All: 0,
			Exist: 1,
			NotExist: 2,
		},
		values: {
			All: "COMMON.ALL",
			Exist: "EXIST",
			NotExist: "NOT_EXIST",
		},
	},
	InternationalMarkDistributionTypes: {
		examsAndQuizzes: 1,
		weekly: 2,
		finalExam: 3,
		attendenceAndConduct: 4,
		quraters: 5,
		semesters: 6,
	},
	SemesterFeesTypes: {
		StudyFees: 1,
		TransportationFees: 3,
	},
	componentSelectors: {
		AssignmentTeacherToClass: "assignment-teacher-to-class",
		UsersList: "kt-users-list",
		Cashier: "kt-cashier",
	},
	eduTypes: {
		private: 1,
		international: 2,
		memorization: 3,
		normal: 4,
		diploma: 6,
		egyptian: 7,
		archieve: 8,
	},
	branches: {
		instructorBranchId: 37,
	},
	defaultCountry: 252, //KSA
	SortingTypes: {
		keys: {
			asc: 1,
			desc: 2,
		},
		values: {
			asc: "ASCENDING",
			desc: "DESCENDING",
		},
	},
	FileUploadType: {
		BranchLogo: "BranchLogo",
		UploadFile: "UploadFile",
		AttachFilesToBranch: "AttachFilesToBranch",
		AttachFilesToSchool: "AttachFilesToSchool",
		AttachFilesToUser: "AttachFilesToUser",
	},
	AcceptFileFormats: {
		All: "*",
		Images: "image/jpeg,image/jpg,image/png,image/gif",
	},
	EmptyImageBase64: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
	PrintExportType: {
		Save: "Save",
		OpenInNewWindow: "OpenInNewWindow",
	},
	SchoolTypes: {
		keys: {
			Private: 1,
			Governmental: 2,
			International: 3,
			OutsideKSA: 4,
		},
		values: {
			Private: "PRIVATE",
			Governmental: "GOVERNMENTAL",
			International: "INTRTNATIONAL",
			OutsideKSA: "OUTSIDE_KSA",
		},
	},
};

export enum Days {
	//
	// Summary:
	//     Specifies the day of the week.

	//
	// Summary:
	//     Indicates Sunday.
	Sunday = 0,
	//
	// Summary:
	//     Indicates Monday.
	Monday = 1,
	//
	// Summary:
	//     Indicates Tuesday.
	Tuesday = 2,
	//
	// Summary:
	//     Indicates Wednesday.
	Wednesday = 3,
	//
	// Summary:
	//     Indicates Thursday.
	Thursday = 4,
	//
	// Summary:
	//     Indicates Friday.
	Friday = 5,
	//
	// Summary:
	//     Indicates Saturday.
	Saturday = 6,
}
export enum Months {
	January = 1,
	February = 2,
	March = 3,
	April = 4,
	May = 5,
	June = 6,
	July = 7,
	August = 8,
	September = 9,
	October = 10,
	November = 11,
	December = 12,
}
export enum PeriodTimes {
	Beginning = 1,
	Middle = 2,
	End = 3,
}
