// Angular
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	OnDestroy
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
// RxJS
import { Subscription } from "rxjs";

// Services
import {
	LayoutUtilsService,
	MessageType,
} from "../../../../core/_base/crud";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { enums } from "../../../../../enums/enums";
import { PreUserModel } from "../../../../../app/_models/pre-user.model";

@Component({
	selector: "kt-new-pre-user",
	templateUrl: "./new-pre-user.dialog.component.html",
	changeDetection: ChangeDetectionStrategy.Default,
})

export class NewPreUserDialogComponent implements OnInit, OnDestroy {
	isLoading = false;

	// Private properties
	private componentSubscriptions: Subscription;

	userForm: FormGroup;
	hasFormErrors = false;

	genders: any[] = [
		{ Id: enums.gender.keys.male, Name: this.translate.instant(enums.gender.values.male) },
		{ Id: enums.gender.keys.female, Name: this.translate.instant(enums.gender.values.female) },
	];

	constructor(
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		public dialogRef: MatDialogRef<NewPreUserDialogComponent>
	) { }

	ngOnInit() {
		//Create Form
		this.createForm();
	}

	// Create form
	createForm() {
		this.userForm = new FormGroup({
			username: new FormControl("", Validators.required),
			password: new FormControl(
				"",
				Validators.compose([
					Validators.required,
					Validators.pattern(
						/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[$@$!%*?&.#]).{8,30}$/
					),
				])
			),
			passwordConfirm: new FormControl(
				"",
				Validators.compose([
					Validators.required,
					Validators.pattern(
						/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[$@$!%*?&.#]).{8,30}$/
					),
				])
			),
			gender: new FormControl("", Validators.required),
			fullName: new FormControl("", Validators.required),
			country: new FormControl(),
			emailAddress: new FormControl("", [
				Validators.email,
				Validators.required,
			]),
			mobileNumber: new FormControl(),
			nationality: new FormControl(),
			city: new FormControl(),
			adress: new FormControl(),
			dob: new FormControl(),
			parentId: new FormControl(),
			branchId: new FormControl(""),
			subjectID: new FormControl("")
		});
	}

	//On destroy
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	onSubmit() {
		const controls = this.userForm.controls;

		/** check form */
		if (this.userForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);

			this.layoutUtilsService.showActionNotification(this.translate.instant("COMMON.PLEASE_FILL_MANDATORY_FIELDS"), MessageType.Read, undefined, undefined, false);
			return;
		}

		if (controls.password.value != controls.passwordConfirm.value) {
			this.layoutUtilsService.showActionNotification(this.translate.instant("AUTH.VALIDATION.PASSWORD_NOT_MATCHING"), MessageType.Read, undefined, undefined, false);
			return;
		}

		let preparedUser: PreUserModel = this.prepareUser();
		this.dialogRef.close(preparedUser);
	}

	//Returns prepared data for save
	prepareUser(): PreUserModel {
		const controls = this.userForm.controls;
		const _user = new PreUserModel();

		_user.clear();
		//Mandatory Field
		_user.username = controls.username.value;
		_user.userCode = controls.username.value;
		_user.password = controls.password.value;
		_user.gender = controls.gender.value;
		_user.groupId = enums.userGroup.student;
		_user.parentId = controls.parentId.value != null ? controls.parentId.value : 0;
		_user.fullName = controls.fullName.value;
		_user.country = controls.country.value != null ? controls.country.value : 0;
		_user.emailAddress = controls.emailAddress.value;
		_user.mobileNumber = controls.mobileNumber.value;
		_user.city = controls.city.value != null ? controls.city.value : 0;
		_user.adress = controls.adress.value;

		_user.isActive = false;
		_user.isDeleted = false;
		_user.createdDate = new Date().toJSON();
		_user.dob = new Date(controls.dob.value).toJSON();
		_user.nationality = 0;
		_user.createdBy = 0;
		_user.companyId = 0;
		_user.branchId = 0;

		return _user;
	}

	//Returns component title
	getComponentTitle() {
		let componentTitle: string = this.translate.instant("USER_MANAGEMENT.OTHER.NEW_USER");
		return componentTitle;
	}

	closeDialog() {
		this.dialogRef.close("close");
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.userForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
