import { BoxBlockListModel } from "./box-block-list.model";
import { BoxGradeUsersModel } from "./box-grade-users.model";
import { BoxTeacherGradeSectionDTO } from "./box-teacher-grade-section-dto.model";
import { GradeSectionByUserModel } from "./gradeSectionByUser.model";
import { GroupModel } from "./group.model";

export class UserModel {
    userID: number;
    username: string;
    password: string;
    userCode: string;
    gender: number;
    groupId: number;
    groupIds: number[];
    allGroups: GroupModel[];
    country?: number;
    companyId?: number;
    branchId: number;
    brachSchoolName: string;
    brachSchoolNameAr: string;
    fullName: string;
    mobileNumber: string;
    emailAddress: string;
    nationality?: number;
    city?: number;
    parentId?: number;
    subjectID: number;
    subjectName: string;
    subjectNameAr: string;
    locationID: number;
    dob: string;
    adress: string;
    profileImagePath: string;
    isActive: boolean;
    isDeleted: boolean;
    createdBy: number;
    createdDate: string;
    schoolId: number;
    gradeId: number;
    gradeName: string;
    gradeNameAr: string;
    stringUserID: string;
    gradeSectionId: number;
    gradeSectionNameAr: string;
    gradeSectionName: string;
    branchNameAr: string;
    branchName: string;
    groupNameAr: string;
    groupName: string;
    jobId: number;
    //drfA_DutyControlUsers	[...]
    // drfA_DailyReportForAgents	[...]
    // drfA_Projects	[...]
    // drfA_SchoolPositives	[...]
    // drfA_StudentIssues	[...]
    // drfA_WorkerAbsences	[...]
    // drfA_WorkerLates	[...]
    zoomId: string;
    group: GroupModel;
    boxBlockList: BoxBlockListModel[];
    // boxDicountsDiscountByNavigation	[...]
    //boxDicountsUser	[...]
    // boxEntitlementsEntitlementBy	[...]
    // boxEntitlementsUser	[...]
    // boxFinancialClaimsCashierUser	[...]
    // boxFinancialClaimsParent	[...]
    boxGradeUsers: BoxGradeUsersModel[];
    // boxReregistrationRegisterationBy	[...]
    // boxReregistrationUser	[...]
    // boxSettelementsSettelementBy	[...]
    // boxSettelementsUser	[...]
    gradeSectionByUser: GradeSectionByUserModel[];
    boxTeacherGradeSectionDTO: BoxTeacherGradeSectionDTO[];
    occupation: string;
    documentNumber: string;
    documentTypeId: number;
    // Extra
    displayBranchName: string;
    displaySubjectName: string;
    displayGradeName: string;
    displayGradeSection: string;
    displayBrachSchoolName: string;
    displayGroupName: string;
    assignedGroups: GroupModel[] = [];

    clear(): void {
        this.userID = 0;
        this.username = '';
        this.password = '';
        this.userCode = '';
        this.gender = 0;
        this.groupId = 0;
        this.branchId = 0;
        this.fullName = '';
        this.mobileNumber = '';
        this.emailAddress = 'user@example.com';
        this.locationID = 0;
        this.dob = '';
        this.adress = '';
        this.profileImagePath = '';
        this.createdBy = 0;
        this.createdDate = '';
        this.isActive = true;
        this.isDeleted = false;
        this.subjectID = 0;
    }
}


