import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { MessageModel } from '../_models/message.model';
import { MessageListModel } from '../_models/message-list.model';
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";

@Injectable()
export class MessageService {
    constructor(private http: HttpClient, private router: Router) { }

    private entityName: string = 'Messages';

    getMessagesByReceiverId(receiverId: number): Observable<MessageListModel> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?receiverId=" + receiverId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetMessagesByReceiverId/' + queryStringParams.join('')

        return this.http.get<MessageListModel>(requestUrl);
    }

    getDraftMessagesBySenderId(senderId: number): Observable<MessageListModel> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?senderId=" + senderId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetDraftMessagesBySenderId/' + queryStringParams.join('');

        return this.http.get<MessageListModel>(requestUrl);
    }

    getMessagesBySenderId(senderId: number): Observable<MessageListModel> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?senderId=" + senderId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetMessagesBySenderId/' + queryStringParams.join('');

        return this.http.get<MessageListModel>(requestUrl);
    }

    getTrashMessagesByUserId(userId: number): Observable<MessageListModel> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?userId=" + userId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetTrashMessagesByUserId/' + queryStringParams.join('');

        return this.http.get<MessageListModel>(requestUrl);
    }

    getMessageReplyHistory(parentId: number): Observable<Array<MessageModel>> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?parentId=" + parentId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetMessageReplyHistory/' + queryStringParams.join('');

        return this.http.get<Array<MessageModel>>(requestUrl);
    }

    

    getCounts(userId: number): Observable<{unreadDraftsCount: number, unreadInboxCount: number}> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?userId=" + userId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetCounts/' + queryStringParams.join('');

        return this.http.get<{unreadDraftsCount: number, unreadInboxCount: number}>(requestUrl);
    }

    postMessage(value: MessageModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PostMessages/';

        return this.http.post<any>(requestUrl, value);
    }

    deleteMessages(id: number): Observable<any> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?id=" + id);

        const requestUrl = environment.apiUrl + this.entityName + '/DeleteMessages/' + queryStringParams.join('');

        return this.http.get<MessageListModel>(requestUrl);
    }

    ReadMessage(message: MessageModel): Observable<MessageModel> {


        const requestUrl = environment.apiUrl + this.entityName + '/ReadMessage/';

        return this.http.post<any>(requestUrl, message);


    }
}
