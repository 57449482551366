// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/theme/content/error-page/error-page.component";
// Auth
import { AuthGuard } from "./core/auth";
import { RemoteConversationalSessionsModule } from './views/pages/remote-conversational-session/remote-conversational-session.module';

const routes: Routes = [
	{
		path: "auth",
		loadChildren: () =>
			import("../app/views/pages/auth/auth.module").then(
				(m) => m.AuthModule
			),
	},
	{
		path: "",
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: "dashboard",
				loadChildren: () =>
					import(
						"../app/views/pages/dashboard/dashboard.module"
					).then((m) => m.DashboardModule),
			},
			{
				path: "Eva_EducatorAssessments",
				loadChildren: () =>
					import(
						"../app/views/pages/Eva_EducatorAssessments/Eva_EducatorAssessments.module"
					).then((m) => m.Eva_EducatorAssessmentsModule),
			},
			{
				path: "Eva_FrmClassNoteCards",
				loadChildren: () =>
					import(
						"../app/views/pages/Eva_FrmClassNoteCards/Eva_FrmClassNoteCards.module"
					).then((m) => m.Eva_FrmClassNoteCardsModule),
			},
			{
				path: "builder",
				loadChildren: () =>
					import(
						"../app/views/theme/content/builder/builder.module"
					).then((m) => m.BuilderModule),
			},
			{
				path: "user-management",
				loadChildren: () =>
					import(
						"../app/views/pages/user-management/user-management.module"
					).then((m) => m.UserManagementModule),
			},
			{
				path: "access-control",
				loadChildren: () =>
					import(
						"../app/views/pages/access-control/access-control.module"
					).then((m) => m.AccessControlModule),
			},
			{
				path: "quizes-and-homeworks",
				loadChildren: () =>
					import(
						"../app/views/pages/quizes-and-homeworks/quizes-and-homeworks.module"
					).then((m) => m.QuizesAndHomeworksModule),
			},
			{
				path: "lesson-preparations",
				loadChildren: () =>
					import(
						"../app/views/pages/lesson-preparations/lesson-preparations.module"
					).then((m) => m.LessonPreparationsModule),
			},
			{
				path: "entities",
				loadChildren: () =>
					import("../app/views/pages/entities/entities.module").then(
						(m) => m.EntitiesModule
					),
			},
			{
				path: "companies",
				loadChildren: () =>
					import(
						"../app/views/pages/companies/companies.module"
					).then((m) => m.CompaniesModule),
			},
			{
				path: "branches",
				loadChildren: () =>
					import("../app/views/pages/branches/branches.module").then(
						(m) => m.BranchesModule
					),
			},
			{
				path: "LearningTracks",
				loadChildren: () =>
					import(
						"../app/views/pages/learningTrack/learningTrack.module"
					).then((m) => m.LearningTracksModule),
			},
			{
				path: "stages",
				loadChildren: () =>
					import("../app/views/pages/stages/stages.module").then(
						(m) => m.StagesModule
					),
			},
			{
				path: "cources",
				loadChildren: () =>
					import("../app/views/pages/courses/courses.module").then(
						(m) => m.CourcesModule
					),
			},
			{
				path: "cities",
				loadChildren: () =>
					import("../app/views/pages/cities/cities.module").then(
						(m) => m.CitiesModule
					),
			},
			{
				path: "groups",
				loadChildren: () =>
					import("../app/views/pages/groups/groups.module").then(
						(m) => m.GroupsModule
					),
			},
			{
				path: "grade",
				loadChildren: () =>
					import("../app/views/pages/grade/grade.module").then(
						(m) => m.GradeModule
					),
			},
			{
				path: "schedule",
				loadChildren: () =>
					import("../app/views/pages/schedule/schedule.module").then(
						(m) => m.ScheduleModule
					),
			},
			{
				path: "courseUnit",
				loadChildren: () =>
					import(
						"../app/views/pages/courseUnits/courseUnits.module"
					).then((m) => m.CourseUnitsModule),
			},
			{
				path: "courseLesson",
				loadChildren: () =>
					import(
						"../app/views/pages/courseLessons/courseLessons.module"
					).then((m) => m.courseLessonsModule),
			},
			{
				path: "grade-section",
				loadChildren: () =>
					import(
						"../app/views/pages/grade-section/grade-section.module"
					).then((m) => m.GradeSectionModule),
			},
			{
				path: "error/403",
				component: ErrorPageComponent,
				data: {
					type: "error-v6",
					code: 403,
					title: "403... Access forbidden",
					desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
				},
			},
			{
				path: "countries",
				loadChildren: () =>
					import(
						"../app/views/pages/countries/countries.module"
					).then((m) => m.CountriesModule),
			},
			{
				path: "applicationLogs",
				loadChildren: () =>
					import(
						"../app/views/pages/applicationLogs/applicationLogs.module"
					).then((m) => m.ApplicationLogsModule),
			},
			{
				path: "aclObjects",
				loadChildren: () =>
					import(
						"../app/views/pages/aclObjects/aclObjects.module"
					).then((m) => m.AclObjectsModule),
			},
			{
				path: "lmsConfigurations",
				loadChildren: () =>
					import(
						"../app/views/pages/lms_configurations/lms_configurations.module"
					).then((m) => m.LMSConfigurtionsModule),
			},
			{
				path: "messages",
				loadChildren: () =>
					import("../app/views/pages/messages/messages.module").then(
						(m) => m.MessagesModule
					),
			},
			{
				path: "notifications",
				loadChildren: () =>
					import(
						"../app/views/pages/notifications/notifications.module"
					).then((m) => m.NotificationsModule),
			},
			{
				path: "requestTeacher",
				loadChildren: () =>
					import(
						"../app/views/pages/requestTeacher/requestTeacher.module"
					).then((m) => m.RequestTeacherModule),
			},
			{
				path: "WFTypes",
				loadChildren: () =>
					import("../app/views/pages/wf-types/wf-types.module").then(
						(m) => m.WFTypesModule
					),
			},
			{
				path: "questionBanks",
				loadChildren: () =>
					import(
						"../app/views/pages/questionBanks/questionBanks.module"
					).then((m) => m.QuestionBanksModule),
			},
			{
				path: "teacherProjectManagers",
				loadChildren: () =>
					import(
						"../app/views/pages/teacher-project-managers/teacher-project-managers.module"
					).then((m) => m.TeacherProjectManagersModule),
			},
			{
				path: "plans",
				loadChildren: () =>
					import("../app/views/pages/plans/plans.module").then(
						(m) => m.PlansModule
					),
			},
			{
				path: "activities",
				loadChildren: () =>
					import(
						"../app/views/pages/activities/activities.module"
					).then((m) => m.ActivitiesModule),
			},
			{
				path: "schoolYears",
				loadChildren: () =>
					import(
						"../app/views/pages/school-years/school-years.module"
					).then((m) => m.SchoolYearsModule),
			},
			{
				path: "exam",
				loadChildren: () =>
					import("../app/views/pages/exam/exam.module").then(
						(m) => m.ExamModule
					),
			},
			{
				path: "attendance",
				loadChildren: () =>
					import(
						"../app/views/pages/attendance/attendance.module"
					).then((m) => m.AttendanceModule),
			},
			{
				path: "supervisor-evaluation",
				loadChildren: () =>
					import(
						"../app/views/pages/supervisor-evaluation/supervisor-evaluation.module"
					).then((m) => m.SupervisorEvaluationModule),
			},
			{
				path: "eleots",
				loadChildren: () =>
					import("../app/views/pages/eleots/eleots.module").then(
						(m) => m.EleotsModule
					),
			},
			{
				path: "teacher-observation-tools",
				loadChildren: () =>
					import("../app/views/pages/teacher-observation-tools/teacher-observation-tools.module").then(
						(m) => m.TeacherObservationToolModule
					),
			},
			{
				path: "remote-conversational-session",
				loadChildren: () =>
					import("../app/views/pages/remote-conversational-session/remote-conversational-session.module").then(
						(m) => m.RemoteConversationalSessionsModule
					),
			},
			{
				path: "classroom-visits",
				loadChildren: () =>
					import(
						"../app/views/pages/classroom-visits/classroom-visits.module"
					).then((m) => m.ClassroomVisitsModule),
			},
			{
				path: "virtual-class-visits",
				loadChildren: () =>
					import(
						"../app/views/pages/classroom-visits/classroom-visits.module"
					).then((m) => m.ClassroomVisitsModule),
			},
			{
				path: "instructors",
				loadChildren: () =>
					import(
						"../app/views/pages/instructors/instructors.module"
					).then((m) => m.InstructorsModule),
			},
			{
				path: "training-programs",
				loadChildren: () =>
					import(
						"../app/views/pages/training-programs/training-programs.module"
					).then((m) => m.TrainingProgramsModule),
			},
			{
				path: "course-trainees",
				loadChildren: () =>
					import(
						"../app/views/pages/course-trainees/course-trainees.module"
					).then((m) => m.CourseTraineesModule),
			},
			{
				path: "branch-school",
				loadChildren: () =>
					import(
						"../app/views/pages/branch-school/branch-school.module"
					).then((m) => m.BranchSchoolModule),
			},
			{
				path: "schools",
				loadChildren: () =>
					import("../app/views/pages/schools/schools.module").then(
						(m) => m.SchoolsModule
					),
			},
			{
				path: "needfinal",
				loadChildren: () =>
					import(
						"../app/views/pages/needfinal/needfinal.module"
					).then((m) => m.NeedFinalModule),
			},
			{
				path: "tktly",
				loadChildren: () =>
					import("../app/views/pages/tktly/tktly.module").then(
						(m) => m.TktlyModule
					),
				//	component: TktlyComponent,
			},
			{
				path: "needplan",
				loadChildren: () =>
					import("../app/views/pages/needPlan/needPlan.module").then(
						(m) => m.NeedPlanModule
					),
			},
			{
				path: "schoolPlans",
				loadChildren: () =>
					import(
						"../app/views/pages/schoolPlans/schoolPlans.module"
					).then((m) => m.SchoolPlansModule),
			},
			{
				path: "course-trainee-lecture",
				loadChildren: () =>
					import(
						"../app/views/pages/course-trainee-lecture/course-trainee-lecture.module"
					).then((m) => m.CourseTraineeLectureModule),
			},
			{
				path: "certificate-template",
				loadChildren: () =>
					import(
						"../app/views/pages/certificate-template/certificate-template.module"
					).then((m) => m.CertificateTemplateModule),
			},
			{
				path: "report-for-agent",
				loadChildren: () =>
					import(
						"../app/views/pages/report-for-agent/report-for-agent.module"
					).then((m) => m.ReportForAgentModule),
			},
			{
				path: "international-student-grades",
				loadChildren: () =>
					import(
						"../app/views/pages/international-student-grades/international-student-grades.module"
					).then((m) => m.ReportForAgentModule),
			},
			{
				path: "pre-users",
				loadChildren: () =>
					import(
						"../app/views/pages/pre-users/pre-users.module"
					).then((m) => m.PreUsersModule),
			},
			{
				path: "certificates",
				loadChildren: () =>
					import(
						"../app/views/pages/training-program-certificate/training-program-certificate.module"
					).then((m) => m.TrainingProgramCertificateModule),
			},
			{
				path: "students",
				loadChildren: () =>
					import("../app/views/pages/students/students.module").then(
						(m) => m.StudentsModule
					),
			},
			{
				path: "training-program-assessment",
				loadChildren: () =>
					import(
						"../app/views/pages/training-program-assessment/training-program-assessment.module"
					).then((m) => m.TrainingProgramAssessmentModule),
			},
			{
				path: "eva-frm-meeting-visit",
				loadChildren: () =>
					import(
						"../app/views/pages/eva-frm-meeting-visit/eva-frm-meeting-visit.module"
					).then((m) => m.EvaFrmMeetingVisitModule),
			},
			{
				path: "eva-frm-laboratory-visit",
				loadChildren: () =>
					import(
						"../app/views/pages/eva-frm-laboratory-visit/eva-frm-laboratory-visit.module"
					).then((m) => m.EvaFrmLaboratoryVisitModule),
			},
			{
				path: "achievement-banks",
				loadChildren: () =>
					import(
						"../app/views/pages/achievement-banks/achievement-banks.module"
					).then((m) => m.AchievementBanksModule),
			},
			{
				path: "cashier",
				loadChildren: () =>
					import("../app/views/pages/cashier/cashier.module").then(
						(m) => m.CashierModule
					),
			},
			{
				path: "weekly-reports",
				loadChildren: () =>
					import(
						"../app/views/pages/weekly-reports/weekly-reports.module"
					).then((m) => m.WeeklyReportsModule),
			},
			{
				path: "assessments",
				loadChildren: () =>
					import(
						"../app/views/pages/assessments/assessments.module"
					).then((m) => m.AssessmentsModule),
			},
			{
				path: "weekly-plans",
				loadChildren: () =>
					import(
						"../app/views/pages/weekly-plans/weekly-plans.module"
					).then((m) => m.WeeklyPlansModule),
			},
			{
				path: "learning-outcomes",
				loadChildren: () =>
					import(
						"../app/views/pages/learning-outcomes/learning-outcomes.module"
					).then((m) => m.LearningOutcomesModule),
			},
			{
				path: "learning-impact-card",
				loadChildren: () =>
					import(
						"../app/views/pages/learning-impact-card/learning-impact-card.module"
					).then((m) => m.LearningImpactCardModule),
			},
			{
				path: "course-credit-hours",
				loadChildren: () =>
					import(
						"../app/views/pages/course-credit-hours/course-credit-hours.module"
					).then((m) => m.CourseCreditHoursModule),
			},
			{
				path: "cetificate-grade-options",
				loadChildren: () =>
					import(
						"../app/views/pages/certificate-grade-options/certificate-grade-options.module"
					).then((m) => m.CertificateGradeOptionsModule),
			},
			{
				path: "setup",
				loadChildren: () =>
					import(
						"./views/pages/cashier-setup/cashier-setup.module"
					).then((m) => m.CashierSetupModule),
			},
			{
				path: "cashier-report",
				loadChildren: () =>
					import(
						"./views/pages/cashier-report/cashier-report.module"
					).then((m) => m.CashierReportModule),
			},
			{
				path: "cashier-administration",
				loadChildren: () =>
					import(
						"./views/pages/cashier-administration/cashier-administration.module"
					).then((m) => m.CashierAdministrationModule),
			},
			{
				path: "cashier-financial-dues",
				loadChildren: () =>
					import(
						"./views/pages/cashier-financial-dues/cashier-financial-dues.module"
					).then((m) => m.CashierFinancialDuesModule),
			},
			{
				path: "teacher-assignments",
				loadChildren: () =>
					import(
						"./views/pages/teacher-assignments/teacher-assignments.module"
					).then((m) => m.TeacherAssignmentsModule),
			},
			{
				path: "announcements",
				loadChildren: () =>
					import(
						"./views/pages/announcements/announcements.module"
					).then((m) => m.AnnouncementsModule),
			},
			{
				path: "jobs",
				loadChildren: () =>
					import("./views/pages/jobs/jobs.module").then(
						(m) => m.JobsModule
					),
			},
			{
				path: "SNSNeed_CourseGradePeriodSettings",
				loadChildren: () =>
					import(
						"./views/pages/SNSNeed_CourseGradePeriodSettings/SNSNeed_CourseGradePeriodSettings.module"
					).then((m) => m.SNSNeed_CourseGradePeriodSettingsModule),
			},
			{
				path: "noor-albyan",
				loadChildren: () =>
					import("./views/pages/noor-albyan/noor-albyan.module").then(
						(m) => m.NoorAlbyanModule
					),
			},
			// { path: "error/:type", component: ErrorPageComponent },
			{ path: "", redirectTo: "dashboard", pathMatch: "full" },
			{ path: "**", redirectTo: "dashboard", pathMatch: "full" },
		],
	},

	// { path: "**", redirectTo: "error/403", pathMatch: "full" },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
