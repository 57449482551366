// USA
export const locale = {
	lang: "en",
	data: {
		TRANSLATOR: {
			SELECT: "Select your language",
		},
		MENU: {
			NEW: "new",
			ACTIONS: "Actions",
			CREATE_POST: "Create New Post",
			PAGES: "Pages",
			FEATURES: "Features",
			APPS: "Apps",
			DASHBOARD: "Dashboard",
			USER_MANAGEMENT: "User Management",
			USERS: "Users",
			ACCESS_CONTROL: "Access Control",
			GROUPS: "Groups",
			Entities: "Entities",
			SYSTEM_SETTINGS: "System Setting",
			BRANCHES: "Branches",
		},
		COMMON: {
			HTTP_RESPONSE_MESSAGE: {
				CONFLICT_VALUE: "Conflict Value ",
				ERROR: "The was a problem",
				BAD_REQUEST: "Bad Request: Please enter required field correctly.",
				UNAUTHORIZED: "Unauthorized Request",
			},
			COLUMN: {
				ID: "Id",
				NAME: "Name",
				NAME_AR: "Name Ar",
				IS_ACTIVE: "Activate",
				IS_GROUP: "Is Group",
				GROUPBY: "Group By",
				GROUPBY_AR: "Group by Ar",
				COMPANYNAME: "Company Name",
				BRANCHNAME: "Branch",
				TRACKNAME: "Track",
				COUNTRYNAME: "Country Name",
				UNITNAME: "Unit name",
				COURSE: "Course",
				GRADE: "Grade",
				ISO: "iso",
				THREELETTERISOCODE: "three Letter Iso Code",
				LESSONNAME: "Lesson name",
				DATE: "Date",
				CREATE_DATE: "Create Date",
				TITLE: "Title",
				TITLE_EN: "English itle",
				CREATED_BY: "Created By",
				STATE: "State",
				SCHOOL: "School",
				SECTION: "Section",
				TEACHER: "Teacher",
				PERIOD_TIME: "Period time",
				TYPE: "Type",
				IS_PRIMARY: "Primary"
			},
			ACTION: {
				SEARCH: "Search",
				ADD: "Add",
				EDIT: "Edit",
				LIST: "List",
				VIEW: "View",
				CREATE: "Create",
				UPDATE: "Update",
				DELETE: "Delete",
				ASSIGN: "Assign",
				PREVIEW: "Preview",
				PRINT: "Print",
				DETAILS: "Details",
				DRAG: "Drag",
				FILTER: "Filter",
				RETURN: "Return",
				SEND: "Send",
				FULL_MARK: "Full Mark",
				EXPORT_TO_EXCEL: "Export To Excel",
				CONFIRM: "Confirm",
				SAVE: "Save",
				CANCEL: "Cancel",
				SELECT: "Select",
				INSERT: "Insert"
			},
			DAYS: {
				SUNDAY: "Sunday",
				MONDAY: "Monday",
				TUESDAY: "Tuesday",
				WEDNESDAY: "Wednesday",
				THURSDAY: "Thursday",
				FRIDAY: "Friday",
				SATURDAY: "Saturday",
			},
			ALL: "All",
			SEARCH: "Search",
			SEARCH_ALL_FIELD: "Search in all fields",
			CREATE: "Create",
			EDIT: "Edit",
			DELETE: "Delete",
			ANALYZE: "Analyze",
			ASSIGN: "Assign to this group",
			SAVE: "Save",
			CANCEL: "Cancel",
			QUIT: "Çıkış",
			ACTIONS: "Actions",
			ACTIVE: "Active",
			PASSIVE: "Inactive",
			RESTORE: "Restore",
			YES: "Yes",
			NO: "No",
			NUM: "No.",
			DELETED: "Delete",
			UNDELETED: "Undeleted",
			BACK: "Back",
			CANCEL_CHANGES: "Cancel changes",
			SAVE_CHANGES: "Save changes",
			SAVE_AND_SENT: "Save and send",
			HELLO: "Hi, ",
			SIGN_OUT: "Sign Out",
			DELETE_DESCRIPTION:
				"Are you sure to permanently delete this record?",
			DELETE_WAIT_DESCRIPTION: "Record deleting ...",
			CONFIRM_WAIT_DESCRIPTION: "Record Confirming ...",
			DELETE_SUCCESS_MESSAGE: "Record deleted successfully.",
			PLEASE_WAIT: "Please wait ...",
			ADDED_SUCCESSFULLY: "Record added successfully",
			UPDATED_SUCCESSFULLY: "Record updated successfully",
			DELETED_SUCCESSFULLY: "Record deleted successfully",
			MANDATORY_FIELD: "Mandatory Field",
			FORM_ERROR_MESSAGE:
				"Oh snap! Change a few things up and try submitting again",
			NO_PERMISSON_MESSAGE:
				"Oh snap! You do not have permission for this section.",
			ISDELETED: "Is Deleted",
			MULTIPLE: "Is Multiple",
			ISLIST: "Is List",
			IS_REQUIRED: "Required",
			CHANGE_DETECTION: "Change Detected",
			QUIT_DESCRIPTION_MESSAGE:
				"Change detected. Are you sure you want to quit.",
			PLEASE_ENTER: "Please enter",
			PLEASE_SELECT: "Please select",
			EMAIL_FORMAT: "Please enter email format.",
			URL_FORMAT: "URL Pattern Invalid",
			NEW_RECORD: "New Record",
			EDIT_RECORD: "Edit Record",
			EXPORT: "Export",
			ALREADY_EXIST_RECORD: "Already Exist Record",
			NO_MATCHES: "No matches found",
			MISSING_VALUE: "Missing Values",
			MAXIMUM_LENGTH: "The maximum length is",
			MORE_ACTIONS: "More Actions",
			RECORD_LISTED: "Records Listed",
			ALL_FIELDS_MANDATORY: "All Fields Mandatory",
			PLEASE_ENTER_INPUT: "Please Enter Input",
			START_DATE_SHOULD_BE_GREATER_THAN_NOW_DATE:
				"Start Date should be greater than now Date",
			END_DATE_SHOULD_BE_GREATER_THAN_END_DATE:
				"End Date should be greater than Start Date",
			NOTES: "Notes",
			MAKE_SURE_ALL_VALUES_ARE_ENTERED_CORRECTLY:
				"Make sure all values are entered correctly",
			SUCCESSFULLY_DONE: "successfully done",
			CREATEDON: "Created on",
			CREATEDBY: "Created by",
			FINALMARK: "Final mark",
			SCHOOL: "School",
			BRANCH: "Branch",
			TEACHER: "Teacher",
			VISIT_DATE: "Visit date",
			GRADE: "Grade",
			COURSE: "Course",
			TOPIC: "Topic",
			NUMBER_OF_STUDENTS: "Number of students",
			VERY_CLEAR: "very clear",
			FAIRLY_STRAIGHTFORWARD: "fairly straightforward",
			NOT_NOTICED: "Not noticed",
			NOTES_AND_RECOMMENDATIONS: "Notes and recommendations",
			CLEAR: "Clear",
			PERIOD: "Period",
			NUMBER_OF_ABSENTEES: "Number of absentees",
			EXPAND_ALL: "Expand All",
			COLLAPSE_ALL: "Collapse All",
			EMPTY: "Empty",
			VALIDATION: {
				LESS_THAN_EQUALTO: "less than or equal to",
			},
			DATE_AND_TIME: "Date and Time",
			TRUE: "True",
			FALSE: "False",
			IS_TRUE: "Is True",
			OF: "of",
			ADD_ROW: "Add Row",
			ARABIC_NAME: "Arabic Name",
			PHONE_NUMBER: "Phone Number",
			SELECT_CONDITION_TO_LIST_DATA:
				"Please Select Condition to List Data",
			DATA_NOT_FOUND: "Data Not Found",
			NOT_FOUND: "Not Found",
			ADDED_BY: "Added By",
			ENROLLMENT_DATE: "Enrollment Date",
			TOTAL: "Total",
			WEEK: "Week",
			GOOD: "Good",
			MIDDLE: "Middle",
			EXCELLENT: "Excellent",
			WEAK: "Weak",
			PERFORMED: "Performed",
			NOT_PERFORMED: "Not Performed",
			REASON: "Reason",
			WORK: "Work",
			PROGRAM: "Program",
			WEIGHT: "Weight",
			FINAL: "Final",
			FIRST_NAME: "First Name",
			STUDENT_NAME_EN: "Student name EN",
			SURNAME: "Surname",
			MIDDLE_NAME: "Middle Name",
			FAMILY_NAME: "Family Name",
			BIRTH_CITY: "Birth City",
			ADMISSION_DATE: "Admission Date",
			LANGUAGE: "Language",
			VALUE_SHOULD_SMALL_THAN: "Value should be small than @param",
			CONFIRMATION: "Confirmation",
			ARE_YOU_SURE_YOU_WANT_TO_CONFIRM:
				"Are you sure you want to confirm?",
			PLEASE_FILL_MANDATORY_FIELDS: "Please fill mandatory fields",
			APPROVE: "Approve",
			STAGES: {
				KINDERGARTEN: "Kindergarten",
				PRIMARY: "Primary",
				INTERMEDIATE: "Intermediate",
				HIGH: "High",
				UNDEFIEND: "Undefiend"
			},
			PICTURE: "Picture",
			CHOOSE_IMAGE: "Choose Image",
			IS_DEFAULT: "Is Default",
			DATE_OF_BIRTH_MUST_BE_IN_THE_PAST: "Date of birth value should be in past", COPY: "copy"
		},
		AUTH: {
			GENERAL: {
				OR: "Or",
				SUBMIT_BUTTON: "Submit",
				NO_ACCOUNT: "Don't have an account?",
				SIGNUP_BUTTON: "Sign Up",
				FORGOT_BUTTON: "Forgot Password",
				BACK_BUTTON: "Back",
				PRIVACY: "Privacy",
				LEGAL: "Legal",
				CONTACT: "Contact",
				PLEASE_ENTER_USERNAME_AND_PASSWORD:
					"Please enter username and password",
				LEARNING_MANAGEMENT_SYSTEM: "Learning Management System",
				LMS_DESCRIPTION:
					"The ultimate Learning Management System for next generation education.",
				SIGN_UP_LABEL: "Do not have an account yet?",
				LOGIN_WITH_SSO: "Login with SSO",
			},
			LOGIN: {
				TITLE: "Login Account",
				BUTTON: "Sign In",
			},
			FORGOT: {
				TITLE: "Forgotten Password?",
				DESC: "Enter your email to reset your password",
				SUCCESS: "Your account has been successfully reset.",
			},
			REGISTER: {
				TITLE: "Sign Up",
				DESC: "Enter your details to create your account",
				SUCCESS: "Your account has been successfuly registered.",
				PRE_USER_REGISTERED_SUCCESSFULLY:
					"Your account has been successfuly registered.<br>You can Sign In after confirmation your account.",
				SIGN_UP_FOR_STUDENT: "Sign Up as Student",
				SIGN_UP_FOR_PARENT: "Sign Up as Parent",
				THERE_IS_NO_ASSIGNED_STUDENT: "There is no assigned student",
				ADD_STUDENT: "Add Student",
			},
			INPUT: {
				EMAIL: "Email",
				FULLNAME: "Fullname",
				PASSWORD: "Password",
				CONFIRM_PASSWORD: "Confirm Password",
				USERNAME: "Username",
			},
			VALIDATION: {
				INVALID: "{{name}} is not valid",
				REQUIRED: "{{name}} is required",
				MIN_LENGTH: "{{name}} minimum length is {{min}}",
				AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
				NOT_FOUND: "The requested {{name}} is not found",
				INVALID_LOGIN: "The login detail is incorrect",
				REQUIRED_FIELD: "Required field",
				MIN_LENGTH_FIELD: "Minimum field length:",
				MAX_LENGTH_FIELD: "Maximum field length:",
				INVALID_FIELD: "Field is not valid",
				IN_ACTIVE_USER: "This User is In-Active",
				PASSWORD_PATTERN:
					"At least 6 characters ",
				PASSWORD_NOT_MATCHING: "Password not matching",
			},
		},
		USER_MANAGEMENT: {
			FIELS: {
				USERNAME: "Username",
				PASSWORD: "Password",
				CONFIRM_PASSWORD: "Confirm Password",
				FIELS: "Gender",
				ROLE: "Role",
				FULL_NAME: "Full Name",
				NATIONALITY: "Nationality",
				EMAIL_ADDRESS: "Email Address",
				MOBILE_NUMBER: "Mobile Number",
				SCHOOL_NAME: "School Name",
				SCHOOL_NAME_EN: "School Name En",
				SCHOOL_NAME_AR: "School Name Ar",
				FAX: "Fax",
				COUNTRY: "Country",
				CITY: "City",
				MAIN_STREET: "Main Street",
				SUB_STREET: "Sub Street",
				BUILDING_NUMBER: "Building Number",
				ZIP_CODE: "Zip Code",
				BOX_MAIL: "Box Mail",
				ADDRESS: "Address",
				SELECT_GENDER: "Select Gender",
				SELECT_ROLE: "Select Role",
				SELECT_COMPANY: "Select Company",
				SELECT_BRANCH: "Select Branch",
				SELECT_TRACK: "Select Track",
				SELECT_NATIONALITY: "Select Nationality",
				SELECT_COUNTRY: "Select Country",
				SELECT_CITY: "Select City",
				SELECT_PARENT: "Select Parent",
				SELECT_SCHOOL: "Select School",
				SELECT_SUBJECT: "Select Subject",
				SELECT_STAGE: "Select stage",
				IS_ACTIVE: "Is Active",
				BIRTH_OF_DATE: "Birth of Date",
				STATUS: "Status",
				GENDERS: "Genders",
				GROUP: "Group",
				BRANCH: "Branch",
				PARENT: "Parent",
				SUBJECT: "Subject",
				STAGE: "Stage",
				TRACK: "Track",
				GRADE: "Grade",
				COURSE: "Course",
				CLASSES: "Classes",
				SELECT_JOB: "Select job"
			},
			USER_ADD_WIZARD: {
				BASIC_INFORMATION: "Basic Information",
				ADDITIONAL_INFORMATION: "Additional Information",
				LOCATION: "Location",
				REVIEW_AND_SUBMIT: "Review and Submit",
			},
			OTHER: {
				NEXT_STEP: "Next Step",
				PREVIOUS: "Previous",
				SUBMIT: "Submit",
				CHOOSE_DATE: "Choose a date",
				USER_LIST: "Users List",
				CREATE_NEW_USER: "Create new User",
				NEW_USER: "New user",
				SEARCH_USER: "Search user",
				SEARCH_IN_ALL_FIELDS: "Search in all fields",
				FILTER_BY_STATUS: "Filter by Status",
				FILTER_BY_ROLE: "Filter by Role",
				FILTER_BY_STUDENT: "Filter By Student",
				FILTER_BY_USER: "Filter by user",
				USER: "User",
				MALE: "Male",
				FEMALE: "Female",
				REVIEW_YOUR_DETAILS_AND_SUBMIT:
					"Review your Details and Submit",
				BACK_TO_THE_USERS_LIST: "Back to the users list",
				SAVE_AND_CONTINUE: "Save & Continue",
				EDIT_USER: "Edit User",
				ANOTHER_TAB: "Another Tab",
				PASSWORD_DONT_MATCH: "Oh snap! Password not match.",
				CHANGE_PASSWORD: "Change Password",
				ASSIGNED_SECTIONS: "Assigned Section",
				SELECT_SECTION: "Select Section",
				SECTIONS: "Sections",
				ASSIGNMENTS: "Assignments",
				FILTER_BY_GROUP: "Filter by Group",
				FILTER_BY_KEY: "Filter by Key",
				ONE_SECTION_FOR_STUDENT:
					"Student should be assigned only for one grade section",
				SELECT_LIST: "Select a list to show",
				SELECT_CLASS: "Select a class",
				BIRTHDAY_DATE_BIG_VARIFICATION:
					"Birthday should be less than today",
				FILTER_BY_BRANCH: "Filter By Branch",
				SELECT_USER: "Select User",
				PROFILE_IMAGE_UPLOAD_MESSAGE: "Click or Drag and Drop!",
				FILTER_BY_GENDER: "Filter by Gender",
				STUDENT_DETAILS: "Student details",
				STUDENT_DETAILS_EN: "Student details (EN)"
			},
			STUDENT_DETAILS: {
				IDENTITY_NO: "Identity number",
				SELECT_IDENTITY_DATE: "Identity number expiry date",
				PASSPORT_NO: "Passport No.",
				SELECT_BIRTHCOUNTRY: "Select birth country",
				BIRTH_CITY: "Birth city",
				BLOOD_TYPE: "Blood type",
				PREVIOUS_SCHOOL: "Previous school",
				HOME_OWNED: "Home owned",
				HEALTH_STATUS: "Health status",
				HEALTH_STATUS_RECO: "Health status recommendations",
				EDUCATIONAL_BEHAVIOR: "Educational behavior",
				EDUCATIONAL_BEHAVIOR_RECO: "ducational behavior recommendations",
				HOOBIES_AND_TALENTS: "Hobbies and talents",
				HOOBIES_AND_TALENTS_RECO: "Hobbies and talents recommendations",
				EXPECTATION_ACHIEVEMENT: "Expectation achievement",
				EXPECTATION_ACHIEVEMENT_RECO: "Expectation achievement recommendations",
				IDENTITY_NO_IS_REQUIRED: "Identity number is required"
			}
		},
		ACCESS_CONTROL: {
			ADD_ACCESS_CONTROL: "Add Access Control",
			EDIT_ACCESS_CONTROL: "Edit Access Control",
			PARENT: "Parent",
			ROOT: "Root",
			ASSING_ENTITIES: {
				ENTITIES: "Entities",
				ADD_ENTITY: "Add Entity",
				FILTER_BY_VIEW: "Filter by View",
				FILTER_BY_CREATE: "Filter by Create",
				FILTER_BY_UPDATE: "Filter by Update",
				FILTER_BY_DELETE: "Filter by Delete",
			},
			ASSIGN_GROUPS: {
				ASSIGN_GROUPS: "Assign Groups",
				SELECT_GROUP: "Select Group",
				ASSIGNED_GROUPS: "Assigned Groups",
			},
		},
		GROUPS: {
			GROUP_LIST: "Group List",
			GROUPS: "Groups",
			NEW_GROUP: "New Group",
			EDIT_GROUP: "Edit Group",
			GROUP_NO: "Group No",
			GROUP_NAME: "Group Name",
		},
		LEARNING_TRACK: {
			COLOR_CODE: "Color",
			TRACK_NAME: "Track Name",
			LEARNING_TRACK: "Learning Track",
		},
		SCHEDULE_PERIOD: {
			START_TIME: "Start Time",
			END_TIME: "End Time",
			ALREADY_EXIST_TIME: "Already Exist Time",
		},
		BREAD_CRUMB: {
			COMMON: {
				HOME: "Home",
				LIST: "List",
			},
			EVA_ASSESSMENTS: {
				EVA_ASSESSMENTS_MANAGEMENT: "Assessments Management",
				EVA_ASSESSMENTS_LIST: "Management List",
			},
			USER_MANAGEMENT: {
				EDIT_USER: "Edit User",
				USER_MANAGEMENT: "User Management",
				USERS: "Users",
				CREATE_USER: "Create User",
				USER_PROFILE: "User Profile",
			},
			ACCESS_CONTROL: {
				ACCESS_CONTROL_MANAGEMENT: "Access Control Management",
				ACCESS_CONTROL: "Access Control",
				ACCESS_CONTROL_LIST: "Access Control List",
				EDIT_ACCESS_CONTROL: "Edit Access Control",
			},
			GROUPS: {},
			ENTINTIES: {
				ENTINTIES_MANAGEMENT: "Manage Entities",
				ENTINTIES: "Entities",
				EDIT_ENTINTIES: "Edit Entities",
				ENTINTIES_LIST: "Entities List",
			},
			BRANCHES: {
				BRANCHES_MANAGEMENT: "Manage Branches",
				BRANCHES: "Branches",
				EDIT_BRANCHES: "Edit Branch",
				BRANCHES_LIST: "Branches List",
			},
			COMPANIES: {
				COMPANIES_MANAGEMENT: "Manage Companies",
				COMPANIES: "Companies",
				EDIT_COMPANIES: "Edit Company",
				COMPANIES_LIST: "Companies List",
			},
			LEARNINGTRACK: {
				LEARNINGTRACK_MANAGEMENT: "Manage Learning Track",
				LEARNINGTRACKS: "Learning Tracks",
				EDIT_LEARNINGTRACK: "Edit Learning Track",
				LEARNINGTRACKS_LIST: "Learning Tracks List",
				SELECT_LEARNING_TRACK: "Select Learning Track",
				FILTER_LEARNING_TRACK: "Filter by Learning Track",
			},
			COURSE: {
				COURSE_MANAGEMENT: "Cource Managment",
				COURSES: "Cources",
				EDIT_COURSES: "Edit Cource",
				COURSE_LIST: "Cources List",
				REMOVE_COURSE: "Remove Course",
			},
			COURSE_UNIT: {
				COURSE_UNIT_MANAGEMENT: "Course Unit  Management",
				UNITS: "Units",
				UNIT: "Unit",
				EDIT_UNITS: "Edit Units",
				COURSE_UNIT_LIST: "Course Unit List",
				ADD_COURSE_UNIT: "Add Course Unit",
			},
			COURSE_LESSONS: {
				LESSONS_MANAGEMENT: "Lessons Management",
				LESSONS_LIST: "Lessons List",
				EDIT_LESSONS: "Edit Lesson",
				ADD_LESSONS: "Add Lesson",
			},
			OUTCOME: {
				TITLE: "Title",
				TITLE_AR: "Arabic title",
				ADD_OUTCOME: "Add Outcome",
				ASSIGN_ROLE: "Assign Role",
			},
			QUESTIONBANKS: {
				QUESTIONBANKS_MANAGEMENT: "Questions bank management",
				QUESTIONBANKS_LIST: "Questions bank list",
			},
			STAGE: {
				MANAGE_STAGE: "Manage Stage",
				STAGE_LIST: "Stage List",
				NEW_STAGE: "New Stage",
				EDIT_STAGE: "Edit Stage",
			},
			GRADES: {
				GRADES_MANAGEMENT: "Manage Grades",
				GRADES: "Grades",
				EDIT_GRADES: "Edit Grade",
				GRADES_LIST: "Grades List",
				NEW_GRADE: "New Grade",
				GRADE: "Grade",
			},
			GRADES_SECTION: {
				GRADES_SECTION_MANAGEMENT: "Manage Grade Sections",
				GRADES_SECTION: "Grade Section",
				EDIT_GRADES_SECTION: "Edit Grade Section",
				GRADES_SECTION_LIST: "Grade Section List",
				NEW_GRADE_SECTION: "New Grade Section",
			},
			CITIES: {
				CITIES_MANAGEMENT: "Manage Cities",
				CITIES: "Cities",
				EDIT_CITIES: "Edit City",
				CITIES_LIST: "Cities List",
			},
			COURSE_UNITS: {
				COURSE_UNITS_MANAGEMENT: "Manage Course Units",
				COURSE_UNITS: "Course Units",
				EDIT_COURSE_UNIT: "Edit Course Unit",
				COURSE_UNITS_LIST: "Course Unit List",
			},

			COUNTRIES: {
				COUNTRIES_MANAGEMENT: "Manage Countries",
				COUNTRIES: "Countries",
				EDIT_COUNTRIES: "Edit Country",
				COUNTRIES_LIST: "Countries List",
			},

			APPLICATIONLOGS: {
				APPLICATIONLOGS_MANAGEMENT: "Manage ApplicationLogs",
				APPLICATIONLOGS: "ApplicationLogs",
				APPLICATIONLOGS_LIST: "ApplicationLogs List",
				USERID: "User",
				ACTIONTAKEN: "Action Taken",
				REFERENCEID: "Reference",
				CATEGORY: "Category",
				ACTIONDATE: "Action Date",
			},
			ACLOBJECTS: {
				ACLOBJECTS_MANAGEMENT: "Manage Procedures",
				ACLOBJECTS: "Procedures",
				EDIT_ACLOBJECTS: "Edit Procedure",
				ACLOBJECTS_LIST: "Procedure List",
				OBJECTTYPEID: "Type",
			},
			SCHEDULE_PERIODS: {
				SCHEDULE_PERIOD: "Schedule Period",
				EDIT_SCHEDULE_PERIOD: "Edit Schedule Period",
				SCHEDULE_PERIOD_LIST: "Schedule Period List",
			},
			SCHEDULE: {
				SCHEDULE: "Schedule",
				EDIT_SCHEDULE: "Edit Schedule",
				SCHEDULE_LIST: "Schedule List",
				SCHEDULE_ADD: "Schedule Add",
				PERIODS: "Periods",
			},
			LMSCONFIGURATIONS: {
				LMSCONFIGURATIONS: "Technical Settings",
				EDIT_LMSCONFIGURATIONS: "Edit Technical Settings",
				LMSCONFIGURATIONS_LIST: "Technical Settings List",
				CONFIGGROUP: "Group",
				CONFIGKEY: "Key",
				CONFIGVALUE: "Value",
				DESCRIPTION: "Description",
			},
			COURSE_LESSON: {
				TITLE: "Title",
				TITLE_AR: "Title Ar",
				TITLE_EN: "Title En",
			},
			WFTYPES: {
				WFTYPES: "Operations Types",
				WFTYPES_MANAGEMENT: "Manage Operations Types",
				EDIT_WFTYPES: "Edit Operation Type",
				WFTYPES_LIST: "Operations Types List",
				WFSTEPS: "Steps",
				DELETE_WFSTEPS: "Delete Step",
				WFSTEPS_LIST: "Steps List",
			},
			REQUEST_TEACHERS: {
				NEW_REQUEST: "New Request",
				REQUEST_TEACHERS: "Request Teachers",
				EDIT_REQUEST_TEACHERS: "Edit request teacher",
				REQUEST_TEACHERS_LIST: "Request teacher list",
				REQUEST_NAME: "Request Name",
				REQUEST_NAMEAR: "Arabic Request Name",
				REJECT: "Reject Request",
				ACCEPT: "Accept Request",
			},
			QUESTION_TYPES: {
				NEW_TYPE: "New Type",
				EDIT_TYPE: "Edit Type",
				DELETE_TYPE: "Delete Type",
				QUESTION_TYPES_LIST: "Question Types List",
				QUESTION_TYPES: "Question Types",
			},
			QUESTION_CATEGORY: {
				NEW_CATEGORY: "New Category",
				EDIT_CATEGORY: "Edit Category",
				DELETE_CATEGORY: "Delete Category",
				QUESTION_CATEGORY_LIST: "Question Categories List",
				QUESTION_CATEGORY: "Questions Categories",
			},
			QUIZZ: {
				QUIZZES: "Quizes",
				QUIZZ: "Quiz",
			},
			QUIZZ_STATUS: {
				NEW_STATUS: "New Status",
				EDIT_STATUS: "Edit Status",
				DELETE_STATUS: "Delete Status",
				QUIZZ_STATUS_List: "Quizz Status List",
				QUIZZ_STATUS: "Quizz Status",
			},
			QUIZZ_TYPES: {
				QUIZZ_TYPES_LIST: "Quizz Types List",
				QUIZZ_TYPES: "Quizz types",
			},
			MESSAGE: {
				MESSAGES: "Messages",
				MESSAGE_LIST: "Message List",
			},
			SESSIONS: {
				SESSIONS: "Online Users",
				SESSIONS_MANAGEMENT: "Online Users",
				SESSIONS_LIST: "Online Users List",
				IS_PERMENENT: "Permanent",
				TEMPORARY: "Temporary",
				CREATED_DATE: "Create Date",
			},
			TEACHER_PROJECT_MANAGER: {
				TEACHER_PROJECT_MANAGER: "My Group",
				TEACHER_PROJECT_MANAGER_MANAGEMENT: "My Group management",
				TEACHER_PROJECTS_MANAGER_MANAGEMENT: "Groups management",
				TEACHER_PROJECT_MANAGER_LIST: "Teachers list",
				ASSIGN_STUDENTS: "Assign Students",
				GROUP: "Group",
				SELECT_STUDENT: "Select Student",
				ASSIGNED_STUDENTS: "Assigned Students",
				PROJECT_MANAGER: "Project manager",
				CREATE_GROUP: 'Create group',
				EDIT_GROUP: "Edit group"
			},
			PLANS: {
				PLANS: "Plans",
				PLANS_LIST: "Plans List",
				PLANS_EDIT: "Edit Plan",
				PLANS_MANAGEMENT: "Plans Management",
				GOALS: "Goal",
				NOTES: "Notes",
				START_DATE: "Start Date",
				APPROVED: "Approved",
				REJECTED: "Rejected",
				TERM: "Term",
			},
			ACTIVITY: {
				ACTIVITY: "Activities",
				ACTIVITIES_LIST: "Activities List",
				ACTIVITIES_EDIT: "Edit Activity",
				ACTIVITIES_MANAGEMENT: "Activities Management",
			},
			QUIZES_AND_HOMEWORK: {
				QUIZES_AND_HOMEWORK_MANAGEMENT:
					"Quizes and Homework Management",
				QUIZES_AND_HOMEWORK: "Quizes and Homework",
				QUIZES_AND_HOMEWORK_LIST: "Quizes and Homework List",
				ADD_QUIZES_AND_HOMEWORK: "Add Quizes and Homework",
				EDIT_QUIZES_AND_HOMEWORK: "Edit Quizes and Homework",
				PREVIEW_QUIZES_AND_HOMEWORK: "Preview Quizes and Homework",
				ANALYZE_QUIZ_RESULT: "Analyze quiz result",
				OTHER_STUDENTS: "Other Students"
			},
			LESSON_PREPARATIONS: {
				LESSON_PREPARATIONS_MANAGEMENT:
					"Lesson Preparations Management",
				LESSON_PREPARATIONS: "Lesson Preparations",
				LESSON_PREPARATIONS_LIST: "Lesson Preparations List",
				ADD_LESSON_PREPARATIONS: "Add Lesson Preparations",
				EDIT_LESSON_PREPARATIONS: "Edit Lesson Preparations",
				VIEW_LESSON_PREPARATIONS: "View Lesson Preparations",
				VIRTUAL_CLASS: "Virtual classes"
			},
			SCHOOL_YEARS: {
				SCHOOL_YEARS_MANAGEMENT: "School Years Management",
				SCHOOL_YEARS: "School Years",
				SCHOOL_YEAR: "School Year",
				SCHOOL_YEARS_LIST: "School Years List",
				ADD_SCHOOL_YEARS: "Add School Years",
				EDIT_SCHOOL_YEARS: "Edit School Years",
			},
			ATTENDANCE: {
				ATTENDANCE: "Attendance",
				ATTENDANCE_LIST: "Attendance List",
				SCHOOL: "School",
				CLASS: "Class",
				COURSE: "Course",
				SECTION: "Section",
			},
		},
		ENTITIES: {
			ORDER: "Order",
			MENU_URL: "Menu Url",
			IMAGE_PATH: "Image Path",
			GROUP_IMAGE_PATH: "Group Image Path",
			SELECT_PARENT: "Select Parent",
		},
		STAGES: {
			STAGE: "Stage",
			STAGES: "Stages",
			SELECT_STAGE: "Select Stage",
			PLEASE_SELECT_COURSE: "Please Select Course",
			FILTER_BY_STAGES: "Filter By Stage",
		},
		GRADE: {
			SELECT_GRADE: "Select Grade",
			FILTER_BY_GRADE: "Filter By Grade",
		},
		COURSES: {
			SELECT_COURSE: "Select course",
		},
		QUIZZES: {
			SELECT_QUIZZES: "Select quiz",
		},
		TERM: {
			SELECT_TERM: "Select term",
			FIRST_TERM: "First term",
			SECOND_TERM: "Second term",
		},
		SCHEDULE: {
			SCHOOL: "School",
			YEAR: "Year",
			SCHEDULE_DETAIL: "Schedule Detail",
			SELECT_PERIOD: "Select Period",
			ASSIGN_PERID: "Assign Period",
			SELECT_TEACHER: "Select Teacher",
			TIME_VALIDATION_MESSAGE:
				"End Time should be greater than Start Time",
			SELECT_GRADE_SECTION: "Select Grade Section",
			PERIODS: "Periods",
			SCHEDULE_PREVIEW: "Schedule Preview",
			NOT_EXIST_SCHEDULE_PERIOD:
				"There is no any Schedule Period. Please define schedule period from Schedule Period Management.",
		},
		COURSE_LESSON: {
			COURSE_LESSON: "Course Lesson",
			ASSIGN_COURSE_LESSON: "Assign Course Lesson",
			ASSIGNED_COURSE_LESSONS: "Assigned Course Lesson",
			SELECT_LESSON: "Select Lesson",
		},
		OUTCOME: {
			OUTCOMES: "Outcomes",
			ASSIGN_OUTCOME: "Assign Course Lesson",
			ASSIGNED_OUTCOMES: "Assigned Course Lesson",
			SELECT_OUTCOME: "Select outcome",
		},
		QUESTION_BANK: {
			SELECT_COGNITIVE_LEVEL: "Select cognitive level",
			SELECT_QUESTION_BANK_TYPE: "Select question bank type ",
			SELECT_QUESTION_CATEGORY: "Select Question Category",
		},
		COURSE_UNIT: {
			SELECT_COURSE_UNIT: "Select Course Unit",
		},
		WFSTEPS: {
			ALL_FIELDS_REQUIRED: "All fields must be filled to add a step",
		},
		REQUEST_TEACHERS: {
			INAPPROPRIATE_REQUEST:
				"A teacher can't be requested from the same school",
			REQUEST_EXIST: "This teacher already requested",
			SEND: "Send request",
			SENT: "Sent",
			RECEIVED: "Received",
			ACCEPTED: "Accepted",
			REJECTED: "Rejected",
		},
		MESSAGE: {
			NEW_MESSAGE: "New Message",
			INBOX: "Inbox",
			DRAFT: "Draft",
			SENT: "Sent",
			TRASH: "Trash",
			RELOAD_LIST: "Reload List",
			DELETE_MESSAGE: "Delete Message",
			SENT_MESSAGE: "Sent Message",
			SAVE_AS_DRAFT: "Save as Draft",
			ENTER_INSTRUCTIONS: "Enter Instructions",
			ATTACHMENT_CONTEXT: "Attachment Upload, Click or Drag and Drop!",
			CLICK_TO_SEE_UPLOADED_FILE: "Click here to open the file",
			OR: "or",
			CLICK_TO_REMOVE: "Click here to remove it",
			BACK_TO_LIST: "Back to list",
			LOAD_MORE_MESSAGE: "Load More Message",
			MESSAGES_LOADED: "Messages Loaded",
			QUESTION_TYPE: "Question Type",
		},
		TEACHER_PROJECT_MANAGER: {
			ASSIGNED_TEACHERS: "Assigned teachers",
			FILL_ALL_FIELDS: "Fill all fields",
		},
		ACTIVITY: {
			DATE_AND_TIME: "Date and time",
			PROGRAM_NAME: "Program",
			PLACE: "Place",
			REQUIRMENTS: "Requirments",
			NEED_AGREE: "Need Agree",
			PUBLISHED: "Published",
			ACTIVITY_FORM: "Form",
			PLAN_NAME: "Plan",
		},
		QUIZES_AND_HOMEWORK: {
			TITLE: "Title",
			ATTEMP_NO: "Attemp No",
			START_DATE: "Start Date",
			END_DATE: "End Date",
			PERIOD_IN_MINUTES: "Period in minutes",
			AVAILABLE: "Available",
			NOT_AVAILABLE: "Not available",
			CLOSED: "Closed",
			QUIZ: "Quiz",
			FINAL_EXAM: "Final exam",
			QUIZZES: "Quizzes",
			ALTERNATIVE_QUIZZES: "Alternative quiz",
			HOMEWORK: "Homework",
			HOMEWORKS: "Homeworks",
			QUESTIONS: "Questions",
			RESULTS: "Results",
			ALL_ATTEMPTS: "All Attempts",
			IMPORT_FROM_QUESTION_BANK: "Import From Question Bank",
			QUESTION_BANK: "Question Bank",
			SECTION: "Section",
			FULL_MARK: "Full Mark",
			COURSE: "Course",
			STATUS: "Status",
			PERIOD: "Period",
			START_DATE_TIME: "Start Date Time",
			END_DATE_TIME: "End Date Time",
			COURSE_NAME: "Course Name",
			TYPE: "Type",
			QUESTION: "Question",
			ANSWER: "Answer",
			STUDENT: "Student",
			QUIZ_TITLE: "Quiz Title",
			REMAINING_PERIOD: "Remaining Period mm:ss",
			FINISHED: "Finished",
			IN_PROGRESS: "In Progress",
			MM_SS: "mm:ss",
			TOTAL_MARK: "Total Mark",
			QUIZ_SUBMISSION: "Quiz Submission",
			QUIZ_SUBMISSION_INFO: "Quiz Submission Info",
			CORRECT_ANSWER: "Correct Answer",
			WRONG_ANSWER: "Wrong Answer",
			STUDENT_ANSWER: "Student Answer",
			BEST_ANSWER: "Best Answer",
			SUB_QUESTION: "Sub Question",
			FIRST_PAIR: "First Pair",
			SECOND_PAIR: "Second Pair",
			MAX_MARK: "Maximum Mark",
			OPTION: "Option",
			IS_BLANK: "Is Blank",
			NEW_QUESTION: "New Question",
			ASSIGNED_QUESTIONS: "Assigned Questions",
			EDIT_QUESTION: "Edit Question",
			EDIT_ANSWER: "Edit Answer",
			ADD_OPTION: "Add Option",
			REMAINING_ATTEMPT: "Remaining Attempt",
			MISSING_SKILLS: "Missing skills",
			TOTAL: "Total",
			STUDENT_NAME: "Student name",
			Q: "Q",
			QUIZ_PREVIEW_DENIED_MESSAGE: "You cannot preview your result before the quiz end time.",
			FOLLOW_UP: "Follow Up",
			SUMMARY_OF_QUIZ: "Summary of quiz",
			HIDE_MARK: "Hide Mark",
			GAT_QUIZ: "GAT"
		},
		LESSON_PREPARATIONS: {
			EDUCATOR: "Educator",
			PROCEDURE: "Procedure",
			STRATIGY: "Stratigy",
			SELECT_YEAR: "Select Year",
			FOLLOW_INSTRUCTIONS:
				"Follow the instructions in the video by clicking",
			HERE: "Here",
			PRIOR_KNOWLADGE: "Prior Knowledge",
			KNOW_AND_DO: "Know and Do",
			PROCEDURAL_STEPS: "Procedural steps",
			LEARNING_OUTCOMES: "Learning Outcomes",
			LEARNING_OUTCOME: "Learning Outcome",
			TEACHING_STRATIGIES: "Teaching Strategies",
			RESOURCES_NEEDED: "Resources needed",
			LESSON_FILES: "Lesson files",
			LESSON_LINKS: "Lesson links",
			LESSON_PROCEDURES: "Lesson procedures",
			PERIOD_IN_MINUTES: "Period in minutes",
			MUTE_PARTICIPANTS: "Mute all participants",
			VIEWERS: "Viewers",
			VCLASS_VIEWRS: "Lesson Preparation VClass Viewers",
			ATTENDEES: "Attendees",
			FORMATIVE_ASSIGNMENT: "Formative Assessment",
			SUMMATIVE_ASSIGNMENT: "Summative Assessment",
			ADD_LINKS: "Add Links",
			PROTECT_CLASS: "Protect Class",
			VIRTUAL_CLASS_DESCRIPTION:
				"No one can access the share except through the username and password that is provided to him when requesting entry. Only do this option if you suffer from unauthorized entry",
			IMPORT_HOMEWORK: "Import Homework",
			NEW_HOMEWORK: "New Homework",
			PLEASE_SELECT_HOMEWORK: "Please Select Homework",
			LESSON_PREPARATION_PREVIEW: "Lesson Preparation Preview",
		},
		SCHOOL_YEARS: {
			GREGORIAN_YEARS: "Gregorian Year",
			HIJRI_YEARS: "Hijri Year",
			STUDENT_NAME: "Student name",
		},
		ATTENDANCE: {
			ATTEND: "Attend",
			EXIST: "Attend",
			LATE: "Late",
			EXECUSED: "Execused",
			ABSENT: "Absent",
			ATTENDANCE_DETAIL: "Attendance Detail",
			ALREADY_EXIST_ATTENDANCE:
				"This attendance already entered. Please go to edit screen.",
			CLASS_TIME: "Class Time",
			ATTENDANCE_DATE: "Attendance Date",
			THERE_IS_NO_SCHEDULE: "There is not any schedule.",
			DEPARTURE: "Departure",
			NO_ATTEND_RECORD: "NOT ADDED"
		},
		ASSESSMENT: {
			ASSESSMENT: "Assessment",
			ASSESSMENT_LIST: "Assessment List",
			MARK: "Mark",
			SKILL: "Skill",
			LATE_DAYS: "Late Days",
			ABSENCE_DAYS: "Absence Days",
			EXCUSE_DAYS: "Excuse Days",
			MISSING_SKILLS: "Missing Skills",
			FULL_MARK_FOR_ALL: "Full mark for all",
			ADD: "Add an assessment",
			EDIT: "Edit assessment",
			ASSESSMENTED: "Assessmented",
		},
		EXAM: {
			AFTER_POST_QUESTION_ANSWER: "Question has been saved successfully.",
			PLEASE_ANSWER_QUESTION: "Please answer the question",
		},
		STUDENTS: {
			STUDENTS: "Students",
		},
		TEACHERS: {
			TEACHERS: "Teachers",
			VALIDATION: {
				CHOOSE_TEACHER_FROM_LIST:
					"Choose a teacher from the list of teachers",
			},
		},
		QUESTION_TYPES: {
			FILL_BLANK: "Fill Blank",
			LONG_ANSWER: "Long Answer",
			MATCHING: "Matching",
			MULTIPLE_CHOICE: "Multiple Choice",
			TRUE_FALSE: "True False",
		},
		SHORTCUTS: {
			MANAGE_SHORTCUTS: "Manage Shortcuts",
			SHORTCUTS_LIST: "Shortcuts List",
			NEW_SHORTCUT: "New Shortcut",
			EDIT_SHORTCUT: "Edit Shortcut",
			SHORTCUTS: "Shortcuts",
			PAGES: "Pages",
			LINK: "Link",
			SELECT_PAGE: "Select Page",
			ASSIGNED_SHORTCUTS: "Assigned Shortcuts",
			SELECT_COLOR: "Select Color",
			ASSIGN_SHORTCUT: "Assign Shortcut",
		},
		SUPERVISOR_EVALUATION: {
			SUPERVISOR_EVALUATIONS_MANAGEMENT:
				"Supervisor Evaluations Management",
			SUPERVISOR_EVALUATIONS: "Supervisor Evaluations",
			SUPERVISOR_EVALUATIONS_LIST: "Supervisor Evaluations List",
			YESTERDAY_NIGHT_ACTIVITIES: "Yesterday Night Activities",
			FIRST_TAB_TITLE: "Evening time for the previous day",
			SECOND_TAB_TITLE: "Morning shift",
			THIRD_TAB_TITLE: "Supervisory and Support Services",
			FOURTH_TAB_TITLE: "Assessment of the school environment",
			FIFTH_TAB_TITLE:
				"Statistics of attendance and absence of teachers and educational services officials",
			SIXTH_TAB_TITLE: "Statistics of attendance and absence of Students",
			SEVENTH_TAB_TITLE: "Active programs at school",
			IN_THE_SEASON: "In the season",
			OUT_OF_THE_SEASONS: "Out of the season",
			CLEANING: "Cleaning",
			SITTING_PLAN: "Sitting Plan",
			LIGHTING: "Lighting",
			OTHER: "Other",
			SQUARES: "Squares",
			EQUIPMENTS: "Equipments",
			EXCEPT_SCHOOL: "Except School",
			PERCENTAGE: "Percentage",
			ABSENCES: "Absences",
			NOTE: "Note",
			ADDRESS_LINE: "Address Line",
			ENTER_ADDRESS_LINE: "Enter Address Line",
			ENTER_STATE_LINE: "Enter State Line",
			ENTER_POST_CODE: "Enter Post Code",
			THIRD_COMPONENT_DESCRIPTION:
				"Activities carried out during the period of the course",
			POST_CODE: "Post Code",
		},
		ELEOTS: {
			MANAGE_ELEOT: "Manage Eleot",
			ELEOT_LIST: "Eleot List",
			NEW_VISIT: "New Visit",
			EDIT_VISIT: "Edit Visit",
			COLLECTION: "Collection",
			TEACHER: "Teacher",
			COURSE: "Course",
			GRADE: "Grade",
			VERY_CLEAR: "Very Clear",
			CLEAR: "Clear",
			SOMEWHAT_CLEAR: "Somewhat Clear",
			NOT_NOTICED: "Not Noticed",
			TEACHER_NAME: "Teacher Name",
			IS_SENT: "Is Sent",
			COURSE_AND_GRADE: "Course and Grade",
			ENABLED: "Enabled",
			SOMEWHAT_ENABLED: "Somewhat Enabled",
			NOT_ENABLED: "Not Enabled",
			LEARNING_ENVIRONMENTS: "Learning Environments",
			TOOLS_DESCRIPTION:
				"Tools supporting the teaching and learning process",
			ACHIEVEMENT_LEVEL_FOR_STUDENT: "Achievement level for students",
			NOTES_AND_RECOMMANDATIONS: "Notes and recommendations",
			SAVE_AND_SENT: "Save and Sent",
		},
		CLASSROOM_VISITS: {
			CLASSROOM_VISITS: "Classroom visits",
			CLASSROOM_VISIT: "Classroom visit",
			ADD_CLASSROOM_VISITS: "Add classroom visit",
			EDIT_CLASSROOM_VISITS: "Edit classroom visit",
			VISIT_DATE: "Visit Date",
			VISIT_TYPE: "Visit Type",
			SCHOOL: "School",
			PERIOD: "Period",
			GRADE: "Grade",
			SECTION: "Section",
			TYPE: "Type",
			STUDENTS_COUNT: "Students Count",
			TEACHER: "Teacher",
			MOBILE_NUMBER: "Mobile Number",
			NATIONALITY: "Nationality",
			SUBJECT: "Subject",
			COURSE: "Course",
			TOPIC: "Topic",
			WRITTEN_TEST: "Written Test",
			ORAL_TEST: "Oral Test",
			OBSERVATION: "Observation",
			MARK: "Mark",
			SEND_DATE: "Send date",
			EFFECT: "Effect",
			VISIT_INFO: "Visit Info",
			PERFORMANCE_MEASUREMENT: "Performance Measurement",
			PERFORMANCE_MEASUREMENT_MARK: "Performance Measurement mark",
			MEASUREMENT_TYPE: "Measurement type",
			ACHIEVEMENT_LEVEL: "Achievement Level",
			RECOMMENDATIONS: "Recommendations ",
			SUPERVISOR_RECOMMENDATIONS: "Supervisor Recommendations",
			NOTES_AND_RECOMMENDATIONS: "Notes and recommendations",
			NOTES: "Notes",
			PERFORMANCE_AND_IMPACT: "Performance and impact",
			PERFORMANCE: "Performance",
			IMPACT: "[[Impact]]",
			WRITTEN_WORK: "Written work",
			INSTRUCTIONAL_VISIT: "Instructional visit",
			PREPARATIONS_ELECTRONIC: "Preparation electronic",
			GENERAL: "General",
			MEMORIZATION: "Memorization",
			QUARTERLY: "Quarterly",
			ARABIC: "Arabic",
			COURSES: "Courses",
			PRACTICAL: "Practical",
			INTRTNATIONAL: "International",
			TEACHERS: "Teachers",
			COURSE_NUMBER: "Course number",
			FIELD_WEIGHT: 'Field weight	',
			BY_FINAL_TOTALS: "By final totals",
			BY_SKILLS: "By skills",
			TEMPLATE: "Template",
			ADD_TEMPLATE: "Add template",
			ADD_SKILL: "Add skill",
			SKILLS: "Skills",
			MARK_OF_10: "Mark of 10",
			MARK_OF_40: "Mark of 40",
			MARK_OF_100: "Percentage of 100",
			HIDE_MARK: "Hide mark",
			ATTENDENCE: "Attendence",
			ATHER: "Impact",
			REALS: {
				Q1: "Employ the learner's previous experience in building new knowledge – Activating El Hakbina",
				Q2: "Learners’ participation in attractive introductory activities",
				Q3: "The learner participates in identifying the lesson's learning outcomes.",
				Q4: "Enabling the learner to absorb the learning outcomes in a logical sequence.",
				Q5: "Providing a supportive, motivating and fair environment for learning content and accomplishing related tasks.",
				Q6: "Following the correct procedures in implementing teaching methods and active learning strategies",
				Q7: "Taking different learning styles into account",
				Q8: "Employing the curriculum in the light of the teacher's guide to achieve learning outcomes",
				Q9: "Activating the written work: (student book, activity book, home assignment, worksheets ...).",
				Q10: "The use of a variety of sources, means and tools while presenting the lesson",
				Q11: "The learner actively participates in learning activities.",
				Q12: "Utilizing the implied content of the curriculum in building the balanced character of the learner.",
				Q13: "Linking the lesson to its applied dimensions.",
				Q14: "Formative Assessment",
				Q15: "Provide feedback serving learning",
				Q16: "Summative Assessment"
			},
			VIRTUALS: {
				Q1: "Quality of electronic communication tools",
				Q2: "Correct entry to the virtual class",
				Q3: "The integrity of the course of the session without interruption",
				Q4: "Learner participation in attractive introductory activities.",
				Q5: "The learner's use of previous experiences in building new knowledge (activating syllabus).",
				Q6: "The learner’s participation in the conclusion of the learning outcomes of the lesson topic.",
				Q7: "Logical sequence and smooth transition in empowering the learner with the learning outcomes",
				Q8: "Provide a supportive, stimulating and fair virtual classroom environment for learning content and getting assignments.",
				Q9: "Efficient use of the capabilities of the virtual teaching program in implementing methods and strategies Appropriate teaching",
				Q10: "The diversity of the interaction between the teacher and the student",
				Q11: "Employing the course in the light of the teacher's guide to achieve the learning outcomes",
				Q12: "Matching the lesson topic to the course distribution plan",
				Q13: "Matching what has been implemented to what was prepared in the electronic preparation",
				Q14: "Reliance in presenting the topic of the lesson on rich and varied electronic resources",
				Q15: "Continuous active participation of the learner in learning activities",
				Q16: "Employing the contents of the curriculum in building a balanced personality in the learner",
				Q17: "Linking the topic of the lesson with its applied dimensions",
				Q18: "Lesson calendar formative calendar",
				Q19: "Provide feedback that supports learning",
				Q20: "The lesson is a concluding calendar",
			},
			ONLINE_PREPARATIONS: {
				Q1: "Virtual classes exist",
				Q2: "Appropriateness of the learning outcomes to the topic of the lesson",
				Q3: "Appropriateness of learning outcomes to class time",
				Q4: "Adequacy of the Haqbana to the content of the lesson",
				Q5: "Adequacy of the injection for the time allocated to it",
				Q6: "The capacity of the haqbana to link previous and later learning",
				Q7: "Suitable preparation and focus for the content of the lesson",
				Q8: "Achieving preparation and focus for the elements that attract and excite the attention of the learner",
				Q9: "Suitable lesson procedures to achieve the outputs",
				Q10: "Smooth transition between lesson procedures",
				Q11: "The clarity of the gradation in the lesson procedures from simple to complex and easy to difficult",
				Q12: "Appropriateness of learning strategies to achieve the outcomes and subject matter of the lesson",
				Q13: "Appropriateness of learning strategies to the nature of the learning type (hypothetical / normal)",
				Q14: "Age-appropriate learning strategies for students",
				Q15: "Adequacy of media and links to learning outcomes and lesson content",
				Q16: "Media and links are appropriate to the nature of the learning type (default / normal)",
				Q17: "Appropriateness of formative assessment questions to learning outcomes",
				Q18: "The ability of formative evaluation questions to demonstrate students' mastery of the basic skills of the lesson",
				Q19: "Comprehensiveness of the final calendar",
				Q20: "The diversity of the final evaluation activities",
				Q21: "Comprehensiveness of assignments and tests for subject learning outcomes",
				Q22: "Diversity of assignment and examination questions between essays and topicals",
			},
			SECTIONS: {
				REVIEW_AND_WARM_UP: "Review and warm up",
				TEACHING: "Teaching",
				ASSESSMENTS: "Assessment",
			},
			ONLINE_PREPARATIONS_SECTIONS: {
				VIRTUAL_CLASSES: "Virtual classes",
				LEARNING_OUTCOMES: "Learning outcomes",
				HAQBANA: "Prior Knowledge",
				REVIEW_AND_WARM_UP: "Review and warm up",
				LESSON_PROCEDURES: "Lesson procedures",
				LEARNING_STRATEGIES: "Teaching Strategies",
				MEDIA_AND_LINKS: "Media and links",
				CONSTRUCTIVE_EVALUATION: "Constructive evaluation",
				FINAL_EVALUATION: "Summative Assessment",
				QUIZZES_AND_HOMEWORKS: "Quizzes and homework",
			},
			VIRTUAL_CLASS_VISITS: "Virtual class visits",
			VIRTUAL_CLASS_VISIT: "Virtual class visit",
			ADD_VIRTUAL_CLASS_VISITS: "Add virtual class visit",
			EDIT_VIRTUAL_CLASS_VISITS: "Edit virtual class visit",
		},
		SOCIAL_NETWORK: {
			ENTER_LINKEDIN_ADDRESS: "Enter LinkedIn Address",
			ENTER_FACEBOOK_ADDRESS: "Enter Facebook Address",
			ENTER_TWITTER_ADDRESS: "Enter Twitter Address",
			ENTER_INSTAGRAM_ADDRESS: "Enter Instagram Address",
			LINKEDIN_ADDRESS: "LinkedIn Address",
			FACEBOOK_ADDRESS: "Facebook Address",
			TWITTER_ADDRESS: "Twitter Address",
			INSTAGRAM_ADDRESS: "Instagram Address",
		},
		DASHBOARD: {
			START_QUIZ: "Start Quiz",
			SUBMIT_EXAM: "Submit Exam",
			FULL_SCREEN: "Full Screen",
			EXIT_FULL_SCREEN: "Exit Full Screen",
			ASSING_TEACHER: "Assign Teacher",
			ADD_STEPS: "Add Steps",
			EDIT_ITEM: "Edit item",
			DELETE_ITEM: "Delete Item",
			BRANCH_NAME: "Branch Name",
			ARABIC_BRANCH_NAME: "Arabic Branch Name",
			COURSE_UNIT_NAME: "Course Unit Name",
			ARABIC_COURSE_UNIT_NAME: "Arabic Course Unit Name",
			COLOR_CODE: "Color Code",
			ADD_QUESTION: "Add Question",
		},
		INSTRUCTORS: {
			INSTRUCTORS: "Instructors",
			INSTRUCTOR: "Instructor",
			INSTRUCTOR_NAME: "Instructor Name",
			INSTRUCTORS_MANAGEMENT: "Instructors Management",
			INSTRUCTORS_LIST: "Instructors List",
			SELECT_INSTRUCTOR: "Select Instructor",
		},
		TRAINING_PROGRAMS: {
			TRAINING_PROGRAMS_MANAGEMENT: "Training Programs Management",
			TRAINING_PROGRAMS_LIST: "Training Programs List",
			TRAINING_PROGRAMS: "Training Programs",
			TRAINING_COURSE: "Training Course",
			WORKSHOP: "Workshop",
			SELECT_TYPE: "Select Type",
			OPEN_ATTENDANCE: "Open Attendance",
			SELECT_STATUS: "Select Status",
			SELECT_SCHOOL_YEAR: "Select School Year",
			SELECT_TERM: "Select Term",
			NUMBER_OF_TARGET: "Number of Target",
			TRAINING_PROGRAM: "Training Program",
			SELECT_TRAINING_PROGRAM: "Select Trainin Program",
			FILTER_BY_TRAINING_PROGRAM: "Filter by Training Program",
			ABSENCE_AVERAGE: "Absence average",
			ATTENDENCE_AVERAGE: "attendence average"
		},
		COURSE_TRAINEES: {
			PLEASE_ASSIGN_TRAINEES: "Please Assign Trainess",
			SELECT_TRAINEE: "Select Trainee",
			TRAINESS: "Trainees",
			COURSE_TRAINEES_MANAGEMENT: "Course Trainees Management",
			COURSE_TRAINEES_LIST: "Course Trainees List",
			COURSE_TRAINEES: "Course Trainees",
			THERE_IS_NO_ASSIGNED_TRAINEES: "There is no assigned users.",
			TRAINEE_NAME: "Trainee Name",
			LATE_IN_MINUTE: "Late in Minute",
		},
		BRANCH_SCHOOL: {
			BRANCH_SCHOOL_MANAGEMENT: "Branch School Management",
			BRANCH_SCHOOL_LIST: "Branch School List",
			BRANCH_SCHOOLS: "Branch Schools",
			BRANCH_SCHOOL: "Branch School",
			MANAGER: "Manager",
			LICENSE_NUMBER: "License Number",
			SELECT_SCHOOL_MANAGER: "Select School Manager",
			SCHOOL_CODE: "School Code",
			SECOND_SCHOOL_CODE: "Second School Code",
			SELECT_BRANCH_SCHOOL: "Select Branch School",
			SCHOOL_PLAN_ID: "School Plan Id",
			SCHOOLS: "Schools",
			ADMINISTRATION: "Administration",
			ADMINISTRATION_AR: "Administration (Arabic)",
			OFFICE: "Office",
			OFFICE_AR: "Office (Arabic)",
			LOGO: "Logo",
			SELECT_LOGO: "Select logo"
		},
		COURSE_TRAINEE_LECTURE: {
			COURSE_TRAINEE_LECTURES_MANAGEMENT:
				"Course Trainee Lectures Management",
			COURSE_TRAINEE_LECTURE: "Course Trainee Lecture",
			COURSE_TRAINEE_LECTURES: "Course Trainee Lectures",
			CLASS_ROOM: "Class Room",
			DATA_NOT_FOUND_SELECT_TRAINING_PROGRAM:
				"Data Not Found. Please Select Training Program",
			TRAINER_ATTENDANCE: "Trainer Attendance",
			FILTER_BY_LECTURE: "Select by Lecture",
			SELECT_LECTURE: "Select Lecture",
		},
		COURSE_TRAINEE_ATTENDANCE: {
			COURSE_TRAINEE_ATTENDANCE_MANAGEMENT:
				"Course Trainee Attendance Management",
			COURSE_TRAINEE_ATTENDANCE: "Course Trainee Attendance",
			COURSE_TRAINEE_ATTENDANCES: "Course Trainee Attendances",
			TRAINER_ATTENDANCE: "Trainer Attendance",
			WRITTING_REPORT: "Writting Report",
			NUMERICAL_REPORT: "Numerical Report",
		},
		CERTIFICATE_TEMPLATE: {
			CERTIFICATE_TEMPLATES_MANAGEMENT:
				"Certificate Templates Management",
			CERTIFICATE_TEMPLATES: "Certificate Templates",
			CERTIFICATE_TEMPLATES_LIST: "Certificate Templates List",
		},
		REPORT_FOR_AGENT: {
			REPORT_FOR_AGENT_MANAGEMENT: "Report for Agent Management",
			REPORT_FOR_AGENTS: "Report for Agents",
			REPORT_FOR_AGENT_LIST: "Report for Agent List",
			MORNING_DUTY: "Morning Duty",
			MORNING_QUEUE: "Morning Queue",
			MORNING: "Morning",
			SELECT_DUTY_LEVEL: "Select Duty Level",
			CONTROL_USERS: "Control Users",
			DUTY_LEVEL: "Duty Level",
			DUTY_QUEUE: "Duty Queue",
			MORNING_LINE_UP: "Morning Line Up",
			IMPLEMENTATION_LEVEL: "Implementation Level",
			REASONS_FOR_NON_IMPLEMENTATION: "Reasons for non-implementation",
			SCHOOL_RADIO: "School Radio",
			TEACHER_PARTICIPATION_RATE: "Teacher Participation Rate",
			STUDENT_PARTICIPATION_RATE: "Student Participation Rate",
			TEACHERS_WHOE_ARE_LATE_IN_LINE: "Teachers who are late in line",
			WATCH_DURING_WORK: "Watch During Work",
			CONTROL_LEVEL: "Control Level",
			OBSERVERS: "Observers",
			SHIFT_END: "Shift End",
			SHIFT: "Shift",
			EVENNG_DUTY_LEVEL: "Evening Duty Level",
			DUTY_CONTROL_USERS: "Duty Control Users",
			TOTAL_NUMBER_OF_WORKS: "Total Number Of Works",
			LATE_STARTERS_FOR_THE_FIRST_CLASS:
				"Late starters for the first class",
			LATE_PERIOD: "Late Period",
			REGULAR_FUNCTIONING: "Regular Functioning",
			PERFORMANCE_LEVEL: "Performance Level",
			PROGRAMS_AND_PROJECTS: "Programs & Projects",
			IMPLEMENTER: "Implementer",
			IMPLEMENT_RATE: "Implement Rate",
		},
		INTERNATIONAL_STUDENT_GRADES: {
			MARKS_DISTRIBUTION: "Marks Distribution",
			INTERNATIONAL_STUDENT_GRADES: "International Student Grades",
			MAX_MARK: "Marks Mark",
			WEEKLY_FOLLOW_UP: "Weekly Follow Up",
			GRADING_TYPE: "Grading Type",
			DISABLE_ENABLE: "Disable & Enable",
			CLASS_WORK: "Class Work",
			PROJECT: "Project",
			GRADING_TOPIC: "Grading Topic",
			DISTRIBUTION_TYPE: "Distribution Type",
			INTERNATIONAL_STUDENT: "International Student",
			PREVIOUS_SCHOOL_NAME: "Previous School Name",
			IS_SEND_TRUE_VALIDATION_MESSAGE:
				"This action not acceptable because of records sended before.",
			CERTIFICATES: "Certificates",
			DETAILED_REPORT: "Detailed report",
			COLLECTIVE_REPORT: "Collective report",
			TRANSCRIPT: "Transcript"
		},
		PRE_USERS: {
			PRE_USERS: "Pre Users",
			PRE_USER_LISTS: "Pre User List",
			PRE_USER_MANAGEMENT: "Pre User Management",
			PRE_USER_CONFIRM_DESCRIPTION:
				"This user will be redirected to the edit page to be approved.",
		},
		CERTIFICATE_TEMPLATE_VARIABLES: {
			COLLECTION_NAME: "Collection Name",
			HOURS: "Training Hours",
			FROM: "From",
			TO: "To",
			PROGRAM_NAME: "Program Name",
			TRAINEE_NAME: "Trainee Name",
			PERIOD: "Duration",
			SCHOOL_YEAR: "School Year",
			VARIABLES: "Variables",
		},
		CONFIG: {
			DASHBOARD: "Dashboard",
			DESC: "Latest updates and statistic charts",
		},
		EHTEYAJ: {
			ID: "ID",
			EHTEYAJ_SCHOOLS: "Ehteyaj schools",
			MANAGE_SCHOOLS: "Manage schools",
			SCHOOL_LIST: "Schools list",
			SCHOOL_NAME: "School name",
			SCHOOL_NAME_EN: "English school name",
			TRACK: "Track",
			INFO: "Info",
			NO_PLAN_FOR_THIS_SCHOOL: "No plan for this school!",
		},
		SCHOOL_PLANS: {
			SCHOOL_PLANS: "School plans",
			MANAGE_SCHOOL_PLANS: "Manage school plans",
			SCHOOL_PLANS_LIST: "School plans list",
			NEW_SCHOOLPLAN: "New school plan",
			EDIT_SCHOOLPLAN: "تعديل خطة",
		},
		FINAL_NEEDS: {
			FINAL_NEEDS: "Final needs",
			FINAL_NEEDS_LIST: "Final needs list",
			EHTEYAJ: "Need",
			TOTAL: "Total",
		},
		NEED_PLANS: {
			MANAGE_NEED_PLANS: "Manage need plans",
			PLANS_LIST: "Plans list",
			FILTER_BY_PLAN: "Filter by plan",
			PLAN: "Plan",
		},
		TRAINING_PROGRAM_CERTIFICATE: {
			TRAINING_PROGRAM_CERTIFICATE_LIST:
				"Training Program Certificate List",
			TRAINING_PROGRAM_CERTIFICATE: "Training Program Certificate",
			ISSUING_THE_CERTIFICATES: "Issuing the Certificates",
		},
		SCHOOL_PLANS_EDIT: {
			TEACHER_SPOTTER: "Teacher spotter",
			SURPLUS_CLASSES: "Surplus classes",
			DEFICIENCY_CLASSES: "Deficiency classes"
		},
		NEED_PLAN_EDIT: {
			NUMBER_OF_CLASSES: "Number of classes",
			ORDER: "Order",
			PRIORITY_IF_NO_OTHER_OPTIONS: "Priority if no other options",
			COUNTED_IN_DEFICIENCY: "Counted in deficiency",
			SUPPORTIVE_SUBJECTS: "Supportive subjects",
			ADD_SUBJECT_DETAILS_IN_PLAN: "Add subject details in plan"
		},
		PROFILE: {
			MY_PROFILE: "My Profile",
			MY_TASKS: "My Tasks",
			MESSAGES: "Messages",
			SETTINGS: "Settings",
			SIGN_OUT: "Sign Out",
			LATEST_TASKS_AND_PROJECTS: "latest tasks and projects",
			LOGS_AND_NOTIFICATIONS: "Logs and notifications",
			MY_ACTIVITIES: "My activities",
			INBOX_AND_TASKS: "Inbox and tasks",
			MY_MESSAGES: "My messages",
			ACCOUNT_SETTING_AND_MORE: "Account settings and more",
		},
		STUDENTS_DISTRIBUTION: {
			STUDENTS_DISTRIBUTION: "توزيع الطلاب",
			STUDENTS: "Students",
			SECTIONS: "Sections"
		},
		PROMOTE_STUDENTS: {
			PROMOTE_STUDENTS: "Promote students",
			PROMOTE_STUDENTS_LIST: "Promote students logs",
			SCHOOL: "School",
			GRADE: "Geade",
			PROMOTE: "Promote",
			PROMOTED_TO: "Promoted to",
			CANCELED: "Canceled",
			ACTIVE: "Active",
			PROMOTED_STUDENTS: "Promoted students",
			DO_YOU_WANT_TO_CANCEL_PROMOTE_ACTION: "Do you want to cancel students promoting?",
			CANCELING_PROMOTE_ACTION: "Canceling promoting action...",
			CANCEL_PROMOTE_ACTION: "Cancel students promotion",
			YES: "Yes",
			NO: "No"
		},
		TRAINING_PROGRAM_ASSESSMENT: {
			ASSESSMENT_MANAGEMENT: "Training Program Assessment Management",
			ASSESSMENT: "Training Program Assessments",
			ASSESSMENT_LIST: "Training Program Assessment List",
			AVERAGE_OF_MARKS: "Average of Marks",
			ASSESSMENTS: "Assessments",
			REPORTS: "Reports",
			ASSESSMENT_INFO: "Assessment Info",
			INSTRUCTOR_EVALUTION: "Instructor Evaluation",
			ADDITIONAL_ITEMS_BENEFITING_REGULATORS: "Additional items benefiting regulators",
			HIGH: "High",
			GOOD: "Good",
			MODERATE: "Moderate",
			LOW: "Low",
			STRENGTHS: "Strengths",
			WEAKNESSES: "Weaknesses",
			AVERAGE_OF_ORGANIZER: "Average of Organizer",
			AVERAGE_OF_INSTRUCTOR: "Average of instructor",
			NUMBER_OF_ASSESSMENT: "Number of Assessment"
		},
		VISIT_MEETING: {
			VISIT_MEETING_LIST: "Visit Meeting List",
			VISIT_MEETING_MANAGEMENT: "Visit Meeting Management",
			PERFORMANCE_AND_IMPACT: "Performance and Impact",
			PERFORMANCE: "Performance",
			IMPACT: "Impact",
			WRITTEN_WORK: "Written Work",
			INSTRUCTIONAL_VISIT: "Instructional Visit",
			PREPARATION_ELECTRONIC: "Preparation Electronic",
			START_END_DATE_MANDATORY_MESSAGE: "Please fill Start Date and End Date"
		},
		WAITING_PERIODS: {
			WAITING_PERIODS: "Waiting periods",
			ALTERNATIVE_TEACHER: "Alternative teacher",
			PERIOD: "Period",
			ATTEND: "Attend",
			PERIOD_TIME: "Period time",
			ATTEND_DONE: "Attend done",
			ADD_WAITING_PERIODS: "Add waiting period",

		},
		TEACHER: "Teacher",
		STUDENT: "Student",
		FILTER_BY_USER_GROUP: "Filter by User Group",
		FILTER_BY_BRANCH: "Filter by Branch",
		FILTER_BY_IS_SENT: "Filter by Is Sent",
		FILTER_BY_SCHOOL: "Filter by School",
		FILTER_BY_GRADE: "Filter by Grade",
		FILTER_BY_COURSE: "Filter by Course",
		FILTER_BY_SECTION: "Filter by Section",
		PARENT: "Parent",
		FILTER_BY_YEAR: "Filter by Year",
		FILTER_BY_TERM: "Filter by Term",
		DAILY_PERIODS: {
			DAILY_PERIODS: "Daily periods",
			ATTEND_DONE: "Attend done",
			YES: "Yes",
			NO: "No"
		},
		FILTER_BY_TYPE: "Filter by Type",
		FILTER_BY_STATUS: "Filter by Status",
		FILTER_BY_UNIT: "Filter by Unit",
		FILTER_BY_TRAINING_PROGRAM: "Filter by Training Program",
		FILTER_BY_TEMPLATE_TYPE: "Filtert by Template Type",
		FILTER_BY_IS_APPROVED: "Filter by Is Approved",
		DAILY_REPORT: "Daily report",
		FILTER_BY_PUBLISH: "Filter by Publish",
		FILTER_BY_PLAN: "Filter by Plan",
		QUIZ_TYPE: "Quiz Type",
		EDUCATION_TRACK: "Education Track",
		EDUCATION_TRACKS: {
			GENERAL: "General",
			MEMORIZATION: "Memorization",
			QUARTERLY: "Quarterly",
			ARABIC: "Arabic",
			COURSES: "Courses",
			PRACTICAL: "Practical",
		},
		MONTHLY_REPORT: {
			MONTHLY_REPORT: "Monthly report",
			SELECT_YEAR: "Select year",
			SELECT_MONTH: "Select month",
			SELECT_STUDENT: "Select Student",
			FIRST_WEEK: "First week",
			SECOND_WEEK: "Second week",
			THIRD_WEEK: "Third week",
			FOURTH_WEEK: "Fourth week"
		},
		GRADE_SECTION: "Grade Section",
		DETAILED_ATTENDENCE_REPORT: "Detailed report",
		LABORATORY_VISIT_MANAGEMENT: "Laboratory Visit Management",
		LABORATORY_VISIT_LIST: "Laboratory Visit List",
		EXPERIENCE: "Experience",
		VISIT_VIEWERS: "Visit Viewers",
		ACHIEVEMENT_BANK: {
			ACHIEVEMENT_BANK: "Achievement bank",
			ACHIEVEMENT_BANK_MANAGER: "Achievement bank manager",
			ACHIEVEMENT_BANK_SUMMARY: "Achievement bank summary",
			OTHER: "Other",
			COLLECTION: "Branch",
			COMMANDER: "Commander",
			TEACHER: "Teacher",
			STUDENT: "Student",
			EDUCATIONAL_SUPERVISOR: "Educational supervisor",
			SCHOOL_MANAGER: "School manager",
			SCHOOL_AGENT: "School agent",
			OBSERVER: "Observer",
			ACTIVITY_PIONEER: "Activity pioneer",
			STUDENT_ORIENTATION: "Student orientation",
			THE_TRUSTED_OF_SOURCES: "The trusted of sources",
			LAB_REPORT: "Lab report",
			SCIENTIFIC: "Schientific",
			ATHLETE: "Athlete",
			SOCIAL: "Social",
			VOLUNTARY: "Voluntary",
			FIRST: "First",
			SECOND: "Second",
			THIRD: "Third",
			FOURTH: "Fourth",
			FIFTH: "Fifth",
			CULTURAL: "Cultural",
			COMPANY_LEVEL: "Compnay level",
			EDUCATION_OFFICE: "Education office",
			EDUCATION_ADMINISTRATION: "Education administration",
			MINISTRY_OF_EDUCATION: "Minstry of education",
			GULF_COUNTRIES: "Gulf countries",
			WORLWIDE: "World wide",
			DRAFT: "Draft",
			SENT: "Sent",
			ACCEPTED: "Accepted",
			REJECTED: "Rejected",
			SEARCH_BY_TITLE: "Search by title",
			DONOR: "Donor",
			BENEFICIARY: "Beneficiary",
			NEW_ACHIEVEMENT: "New achievement",
			EDIT_ACHIEVEMENT: "Edit achievement",
			ACHIEVERS: "Achievers",
			SELECT_ACHIEVERS: "Select achievers",
			OTHER_ACHIEVERS: "Other achievers",
			OTHER_ACHIEVERS_PLACEHOLDER: "Other achievers (Each name in a new line)",
			ACHIEVEMENT_TYPE: "Achievement type",
			ACHIEVEMENT_LEVEL: "Achievement level",
			POSITION: "Position",
			ACHIEVEMENT_SUMMARY: "Achievement summary",
			ATTACHMENTS: "Attachments",
			REASON_FOR_REJECT: "Reason for reject",
			RANK: "Rank",
			IS_SEEN: "Is seen",
			GOLDEN_TROPHY: "Golden trophy",
			GOLDEN_STAR: "Golden star",
			SILVER_STAR: "Silver star",
			BRONZE_STAR: "Bronze star",
		},
		FILTER_BY_PARENT: "Filter by Parent",
		PARENTS: "Parent",
		SELECT_STUDENT_PARENT: "Please Select Student or Parent",
		PAYMENT: "Payment",
		RETURN_DUES: "Return Dues",
		INVOICES: "Invoices",
		SPECIAL_DISCOUNT: "Special Discount",
		FATHER_PROMISSORY: "Father Promissory",
		PAYMENT_REP: "Payment Rep.",
		PAYMENT_COUNCHERS: "Payment Vouchers",
		PAYMENT_CASH: "Payments List",
		ACC_SHEET: "Acc. Sheet",
		DISCOUNT_REPORT: "Discount Report",
		ADD_STUDENT: "Add Student",
		IMPORT_STUDENT: "Import Student",
		MOTHER_INFO: "Mother Info",
		RESPONSIBLE_INFO: "Responsible Info",
		CALCULATE_BRO_INFO: "Calculare Bro Discount",
		FILTER_BY_PARENT_PHONE_NUMBER: "Filter by Parent Phone Number",
		FINANCIAL_OPERATIONS: "Financial Operations",
		REPORTS_AND_BONDS: "Reports & Bonds",
		BASIC_OPERATIONS: "Basic Operations",
		ADD_PAY_DETAILS: "Dues",
		BUSES: "Buses",
		PREVIOUS_BALANCE: "Previous Balance",
		ADJUSTMENT: "Adjustment",
		ELECTRONIC_WITHDRAWL: "Electronic Withdrawl",
		ADJUSTMENT_UPDATE: "Adjustment Update",
		PREVIOUS_DISCOUNT: "Previous Discount",
		FORMS_AND_REPORTS: "Forms & Reports",
		PAYMENT_SHEET: "Payment Sheet",
		FINANCIAL_REQUEST: "Financial Request",
		DATA_HISTORY: "Data History",
		BACK_ORDER: "Back Order",
		REG_FORM: "Reg Form",
		ACCEPTANCE_FORM: "Acceptance Form",
		DISPLAY_ATTACHMENTS: "Display Attachments",
		VAT_RECALCULATION: "VAT Recalculation",
		MOVE_TO_FATHER: "Move to Father",
		OTHER_OPERATION: "Other Operation",
		CHILDS: "Childs",
		NO_ASSOCIATED_CHILD_FOUND: "No associated child found",
		ENTITY_GROUP_DELETE_MESSAGE: "The menu group with children cannot be deleted.",
		WEEKLY_REPORTS: {
			WEEKLY_REPORTS: "Weekly reports",
			NEW_WEEKLY_REPORT: "New weekly report",
			WEEKLY_REPORT_BY: "ًWeekly report by",
			EDIT_WEEKLY_REPORT: "Edit weekly report",
			WEEK_ACTIVITIES: "Activities done among week",
			INTERVIEWS: "Parent interview",
			COMMUNICATION_ENABLED: "Enable communications",
			COMMUNICATION_TYPE: "Communion type",
			STUDENT_MONITORING: "Observe students scientifically and behaviorally",
			UNDER_ACHIEVERS: "Behavioral observations",
			ADVANTAGES: "Highlight pros",
			DRAWBACKS: "Highlight cons",
			OTHERS: "Others",
			OPENIONS: "Openions and suggestions",
		},
		MONTHLY_ASSESSMENTS: {
			MONTHLY_ASSESSMENTS: 'Monthly assessments',
			NEW_MONTHLY_ASSESSMENT: 'New monthly assessment',
			MONTHLY_ASSESSMENT: 'Monthly assessment',
			EDIT_MONTHLY_ASSESSMENT: 'Edit monthly assessment',
			MARK: "Mark",
			ABSENCE_DAYS: "Absence days",
			LATE_DAYS: "Late days",
			EXCUSE_DAYS: "Excuse days",
			MISSING_SKILLS: "Missing skills",
			BEHAVIORAL_OBSERVATIONS: "Behavioral observations",
			IMPORT_ATTENDECE: "Import attendece",
			FROM_TEACHER: "From teacher records",
			FROM_VIRTUL: "From virtual classes",
			IMPORT: "Import",
			NOTES: "Notes",
		},
		WEEKLY_PLANS: {
			WEEKLY_PLANS: "Weekly study plans",
			EDIT_WEEKLY_PLANS: "Edit weekly plan",
			NEW_WEEKLY_PLANS: "Add weekly plan",
		},
		BANKS: "Banks",
		BANK_MANAGEMENT: "Bank Management",
		BANK_NAME: "Bank Name",
		BANK_NAME_AR: "Bank Name Ar",
		DESCRIPTION: "Description",
		DESCRIPTION_AR: "Description Ar",
		ICON_URL: "Icon URL",
		NEW_BANK: "New Bank",
		EDIT_BANK: "Edit Bank",
		MANAGE_BANKS: "Manage Banks",
		BANK_LIST: "Bank List",
		MANAGE_PAYMENT_METHODS: "Manage Payment Methods",
		PAYMENT_METHODS_LIST: "Payment Methods List",
		EDIT_PAYMENT_METHOD: "Edit Payment Method",
		NEW_PAYMENT_METHOD: "New Payment Method",
		MANAGE_DISCOUNT_TYPE: "Manage Discount Types",
		DISCOUNT_TYPE_LIST: "Discount Types List",
		EDIT_DISCOUNT_TYPE: "Edit Discount Type",
		NEW_DISCOUNT_TYPE: "New Discount Type",
		MERGE_WITH_BROTHER: "Merge with Brother",
		IS_PERCENTAGE: "Is Percentage",
		SETUPS: "Setups",
		ASSIGN_PAYMENT_METHODS: "Assign Payment Methods",
		SELECT_ITEM: "Select Item",
		SELECT_PAYMENT_MENTHOD: "Select Payment Method",
		ASSIGNED_PAYMENT_METHODS: "Assigned Payment Methods",
		WEEKS: {
			1: "First week",
			2: "Second week",
			3: "Third week",
			4: "Fourth week",
			5: "Fifth week",
			6: "Sixth week",
			7: "Seventh week",
			8: "Eighth week",
			9: "Ninth week",
			10: "Tenth week",
			11: "Eleventh week",
			12: "Twelfth week",
			13: "Thirteenth week",
			14: "Fourteenth week",
			15: "Fifteenth week",
			16: "Sixteenth week",
			17: "Seventeenth week",
			18: "Eighteenth week",
			19: "Nineteenth week",
			20: "Twentieth week",
			21: "Twenty-firs week",
			22: "Twenty-second week",
			23: "Twenty-third week",
			24: "Twenty-fourth week",
			25: "Twenty-fifth week",
			26: "Twenty-sixth week",
			27: "Twenty-seventh week",
			28: "Twenty-eighth week",
			29: "Twenty-ninth week",
			30: "Thirtieth week",
			31: "Thirtieth -firs week",
			32: "Thirtieth -second week",
			33: "Thirtieth -third week",
			34: "Thirtieth -fourth week",
			35: "Thirtieth -fifth week",
			36: "Thirtieth -sixth week",
			37: "Thirtieth -seventh week",
			38: "Thirtieth -eighth week",
			39: "Thirtieth - ninth week",
			40: "Fortieth week",
			41: "Fortieth -firs week",
			42: "Fortieth -second week",
			43: "Fortieth -third week",
			44: "Fortieth -fourth week",
			45: "Fortieth -fifth week",
			46: "Fortieth -sixth week",
			47: "Fortieth -seventh week",
			48: "Fortieth -eighth week"
		},
		EDUCATOR_ASSESSMENTS: {
			ASSESSMENT_TYPE: "Assessment type",
			FILTER_BY_ASSESSMENT_TYPE: "Filter by assessment type",
			PROJECT_MANAGER_TO_TEACHER: "Project manager to teacher",
			AGENT_TO_PROJECT_MANAGER: "Agent to project manager",
			ACADIMIC_DIRECTOR_TO_AGENT: "Academic director to agent",
			ACADIMIC_DIRECTOR_TO_PROJECT_MANAGER: "Academic director to project manager",
			SCHOOL_ASSESSMENT: "School assessment"
		},
		LEARNING_CARD: {
			LEARNING_CARD: 'Learning Impact report',
			LEARNING_CARD_BY_COURSE: 'Learning Impact report by course',
			BY_COURSE: 'By course',
			LEARNING_CARD_BY_TOTAL: 'Learning Impact report by totals',
			BY_TOTAL: 'By totals',
			TOTAL: 'Total',
			AVERAGE: 'Average',
			SKILL: 'Skill'
		},
		COURSE_CREDIT_HOURS: {
			COURSE_CREDIT_HOURS: "Course credit hours",
			ADD_CREDIT_HOURS: "Add credit hours",
			EDIT_CREDIT_HOURS: "Edit credit hours"
		},
		CERTIFICATE_GRADE_OPTIONS: {
			CERTIFICATE_GRADE_OPTIONS: "Certificate grade options",
			ADD_CERTIFICATE_GRADE_OPTION: "Add grade",
			EDIT_CERTIFICATE_GRADE_OPTION: "Edit grade",
			MIN_MARK: "Min mark",
			MARK_TITLE: "Mark title"

		},
		I_AM_A_PARENT: "I am a Parent",
		I_AM_A_STUDENT: "I am a Student",
		GENDER: "Gender",
		INCOME_TYPE_MANAGEMENT: "Income Type Management",
		INCOME_TYPE_LIST: "Income Types List",
		NEW_INCOME_TYPE: "New Payment Method",
		EDIT_INCOME_TYPE: "Edit Payment Method",
		SEMESTER_MANAGEMENT: "Semeste Management",
		SEMESTER_LIST: "Semester List",
		NEW_SEMESTER_TYPE: "New Semester Method",
		EDIT_SEMESTER_TYPE: "Edit Semester Method",
		INCOME_SETTINGS_MANAGEMENT: "Income Setting Management",
		INCOME_SETTINGS_LIST: "Income Setting List",
		SEMESTER: "Semester",
		INCOME_TYPE: "Income Type",
		ORDERING_IN_PAYMENT: "Ordering In Payment",
		SAUDI_VAT_RATIO: "Saudi Vat Ratio",
		NON_SAUDI_VAT_RATIO: "Non Saudi Vat Ratio",
		BROTHER_DISCOUNT_MANAGEMENT: "Brother Discount Management",
		BROTHER_DISCOUNT_LIST: "Brother Discount List",
		NEW_BROTHER_DISCOUNT: "New Brother Discount",
		EDIT_BROTHER_DISCOUNT: "Edit Brother Discount",
		DISCOUNT_VALUE: "Discount Value",
		PERCENTAGE: "Percentage",
		VALUE: "Value",
		BROTHER_ORDER: "Brother Order",
		GRADE_INCOME_SETTINGS_MANAGEMENT: "Grade Income Settings Management",
		GRADE_INCOME_SETTINGS_LIST: "Grade Income Settings List",
		NEW_GRADE_INCOME_SETTINGS_TYPE: "New Grade Income Settings Method",
		EDIT_GRADE_INCOME_SETTINGS_TYPE: "Edit Grade Income Settings Method",
		STUDY_TYPE: "Study Type",
		DISTRBUTE_VALUE: "Distrburte Value",
		AUTO_COMPLETE_HINT: "Type at least 4 character to find record.",
		EDIT_MODE: "Edit Mode",
		LINKING_PAYMENT_METHOD: "Linking Payment Method",
		COMMISION_RATIO: "Commision Ratio",
		MAX_COMMITION: "Max Commition",
		LINKING_SCHOOLS: "Linked Schools",
		MANAGE_REGISTRATION_ADJUSTMENT: "Manage Registration Adjustment",
		REGISTRATION_ADJUSTMENT: "Registration Adjustment",
		REGISTRATION_ADJUSTMENT_LIST: "Registration Adjustment List",
		EDIT_REGISTRATION_ADJUSTMENT: "Edit Registration Adjustment",
		NEW_REGISTRATION_ADJUSTMENT: "New Registration Adjustment",
		PERIOD_FROM: "Period From",
		PERIOD_TO: "Period To",
		EXIST: "Exist",
		NOT_EXIST: "Not Exist",
		SEND_EMAIL: "Send Email",
		VALUE_GREATER_THAN: "Value Greater Than",
		VALUE_LESS_THAN: "Value Less Than",
		MAX_REMAINING_PERCENT: "Max Remaining Percent",
		FINANCIAL_DUES_LIST: "Financial Dues List",
		MANAGE_FINANCIAL_DUES: "Manage Financial Dues",
		REFERENCE_NUMBER: "Reference Number",
		PAYMENT_METHOD: "Payment Method",
		CHECK_NUMBER: "Check Number",
		DATE_OF_CHECK_OR_TRANSFER: "Date of Check or Transfer",
		RESPONSIBLES: "Responsibles",
		TEXT: "Text",
		TYPE_OF_PAYMENT: "Type of Payment",
		MANAGE_PAYMENT: "Manage Payment",
		CASHIER: "Cashier",
		PERMENANT: "Permenant",
		TEMPRORARY: "Temprorary",
		IS_PERMENANT: "Is Permenant",
		AMOUNT: "Amount",
		VALUE_FROM: "Value From",
		VALUE_TO: "Value To",
		ADD_SIBLING_DISCOUNT: "Add Sibling Discount",
		ADD_SPECIAL_DISCOUNT: "Add Special Discount",
		DISCOUNT_ON_BASE_AMOUNT: "Discount On Base Amount",
		INCLUDE_VAT: "Include Vat",
		TOTAL_STUDENT: "Total Student",
		GRADE_OR_SECTION: "Grade / Section",
		STAGE_GRADE_SETTINGS: "Stage Grade Settings",
		ACTIVATED_SUCCESSFULLY: "Activated Successfully",
		DE_ACTIVATED_SUCCESSFULLY: "DeActivated Successfully",
		ARE_YOU_SURE_ACTIVATE_THIS_RECORD: "Are you sure activate this record.",
		ARE_YOU_SURE_DE_ACTIVATE_THIS_RECORD: "Are you sure deactivate this record.",
		NUMBER_OF_PARENTS: "Number of Parents",
		NUMBER_OF_STUDENTS: "Number of Students",
		NUMBER_OF_TEACHERS: "Number of Teachers",
		NUMBER_OF_BRANCHES: "Number of Branches",
		NUMBER_OF_LESSONS_IN_THIS_YEAR: "Number of Lessons in this Year",
		NUMBER_OF_EXAMS_IN_THIS_YEAR: "Number of Exams in this Year",
		NUMBER_OF_HOMEWORKS_IN_THIS_YEAR: "Number of Homeworks in this Year",
		STUDENT_COUNT_AND_PERCENTAGE_BY_BRANCH: "Student Count and Percentage By Branch",
		STUDENT_COUNT_AND_BY_NATIONALITIES: "Student Count and Percentage By Nationalities",
		FROM_GRADE: "From Grade",
		TO_GRADE: "To Grade",
		UPGRADE_GRADE: "Upgrade Grade",
		DRAG_AND_DROP_ORDER_MESSAGE: "Drag and Drop records to re-order then click Save button.",
		SAVE_ORDER: "Save Re-Order",
		SELECT_ALL: "Select All",
		DUES_ALLOCATION: "Dues Allocation",
		DUES_ALLOCATION_LIST: "Dues Allocation List",
		MANAGE_DUES_ALLOCATION: "Manage Dues Allocation",
		FILTER_BY_SEMESTER: "Filter by Semester",
		FILTER_BY_LEARNING_TRACK: "Filter by Learning Track",
		FILTER_BY_ROW: "Filter by Row",
		ROW: "Row",
		FILTER_BY_CLASS: "Filter by Class",
		FILTER_BY_INCOME_TYPE: "Filter by Income Type",
		FEES_STATUS: "Fees Status",
		FILTER_BY_FEES_STATUS: "Filter by Fees Status",
		STUDENT_STATUS: "Student Status",
		FILTER_BY_STUDENT_STATUS: "Filter by Student Status",
		STUDENTS_STATUS: "Students Status",
		FILTER_BY_STUDENTS_STATUS: "Filter by Students Status",
		CHOOSE: "Choose",
		DUE_AMOUNT: "Due Amount",
		STAFF_STATUS: "Staff Status",
		SCHOOL_STUFF: "School Stuff",
		NON_SCHOOL_STUFF: "Non School Stuff",
		FILTER_BY_STUFF_STATUS: "Filter by Staff Status",
		DUE_STATUS: "Due Status",
		FILTER_BY_DUE_STATUS: "Filter by Due Status",
		ASSIGNED: "Assigned",
		UN_ASSIGNED: "Un Assigned",
		OPPOSITE_ACCOUNT: "Opposite Account",
		RELAY_COA_ACCOUNT: "Relay Coa Account",
		CANCEL_BROTHER_DISCOUNT: "Cancel Brother Discount",
		TAXED_DUES_DISCOUNT: "Taxed Dues Discount",
		CALCULATE_TOTAL_DUE: "Calculate Toral Due",
		CANCEL_SPECIAL_DISCOUNT: "Cancel Special Discount",
		FIRST_SEMESTER: "First Semster",
		SECOND_SEMESTER: "Second Semster",
		DUE_NAME: "Due Name",
		STUDENT_FEE_VAT: "Student Fee Vat",
		STUDENT_FEE_VALUE: "Student Fee Value",
		PAID_VAT: "Paid Vat",
		PAID_VALUE: "Paid value",
		REMAINING_VAT: "Remaining Vat",
		REMAINING_VALUE: "Remaining Value",
		CHECK: "Check",
		VALUE_CONTAIN_VAT: "Value Contain Vat",
		VAT: "Vat",
		NEW_STUDENT_STATUS: "New Student Status",
		EDIT_STUDENT_STATUS: "Edit Student Status",
		SUB_STUDENT_STATUS: "Sub Student Status",
		NEW_SUB_STUDENT_STATUS: "New Sub Student Status",
		EDIT_SUB_STUDENT_STATUS: "Edit Sub Student Status",
		MAIN_STUDENT_STATUS: "Main Student Status",
		CERTIFICATE_ISSUE_DATE: "Certificate issue date",
		NOT_INCLUDED_IN_TOTAL: "Not included in total mark",
		ENABLE_RE_ORDER: "Enable ReOrder",
		PLEASE_SELECT_STUDENT: "Please select student",
		STUDENT_IS_ASSIGNED_TO_ANOTHER_GROUP: "Student is assigned to another group",
		EXPORT_AS_EXCEL: "Export Excel",
		EXPORT_AS_PDF: "Export PDF",
		SEMESTER_INCOME_SETTINGS: "Semester Income Settings",
		GRADE_INCOME_SETTINGS: "Grade Income Settings",
		NEW_INCOME_SETTING: "New Income Setting",
		EDIT_INCOME_SETTING: "Edit Income Setting",
		BROTHER_DISCOUNT_ORDER: "Brother Discount Order",
		SIBLING_ORDER: "Sibling Order",
		SIBLING_ORDER_VALIDATION_MESSAGE: "Sibling Order Values must be unique.",
		ENABLE_SET_CUMULATIVE_AMOUNT: "Enable Set Cumulative Amount",
		UPDATE_AMOUNTS: "Update Amounts",
		ASSIGN_TO_SCHOOL: "Assign to school",
		ASSIGN_TO_GRADE: "Assign to grade",
		ASSIGN_TO_SECTION: "Assign to section",
		ASSIGN_TO_COURSE: "Assign to course",
		MULTI_ASSIGN: "Multi assign",
		ASSIGN_SPECIAL_DISCOUNT: "Assign Special Discount",
		SPECIAL_DISCOUNT_VALUE_VALIDATION_MESSAGE: "From Value must be smaller then To Value",
		SPECIAL_DISCOUNT_DATE_VALIDATION_MESSAGE: "From Date must be smaller then To Date",
		SPECIAL_DISCOUNT_DATE_FROM_SEMESTER_START_VALIDATION_MESSAGE: "From Date must be bigger than Semester Start Date (@date)",
		SPECIAL_DISCOUNT_DATE_FROM_SEMESTER_END_VALIDATION_MESSAGE: "From Date must be smaller than Semester End Date (@date)",
		SPECIAL_DISCOUNT_DATE_TO_SEMESTER_START_VALIDATION_MESSAGE: "To Date must be bigger than Semester Start Date (@date)",
		SPECIAL_DISCOUNT_DATE_TO_SEMESTER_END_VALIDATION_MESSAGE: "To Date must be smaller than Semester End Date (@date)",
		ANSWER: "Answer",
		CHOICES: "Choices",
		MANAGE_BUS_SETTING: "Manage Bus Setting",
		BUS_SETTING: "Bus Setting",
		BUS_SETTING_LIST: "Bus Setting List",
		EDIT_BUS_SETTING: "Edit Bus Setting",
		NEW_BUS_SETTING: "New Bus Setting",
		BUS_NUMBER: "Bus Number",
		DRIVER_NAME: "Driver Name",
		DRIVER_MOBILE: "Driver Mobile",
		SUPERVISOR_NAME: "Supervisor Name",
		SUPERVISOR_MOBILE: "Supervisor Mobile",
		BUS_CYCLE: "Bus Cycle",
		ACCOUNT_NO: "Account No",
		SIBLING_DISCOUNT_SAME_BRANCH_MESSAGE: "This branch already saved. Please select different branch",
		YOUR_EXAM_ATTEMPT_IS_OVER: "Your Exam Attempt is over",
		PHONE_NUMBER_VALIDATION_MESSAGE: "Phone Number have to consist of 9 digits and should not start with zero",
		IS_WAITING: "Is Waiting",
		SEMESTER_FEES_DETAIL_EMPTY_MESSAGE: "Semester Fees Details Not Found",
		SEMESTER_FEES: "Semester Fees",
		ANOTHER_TEACHER_IS_ASSIGNED: "This class has been assigned to another teacher",
		TEACHER_ASSIGNMENT: "Teacher assignments",
		ANOTHER_CLASS_HAS_BEEN_ASSIGNED_TO_THIS_SLOT: "Another class is added to this slot",
		PAYMENT_NOTES: "Payment Notes",
		NEW_PAYMENT_NOTE: "New Paymetn Note",
		EDIT_PAYMENT_NOTE: "Edit Paymetn Note",
		NO_ASSIGNMENTS_WERE_FOUND: "You are not assigned yet, please contact your adminstrator",
		TOTAL_AMOUNT: "Total Amount",
		SEMESTER_ENTITLEMENT: "Semester Entitlement",
		TEXT_OF_VALUE: "Text of Value",
		FULL_YEAR: "Full Year",
		PAYMENT_VALUE_VALIDATION_MESSAGE: "Payment Value can not be more then Remainin Value",
		PLEASE_SELECT_A_COURSE: "Please select a course",
		FILTER_BY_TEACHER: "Filter By Teacher",
		FILTER_BY_GRADE_AND_SECTION: "Filter By Grade and Section",
		MENU_NAME: "Menu Name",
		SELECT_GROUP_MESSAGE: "Please Select Group",
		SELECT_SHORT_CUT_MESSAGE: "Please Select Shortcut",
		LIST_HAS_BEEN_REFRESHED: "List has been refreshed.",
		THERE_IS_NO_ASSIGNED_RECORD: "There is not assigned record.",
		ASSIGN_TO_ALL_SCHOOLS: "Assign to all schools",
		ONLY_ENGLISH_AND_NUMBERS: "Only English and numbers",
		IMPORT_USER: "Import user",
		THIS_USER_CAN_NOT_BE_IMPORTED: "This user can't be imported",
		USER_NOT_FOUND: "User not found",
		CURRENT_BRANCH: "Current branch",
		IMPORT: "Import",
		STUDY_FEES_ONLY_ONE_RECORD_MESSAGE: "Study Fees can only be selected once for each semester of each school.",
		REPORT_ADDED_SUCCESSFULLY: "Report saved successfully",
		SCHOOL_YEAR: "School Year",
		PATH: "Path",
		FOR_INTERNATIONAL_SCHOOLS: "Add course to intrtnational schools",
		PLEASE_ENTER_FILE_NAME: "Please enter file name.",
		PLEASE_UPLOAD_FILE: "Please select any file.",
		ADD_NEW_QUIZ: "Add new quiz",
		QUIZ_SAVED_SUCCESSFULLY: "Quiz saved successfully",
		QUIZ_UPDATED_SUCCESSFULLY: "Quiz updated successfully",
		SENTENCE_IS_TRUE: "Answer is true?",
		MOTHER_NAME: "Mother Name",
		MOTHER_AR_NAME: "Mother Arabic Name",
		ID_NUMBER: "ID Number",
		MOTHERS_INFO_OF: "Mother's info of ",
		JOB: "Job",
		EMAIL_ADDRESS: "Email Address",
		MOBILE_NUMBER_1: "Mobile Number 1",
		MOBILE_NUMBER_2: "Mobile Number 2",
		PHONE_NUMBER_HINT: "Phone Number, Type like sample format, 00966512345678",
		EMAIL_HINT: "Email, Type like sample format, sample@hotmail.com",
		EXAM_SUBMITED: "Exam has been Submitted",
		HOMEWORK_IS_ALREADY_SUBMITTED: "Homework Already Submitted.",
		TIME_REAMAINING: "Time Remaining",
		ATTEMPT_REMAINING: "Attempt Remainig",
		SECOND_PAIR: "Second Pair",
		FIRST_PAIR: "First Pair",
		TIME_OVER_FOR_QUESTION: "Time is over for this question.",
		LEFT: "Left",
		SECONDS_TO_FINISH: "seconds to finish exam",
		SECONDS_TO_FINISH_QUESTION: "seconds to finish question",
		DISCOUNT_REASON: "Discount Reason",
		DISCOUNT_TYPE: "Discount Type",
		GROUP_STUDENTS: "Group students",
		"Create Question": "Create Question",
		CALCULATE_BROTHER_INSTRUCTURE: "Please select Semester and click Start button to start process.",
		START: "Start",
		FROM_SCHOOL: "From School",
		TO_SCHOOL: "To School",
		LESSON_PREPERATION_COPIED_SUCCESSFULLY: "Lesson prepration copied successfully",
		VIEW_VIEWRS: "View viewrs",
		VIEWED_BY: "Viewed by",
		STUDENTS_GROUPS: "Student groups",
		VIEW_NOTES: "View notes",
		ADD_NOTE: "Add notes",
		NOTE: "Notes",
		PLEASE_WRITE_YOU_NOTES: "Please enter your notes",
		NOTE_SENT_SUCCESSFULLY: "Note sent successfully",
		NO_NOTES: "No notes were found",
		MARK_SHOULD_BE_0_TO_100: "Mark should be 0 - 100",
		ENTER_USERNAME_OR_SELECT_SCHOOL: "Please enter username or select school",
		CALCULATE_BRO_DISCOUNT_CATCH_MESSAGE: "Brother Discount Calculation Not Succeed.",
		UPGRADE_GRADE_VALIDAITON_MESSAGE: "Please select To Grade and From School and To School on changed rows.",
		PARENT_PROMISSORY_UNDERTAKE: "I undertake to pay by this deed an order",
		BENEFICERY: "Beneficery",
		PARENT_PROMISSORY_AMOUNT_DESCRIPTION: "The amount show above",
		PARENT_PROMISSORY_RETURN_EXPENSES_DESCRIPTION: "The holder of this paper has the right to return without expenses or protest.",
		PARENT_PROMISSORY_NAME: "Promissory Name",
		OCCUPATION: "Occupation",
		SIGNATURE: "Signature",
		PARENT_PROMISSORY_FOOT_NOTE: "This bond is for an order payable without recourse without expenses and without protest (Proteto) This bond is payable without disruption or protest under Cabinet Resolution No. 692 dated 9/26/1383 culminating in Royal Decree No. 37 dated 10/11/1383 AH with the issuance of the Commercial Papers Regulations",
		VAT_NUMBER: "Vat Number",
		PARENT_PROMISSORY_DUE_DATE_FIXED: "In the event that a date is not recorded, the bond will be upon sight",
		PARENT_PROMISSORY_UPON_VIEWING: "Upon viewing",
		PARENT_PROMISSORY: "Parent Promissory",
		FILTER_BY_CREATOR: "Filer by creator",
		CITY: "City",
		PLEASE_SELECT_GRADE: "Please select grade",
		DISCOUNT_NAME: "Discount Name",
		DOCUMENT_TYPES: "Document Types",
		NEW_DOCUMENT_TYPE: "New Document Type",
		EDIT_DOCUMENT_TYPE: "Edit Document Type",
		Q: "Q",
		DRAG_AND_DROP_SECOND_COLUMN_ITEMS: "Drag and drop second column items to match the order of the first column",
		STARTS_ON: "Starts on",
		ENDS_ON: "Ends on",
		GRADE_ORDER: "Grade Order",
		BROTHER_DISCOUNT: "Brother Discount",
		BROTHER_NUMBER: "Brother Number",
		MY_STUDENTS: "My students",
		SEARCH_FOR_A_STUDENT: "Search for student",
		FAITHFUL_GROUP_STUDENTS: "My group's' students",
		MY_SECTIONS_STUDENTS: "My sections students",
		DOCUMENT_NUMBER: "Document Number",
		"New instructor": "New instructor",
		"New program": "New program",
		"Term 1": "Term 1",
		"Term 2": "Term 2",
		"Term 3": "Term 3",
		"New trainee": "New trainee",
		MESSAGE_PARENT: "Message parent",
		MESSAGE_STUDENT: "Message student",
		GROUP_MESSAGE_PARENT: "Group message to parents",
		GROUP_MESSAGE_STUDENT: "Group message to students",
		MESSAGE_SENT_SUCCESSFULLY: "Message sent successfully",
		PAYMENT_CONFIRMATION: "Payment Confirmation",
		TOTAL_PAYMENT: "Total Value",
		PAYMENT_TOTAL_VALUE_VALIDATION_MESSAGE: "Changed Values have to be equal to the defined Total Value",
		CHANGED_VALUE: "Changed Value",
		NEW_MEESAGES: "New messages",
		TERM_1: "Term 1",
		TERM_2: "Term 2",
		TERM_3: "Term 3",
		FIND_TRAINEE: "Find trainee",
		NEW_LECTURE: "New lecture",
		EFFECT: "Effect",
		NEW_TEMPLATE: "New template",
		PRINT_INVOICE: "Print Invoice",
		INVOICE: "Invoice",
		COMPANY: "Company",
		INVOICE_NUMBER: "Invoice Number",
		PRINT_DATE: "Print Date",
		DUE_DATE: "Due Date",
		PARENT_PHONE_NUMBER: "Parent Phone Number",
		INVOICE_DATE: "Invoice Date",
		TOTAL_FEES_VITHOUT_VAT: "Total Without Vat",
		TOTAL_DISCOUNT: "Total Discount",
		TOTAL_TAX: "Total Tax",
		TOTAL_CALCULATION: "Total Calculation",
		NEW_ASSESSMENT: "New assessment",
		CONTINUE: "Continue",
		YOUR_CERTIFICATE_IS_READY: "Your certificate is ready",
		PENDING: "Pending",
		SEND_MESSAGE_TO_TRAINEES: "Send a message to trainees",
		NO_TRAINERS_WERE_ASSIGNED_TO_THIS_PROGRAM: "No trainers where assigned to this program",
		SORTING_TYPE: "Sorting Type",
		ASCENDING: "Ascending",
		DESCENDING: "Descending",
		CLONE_DISCOUNTS: "Clone Discounts",
		CLONE: "Clone",
		SOURCE: "Source",
		DESTINATION: "Destination",
		SOURCE_AND_DESTINATION_SEMESTER_SAME_MESSAGE: "Source and Destination Semester can not be same",
		IS_CHECK: "Is Check",
		ANNOUNCEMENTS: "Announcements",
		ANNOUNCEMENTS_LATEST: "latest announcements",
		MOTHER_MANAGEMENT: "Mother Management",
		MOTHER_ID: "Mother ID",
		NUMBER_OF_CHILD: "Number of Child",
		ASSIGNED_STUDENTS: "Assigned Students",
		REJECT: "Reject",
		DUES_ALLOCATION_HINT_1: "Grades DropDown will list the grades that has Fees Value (Grade Income Setting Tab)",
		DUES_ALLOCATION_HINT_2: "Stage DropDown will list selected stages for this Semester Fee (Semester Income Setting)",
		STUDENT_HISTORY: "Student History",
		USER_NAME: "User Name",
		ITEM_NAME: "Item Name",
		UPGRADE_STUDENT: "Upgrade Student",
		FROM_SCHOOL_YEAR: "From School Year",
		TO_SCHOOL_YEAR: "To School Year",
		FROM_SCHOOL_YEAR_TO_SCHOOL_YEAR_VALIDATION: "From School Year should small then To School Year",
		ENTITLEMENTS_REPORT: "Entitlements Report",
		ENTITLEMENT: "Entitlement",
		DISCOUNT: "Discount",
		REMAINING_AMOUNT: "Remaining Amount",
		CLAIMS_REPORT: "Claims Report",
		CLAIM_ID: "Claim ID",
		FEES_NAME: "Fees Name",
		DETAILED_REPORT: "Detailed Report",
		FILE_UPLUAD_FAILED: "File uploading failed",
		OUTCOME_EN: "Outcome (English)",
		OUTCOME_AE: "Outcome (Arabic)",
		YOU_HAVE_NEW_LESSON_PREPARATION__NOTE: "You have new lesson preparation note",
		HAS_ADDED_NEW_NOTE_TO_YOUR_lESSON_PREPARATION: 'has added a new note on your lesson preparation',
		JOBS_LIST: "Jobs list",
		NEW_JOB: "New job",
		EDIT_JOB: "Edit job",
		NOOR_ALBYAN: "Noor Albyan",
		NEW_FILE: "New file",
		ORDER: "Order",
		FILE_LINK: "File link",
		FILE_IS_NOT_SUPPORTED: "File is not supported",
		PERSONAL_INFORMATION_FOR_STUDENT: "Personal Information for Student",
		PERSONAL_INFORMATION_FOR_PARENT: "Personal Information for Parent",
		CONTACT_INFORMATION: "Contact Information",
		STUDENT_FULL_NAME: "Student Full Name",
		PARENT_FULL_NAME: "Parent Full Name",
		PLACE_OF_BIRTH: "Place of Birth",
		WORK_PLACE: "Work Place",
		PARENT_MOBILE_NUMBER: "Parent Mobile Number",
		MOTHER_MOBILE_NUMBER: "Mother Mobile Number",
		ALTERNATIVE_MOBILE_NUMBER: "Alternative Mobile Number",
		PLEASE_ADD_ALL_REQUIRED_DETAILS_AND_MAKE_SURE_YOU_ENTER_NUMBERS_IN_ENGLISH: "Please add all the required details and make sure you enter the numbers in English",
		GRADE_AND_TRACK: "Grade - Track",
		STUDY_FEES: "Study Fees",
		TRANSPORTATION_FEES: "Transportation Fees",
		TOTAL_DUES: "Total Dues",
		TOTAL_PAID: "Total Paid",
		TOTAL_REMAIN: "Total Remain",
		MOBILE: "Mobile",
		GRAND_TOTAL: "Grand Total",
		DOWNLOAD: "Download",
		SHOW_PREVIOUS_BALANCE: "Show Previous Balance",
		STUDENT_STATEMENT: "Student Statement",
		FILE_UPLOAD: "File Upload",
		"Loading...": "Loading...",
		SAVE_AND_CLOSE: "Save & Close",
		FILES: "Files",
		ADD_FILES: "Add Files",
		UPLOAD_FILES: "Upload Files",
		BACK: "Go back",
		NO_FILES: "No files",
		MARK_OUT_OF_10: "Mark out of 10",
		ATTACHMENT: "Attachment",
		EXAM_CONTROL: "Exam control",
		ATTACHMENT_MANDATORY: "Attachment is Mandatory",
		THERE_IS_NO_ATTACHED_FILES: "There is No Attached Files",
		PLEASE_FILL_REQUIRED_FILTERS: "Please fill required filters",
		REGISTRATION_FORM: "Registration Form",
		ALIAS_EN: "Alias EN",
		ALIAS_AR: "Alias AR",
		MANDATORY_ALIAS_MESSAGE: "File Alias are Mandatory",
		SPECIAL_DISCOUNT_AMOUNT_VALIDATION: "Amount must between @fromvalue to @tovalue",
		PLEASE_SELECT_SCHOOL_GRADE_SECTION_COURSE: "Please select school, grade, section and course",
		UPDATED_BY: "Updated by",
		TEACHER_DID_NOT_SUBMMIT_YET: "Teacher did not submmit yet",
		PAYMENT_METHODS: "Payment Methods",
		MANAGE_BANKS_AND_PAYMENT_METHODS: "Manage Banks & Payment Methods",
		VIEW_AND_PRINT: "View and print",
		FILTER_BY_SCHOOL_YEAR: "Filter By School Year",
		FILTER_APPLIED_SUCCESSFULLY: 'Filter Applied Successfully.',
		FROM_DAYS: "From Days",
		TO_DAYS: "To Days",
		MANAGE_SETTLEMENT_SETTING: "Manage Settlement Setting",
		SETTLEMENT_LIST: "Settlement List",
		SETTLEMENT_SETTING: "Settlement Setting",
		EDIT_SETTLEMENT_SETTING: "Edit Settlement Setting",
		NEW_SETTLEMENT_SETTING: "New Settlement Setting",
		NEW_INVOICE: "New Invoice",
		PREVIEW_INVOICE: "Preview Invoice",
		VIEW_BILLS: "View Bills",
		CREATE_BILLS: "Create Bills",
		STUDENT_IS_BLOCKED_PLEASE_CONTACT_SCHOOL: "This student is blocked, please conact your school",
		THIS_PAGE_IS_FOR_INTERNATIONAL_SCHOOLS_ONLY: "This page is for international schools students only",
		PARENT_NATIONAL_ID: "Parent National ID",
		STUDENT_NATIONALITY: "Student Nationality",
		VAT_VALUE: "Vat Value",
		VAT_AMOUNT: "Vat Amount",
		CREATE_BILLS_CONFIRMATION_MESSAGE: "Are you confirm to create bill for @student",
		AUTO_COMPLETE_PLACE_HOLDER_LABEL: "Type at least 4 character to find record.",
		REMAINING_VALUE_AND_VAT: "Remaining Value & Vat",
		CASHIER_MANAGEMENT: "Cashier Management",
		ASSIGNED_STUDENT_FEES: "Assigned Student Fees",
		SELECT_STUDENT_FEES: "Select Student Fees",
		PLEASE_SELECT_ITEMS: "Please Select Items",
		STUDENT_DUES: "Student Dues",
		BUSES_SEMESTER_FEES_HINT: "Depend on the Semester selection.",
		QUIZ_CREATOR: "Quiz creator",
		NEW_GRADE_ORDER_VALIDATION: "Grades re-ordered, please save latest changes and try again.",
		DUE_ASSIGN_GRADE_NOT_MATCHING_VALIDATION_MESSAGE: "Cannot assign because of not matching Grade",
		PAID_AMOUNT: "Paid Amount",
		SERIAL_NO: "Serial No",
		REFERENCE_NO: "Reference No",
		PARENT_NAME: "Parent Name",
		PAYMENT_DATE: "Payment Date",
		CASHIER_REPORTS: "Cashier Reports",
		STUDENT_SUB_STATUS: "Student Sub Status",
		WITH_DRAWAL_DATE: "With Drawel Date",
		WITH_DRAWAL_REASON: "With Drawel Reason",
		TRANSFERRED_SCHOOLS: "Transferred Schools",
		PRIVATE: "Private",
		GOVERNMENTAL: "Governmental",
		INTRTNATIONAL: "International",
		OUTSIDE_KSA: "Outside KSA",
		CALCULATE: "Calculate",
		REPORT_TYPE: "Report Type",
		SELECT_REPORT_TYPE: "Select Report Type",
		ELECTRONIC_WITHDRAWAL: "Electronic Withdrawal",
		FinancialReport: "Financial Report",
		PLEASE_SELECT_REPORT_TYPE: "Please Select Report Type",
		STUDENT_FINANCIAL_REPORT: "Student Financial Report",
		USER: "User",
		BRANCH_FINANCIAL_REPORT: "Branch Financial Report",
		STUDENT_SEMESTER_FINANCIAL_REPORT: "Student Semester Financial Report",
		START_PROCESS: "Start Process",
		IN_PROGESS_DONT_CLOSE_MESSAGE: "Process started. Please Do Not Turn Off the Screen.",
		FATHER_FINANCIAL_REPORT: "Father's Financial Report",
		HISTORICAL_FINANCIAL_REPORT: "Historical Financial Report",
		DETAILED_BENEFIT_REPORT: "Detailed Benefit Report",
		DUES_TYPE: "Dues Type",
		DUES: "Dues",
		REMAIN_GREATER_THAN: "Remain Greater Than",
		REMAIN_LESS_THAN: "Remain Less Than",
		DATE_FROM: "Date From",
		DATE_TO: "Date To",
		ENTITLEMENT_AMOUNT: "Entitlement Amoun",
		studentName: "Student",
		idNumber: "ID Number",
		displayGradeName: "Grade",
		mobile: "Mobile",
		studyFeesAmount: "Study Fees",
		totalDiscountAmount: "Total Discount",
		totalPaidAmount: "Total Paid",
		totalReminingAmount: "Total Remaining",
		prevAmount: "Prev Amount",
		transportationFeesAmount: "Transportation Fees",
		Index: "Index",
		StudentFinancialReport: "Student Financial Report",
		BranchFinancialReport: "Branch Financial Report",
		StudentSemesterFinancialReport: "Student Semester Financial Report",
		FatherFinancialReport: "Father Financial Report",
		HistoricalFinancialReport: "Historical Financial Report",
		DetailedBenefitReport: "Detailed Benefit Report",
		PreviousBalanceReport: "Previous Balance Report",
		CashierFinancialReportPrintMessage: "Print process started for @RecordCount records. Please Wait...",
		All: "None",
		SETTLEMENT_DATE: "Settlement Date",
		SETTLEMENT_AMOUNT: "Settlement Amount",
		TOTAL_ENTITLEMENT: "Total Entitlement",
		DiscountReport: "Discount Report",
		BrotherDiscountReport: "Brother Discount Report",
		DiscountReportByFather: "Discount Report By Father",
		PrivateDiscountReport: "Private Discount Report",
		PARENT_WORK_PLACE: "Parent Work Place",
		ALL: "All",
		RECORD_SAVING: "Record Saving ...",
		SAVE_DESCRIPTION: "Are you sure to save this record?",
		FATHER: "Father",
		FinancialReconciliationReport: "Financial Reconciliation Report",
		DiscountsAndPreviousBalanceAttachments: "Discounts and Previous Balance Attachments",
		FinancialReconcileDetails: "Financial Reconcile Details",
		BasicDataReport: "Basic Data Report",
		PAYMENT_STATEMENT: "Payment Statement",
		SCHOOL_GENDER: "School Gender",
		MovementDetailedDailyRegistration: "Movement Detailed Daily Registration",
		TotalDailyRegistration: "Total Daily Registration",
		CountOfTransferredAndNewStudents: "Count Of Transferred And New Students",
		JournalRecordsReport: "Journal Records Report",
		StudentsListByClassReport: "Students List By Class Report",
		ReligionAndSecondLanguageByRowReport: "Religion And Second Language By Row Report",
		SchoolCountByClassesReport: "School Count By Classes Report",
		SchoolCountByLevelsReport: "School Count By Levels Report",
		TotalStudentCountBySemester: "Total Student Count By Semester",
		TotalCountOfClassesAndStudentsReport: "Total Count Of Classes And Students Report",
		CountOfStudentsNationalitiesReport: "Count Of Students Nationalities Report",
		StudentsCountInDateReport: "Students Count In Date Report",
		AttachmentsReport: "Attachments Report",
		CASHIER_REPORT_NOT_DEFINED_REPORT: "Not Defined Report",
		taxAmount: "Tax",
		totalEntitelmentAmount: "Total Entitlement",
		displayBranchName: "Branch",
		FinancialReportFee: "Financial Report Fee",
		TotalFeesBySemester: "Total Fees By Semester",
		TotalLevelReport: "Total Level Report",
		PromissoryNoteArchive: "Promissory Note Archive",
		DUE_DURATION_FROM: "Due Duration From",
		DUE_DURATION_TO: "Due Duration To",
		FatherInstallmentReport: "Father Installment Report",
		SchoolReportCompiled: "School Report Compiled",
		DailyCashReport: "Daily Cash Report",
		PreviousBalanceSchoolReport: "Previous Balance Report",
		GeneralDues: "General Dues",
		displayStageName: "Stage",
		subStudentStatusDisplayName: "Sub Student Status",
		fatherName: "Father Name",
		parentChildrenCount: "Child Count"
	}
};
