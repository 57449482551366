// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';

import { EntityService } from '../../../../_services/entity.service';
import { AclByEntitiesModel } from '../../../../_models/acl-by-entities.model';
import { TranslateService } from '@ngx-translate/core';
import { enums } from '../../../../../../src/enums/enums';

@Injectable()
export class MenuConfigService {
	// Public properties
	onConfigUpdated$: Subject<any>;
	// Private properties
	private menuConfig: any;

	/**
	 * Service Constructor
	 */
	constructor(
		private translate: TranslateService,
		private entityService: EntityService) {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config?: any) {
		// this.menuConfig = config;
		// this.onConfigUpdated$.next(this.menuConfig);

		this.loadMenuConfigDynamically();
	}

	loadMenuConfigDynamically() {
		//let userId: number = this.authService.loggedUser.userID;
		let localStorageUser = localStorage.getItem(enums.LocalStorageKeys.LoggedInUser);

		if (localStorageUser != undefined) {
			let localStorageUserMode = JSON.parse(localStorageUser);
			let userId: number = localStorageUserMode.userID;
			let groupId: number = localStorageUserMode.groupId;

			this.entityService.getEntitiesByUserId(userId, groupId).subscribe(response => {
				let responseArray: Array<AclByEntitiesModel> = response;
				let asideMenuItems: Array<AsideMenuModel> = new Array<AsideMenuModel>();
				let tempMenuItem = new AsideMenuModel();

				var SingleMainMenuItems = responseArray.filter((item) => {
					return item.isGroup == false && item.parentId == 0;
				});

				var GroupMainMenuItems = responseArray.filter((item) => {
					return item.isGroup == true && item.parentId == 0;
				});

				var subMainMenuItems = responseArray.filter((item) => {
					return item.isGroup == true && item.parentId > 0;
				});

				var subMenuItems = responseArray.filter((item) => {
					return item.isGroup == false;
				});

				GroupMainMenuItems.map((menuGroup) => {

					tempMenuItem = new AsideMenuModel();
					tempMenuItem.title = this.translate.currentLang == 'ar' ? menuGroup.entryNameAr : menuGroup.entityName;
					tempMenuItem.groupName = menuGroup.groupBy;
					tempMenuItem.icon = menuGroup.groupImagePath;
					tempMenuItem.order = menuGroup.groupOrder;
					tempMenuItem.root = true;
					tempMenuItem.bullet = 'line';
					tempMenuItem.submenu = new Array<AsideSubMenuModel>();

					if (subMainMenuItems.length > 0) {
						let subMainMenuItemsByCurrentParentId = subMainMenuItems.filter((item) => {
							return item["parentId"] == menuGroup.entryId;
						});
						var tempSubMainMenuItem;
						subMainMenuItemsByCurrentParentId.map((subMenuItem) => {
							tempSubMainMenuItem = new AsideSubGroupMenuModel();
							tempSubMainMenuItem.title = this.translate.currentLang == 'ar' ? subMenuItem.entryNameAr : subMenuItem.entityName;
							tempSubMainMenuItem.bullet = 'line';
							tempSubMainMenuItem.submenu = new Array<AsideSubMenuModel>();

							var tempSubMenu = subMenuItems.filter((item) => {
								return item["parentId"] == subMenuItem.entryId && item["isGroup"] == false;
							});
							tempSubMenu.forEach(element => {
								let tempSubMenu: AsideSubMenuModel = new AsideSubMenuModel();
								tempSubMenu.title = this.translate.currentLang == 'ar' ? element.entryNameAr : element.entityName;
								tempSubMenu.page = element.menuURL;
								tempSubMainMenuItem.submenu.push(tempSubMenu);
							});

							if (tempSubMainMenuItem) {
								tempMenuItem.submenu.push(tempSubMainMenuItem);
							}
						});
					}

					var currentSubMenuItems = subMenuItems.filter((item) => {
						return item.parentId == menuGroup.entryId && item.isGroup == false;
					});

					currentSubMenuItems.forEach(element => {
						let tempSubMenu: AsideSubMenuModel = new AsideSubMenuModel();
						tempSubMenu.title = this.translate.currentLang == 'ar' ? element.entryNameAr : element.entityName;
						tempSubMenu.page = element.menuURL;
						tempMenuItem.submenu.push(tempSubMenu);
					});
					asideMenuItems.push(tempMenuItem);
				});

				SingleMainMenuItems.forEach(menuItem => {
					tempMenuItem = new AsideMenuModel();
					tempMenuItem.page = menuItem.menuURL;
					tempMenuItem.title = this.translate.currentLang == 'ar' ? menuItem.entryNameAr : menuItem.entityName;
					tempMenuItem.groupName = menuItem.groupBy;
					tempMenuItem.icon = menuItem.groupImagePath;
					tempMenuItem.order = menuItem.groupOrder;
					tempMenuItem.root = true;
					tempMenuItem.bullet = 'line';
					tempMenuItem.submenu = null;

					asideMenuItems.push(tempMenuItem);
				});

				this.menuConfig = asideMenuItems.sort((a, b) => a.order - b.order);
				this.onConfigUpdated$.next(this.menuConfig);
			});
		}
	}
}

export class AsideMenuModel {
	title: string;
	root: boolean;
	icon: string;
	bullet: string;
	page?: string;
	groupName?: string;
	order: number;
	submenu?: any;//Array<AsideSubMenuModel>
}

export class AsideSubGroupMenuModel {
	title: string;
	bullet: string;
	submenu?: [];
}

export class AsideSubMenuModel {
	title: string;
	page: string;
}