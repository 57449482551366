import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { SessionsModel } from "../_models/session.model";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";
import { PageEvent } from "@angular/material";
import { PaginationResponseDetails, PaginationResponseModel } from "../_models/pagination-response.model";

@Injectable()
export class SessionsService {
	constructor(private http: HttpClient, private router: Router) {}

	private entityName: string = "Sessions";

	getSessions(page?: PageEvent): Observable<PaginationResponseModel> {
		let requestUrl = environment.apiUrl + this.entityName + "/GetSession";

		//Add pagination params if exist.
		if (page && page.pageIndex != null && page.pageSize != null) {
			requestUrl += "?page=" + page.pageIndex;
			requestUrl += "&pageSize=" + page.pageSize;
		}

		return this.http.get<PaginationResponseModel>(requestUrl).pipe(
			map((response: PaginationResponseModel) => {
				if (response.pagingDetails == undefined) {
					let tempPagination: PaginationResponseModel = new PaginationResponseModel();
					tempPagination.isSinglResult = true;
					tempPagination.results = response;

					let tempPaginationDetail: PaginationResponseDetails = new PaginationResponseDetails();
					tempPaginationDetail.totalRecordsCount = tempPagination.results.length;

					tempPagination.pagingDetails = tempPaginationDetail;

					return tempPagination;
				}

				return response;
			})
		);
	}

	getSession(id: number): Observable<SessionsModel> {
		const requestUrl = environment.apiUrl + this.entityName + "/GetSession/" + id;
		return this.http.get<SessionsModel>(requestUrl);
	}
	getVersion(): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/version";


		return this.http.get<any>(requestUrl);
	}

	deleteSession(id: number): Observable<any> {
		const requestUrl = environment.apiUrl + this.entityName + "/DeleteSession?id=" + id;
		return this.http.get<any>(requestUrl);
	}
}
