// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { AuthNoticeService } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { enums } from '../../../../../enums/enums';
import { PreUserModel } from '../../../../_models/pre-user.model';
import { PreUserService } from '../../../../_services/pre-user.service';
import { MatDialog } from "@angular/material/dialog";
import { NewPreUserDialogComponent } from '../new-pre-user/new-pre-user.dialog.component';
import { MatTableDataSource } from "@angular/material/table";
import { LayoutUtilsService, MessageType } from "../../../../core/_base/crud";
import { UserService } from '../../../../../app/_services/user.service';
import { UserFilterModel } from '../../../../../app/_models/user-filter.model';
import { UserModel } from '../../../../../app/_models/user.model';

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	enums = enums;
	registerForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	userForm: FormGroup;
	hasFormErrors = false;

	genders: any[] = [
		{ Id: enums.gender.keys.male, Name: this.translate.instant(enums.gender.values.male) },
		{ Id: enums.gender.keys.female, Name: this.translate.instant(enums.gender.values.female) },
	];

	selectedRegistrationType: number = 0;
	formTitle: string;

	assignedStudents: Array<PreUserModel> = new Array<PreUserModel>();
	assignedUsersDataSource = new MatTableDataSource();
	assignedUsersDisplayedColumns = ["username", "fullName", "actions"];
	studentAlertMessage: string;

	constructor(
		private preUserService: PreUserService,
		private userService: UserService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService,
		private router: Router,
		public dialog: MatDialog,
		private cdr: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * On init
	 */
	ngOnInit() {
		this.createForm();
		this.updateFormTitle();
		this.updateStudentAlertMessage();
	}

	/*
	* On destroy
	*/
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	assignStudent() {
		const dialogRef = this.dialog.open(NewPreUserDialogComponent, {
			width: "800px"
		});

		dialogRef.afterClosed().subscribe((response) => {
			if (response == "close") {
				return;
			}

			let responseUser: PreUserModel = response;

			this.assignedStudents.push(responseUser);
			this.assignedUsersDataSource.data = this.assignedStudents;

			this.updateStudentAlertMessage();
			this.cdr.detectChanges();
		});
	}

	unAssignUser(selectedUser: PreUserModel) {
		this.assignedStudents = this.assignedStudents.filter(x => x.preUserId != selectedUser.preUserId);
		this.assignedUsersDataSource.data = this.assignedStudents;
		this.layoutUtilsService.showActionNotification(this.translate.instant("COMMON.DELETE_SUCCESS_MESSAGE"), MessageType.Create, undefined, undefined, false);
	}

	// Create form
	createForm() {
		this.userForm = new FormGroup({
			username: new FormControl("", Validators.required),
			password: new FormControl(
				"",
				Validators.compose([
					Validators.required,
					Validators.pattern(
						/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[$@$!%*?&.#]).{8,30}$/
					),
				])
			),
			passwordConfirm: new FormControl(
				"",
				Validators.compose([
					Validators.required,
					Validators.pattern(
						/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=.*[$@$!%*?&.#]).{8,30}$/
					),
				])
			),
			gender: new FormControl("", Validators.required),
			fullName: new FormControl("", Validators.required),
			country: new FormControl(),
			emailAddress: new FormControl("", [
				Validators.email,
				Validators.required,
			]),
			mobileNumber: new FormControl(),
			nationality: new FormControl(),
			city: new FormControl(),
			adress: new FormControl(),
			dob: new FormControl(),
			parentId: new FormControl(),
			branchId: new FormControl(""),
			subjectID: new FormControl("")
		});
	}

	async submit() {
		const controls = this.userForm.controls;

		/** check form */
		if (this.userForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);

			this.authNoticeService.setNotice(this.translate.instant('COMMON.PLEASE_FILL_MANDATORY_FIELDS'), 'danger');
			return;
		}

		if (controls.password.value != controls.passwordConfirm.value) {
			this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.PASSWORD_NOT_MATCHING'), 'danger');
			return;
		}

		let editedUser: PreUserModel = this.prepareUser();

		let alreadyExistUser: boolean = false;

		//Check User already exist or not in User Table.
		await this.userService.getUserByUsername(editedUser.username).toPromise().then(response => {
			if (response != null) {
				alreadyExistUser = true;
			}
		});

		//Check User already exist or not in User Table.
		await this.preUserService.getPreUserByUsername(editedUser.username).toPromise().then(response => {
			if (response != null) {
				alreadyExistUser = true;
			}
		});

		if (alreadyExistUser) {
			this.layoutUtilsService.showActionNotification(editedUser.username + " " +this.translate.instant("COMMON.ALREADY_EXIST_RECORD"), MessageType.Read, undefined, undefined, false);
			return;
		}

		let postPreUserResponse: PreUserModel = new PreUserModel();
		//Post only Student or Parent
		await this.preUserService.postPreUser(editedUser).toPromise().then((response) => {
			postPreUserResponse = response;
		});

		//If Parent and exist assigned student
		if (this.selectedRegistrationType == enums.userGroup.parentOfStudent && this.assignedStudents.length > 0) {
			return this.saveStudents(postPreUserResponse.preUserId);
		}

		this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.PRE_USER_REGISTERED_SUCCESSFULLY'), 'success');
		this.router.navigateByUrl('/auth/login');
	}

	async saveStudents(parentId: number) {

		//Update Parent of students
		this.assignedStudents.forEach(student => {
			student.parentId = parentId;
		});

		this.preUserService.postMultiplePreUsers(this.assignedStudents).toPromise().then((response) => {

			this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.PRE_USER_REGISTERED_SUCCESSFULLY'), 'success');
			this.router.navigateByUrl('/auth/login');

		});
	}

	//Returns prepared data for save
	prepareUser(): PreUserModel {
		const controls = this.userForm.controls;
		const _user = new PreUserModel();

		_user.clear();
		//Mandatory Field
		_user.username = controls.username.value;
		_user.userCode = controls.username.value;
		_user.password = controls.password.value;
		_user.gender = controls.gender.value;
		_user.groupId = this.selectedRegistrationType;
		_user.parentId = controls.parentId.value != null ? controls.parentId.value : 0;
		_user.fullName = controls.fullName.value;
		_user.country = controls.country.value != null ? controls.country.value : 0;
		_user.emailAddress = controls.emailAddress.value;
		_user.mobileNumber = controls.mobileNumber.value;
		_user.city = controls.city.value != null ? controls.city.value : 0;
		_user.adress = controls.adress.value;

		_user.isActive = false;
		_user.isDeleted = false;
		_user.createdDate = new Date().toJSON();
		_user.dob = new Date(controls.dob.value).toJSON();
		_user.nationality = 0;
		_user.createdBy = 0;
		_user.companyId = 0;
		_user.branchId = 0;

		return _user;
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.userForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	selectRegistrationType(selectType: number) {
		//Update Selected Registration Type
		this.selectedRegistrationType = selectType;

		this.updateFormTitle();
	}

	updateFormTitle() {
		if (this.selectedRegistrationType == 0)
			this.formTitle = this.translate.instant("AUTH.REGISTER.TITLE");
		else if (this.selectedRegistrationType == enums.userGroup.student)
			this.formTitle = this.translate.instant("AUTH.REGISTER.SIGN_UP_FOR_STUDENT");
		else if (this.selectedRegistrationType == enums.userGroup.parentOfStudent)
			this.formTitle = this.translate.instant("AUTH.REGISTER.SIGN_UP_FOR_PARENT");
	}

	updateStudentAlertMessage() {
		if (this.assignedUsersDataSource.data.length > 0) {
			this.studentAlertMessage = this.translate.instant("BREAD_CRUMB.TEACHER_PROJECT_MANAGER.ASSIGNED_STUDENTS");
		}
		else {
			this.studentAlertMessage = this.translate.instant("AUTH.REGISTER.THERE_IS_NO_ASSIGNED_STUDENT");
		}
	}
}
