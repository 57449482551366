export class PreUserModel {
    preUserId: number;
    username: string;
    password: string;
    gender: number;
    fullName: string;
    userCode: string;

    groupId: number;
    groupName: string;
    groupNameAr: string;
    displayGroupName: string;

    branchId: number;
    branchName: string;
    branchNameAr: string;
    displayBranchName: string;

    brachSchoolName: string;
    brachSchoolNameAr: string;
    displayBrachSchoolName: string;

    country: number;
    companyId: number;

    mobileNumber: string;
    emailAddress: string;
    nationality: number;
    city: number;
    parentId: number;
    locationID: number;
    dob: string;
    adress: string;
    profileImagePath: string;
    createdBy: number;
    createdDate: string;
    isActive: boolean;
    isDeleted: boolean;

    gradeId: number;

    gradeSectionId: number;
    gradeSectionName: string;
    gradeSectionNameAr: string;
    displayGradeSection: string;

    subjectID: number;
    courseName: string;
    initCourseId:number;
    clear(): void {
        this.preUserId = 0;
        this.username = '';
        this.password = '';
        this.userCode = '';
        this.gender = 0;
        this.groupId = 0;
        this.country = 0;
        this.companyId = 0;
        this.branchId = 0;
        this.fullName = '';
        this.mobileNumber = '';
        this.emailAddress = 'user@example.com';
        this.nationality = 0;
        this.city = 0;
        this.locationID = 0;
        this.dob = '';
        this.adress = '';
        this.profileImagePath = '';
        this.createdBy = 0;
        this.createdDate = '';
        this.isActive = true;
        this.isDeleted = false;
        this.subjectID = 0;
        this.initCourseId = 0;
    }
}

