import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of, observable, Subscription, interval, Subject } from "rxjs";
import { UserModel } from "../_models/user.model";
import { environment } from "../../environments/environment";
import { LayoutConfigModel, LayoutConfigService } from "../core/_base/layout";
import { TranslateService } from "@ngx-translate/core";
import { enums } from "../../enums/enums";
import moment from "moment";
import { LayoutUtilsService, MessageType } from "../core/_base/crud";
import { SchoolYearModel } from "../_models/school-year.model";
import { SchoolYearService } from "./school-year.service";

@Injectable()
export class AuthService {
	private manualRefreshTokenSubscription: Subscription;

	constructor(
		private schoolYearService: SchoolYearService,
		private http: HttpClient,
		private translate: TranslateService,
		private layoutConfigService: LayoutConfigService,
		private layoutService: LayoutUtilsService
	) {
		//Set Up Subscription and call the function every 5 second
		//This subscription followinf manual refresh token.
		this.manualRefreshTokenSubscription = interval(5000).subscribe((val) => {
			this.updateTokenManually();
		});
	}

	//Hold logged User Information to use everywhere.
	loggedUser: UserModel;

	//Hold Current School Year
	currentSchoolYear: SchoolYearModel;

	//login status. According to login status redirect login page or authorized page.
	loggedIn = false;

	showTawk = new EventEmitter<boolean>()

	async isAuthenticated() {

		let accessTokenQueryString: string = this.getParamValueQueryString(enums.LocalStorageKeys.AccessToken);
		let userIdQueryString: string = this.getParamValueQueryString(enums.LocalStorageKeys.LoggeddUserId);

		if (accessTokenQueryString != undefined && userIdQueryString != undefined) {
			let accessTokenQueryStringDecrypted: string = atob(accessTokenQueryString);
			let userIdQueryStringDecrypted: string = atob(userIdQueryString);

			//Clear Local Storage and Set New Values
			localStorage.setItem(enums.LocalStorageKeys.AccessToken, accessTokenQueryStringDecrypted);
			localStorage.setItem(enums.LocalStorageKeys.LoggeddUserId, userIdQueryStringDecrypted);

			//Remove Query String Parameters and Refresh Page
			//window.location.href = window.location.href.split("?")[0];
		}

		//get user token from the local storage if there is
		const accessToken = localStorage.getItem(enums.LocalStorageKeys.AccessToken);
		const loggeddUserId = localStorage.getItem(enums.LocalStorageKeys.LoggeddUserId);

		if (accessToken == null || accessToken == undefined || accessToken == "" || loggeddUserId == undefined)
			this.loggedIn = false;
		else {
			// await this.tokenExpirationTimer();
			let validToken: boolean = false;

			await this.refreshToken().then(response => { validToken = response; });

			if (validToken) {
				await this.fethLoggedInUser(Number(loggeddUserId));
				await this.getCurrentSchoolYear();
			}
			else {
				this.loggedIn = false;
			}
		}

		return this.loggedIn;
	}

	async getCurrentSchoolYear() {
		await this.schoolYearService.getCurrentSchoolYear().toPromise().then(response => {
			this.currentSchoolYear = response;
		});
	}

	async fethLoggedInUser(loggeddUserId: number) {
		const requestUrl = environment.apiUrl + "Users/GetUserById?id=" + loggeddUserId + "&groupId=" + Number(localStorage.getItem(enums.LocalStorageKeys.DefualtGroupId));
		await this.http.get<UserModel>(requestUrl).toPromise().then((response) => {
			this.loggedUser = response;
			let userInfoForLocalStorage: UserModel = new UserModel();
			userInfoForLocalStorage.userID = response.userID;
			userInfoForLocalStorage.gender = response.gender;
			userInfoForLocalStorage.groupId = response.groupId;
			userInfoForLocalStorage.companyId = response.companyId;
			userInfoForLocalStorage.branchId = response.branchId;
			userInfoForLocalStorage.fullName = response.fullName;

			userInfoForLocalStorage.displayBranchName = this.translate.currentLang == enums.langs.ar ? response.branchNameAr : response.branchName;
			userInfoForLocalStorage.displayGroupName = this.translate.currentLang == enums.langs.ar ? response.groupNameAr : response.groupName;
			userInfoForLocalStorage.displayGradeSection = this.translate.currentLang == enums.langs.ar ? response.gradeSectionNameAr : response.gradeSectionName;

			//Prepare Assigned Groups
			response.assignedGroups = [];
			response.groupIds.forEach(groupId => {
				let foundGroup = response.allGroups.find(x => x.groupId == groupId);
				foundGroup.displayName = this.translate.currentLang == enums.langs.ar ? foundGroup.groupNameAr : foundGroup.groupName;

				response.assignedGroups.push(foundGroup);
			});

			if (this.loggedUser.group.assignToSchool && (this.loggedUser.schoolId == null || this.loggedUser.schoolId == 0)) {

				this.loggedUser = null;
				this.loggedIn = false;
				this.layoutService.showActionNotification(this.translate.instant("NO_ASSIGNMENTS_WERE_FOUND"), MessageType.Read, 4000, false, false);
				return;
			}

			if (response.isActive) {
				localStorage.setItem(enums.LocalStorageKeys.LoggedInUser, JSON.stringify(userInfoForLocalStorage));
				this.loggedIn = true;

				if(!response.groupIds.includes(enums.userGroup.student) &&
				 !response.groupIds.includes(enums.userGroup.parentOfStudent)) this.showTawk.emit(true);
				else this.showTawk.emit(false)
	
			} else
				this.loggedIn = false;
		}).catch((error) => {
			this.loggedIn = false;
		});
	}

	loginUser(username: string, password: string): Observable<any> {
		const requestUrl = environment.apiUrl + "Users/Login/";
		let body = { username: username, password: password };

		return this.http.post<any>(requestUrl, body);
	}

	LoginByAdmin(username: string): Observable<any> {
		const requestUrl = environment.apiUrl + "Users/LoginByAdmin/";
		let body = { username: username };

		return this.http.post<any>(requestUrl, body);
	}

	getParamValueQueryString(paramName) {
		const url = window.location.href;
		let paramValue;

		if (url.includes("?")) {
			const httpParams = new HttpParams({
				fromString: url.split("?")[1],
			});
			paramValue = httpParams.get(paramName);
		}

		return paramValue;
	}

	async refreshToken() {
		let result: boolean = false;

		const requestUrl = environment.apiUrl + "Users/RefreshToken";

		await this.http.post<any>(requestUrl, {}).toPromise().then(response => {
			if (response && response.token) {
				if (response.isActive) {
					localStorage.setItem(enums.LocalStorageKeys.AccessToken, response.token);
					localStorage.setItem(enums.LocalStorageKeys.LoggeddUserId, response["userId"]);

					const tokenExpiresAt = moment(Date.parse(response["createdDate"])).add(enums.auth.tokenAge, 'hours').toLocaleString();
					localStorage.setItem(enums.LocalStorageKeys.TokenExpiresAt, tokenExpiresAt);

					result = true;
				}
			}
		}).catch((error) => {

		});

		return result;
	}

	async updateTokenManually() {
		let tokenExpiresAt = localStorage.getItem(enums.LocalStorageKeys.TokenExpiresAt);

		//If Already Logged In &
		//If Has acceptable token 
		if (this.loggedIn && tokenExpiresAt != undefined) {

			//Get Token Expires In Second
			const tokenExpiresAtInMS = Date.parse(tokenExpiresAt);

			//Get Current Time
			const nowTimeInMs = Date.now();

			//Calculate difference as second
			const diffInSecond = (tokenExpiresAtInMS - nowTimeInMs) / 1000;

			//If Differences closer then 60 second, refresh the token.
			if ((diffInSecond - 60) < 60) {
				await this.refreshToken();
			}
		}
	}
}
