import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { toInteger } from "lodash";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
import { AuthService } from "./auth.service";

export class TableColumn {
  constructor(
    public key: string,
    public title: string,
    public display = true
  ) { }
}

@Injectable()
export class CommonService  {


  constructor( private router: Router, http: HttpClient, authService: AuthService) {

  }



  private exportAsExcelFile(json: any[], excelFileName: string, columns: TableColumn[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.mergeDataWithColumns(json, columns)
    );

    const workbook: XLSX.WorkBook = {
      Sheets: {
        data: worksheet,
      },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName +
      "_export_" +
      new Date().getHours() +
      ":" +
      new Date().getMinutes() +
      ":" +
      new Date().getSeconds() +
      EXCEL_EXTENSION
    );
  }

  private mergeDataWithColumns(
    data: object[],
    columns: TableColumn[]
  ): any[] {
    const table = [];
    let tempRow: object;

    if (columns) {
      data.forEach((row) => {
        tempRow = {};
        columns.forEach(({ display, key, title }) => {
          if (display) {
            tempRow[title] = row[key];
          }
        });
        table.push(tempRow);
      });
      return table;
    }
    return data;
  }

  ExporteAsExcel(dataSource: any, fileName: string, columns: TableColumn[]) {
    this.exportAsExcelFile(dataSource, fileName, columns);
  }

  dropdownListFilter(value: string, data: any, columnName: string, columnName2: string) {
    var items = [];

    data.forEach((element) => {
      if (element[columnName2]) {
        if (element[columnName].toLowerCase().includes(value.toLowerCase()) || element[columnName2].toLowerCase().includes(value.toLowerCase()))
          items.push(element);
      } else {
        if (element[columnName] != null && element[columnName].toLowerCase().includes(value.toLowerCase()))
          items.push(element);
      }
    });

    return items;
  }

  croppingText(text: string, count: number) {
    let result: string = text;

    if (text.length >= count) {
      result = text.substr(0, count) + '...';
    }

    return result;
  }

  getDaysBetweenTwoDates(startDate: Date, endDate: Date): number {
    let differenceDays: number = 0;

    let differenceInTime: number = Number(endDate.getTime() - startDate.getTime());
    differenceDays = toInteger(differenceInTime / (1000 * 3600 * 24));

    return differenceDays;
  }

  getMinutesBetweenTwoDates(startDate: Date, endDate: Date): number {
    let responseDiffMinutes: number = 0;
    let diffDays: number = this.getDaysBetweenTwoDates(startDate, endDate);

    let diffMinutes = toInteger(Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60) % 60);

    responseDiffMinutes = Number(diffDays * 1440 + diffMinutes);

    return responseDiffMinutes;
  }
}
