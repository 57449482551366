export class PaginationResponseModel {
    error: string;
    isSinglResult: boolean;
    pagingDetails?: PaginationResponseDetails;
    result?: any;
    results?: any;
}

export class PaginationResponseDetails {
    currentPageNumber: number;
    pageSize: number
    totalRecordsCount: number;
}

