import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { EntityModel } from '../_models/entity.model';
import { AclByEntitiesModel } from '../_models/acl-by-entities.model';
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { enums } from "../../enums/enums";

@Injectable()
export class EntityService {
    constructor(private http: HttpClient, private translate: TranslateService) { }

    private entityName: string = 'Entities';

    getEntities(): Observable<EntityModel[]> {
        const requestUrl = environment.apiUrl + this.entityName + '/GetEntity/';

        return this.http.get<EntityModel[]>(requestUrl).pipe(map((serviceResponse: EntityModel[]) => {

            serviceResponse.forEach(element => {
                element.displayEntryName = this.translate.currentLang == enums.langs.ar ? element.entryNameAr : element.entryName;
            });

            return serviceResponse;
        }));
    }

    getEntity(id: number): Observable<EntityModel> {
        const requestUrl = environment.apiUrl + this.entityName + '/GetEntity/' + id;
        return this.http.get<EntityModel>(requestUrl);
    }

    postEntity(value: EntityModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PostEntity/';
        return this.http.post<any>(requestUrl, value);
    }

    putEntity(value: EntityModel): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/PutEntity';
        return this.http.post<any>(requestUrl, value);
    }

    deleteEntity(id: number): Observable<any> {
        const requestUrl = environment.apiUrl + this.entityName + '/DeleteEntity/' + id;
        return this.http.get<any>(requestUrl);
    }

    getEntitiesByUserId(userId: number, groupId?: number): Observable<AclByEntitiesModel[]> {
        let queryStringParams: string[] = [];
        queryStringParams.push("?userId=" + userId);

        if (groupId != undefined)
            queryStringParams.push("&groupId=" + groupId);

        const requestUrl = environment.apiUrl + this.entityName + '/GetEntitiesByUserId/' + queryStringParams.join('');
        return this.http.get<AclByEntitiesModel[]>(requestUrl)
            .pipe(map((serviceResponse: AclByEntitiesModel[]) => {

                serviceResponse.forEach(element => {
                    element.displayName = this.translate.currentLang == enums.langs.ar ? element.entryNameAr : element.entityName;
                });

                return serviceResponse;
            }));
    }
}
