// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Observable, Subscription } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '../../../core/_config/layout.config';
import { PageConfig } from '../../../core/_config/page.config';
// User permissions
import { NgxPermissionsService } from 'ngx-permissions';
import { EntityService } from '../../../_services/entity.service';
import { AuthService } from '../../../_services/auth.service';
import { enums } from '../../../../enums/enums';
import { AclByEntitiesModel } from '../../../../app/_models/acl-by-entities.model';

@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public variables
	selfLayout: string;
	asideDisplay: boolean;
	asideSecondary: boolean;
	subheaderDisplay: boolean;
	desktopHeaderDisplay: boolean;
	fitTop: boolean;
	fluid: boolean;

	// Private properties
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param menuConfigService: MenuConfifService
	 * @param pageConfigService: PageConfigService
	 * @param htmlClassService: HtmlClassService
	 * @param store
	 * @param permissionsService
	 */
	constructor(
		private entityService: EntityService,
		private authService: AuthService,
		private layoutConfigService: LayoutConfigService,
		private menuConfigService: MenuConfigService,
		private pageConfigService: PageConfigService,
		private htmlClassService: HtmlClassService,
		private permissionsService: NgxPermissionsService) {
		this.loadRolesWithPermissions();

		// register configs by demos
		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
		// this.menuConfigService.loadConfigs(new MenuConfig().configs);
		this.menuConfigService.loadConfigs();


		this.pageConfigService.loadConfigs(new PageConfig().configs);

		// setup element classes
		this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
			// reset body class based on global and page level layout config, refer to html-class.service.ts
			document.body.className = '';
			this.htmlClassService.setConfig(layoutConfig);
		});
		this.unsubscribe.push(subscr);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
		this.selfLayout = objectPath.get(config, 'self.layout');
		this.asideDisplay = objectPath.get(config, 'aside.self.display');
		this.subheaderDisplay = objectPath.get(config, 'subheader.display');
		this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
		this.fitTop = objectPath.get(config, 'content.fit-top');
		this.fluid = objectPath.get(config, 'content.width') === 'fluid';

		// let the layout type change
		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
			setTimeout(() => {
				this.selfLayout = objectPath.get(cfg, 'self.layout');
			});
		});
		this.unsubscribe.push(subscr);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}

	/**
	 * NGX Permissions, init roles
	 */
	loadRolesWithPermissions() {
		let userId: number = this.authService.loggedUser.userID;

		const subscr = this.entityService
			.getEntitiesByUserId(userId)
			.subscribe(response => {
				let permissions: string[] = [];

				response.forEach(element => {
					if (element.menuURL && element.menuURL.split('/').length >= 1) {
						let moduleName: string = element.menuURL.split('/')[1];

						if (element.canView == true)
							permissions.push(moduleName + "-canView");

						if (element.canAdd == true)
							permissions.push(moduleName + "-canAdd");

						if (element.canEdit == true)
							permissions.push(moduleName + "-canEdit");

						if (element.canDelete == true)
							permissions.push(moduleName + "-canDelete");
					}
				});

				// Check Custom Entities

				let custom_entity_names = Object.values(enums.custom_entity_names)

				custom_entity_names.forEach(entity => {
					// if exist
					let foundEntity: AclByEntitiesModel = response.find(x => x.menuURL != null && x.menuURL.indexOf(entity) != -1)

					//If entity exist and has permission and not already exist at list
					if (foundEntity && foundEntity.canView == true && permissions.indexOf(entity + "-canView") == -1)
						permissions.push(entity + "-canView");

					//If entity exist and has permission and not already exist at list
					if (foundEntity && foundEntity.canAdd == true && permissions.indexOf(entity + "-canAdd") == -1)
						permissions.push(entity + "-canAdd");

					//If entity exist and has permission and not already exist at list
					if (foundEntity && foundEntity.canEdit == true && permissions.indexOf(entity + "-canEdit") == -1)
						permissions.push(entity + "-canEdit");

					//If entity exist and has permission and not already exist at list
					if (foundEntity && foundEntity.canDelete == true && permissions.indexOf(entity + "-canDelete") == -1)
						permissions.push(entity + "-canDelete");

				});

				this.permissionsService.addPermission(permissions);
			});

		this.unsubscribe.push(subscr);
	}
}
