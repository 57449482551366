// Angular
import { Component, HostBinding, OnInit, Input, Inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslationService } from '../../../../../core/_base/layout';
import { DOCUMENT } from '@angular/common';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
	dir: string;
}

@Component({
	selector: 'kt-language-selector',
	templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {
	// Public properties
	@HostBinding('class') classes = '';
	@Input() iconType: '' | 'brand';


	languages: LanguageFlag[] = [
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/260-united-kingdom.svg',
			dir: 'ltr'
		},
		{
			lang: 'ar',
			name: 'العربية',
			flag: './assets/media/flags/133-saudi-arabia.svg',
			dir: 'rtl'
		}
	];

	defaultLanguage: LanguageFlag = this.languages[0];
	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 */
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private translationService: TranslationService,
		private router: Router) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.setSelectedLanguage();
	}

	switchAndRefreshPage(lang) {
		localStorage.setItem('language', lang);

		//Refresh page for load new selected language.
		document.location.href = window.location.href;
	}

	/**
	 * Set language
	 *
	 * @param lang: any
	 */
	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.defaultLanguage = language;
			} else {
				language.active = false;
			}
		});

		this.translationService.setLanguage(lang);

		this.switchLanguageDirection();
	}

	/**
	 * Set selected language
	 */
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}

	//According to selected language, switch position all component.
	switchLanguageDirection() {
		const htmlEl = this.document.getElementsByTagName('HTML')[0];

		//Check, there is no changing. Return.
		if (document.getElementById("style-" + this.defaultLanguage.dir || '') != null) {
			return;
		}

		//Remove attribute from html tag brefore direction switching
		htmlEl.removeAttribute("lang");
		htmlEl.removeAttribute("dir");
		htmlEl.removeAttribute("direction");
		htmlEl.removeAttribute("style");

		//Create new style link and configure properties after append the style link to page.
		const newLinkEl = this.document.createElement('link');
		newLinkEl.id = "style-" + this.defaultLanguage.dir;
		newLinkEl.rel = 'stylesheet';
		newLinkEl.href = './assets/css/' + 'style-' + this.defaultLanguage.dir + '.css';

		const headEl = this.document.getElementsByTagName('head')[0];
		headEl.appendChild(newLinkEl);

		if (this.defaultLanguage.dir == 'ltr') {
			if (document.getElementById("style-rtl") != null) {
				document.getElementById("style-rtl").remove();
			}
		}
		else if (this.defaultLanguage.dir == 'rtl') {

			if (document.getElementById("style-ltr") != null) {
				document.getElementById("style-ltr").remove();
			}

			//Add new attribute to html tag for direction switching.
			htmlEl.setAttribute("lang", this.defaultLanguage.lang);
			htmlEl.setAttribute("dir", this.defaultLanguage.dir);
			htmlEl.setAttribute("direction", this.defaultLanguage.dir);
			htmlEl.setAttribute("style", "direction: " + this.defaultLanguage.dir);
		}
	}
}
