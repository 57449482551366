// Angular
import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from '../../../core/_base/layout';
// Auth
import { AuthNoticeService } from '../../../core/auth';
import { DOCUMENT } from '@angular/common';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
	dir: string;
}

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	headerLogo: string;
	currentLang: string;

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		@Inject(DOCUMENT) private document: Document,) {
	}

	language: LanguageFlag;
	languages: LanguageFlag[] = [
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/260-united-kingdom.svg',
			dir: 'ltr'
		},
		{
			lang: 'ar',
			name: 'العربية',
			flag: './assets/media/flags/133-saudi-arabia.svg',
			dir: 'rtl'
		}
	];

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.headerLogo = this.layoutConfigService.getLogo();

		this.splashScreenService.hide();

		this.changeLanguage(this.translationService.getSelectedLanguage());
	}

	changeLanguage(lang: string) {
		this.translationService.setLanguage(lang);
		this.translationService.setLanguage(lang);
		this.setLanguage(lang);
		this.currentLang = lang;
	}

	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;
			} else {
				language.active = false;
			}
		});

		this.translationService.setLanguage(lang);

		this.switchLanguageDirection();
	}

	//According to selected language, switch position all component.
	switchLanguageDirection() {

		this.translationService.getSelectedLanguage()

		const htmlEl = this.document.getElementsByTagName('HTML')[0];

		//Check, there is no changing. Return.
		if (document.getElementById("style-" + this.language.dir) != null) {
			return;
		}

		//Remove attribute from html tag brefore direction switching
		htmlEl.removeAttribute("lang");
		htmlEl.removeAttribute("dir");
		htmlEl.removeAttribute("direction");
		htmlEl.removeAttribute("style");

		//Create new style link and configure properties after append the style link to page.
		const newLinkEl = this.document.createElement('link');
		newLinkEl.id = "style-" + this.language.dir;
		newLinkEl.rel = 'stylesheet';
		newLinkEl.href = './assets/css/' + 'style-' + this.language.dir + '.css';

		const headEl = this.document.getElementsByTagName('head')[0];
		headEl.appendChild(newLinkEl);

		if (this.language.dir == 'ltr') {
			if (document.getElementById("style-rtl") != null) {
				document.getElementById("style-rtl").remove();
			}
		}
		else if (this.language.dir == 'rtl') {

			if (document.getElementById("style-ltr") != null) {
				document.getElementById("style-ltr").remove();
			}

			//Add new attribute to html tag for direction switching.
			htmlEl.setAttribute("lang", this.language.lang);
			htmlEl.setAttribute("dir", this.language.dir);
			htmlEl.setAttribute("direction", this.language.dir);
			htmlEl.setAttribute("style", "direction: " + this.language.dir);
		}
	}

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
